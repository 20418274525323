import React from "react";
import { Menu, Dropdown, Tooltip } from "antd";
import _t from "../../../languages/translate";
import { ColumnHeightOutlined } from "@ant-design/icons";

const SizeRow = ({ onHandelSize }) => {
  const menu = (
    <Menu>
      <Menu.Item>
        <a
          onClick={() => {
            onHandelSize("large");
          }}
        >
          {_t("strLarger")}
        </a>
      </Menu.Item>
      <Menu.Item>
        <a
          onClick={() => {
            onHandelSize("middle");
          }}
        >
          {_t("strMiddle")}
        </a>
      </Menu.Item>
      <Menu.Item>
        <a
          onClick={() => {
            onHandelSize("small");
          }}
        >
          {_t("strCompact")}
        </a>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Tooltip placement="topLeft" title={_t("strRowSize")} color="#2db7f5">
        <Dropdown overlay={menu} trigger="click" arrow>
          <ColumnHeightOutlined
            style={{ fontSize: 16, marginTop: 5, cursor: "pointer" }}
          />
        </Dropdown>
      </Tooltip>
    </>
  );
};

export default SizeRow;
