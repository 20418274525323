import { _URL } from "./../config.json";
import http from "./http";

const apiEndpoint = _URL + "/api/ReportTemp";

export function reportTempColumnsByKey(key) {
  return http.post(apiEndpoint + "/GetColumns", key);
}

export function getAllReportTemp() {
  return http.get(`${apiEndpoint}`);
}

export function getStockTypes() {
  return http.get(`${apiEndpoint}/GetInStockTypeFilter`);
}
