import React, { useState, useEffect, useContext } from "react";
import { saveAs } from "file-saver";
import _t from "../../../languages/translate";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import Printing from "../../helper/Print/Printing";
import {
  Button,
  Drawer,
  message,
  Space,
  Modal,
  Popover,
  Tooltip,
  Divider,
} from "antd";
import {
  SearchOutlined,
  PrinterOutlined,
  MoreOutlined,
  FileExcelOutlined,
  PartitionOutlined,
  FilePdfOutlined,
  LinkOutlined,
} from "@ant-design/icons";
import FilterInventoryRep from "../../helper/Modal/FilterInventoryRep";
import DataTable, { pageSizeKey } from "../../helper/dataTable/dataTable";
import {
  getInventoryReport,
  getInventoryHtml,
  getInventoryXls,
  getInventoryPdf,
} from "../../../services/Reports/ReportService";
import { LanguageContext } from "../../../languages/Language";

const InventoryReport = () => {
  let location = useLocation();
  const [dataSource, setDataSource] = useState([]);
  const [reportFilter, setReportFilter] = useState({});
  const [columns, setColumns] = useState([]);
  const [loadData, setLoadData] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visiblePrDrawer, setVisiblePrDrawer] = useState(false);
  const [objSearchState, setObjSearchState] = useState(null);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [meta, setMeta] = useState({});
  const [html, setHtml] = useState("");
  const savedPageSize = localStorage.getItem(pageSizeKey);
  const { userLanguage } = useContext(LanguageContext);

  const handelLinkDataObj = () => {
    let dataObj = JSON.parse(localStorage.getItem("linkObj"));
    setObjSearchState(dataObj);
    localStorage.removeItem("linkObj");
  };

  useEffect(() => {
    const fetchData = async () => {
      if (localStorage.getItem("linkObj") !== null) {
        handelLinkDataObj();
      }
      const query = new URLSearchParams(location.search);
      if (savedPageSize && !query.has(pageSizeKey))
        query.set(pageSizeKey, savedPageSize);
      try {
        setLoadData(true);
        if (objSearchState !== null) {
          const { data: responce } = await getInventoryReport(
            query.toString(),
            objSearchState
          );
          
          if (responce.data.dataSource.length > 0) {
            setColumns(responce.data.columns);
            setDataSource(responce.data.dataSource);
            setReportFilter(responce.data.reportFilter);
            setMeta(responce.meta);
          } else {
            setDataSource([]);
            message.info(_t("msgThereIsNoData"), 3);
          }
        }
      } catch (error) {
        if (error.response) {
          message.error(
            error.response.data?.message || _t("msgUnknownError"),
            3
          );
        } else if (error.request) {
          message.error(_t("msgServerDisconnected"), 3);
        } else {
          message.error(_t("msgUnknownError"), 3);
        }
      } finally {
        setLoadData(false);
      }
    };

    fetchData();
  }, [
    location.search,
    savedPageSize,
    objSearchState,
    setDataSource,
    setMeta,
    setLoadData,
  ]);

  const onClosePrDrawer = () => {
    setVisiblePrDrawer(false);
  };

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const handelInStockRep = async (data) => {
    setLoadData(true);
    data.columns = columnsToObject();
    setObjSearchState(data);
    setVisible(false);
  };

  const columnsToObject = () => {
    const savedColumns = localStorage.getItem("InStockRep");
    const parsedColumns = savedColumns && JSON.parse(savedColumns);
    const filteredColumns = Array.isArray(parsedColumns)
      ? parsedColumns.filter(
          (c) => c.key !== "edit" && c.key !== "reportTempMenu"
        )
      : columns;
    return filteredColumns;
  };

  const onPrint = async (obj) => {
    try {
      obj.columns = columnsToObject();
      const query = new URLSearchParams(location.search);
      const data = await getInventoryHtml(
        query.toString(),
        objSearchState,
        userLanguage === "ar" ? 0 : 1
      );
      setHtml(data.data);
      setVisiblePrDrawer(true);
    } catch (error) {
      if (error.response) {
        message.error(error.response.data?.message || _t("msgUnknownError"), 3);
      } else if (error.request) {
        message.error(_t("msgServerDisconnected"), 3);
      } else {
        message.error(_t("msgUnknownError"), 3);
      }
    } finally {
      setLoadData(false);
    }
  };

  const onExportToXls = async (obj) => {
    const hide = message.loading(_t("strDataExporting"), 0);
    try {
      obj.columns = columnsToObject();
      const query = new URLSearchParams(location.search);
      const { data, headers } = await getInventoryXls(
        query.toString(),
        obj,
        userLanguage === "ar" ? 0 : 1
      );
      const byteCharacters = atob(data.data);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
      saveAs(blob, "InventoryReport.xlsx");
    } catch (error) {
      if (error.response) {
        message.error(error.response.data?.message || _t("msgUnknownError"), 3);
      } else if (error.request) {
        message.error(_t("msgServerDisconnected"), 3);
      } else {
        message.error(_t("msgUnknownError"), 3);
      }
    } finally {
      setTimeout(hide);
    }
  };

  const onExportToPDF = async (obj) => {
    const hide = message.loading(_t("strDataExporting"), 0);
    try {
      obj.columns = columnsToObject();
      const query = new URLSearchParams(location.search);
      const { data, headers } = await getInventoryPdf(
        query.toString(),
        obj,
        userLanguage === "ar" ? 0 : 1
      );
      const byteCharacters = atob(data.data);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "application/pdf" });
      saveAs(blob, "InventoryReport.pdf");
      setTimeout(hide);
      console.log(data);
    } catch (error) {
      if (error.response) {
        message.error(error.response.data?.message || _t("msgUnknownError"), 3);
      } else if (error.request) {
        message.error(_t("msgServerDisconnected"), 3);
      } else {
        message.error(_t("msgUnknownError"), 3);
      }
    } finally {
      setTimeout(hide);
    }
  };

  //**.start >> Drawing links ********************
  const linksColumns = [
    {
      key: "reportTempMenu",
      align: "center",
      title: <LinkOutlined />,
      width: 50,
      fixed: "left",
      render: (item) => {
        return item.reportTempMenu.length !== 0 ? (
          <Popover placement="bottomRight" content={() => linkCont(item)}>
            <Button
              type="link"
              icon={<MoreOutlined style={{ color: "#3a7df2", fontSize: 16 }} />}
            />
          </Popover>
        ) : undefined;
      },
    },
  ];

  const linkCont = (item) => (
    <div>
      {item.reportTempMenu.map((e) =>
        e.type === 0 ? (
          <p key={e.key}>
            <Link target="_blank" rel="noopener noreferrer" to={e.url}>
              <label style={{ cursor: "pointer" }}>{e.name}</label>
            </Link>
          </p>
        ) : (
          <p key={e.key}>
            <Link
              target="_blank"
              rel="noopener noreferrer"
              to={e.url}
              onClick={() => handelLinkObj(item, e.reportKey)}
            >
              <label style={{ cursor: "pointer" }}>{e.name}</label>
            </Link>
          </p>
        )
      )}
    </div>
  );

  const handelLinkObj = (item, repKey) => {
    reportFilter.itemIds = [item.itemId];
    let obj = {
      reportFilter: reportFilter,
      row: JSON.stringify(item),
      reportKey: repKey,
      timeShift: new Date().getTimezoneOffset(),
    };
    localStorage.setItem("linkObj", JSON.stringify(obj));
  };
  //**.end >> Drawing links ********************

  const customExpandIcon = (props) => {
    if (props.record.children.length < 1) {
      return <span></span>;
    }
    if (props.expanded) {
      return (
        <button
          type="button"
          className="ant-table-row-expand-icon ant-table-row-expand-icon-expanded"
          onClick={(e) => {
            props.onExpand(props.record, e);
          }}
        />
      );
    } else {
      return (
        <button
          type="button"
          className="ant-table-row-expand-icon ant-table-row-expand-icon-collapsed"
          onClick={(e) => {
            props.onExpand(props.record, e);
          }}
        />
      );
    }
  };

  const expandAll = (
    <>
      <Tooltip
        placement="topLeft"
        title={_t("strShowAllDetails")}
        color="#2db7f5"
      >
        <PartitionOutlined
          style={{ fontSize: 16, marginTop: 5 }}
          onClick={() => {
            setExpandedRowKeys(
              expandedRowKeys.length === dataSource.length
                ? []
                : dataSource.map((d) => d.id)
            );
          }}
        />
      </Tooltip>
      <Divider type="vertical" />
    </>
  );

  return (
    <>
      <Drawer
        width={720}
        onClose={onClosePrDrawer}
        visible={visiblePrDrawer}
        title={_t("strPrintPreview")}
      >
        <Printing htmlRep={html} />
      </Drawer>

      <Modal
        title={_t("strStockReport")}
        visible={visible}
        onCancel={() => onClose()}
        footer={null}
        width={800}
      >
        <FilterInventoryRep
          onFilterData={objSearchState?.reportFilter !== null ? objSearchState?.reportFilter : undefined}
          onHandelData={(data) => handelInStockRep(data)}
        />
      </Modal>

      <DataTable
        rowKey="id"
        hideSearch
        loading={loadData}
        dataSource={dataSource}
        columns={[...linksColumns, ...columns]}
   
        saveColumnsProps="InStockRep"
        expandIcon={(treeBran) => customExpandIcon(treeBran)}
        meta={meta}
        extraActions={expandAll}
        expandedRowKeys={expandedRowKeys}
        onExpand={(expanded, record) => {
          console.log(expanded, record.id);
          if (!expanded)
            setExpandedRowKeys((state) => state.filter((e) => e !== record.id));
          else setExpandedRowKeys((state) => [...state, record.id]);
        }}
        extra={
          <Space size="small">
            <Button
              size="small"
              type="primary"
              onClick={showDrawer}
              style={{ width: 150, borderRadius: 5 }}
              icon={<SearchOutlined />}
            >
              {_t("strSearch")}
            </Button>
            <Divider type="vertical" />
            <Button
              onClick={() => onPrint(objSearchState)}
              size="small"
              type="link"
              style={{
                width: 100,
                borderRadius: 5,
              }}
              icon={<PrinterOutlined />}
              disabled={dataSource.length !== 0 ? false : true}
            >
              {" "}
              {_t("strPrint")}
            </Button>
            <Divider type="vertical" />
            <Button
              size="small"
              onClick={() => {
                onExportToXls(objSearchState);
              }}
              type="link"
              style={{ width: 100, borderRadius: 5 }}
              disabled={dataSource.length !== 0 ? false : true}
              icon={<FileExcelOutlined style={{ fontSize: 16 }} />}
            >
              {_t("strExportToExcel")}
            </Button>
            <Divider type="vertical" />
            <Button
              size="small"
              onClick={() => {
                onExportToPDF(objSearchState);
              }}
              type="link"
              style={{ width: 100, borderRadius: 5 }}
              disabled={dataSource.length !== 0 ? false : true}
              icon={<FilePdfOutlined style={{ fontSize: 16 }} />}
            >
              {_t("strExportToPDF")}
            </Button>
          </Space>
        }
        bordered
        rowClassName={(record, index) =>
          record.rowType === "Total"
            ? "treeMain-row"
            : record.rowType === "Detail"
            ? "treeDetails-row"
            : index % 2 === 0
            ? "table-row-light"
            : "table-row-dark"
        }
      />
    </>
  );
};

export default InventoryReport;
