import { LanguageContext } from "./Language";
import React, { useContext, useEffect } from "react";
import { languageOptions } from "./langOptions";
import { setLanguage } from "../services/Authorization/usersService";
import { refreshToken } from "../services/Authorization/authService";
import { Select } from "antd";

const { Option } = Select;

export default function LanguageSelector({ onHandelLang }) {
  const { userLanguage, userLanguageChange, direction } = useContext(
    LanguageContext
  );

  const langChange = async (value) => {
    userLanguageChange(value);
    try {
      await setLanguage(value); // save lang
      refreshToken(); // Refresh Token
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    let defaultLanguage = window.localStorage.getItem("lang");
    onHandelLang(defaultLanguage);
    if (!defaultLanguage) {
      defaultLanguage = window.navigator.language.substring(0, 2);
    }
    userLanguageChange(defaultLanguage);
  }, [userLanguageChange]);

  return (
    <div
      className={userLanguage === "ar" ? "lang-text-left" : "lang-text-right"}
    >
      <Select
        onChange={langChange}
        value={userLanguage}
        bordered={false}
        style={{ color: "#78909c" }}
      >
        {Object.entries(languageOptions).map(([id, name]) => (
          <Option key={id} value={id}>
            {name}
          </Option>
        ))}
      </Select>
    </div>
  );
}
