import React from "react";
import { Resizable } from "react-resizable";

const ResizableTitle = ({ onResize, width, direction, ...restProps }) => {
  if (!width) {
    return <th {...restProps} />;
  }

  return (
    <Resizable
      width={width}
      height={0}
      handle={(d) => (
        <span
          data-handle={d}
          style={d === "sw" ? { right: "auto", left: -5 } : {}}
          className="react-resizable-handle"
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      )}
      onResize={onResize}
      resizeHandles={direction === "rtl" ? ["sw"] : ["se"]}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...restProps} />
    </Resizable>
  );
};

export default ResizableTitle;
