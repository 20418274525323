import React, { useEffect, useState } from "react";
import { Card, Row, Space, Divider, Col, Empty } from "antd";
import SortColumns from "./sortColumns";
import SizeRow from "./sizeRow";
import ResizableTable from "./../resizableTable/resizableTable";
import { useHistory } from "react-router-dom";
import { getSortValue } from "./sortFunctions";
import _t from "../../../languages/translate";
import { getColumns, saveColumns } from "./cachColumns";

export const pageKey = "pageNumber",
  pageSizeKey = "pageSize",
  tablePageSizes = ["10", "20", "50", "100", "200", "500", "800", "1000"],
  searchKey = "search",
  sortKey = "sort";

export default function DataTable({
  columns,
  onColumnsChange,
  saveColumnsProps,
  extra,
  reload,
  pageSize,
  extraActions,
  hideFunctions,
  hideController,
  meta,
  hideStyle,
  hidePagination,
  loading,
  dataSource,
customSize,
  ...rest
}) {

  let history = useHistory();
  const [editableCols, setEditableCol] = useState([]);
  const [fixedColLeft, setFixedColLeft] = useState([]);
  const [fixedColRight, setFixedColRight] = useState([]);
  
  const [rowSize, setRowSize] = useState(!customSize ? "small" : customSize);




  useEffect(() => {
    const nextColumns =
      typeof saveColumnsProps === "string"
        ? getColumns(columns, saveColumnsProps)
        : columns;
    setEditableCol(nextColumns.filter((col) => !col.fixed));
    setFixedColLeft(nextColumns.filter((col) => col.fixed === "left"));
    setFixedColRight(nextColumns.filter((col) => col.fixed === "right"));
  }, [
    columns,
    saveColumnsProps,
    setEditableCol,
    setFixedColLeft,
    setFixedColRight,
  ]);

  const handleColumnsChange = (updatedColumn) => {
    const nextColumns = editableCols.map((col) =>
      col.key === updatedColumn.key ? updatedColumn : col
    );
    setEditableCol(nextColumns);
    cachColumns(nextColumns);
  };

  const handleHideChange = (checked, key) => {
    const updatedColumns = editableCols.map((col) =>
      col.key === key ? { ...col, hidden: checked } : col
    );
    setEditableCol(updatedColumns);
    cachColumns(updatedColumns);
  };

  const handleSortChange = (updatedColumns) => {
    setEditableCol(updatedColumns);
    cachColumns(updatedColumns);
  };

  const cachColumns = (cols) => {
    if (typeof saveColumnsProps === "string") {
      saveColumns(
        [...fixedColLeft, ...cols, ...fixedColRight],
        saveColumnsProps
      );
    }
  };

  const sortedColumns = editableCols.filter((col) => !col.hidden);

  const handleTableChange = (pagination, _, sorter) => {
    const query = new URLSearchParams(history.location.search);

    const sortValue = getSortValue(sorter);
    const { current, pageSize } = pagination;

    if (sortValue.length > 0) query.set(sortKey, sortValue);
    else query.delete(sortKey);

    if (current && current > 1) query.set(pageKey, current.toString());
    else query.delete(pageKey);

    if (pageSize) query.set(pageSizeKey, pageSize.toString());
    else query.delete(pageSizeKey);

    history.push({
      pathname: history.location.pathname,
      search: query.toString(),
    });
  };

  const handlePageSize = (size) => {
    localStorage.setItem(pageSizeKey, size.toString());
  };

  const getPageSize = () => {
    const savedPageSize = localStorage.getItem(pageSizeKey);
    const urlPageSize = new URLSearchParams(history.location.search).get(
      pageSizeKey
    );

    if (urlPageSize !== null) return Number(urlPageSize);

    if (savedPageSize !== null) return Number(savedPageSize);

    return meta?.pageSize ? meta?.pageSize : 10;
  };

  const clearColumnsCash = () => {
    setEditableCol(columns.filter((col) => !col.fixed));
    setFixedColLeft(columns.filter((col) => col.fixed === "left"));
    setFixedColRight(columns.filter((col) => col.fixed === "right"));
    cachColumns(columns);
  };

  return (
    <>
      {!hideController ? (
        <Row className="bgHeader">
          <Col span={16}>{extra}</Col>

          {!hideFunctions ? (
            <Col span={8} style={{ textAlign: "end" }}>
              <Space size="small">
                {reload}

                {extraActions}

                <SizeRow onHandelSize={(data) => setRowSize(data)} />
                <Divider type="vertical" />

                <SortColumns
                  onSort={handleSortChange}
                  columns={editableCols}
                  onHideClick={handleHideChange}
                  onClearCash={clearColumnsCash}
                />
              </Space>
            </Col>
          ) : undefined}
        </Row>
      ) : undefined}
      <div className={!hideStyle ? "bgFooter" : ""}>
        <Card bodyStyle={{ padding: 0 }} loading={loading} bordered={false}>
          {meta?.totalRecords || meta?.totalRecords ===0 ? (
            <Row>
              <ResizableTable
                expandIconColumnIndex={1}
                {...rest}
                columns={[...fixedColLeft, ...sortedColumns, ...fixedColRight]}
                onColumnsChange={handleColumnsChange}
                size={rowSize}                     
                scroll={{x:900,y:"70vh"}}
                dataSource={dataSource}
                onChange={handleTableChange}
                pagination={
                  !hidePagination
                    ? {
                      current: meta?.pageNumber,
                      total: meta?.totalRecords,
                      showTotal: (total, range) => `${range[0]} - ${range[1]}  ${_t("strFrom")} ${total}`,
                      pageSize: getPageSize(),
                      pageSizeOptions:
                        pageSize !== undefined ? pageSize : tablePageSizes,
                      showSizeChanger: true,
                      hideOnSinglePage: false,
                      defaultCurrent: meta?.pageNumber,
                      onShowSizeChange: (_, size) => handlePageSize(size),
                    }
                    : false
                }
              />
            </Row>
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
        </Card>
      </div>
    </>
  );
}
