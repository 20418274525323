import React, { useContext, useState, useEffect, useRef } from 'react';
import 'antd/dist/antd.css';
import _t from '../../../languages/translate'
import { InputNumber, Input, Form, Switch } from 'antd';
import { EditableContext } from "./editableRow"
import EditableTableSelect from "./editableTableSelect";
import { CloseOutlined, CheckOutlined} from "@ant-design/icons";
const { Search } = Input;
const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    opt,
    handleSave,
    column,
    ...restProps
}) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);
    const form = useContext(EditableContext);
    let childNode = children;

    useEffect(() => {
        if (editing) {
            inputRef.current?.focus();
        }
    }, [editing]);



    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({ [dataIndex]: record[dataIndex] })
    };

    const save = async () => {
        try {
            const values = await form.validateFields();
            toggleEdit();
            handleSave({ ...record, ...values }, dataIndex, column.type);
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    };

    const handelSearch = value => {
        console.log(value);
    }



    const getField = () => {
        const props = column.fieldProps || {};
        const rules = column.validationRules || [];
        switch (column.type) {
            case "_search":
                return <Form.Item style={{ margin: 0 }} name={dataIndex} rules={rules}>
                    <Search {...props} ref={inputRef} onBlur={save} onSearch={handelSearch} size="small" />
                </Form.Item>
            case "_select":
                return <Form.Item style={{ margin: 0 }} name={dataIndex} rules={rules}>
                    <EditableTableSelect ref={inputRef} {...props} size="small" onPressEnter={save} onBlur={save} allowClear={true} placeholder="Please Select" showSearch={true} />
                </Form.Item>
            case "_number":
                return <Form.Item style={{ margin: 0 }} name={dataIndex} rules={rules}>
                    <InputNumber {...props} ref={inputRef} size="small" onPressEnter={save} onBlur={save} />
                </Form.Item>
            case "_bool":
                return <Form.Item style={{ margin: 0 }} name={dataIndex} valuePropName="checked">
                    <Switch {...props} ref={inputRef}  onPressEnter={save} onBlur={save} size="small" checkedChildren={<CloseOutlined style={{fontSize:8}} />} unCheckedChildren={<CheckOutlined style={{fontSize:8}} />}/>
                </Form.Item>
            default:
                return <Form.Item style={{ margin: 0 }} name={dataIndex} rules={rules}>
                    <Input ref={inputRef} size="small" onPressEnter={save} onBlur={save} />
                </Form.Item>
        }
    }

    if (editable) {
        childNode = editing ? getField() : (
            <div
                className="editable-cell-value-wrap"
                style={{
                    paddingRight: 24,
                }}
                onClick={toggleEdit}
            >
                {children}
            </div>
        );
    }

    return <td {...restProps}>{childNode}</td>;
};

export default EditableCell;
