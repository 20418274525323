import React, { useState, useEffect } from "react";
import {
  Form,
  Row,
  Col,
  Button,
  Input,
  Select,
  Popconfirm,
  InputNumber,
  Card,
  Modal,
  message,
  Checkbox,
} from "antd";
import moment from "moment";
import { permissions } from "../../../services/Authorization/authService";
import { searchItem } from "../../../services/Items/itemService";
import { DeleteTwoTone, PlusOutlined } from "@ant-design/icons";
import InvItemListSelectorForm from "./../../helper/Modal/InvItemListSelector";
import _t from "../../../languages/translate";
import "moment/locale/ar-ly";
const { Option } = Select;
const { Search } = Input;
const { confirm } = Modal;

const MunfEditInOutForm = ({ onHandelData, onSelectEditObj }) => {
  
  const [form] = Form.useForm();
  //******************************//
  const [listState_IN, setListState_IN] = useState(
    onSelectEditObj.munfDetailIns
  );
  const [listState_Out, setListState_Out] = useState(
    onSelectEditObj.munfDetailOuts
  );
  //******************************//
  const [searchData_IN, setSearchData_IN] = useState([]);
  const [searchData_Out, setSearchData_Out] = useState([]);
  const [searchIndex, setSearchIndex] = useState();
  const [visibilityInvItemList_IN, setVisibilityInvItemList_IN] = useState(
    false
  );
  const [visibilityInvItemList_Out, setVisibilityInvItemList_Out] = useState(
    false
  );
  const [disPerm, setDisPerm] = useState(false);
  //******************************// //*******************// Modal List IN //*******************// //******************************//

  const showModalInvItemList_IN = () => {
    setVisibilityInvItemList_IN(true);
  };

  const handleCancelInvItemList_IN = () => {
    setVisibilityInvItemList_IN(false);
  };

  //******************************// //*******************// Modal List Out //*******************// //******************************//

  const showModalInvItemList_Out = () => {
    setVisibilityInvItemList_Out(true);
  };

  const handleCancelInvItemList_Out = () => {
    setVisibilityInvItemList_Out(false);
  };

  //******************************// //*******************// Save //*******************// //******************************//

  const onFinish = async (values) => {
    const checkList_IN = onCheckList_IN(values.munfDetailIns);
    const checkList_Out = onCheckList_Out(values.munfDetailOuts);
    if (checkList_IN) {
      values.munfDetailIns = checkList_IN;
    } else {
      return;
    }
    if (checkList_Out) {
      values.munfDetailOuts = checkList_Out;
    } else {
      return;
    }
    values.count = form.getFieldValue("count");
    onHandelData(values);
  };

  //******************************// //*******************// Search List IN//*******************// //******************************//
  const [objState, setObjState] = useState([]);
  useEffect(() => {
    onSelectEditObj.munfDetailIns.forEach(
      (item) => (item.totalQty = item.qty * onSelectEditObj.count)
    );
    onSelectEditObj.munfDetailOuts.forEach(
      (item) => (item.totalQty = item.qty * onSelectEditObj.count)
    );
    setObjState(onSelectEditObj);
    form.setFieldsValue({
      munfDetailIns: onSelectEditObj.munfDetailIns,
      munfDetailOuts: onSelectEditObj.munfDetailOuts,
      count: onSelectEditObj.count,
    });
    setDisPerm(!permissions._MunfCompositeTemp_Put);
  }, [setObjState, setDisPerm]);

  const onCheckList_IN = (values) => {
    let start = false;
    const removingItemEmpty = values.filter(function (item) {
      return item.itemId !== undefined;
    });

    if (removingItemEmpty.length === 0) {
      message.error(_t("msgListOrderReq"), 5);
      return false;
    }

    removingItemEmpty.map((items, index) => {
      if (items.qty === null || items.qty === 0 || items.qty === undefined) {
        message.error(_t("msgQTYreqOne") + (index + 1) + _t("msgQTYreqTwo"), 3);
        return false;
      }
      start = true;
    });
    if (start) {
      return removingItemEmpty;
    } else {
      return false;
    }
  };

  const codeSearch_IN = async (value, i, add) => {
    try {
      const { data: response } = await searchItem(value, "", false);

      setSearchData_IN(response.data);
      setSearchIndex(i);
      if (response.data.length > 1) {
        showModalInvItemList_IN();
      } else if (response.data.length === 1) {
        const newList = form.getFieldValue("munfDetailIns");
        const OldId = newList[i].id;

        response.data.map((_, i) => {
          response.data[i].itemUnit_MapId =
            response.data[i].itemUnit_Maps[0].id;
          response.data[i].itemId = response.data[i].id;
          response.data[i].itemCode = response.data[i].code;
          response.data[i].itemName = response.data[i].name;
          response.data[i].qty = 1;
          response.data[i].totalQty =
            response.data[i].qty * form.getFieldValue("count"); //objState.count;
          response.data[i].note = "";
          delete response.data[i].id;
        });
        newList.splice(i, 1, ...response.data);
        newList[i].id = OldId;

        form.setFieldsValue({ munfDetailIns: newList });
        setListState_IN(newList);
        let lastItem = newList?.length - 1;
        if (newList[lastItem].itemId !== 0) {
          add();
        }
      } else {
        if (listState_IN.length !== 0) {
          form.setFieldsValue({ munfDetailIns: listState_IN });
          console.log(listState_IN);
          let lastItem = listState_IN.length - 1;
          if (listState_IN[lastItem].itemId !== 0) {
            add();
          }
        }
        return message.error(_t("msgThereIsNoValue"), 3);
      }
    } catch (error) {
      if (error.response !== undefined) {
        if (error.response) {
          message.error(
            error.response.data?.message || _t("msgUnknownError"),
            3
          );
        } else if (error.request) {
          message.error(_t("msgServerDisconnected"), 3);
        } else {
          message.error(_t("msgUnknownError"), 3);
        }
      }
    }
  };

  const nameSearch_IN = async (value, i, add) => {
    try {
      const { data: response } = await searchItem("", value, false);
      setSearchData_IN(response.data);
      setSearchIndex(i);
      if (response.data.length > 1) {
        showModalInvItemList_IN();
      } else if (response.data.length === 1) {
        const newList = form.getFieldValue("munfDetailIns");
        const OldId = newList[i].id;

        response.data.map((_, i) => {
          response.data[i].itemUnit_MapId =
            response.data[i].itemUnit_Maps[0].id;
          response.data[i].itemId = response.data[i].id;
          response.data[i].itemCode = response.data[i].code;
          response.data[i].itemName = response.data[i].name;
          response.data[i].qty = 1;
          response.data[i].totalQty =
            response.data[i].qty * form.getFieldValue("count"); //objState.count;
          response.data[i].note = "";
          delete response.data[i].id;
        });
        newList.splice(i, 1, ...response.data);
        newList[i].id = OldId;

        form.setFieldsValue({ munfDetailIns: newList });
        setListState_IN(newList);
        let lastItem = newList.length - 1;
        if (newList[lastItem].itemId !== 0) {
          add();
        }
      } else {
        if (listState_IN.length !== 0) {
          form.setFieldsValue({ munfDetailIns: listState_IN });
          console.log(listState_IN);
          let lastItem = listState_IN.length - 1;
          if (listState_IN[lastItem].itemId !== 0) {
            add();
          }
        }
        return message.error(_t("msgThereIsNoValue"), 3);
      }
    } catch (error) {
      if (error.response) {
        if (error.response) {
          message.error(
            error.response.data?.message || _t("msgUnknownError"),
            3
          );
        } else if (error.request) {
          message.error(_t("msgServerDisconnected"), 3);
        } else {
          message.error(_t("msgUnknownError"), 3);
        }
      }
    }
  };

  const handelDataInvItemList_IN = (data, add) => {
    setVisibilityInvItemList_IN(false);
    const newList = form.getFieldValue("munfDetailIns");
    const OldId = newList[searchIndex]?.id;

    data.map((_, i) => {
      data[i].itemUnit_MapId = data[i].itemUnit_Maps[0].id;
      data[i].itemId = data[i].id;
      data[i].itemCode = data[i].code;
      data[i].itemName = data[i].name;
      data[i].qty = 1;
      data[i].totalQty = data[i].qty * form.getFieldValue("count"); //objState.count;
      data[i].note = "";
      delete data[i].id;
    });
    newList.splice(searchIndex, 1, ...data);
    newList[searchIndex].id = OldId;

    form.setFieldsValue({ munfDetailIns: newList });
    let lastItem = newList.length - 1;
    if (newList[lastItem]?.itemId !== 0) {
      add();
    }
    setListState_IN(newList);
  };

  //******************************// //*******************// Search List Out//*******************// //******************************//

  const onCheckList_Out = (values) => {
    let start = true;
    let totalDistribution = 0;
    const removingItemEmpty = values.filter(function (item) {
      return item.itemId !== undefined;
    });

    if (removingItemEmpty.length === 0) {
      message.error(_t("msgListOrderReq"), 5);
      start = false;
    }
    if (start) {
      removingItemEmpty.map((items, index) => {
        if (items.qty === null || items.qty === "" || items.qty === undefined) {
          message.error(
            _t("msgQTYreqOne") + (index + 1) + _t("msgQTYreqTwo"),
            3
          );
          start = false;
        }
      });
    }
    if (start) {
      removingItemEmpty.map((items, index) => {
        totalDistribution = totalDistribution + items.distributionRatio;
        if (
          items.distributionRatio === null ||
          items.distributionRatio === 0 ||
          items.distributionRatio === undefined
        ) {
          message.error(
            _t("msgDistributionReqOne") +
              (index + 1) +
              _t("msgDistributionReqTwo"),
            5
          );
          start = false;
        }
      });
    }
    if (start) {
      if (totalDistribution > 100 || totalDistribution < 100) {
        message.error(
          _t("msgDistributionRatio") +
            " " +
            totalDistribution +
            "%" +
            " " +
            _t("msgDistributionRatio2"),
          5
        );
        start = false;
      }
    }

    if (start) {
      return removingItemEmpty;
    } else {
      return false;
    }
  };

  const codeSearch_Out = async (value, i, add) => {
    try {
      const { data: response } = await searchItem(value, "", false);

      setSearchData_Out(response.data);
      setSearchIndex(i);
      if (response.data.length > 1) {
        showModalInvItemList_Out();
      } else if (response.data.length === 1) {
        const newList = form.getFieldValue("munfDetailOuts");
        const OldId = newList[i].id;

        response.data.map((_, i) => {
          response.data[i].itemUnit_MapId =
            response.data[i].itemUnit_Maps[0].id;
          response.data[i].itemId = response.data[i].id;
          response.data[i].itemCode = response.data[i].code;
          response.data[i].itemName = response.data[i].name;
          response.data[i].qty = 1;
          response.data[i].totalQty =
            response.data[i].qty * form.getFieldValue("count"); //objState.count;
          response.data[i].note = "";
          delete response.data[i].id;
        });
        newList.splice(i, 1, ...response.data);

        newList[i].id = OldId;

        form.setFieldsValue({ munfDetailOuts: newList });
        setListState_Out(newList);

        let lastItem = newList?.length - 1;
        if (newList[lastItem].itemId !== 0) {
          add();
        }
      } else {
        if (listState_Out.length !== 0) {
          form.setFieldsValue({ munfDetailOuts: listState_Out });

          let lastItem = listState_Out.length - 1;
          if (listState_Out[lastItem].itemId !== 0) {
            add();
          }
        }
        return message.error(_t("msgThereIsNoValue"), 3);
      }
    } catch (error) {
      if (error.response !== undefined) {
        if (error.response) {
          message.error(
            error.response.data?.message || _t("msgUnknownError"),
            3
          );
        } else if (error.request) {
          message.error(_t("msgServerDisconnected"), 3);
        } else {
          message.error(_t("msgUnknownError"), 3);
        }
      }
    }
  };

  const nameSearch_Out = async (value, i, add) => {
    try {
      const { data: response } = await searchItem("", value, false);
      setSearchData_Out(response.data);
      setSearchIndex(i);
      if (response.data.length > 1) {
        showModalInvItemList_Out();
      } else if (response.data.length === 1) {
        const newList = form.getFieldValue("munfDetailOuts");
        const OldId = newList[i].id;

        response.data.map((_, i) => {
          response.data[i].itemUnit_MapId =
            response.data[i].itemUnit_Maps[0].id;
          response.data[i].itemId = response.data[i].id;
          response.data[i].itemCode = response.data[i].code;
          response.data[i].itemName = response.data[i].name;
          response.data[i].qty = 1;
          response.data[i].totalQty =
            response.data[i].qty * form.getFieldValue("count"); //objState.count;
          response.data[i].note = "";
          delete response.data[i].id;
        });
        newList.splice(i, 1, ...response.data);
        newList[i].id = OldId;

        form.setFieldsValue({ munfDetailOuts: newList });
        setListState_Out(newList);
        let lastItem = newList.length - 1;
        if (newList[lastItem].itemId !== 0) {
          add();
        }
      } else {
        if (listState_Out.length !== 0) {
          form.setFieldsValue({ munfDetailOuts: listState_Out });
          let lastItem = listState_Out.length - 1;
          if (listState_Out[lastItem].itemId !== 0) {
            add();
          }
        }
        return message.error(_t("msgThereIsNoValue"), 3);
      }
    } catch (error) {
      if (error.response) {
        if (error.response) {
          message.error(
            error.response.data?.message || _t("msgUnknownError"),
            3
          );
        } else if (error.request) {
          message.error(_t("msgServerDisconnected"), 3);
        } else {
          message.error(_t("msgUnknownError"), 3);
        }
      }
    }
  };

  const handelDataInvItemList_Out = (data, add) => {
    setVisibilityInvItemList_Out(false);
    const newList = form.getFieldValue("munfDetailOuts");
    const OldId = newList[searchIndex]?.id;

    data.map((_, i) => {
      data[i].itemUnit_MapId = data[i].itemUnit_Maps[0].id;
      data[i].itemId = data[i].id;
      data[i].itemCode = data[i].code;
      data[i].itemName = data[i].name;
      data[i].qty = 1;
      data[i].totalQty = data[i].qty * form.getFieldValue("count"); //objState.count;
      data[i].note = "";
      delete data[i].id;
    });
    newList.splice(searchIndex, 1, ...data);

    newList[searchIndex].id = OldId;

    form.setFieldsValue({ munfDetailOuts: newList });
    let lastItem = newList.length - 1;
    if (newList[lastItem]?.itemId !== 0) {
      add();
    }
    setListState_Out(newList);
  };

  //******************************// //*******************// List State//*******************// //******************************//

  const onDeleteList = (i, remove, typeList) => {
    remove(i);
    if (typeList == "In") {
      const newList = form.getFieldValue("munfDetailIns");
      setListState_IN(newList);
    } else {
      const newList = form.getFieldValue("munfDetailOuts");
      setListState_Out(newList);
    }
  };

  const onChangelist_In = () => {
    const newList = form.getFieldValue("munfDetailIns");
    setListState_IN(newList);
  };

  const onChangelist_Out = () => {
    const newList = form.getFieldValue("munfDetailOuts");
    setListState_Out(newList);
  };

  //******************************// //*******************// Form //*******************// //******************************//

  return (
    <Form
      form={form}
      onFinish={onFinish}
      onValuesChange={(changedValues) => {
        const allValues = form.getFieldsValue();
        const changedKeys = Object.keys(changedValues)[0];
        const count = allValues["count"];
        if (changedKeys === "count") {
          const mdout = allValues["munfDetailOuts"];
          const mdin = allValues["munfDetailIns"];

          if (Array.isArray(mdin) && mdin.length) {
            mdin.forEach((item) =>
              item !== undefined
                ? item.qty !== undefined && item.qty !== null
                  ? (item.totalQty = item.qty * count)
                  : (item.totalQty = 0)
                : undefined
            );
          }

          if (Array.isArray(mdout) && mdout.length) {
            mdout.forEach((item) =>
              item !== undefined
                ? item.qty !== undefined && item.qty !== null
                  ? (item.totalQty = item.qty * count)
                  : (item.totalQty = 0)
                : undefined
            );
          }

          form.setFieldsValue({
            count,
            munfDetailIns: mdin,
            munfDetailOuts: mdout,
          });
        } else if (
          changedKeys === "munfDetailIns" ||
          changedKeys === "munfDetailOuts"
        ) {
          const rowIndex = changedValues[changedKeys].length - 1;
          const changedSubKey = Object.keys(
            changedValues[changedKeys][rowIndex] || {}
          )[0];
          if (!changedSubKey) return;

          const results = allValues;
          if (changedSubKey === "qty") {
            results[changedKeys][rowIndex].totalQty =
              results[changedKeys][rowIndex][changedSubKey] * count;

            form.setFieldsValue({
              count,
              munfDetailIns: results.munfDetailIns,
              munfDetailOuts: results.munfDetailOuts,
            });
          } else if (
            changedSubKey === "totalQty" ||
            changedSubKey === "totalQty"
          ) {
            results[changedKeys][rowIndex].qty =
              results[changedKeys][rowIndex][changedSubKey] / count;
            form.setFieldsValue({
              count,
              munfDetailIns: results.munfDetailIns,
              munfDetailOuts: results.munfDetailOuts,
            });
          }
        }
      }}
      layout="vertical"
    >
      <Row gutter={12}>
        <Col xs={24} md={24}>
          <Form.Item name="count" label={_t("strCount")}>
            <InputNumber min={0} disabled={disPerm} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={12}>
        <Col xs={24} md={24}>
          <Form.Item label={_t("strTheInput")}>
            <Card bodyStyle={{ padding: 10 }} className="scrollCard">
              <Form.List name="munfDetailIns">
                {(fields, { add, remove }) => {
                  return (
                    <div>
                      <Modal
                        visible={visibilityInvItemList_IN}
                        onCancel={() => handleCancelInvItemList_IN()}
                        title={_t("strUnit")}
                        footer={null}
                        destroyOnClose={true}
                        width={1000}
                      >
                        <InvItemListSelectorForm
                          onHandelData={(data) =>
                            handelDataInvItemList_IN(data, add)
                          }
                          onSearchData={searchData_IN}
                        />
                      </Modal>
                      {fields.map((field, i) => (
                        <Row gutter={[8, 8]} key={field.key}>
                          <Col hidden>
                            <Form.Item
                              {...field}
                              defaultValue={0}
                              name={[field.name, "id"]}
                              fieldKey={[field.fieldKey, "id"]}
                              hidden
                            >
                              <Input autoComplete="off" />
                            </Form.Item>
                          </Col>

                          <Col hidden>
                            <Form.Item
                              {...field}
                              defaultValue={0}
                              name={[field.name, "itemId"]}
                              fieldKey={[field.fieldKey, "itemId"]}
                              hidden
                            >
                              <Input autoComplete="off" />
                            </Form.Item>
                          </Col>

                          <Col xs={1} md={1} sm={1}>
                            <Form.Item
                              style={{ textAlign: "center" }}
                              fieldKey={[field.fieldKey, "rowCount"]}
                              style={{ marginBottom: -25 }}
                            >
                              <label>{i + 1}</label>
                            </Form.Item>
                          </Col>
                          <Col xs={7} md={3} sm={3}>
                            <Form.Item
                              {...field}
                              name={[field.name, "itemCode"]}
                              fieldKey={[field.fieldKey, "itemCode"]}
                              style={{ marginBottom: -25 }}
                            >
                              <Search
                                min={0}
                                max={9999}
                                placeholder={_t("strCode")}
                                size="small"
                                autoComplete="off"
                                onSearch={(v) =>
                                  codeSearch_IN(v, field.name, add)
                                }
                                disabled={disPerm}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={8} md={5} sm={5}>
                            <Form.Item
                              {...field}
                              name={[field.name, "itemName"]}
                              fieldKey={[field.fieldKey, "itemName"]}
                              style={{ marginBottom: -25 }}
                              rules={[
                                {
                                  required: false,
                                  message: _t("strFieldRequired"),
                                },
                              ]}
                            >
                              <Search
                                placeholder={_t("strName")}
                                autoComplete="off"
                                size="small"
                                onSearch={(v) =>
                                  nameSearch_IN(v, field.name, add)
                                }
                                disabled={disPerm}
                              />
                            </Form.Item>
                          </Col>

                          <Col hidden>
                            <Form.Item
                              {...field}
                              name={[field.name, "itemUnit_Maps"]}
                              fieldKey={[field.fieldKey, "itemUnit_Maps"]}
                              hidden
                            >
                              <Input size="small" />
                            </Form.Item>
                          </Col>
                          <Col xs={8} md={3} sm={3}>
                            <Form.Item
                              key={field.key}
                              shouldUpdate={(prev, curr) =>
                                prev.munfDetailIns?.length !==
                                curr.munfDetailIns?.length
                              }
                              fieldKey={[field.fieldKey, "itemUnit_MapId"]}
                              style={{ marginBottom: -25 }}
                            >
                              {({ getFieldValue }) => {
                                const list = getFieldValue("munfDetailIns");
                                const unitList =
                                  Array.isArray(list) &&
                                  list[field.name]?.itemUnit_Maps;
                                return (
                                  <Form.Item
                                    {...field}
                                    name={[field.name, "itemUnit_MapId"]}
                                    rules={[{ required: false, message: " " }]}
                                  >
                                    <Select
                                      showSearch
                                      optionFilterProp="children"
                                      placeholder={_t("strUnit")}
                                      autoComplete="off"
                                      size="small"
                                      disabled={disPerm}
                                      onChange={() => {
                                        onChangelist_In();
                                      }}
                                    >
                                      {unitList &&
                                        unitList.map((item) => (
                                          <Option key={item.id} value={item.id}>
                                            {item.itemUnitName}
                                          </Option>
                                        ))}
                                    </Select>
                                  </Form.Item>
                                );
                              }}
                            </Form.Item>
                          </Col>

                          <Col xs={10} md={2} sm={2}>
                            <Form.Item
                              {...field}
                              name={[field.name, "qty"]}
                              fieldKey={[field.fieldKey, "qty"]}
                              rules={[{ required: false, message: " " }]}
                              style={{ marginBottom: -25 }}
                            >
                              <InputNumber
                                style={{ width: "100%" }}
                                min={0}
                                step={0.1}
                                size="small"
                                placeholder={_t("strQuantity")}
                                autoComplete="off"
                                disabled={disPerm}
                                onChange={() => {
                                  onChangelist_In();
                                }}
                              />
                            </Form.Item>
                          </Col>

                          <Col xs={10} md={2} sm={2}>
                            <Form.Item
                              {...field}
                              name={[field.name, "totalQty"]}
                              fieldKey={[field.fieldKey, "totalQty"]}
                              rules={[{ required: false, message: " " }]}
                              style={{ marginBottom: -25 }}
                            >
                              <InputNumber
                                style={{ width: "100%" }}
                                min={0}
                                step={0.1}
                                size="small"
                                placeholder={_t("strTotalQty")}
                                autoComplete="off"
                                disabled={disPerm}
                                onChange={() => {
                                  onChangelist_In();
                                }}
                              />
                            </Form.Item>
                          </Col>

                          <Col xs={10} md={2} sm={2}>
                            <Form.Item
                              {...field}
                              name={[field.name, "wasteRatio"]}
                              fieldKey={[field.fieldKey, "wasteRatio"]}
                              rules={[{ required: false, message: " " }]}
                              style={{ marginBottom: -25 }}
                            >
                              <InputNumber
                                style={{ width: "100%" }}
                                step={0.1}
                                size="small"
                                min={0}
                                max={100}
                                placeholder={"%" + _t("strWasteRatio")}
                                autoComplete="off"
                                disabled={disPerm}
                                onChange={() => {
                                  onChangelist_In();
                                }}
                              />
                            </Form.Item>
                          </Col>

                          <Col xs={12} md={5} sm={5}>
                            <Form.Item
                              {...field}
                              name={[field.name, "note"]}
                              fieldKey={[field.fieldKey, "note"]}
                              rules={[
                                {
                                  required: false,
                                  message: _t("strFieldRequired"),
                                },
                              ]}
                              style={{ marginBottom: -25 }}
                            >
                              <Input
                                min={0}
                                max={9999}
                                size="small"
                                placeholder={_t("strNote")}
                                autoComplete="off"
                                disabled={disPerm}
                                onChange={() => {
                                  onChangelist_In();
                                }}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={2} md={1} sm={1} style={{ paddingTop: 6 }}>
                            <Popconfirm
                              title={_t("strSureToDelete")}
                              onConfirm={() => onDeleteList(i, remove)}
                              disabled={disPerm}
                            >
                              <DeleteTwoTone
                                style={{ fontSize: 20 }}
                                twoToneColor="#ff5252"
                                disabled={disPerm}
                              />
                            </Popconfirm>
                          </Col>
                        </Row>
                      ))}

                      <Form.Item>
                        <Button
                          type="dashed"
                          style={{ marginTop: 5 }}
                          disabled={disPerm}
                          onClick={() => {
                            add();
                          }}
                          block
                        >
                          <PlusOutlined /> {_t("strAdd")}
                        </Button>
                      </Form.Item>
                    </div>
                  );
                }}
              </Form.List>
            </Card>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={12}>
        <Col xs={24} md={24}>
          <Form.Item label={_t("strTheOutput")}>
            <Card bodyStyle={{ padding: 10 }} className="scrollCard">
              <Form.List name="munfDetailOuts">
                {(fields, { add, remove }) => {
                  return (
                    <div>
                      <Modal
                        visible={visibilityInvItemList_Out}
                        onCancel={() => handleCancelInvItemList_Out()}
                        title={_t("strUnit")}
                        footer={null}
                        destroyOnClose={true}
                        width={1000}
                      >
                        <InvItemListSelectorForm
                          onHandelData={(data) =>
                            handelDataInvItemList_Out(data, add)
                          }
                          onSearchData={searchData_Out}
                        />
                      </Modal>

                      {fields.map((field, i) => (
                        <Row gutter={[8, 8]} key={field.key}>
                          <Col hidden>
                            <Form.Item
                              {...field}
                              defaultValue={0}
                              name={[field.name, "id"]}
                              fieldKey={[field.fieldKey, "id"]}
                              hidden
                            >
                              <Input autoComplete="off" />
                            </Form.Item>
                          </Col>
                          <Col hidden>
                            <Form.Item
                              {...field}
                              defaultValue={0}
                              name={[field.name, "itemId"]}
                              fieldKey={[field.fieldKey, "itemId"]}
                              hidden
                            >
                              <Input autoComplete="off" />
                            </Form.Item>
                          </Col>
                          <Col xs={1} md={1} sm={1}>
                            <Form.Item
                              style={{ textAlign: "center" }}
                              fieldKey={[field.fieldKey, "rowCount"]}
                              style={{ marginBottom: -25 }}
                            >
                              <label>{i + 1}</label>
                            </Form.Item>
                          </Col>
                          <Col xs={4} md={3} sm={3}>
                            <Form.Item
                              {...field}
                              name={[field.name, "itemCode"]}
                              fieldKey={[field.fieldKey, "itemCode"]}
                              style={{ marginBottom: -25 }}
                            >
                              <Search
                                placeholder={_t("strCode")}
                                size="small"
                                autoComplete="off"
                                disabled={disPerm}
                                onSearch={(v) =>
                                  codeSearch_Out(v, field.name, add)
                                }
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={4} md={5} sm={5}>
                            <Form.Item
                              {...field}
                              name={[field.name, "itemName"]}
                              fieldKey={[field.fieldKey, "itemName"]}
                              style={{ marginBottom: -25 }}
                              rules={[
                                {
                                  required: false,
                                  message: _t("strFieldRequired"),
                                },
                              ]}
                            >
                              <Search
                                placeholder={_t("strName")}
                                autoComplete="off"
                                size="small"
                                disabled={disPerm}
                                onSearch={(v) =>
                                  nameSearch_Out(v, field.name, add)
                                }
                              />
                            </Form.Item>
                          </Col>
                          <Col hidden>
                            <Form.Item
                              {...field}
                              name={[field.name, "itemUnit_Maps"]}
                              fieldKey={[field.fieldKey, "itemUnit_Maps"]}
                              hidden
                            >
                              <Input size="small" />
                            </Form.Item>
                          </Col>
                          <Col xs={3} md={3} sm={3}>
                            <Form.Item
                              key={field.key}
                              shouldUpdate={(prev, curr) =>
                                prev.munfDetailOuts?.length !==
                                curr.munfDetailOuts?.length
                              }
                              fieldKey={[field.fieldKey, "itemUnit_MapId"]}
                              style={{ marginBottom: -25 }}
                            >
                              {({ getFieldValue }) => {
                                const list = getFieldValue("munfDetailOuts");
                                const unitList =
                                  Array.isArray(list) &&
                                  list[field.name]?.itemUnit_Maps;
                                return (
                                  <Form.Item
                                    {...field}
                                    name={[field.name, "itemUnit_MapId"]}
                                    rules={[{ required: false, message: " " }]}
                                  >
                                    <Select
                                      showSearch
                                      optionFilterProp="children"
                                      placeholder={_t("strUnit")}
                                      autoComplete="off"
                                      size="small"
                                      disabled={disPerm}
                                      onChange={() => {
                                        onChangelist_Out();
                                      }}
                                    >
                                      {unitList &&
                                        unitList.map((item) => (
                                          <Option key={item.id} value={item.id}>
                                            {item.itemUnitName}
                                          </Option>
                                        ))}
                                    </Select>
                                  </Form.Item>
                                );
                              }}
                            </Form.Item>
                          </Col>
                          <Col xs={2} md={2} sm={2}>
                            <Form.Item
                              {...field}
                              name={[field.name, "qty"]}
                              fieldKey={[field.fieldKey, "qty"]}
                              rules={[{ required: false, message: " " }]}
                              style={{ marginBottom: -25 }}
                            >
                              <InputNumber
                                style={{ width: "100%" }}
                                min={0}
                                step={0.1}
                                size="small"
                                disabled={disPerm}
                                placeholder={_t("strQuantity")}
                                autoComplete="off"
                                onChange={() => {
                                  onChangelist_Out();
                                }}
                              />
                            </Form.Item>
                          </Col>

                          <Col xs={10} md={2} sm={2}>
                            <Form.Item
                              {...field}
                              name={[field.name, "totalQty"]}
                              fieldKey={[field.fieldKey, "totalQty"]}
                              rules={[{ required: false, message: " " }]}
                              style={{ marginBottom: -25 }}
                            >
                              <InputNumber
                                style={{ width: "100%" }}
                                step={0.1}
                                min={0}
                                size="small"
                                disabled={disPerm}
                                placeholder={_t("strTotalQty")}
                                autoComplete="off"
                                onChange={() => {
                                  onChangelist_In();
                                }}
                              />
                            </Form.Item>
                          </Col>

                          <Col xs={2} md={2} sm={2}>
                            <Form.Item
                              {...field}
                              name={[field.name, "distributionRatio"]}
                              fieldKey={[field.fieldKey, "distributionRatio"]}
                              rules={[{ required: false, message: " " }]}
                              style={{ marginBottom: -25 }}
                            >
                              <InputNumber
                                style={{ width: "100%" }}
                                step={0.1}
                                min={0}
                                max={100}
                                size="small"
                                disabled={disPerm}
                                placeholder={"%" + _t("strDistribution")}
                                autoComplete="off"
                                onChange={() => {
                                  onChangelist_Out();
                                }}
                              />
                            </Form.Item>
                          </Col>

                          <Col xs={4} md={5} sm={5}>
                            <Form.Item
                              {...field}
                              name={[field.name, "note"]}
                              fieldKey={[field.fieldKey, "note"]}
                              rules={[
                                {
                                  required: false,
                                  message: _t("strFieldRequired"),
                                },
                              ]}
                              style={{ marginBottom: -25 }}
                            >
                              <Input
                                min={0}
                                max={9999}
                                size="small"
                                disabled={disPerm}
                                placeholder={_t("strNote")}
                                autoComplete="off"
                                onChange={() => {
                                  onChangelist_Out();
                                }}
                              />
                            </Form.Item>
                          </Col>

                          <Col xs={2} md={1} sm={1} style={{ paddingTop: 6 }}>
                            <Popconfirm
                              disabled={disPerm}
                              title={_t("strSureToDelete")}
                              onConfirm={() => onDeleteList(i, remove)}
                            >
                              <DeleteTwoTone
                                disabled={disPerm}
                                style={{ fontSize: 20 }}
                                twoToneColor="#ff5252"
                              />
                            </Popconfirm>
                          </Col>
                        </Row>
                      ))}

                      <Form.Item>
                        <Button
                          type="dashed"
                          style={{ marginTop: 5 }}
                          disabled={disPerm}
                          onClick={() => {
                            add();
                          }}
                          block
                        >
                          <PlusOutlined /> {_t("strAdd")}
                        </Button>
                      </Form.Item>
                    </div>
                  );
                }}
              </Form.List>
            </Card>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={12}>
        <Col xs={24} md={24}>
          <Button
            onClick={() => form.submit()}
            type="primary"
            disabled={disPerm}
          >
            {_t("strSave")}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default MunfEditInOutForm;
