import axios from "axios";
import { message } from "antd";
import {
  logout,
  saveJwt,
  refreshJwt,
  getJwt,
  getRefreshJwt,
  saveRefreshJwt,
} from "./Authorization/authService";
import { _URL } from "../config.json";
import _t from "../languages/translate";
const apiEndpoint = `${_URL}/api/Auth`;

let isRefreshRequesting = false;
let requestsToRefresh = [];

export function setJwt(jwt) {
  axios.defaults.headers.common.Authorization = `Bearer ${jwt}`;
 
}

axios.interceptors.response.use(r=>{console.log(r.headers);   return r}, async (error) => {

  const { response, config } = error;
  const status = response && response.status;

  if (status === 401 && response.statusText === "Unauthorized") {
    
    if (!isRefreshRequesting) {
      isRefreshRequesting = true;
      const tokenObj = {
        token: getJwt(),
        refreshToken: getRefreshJwt(),
      };
      axios
        .post(`${apiEndpoint}/refreshtoken`, tokenObj)
        .then(({ data }) => {
          // New token received
         
          console.log("virXX:",data);
          setJwt(data.token);
          saveJwt(data.token);
          saveRefreshJwt(data.refreshToken);
          requestsToRefresh.forEach((cb) => cb(data.token));
        })
        .catch(() => {
          // An error occurred while getting a new token

          requestsToRefresh.forEach((cb) => cb(null));
          logout();
          window.location.href = "/";
        })
        .finally(() => {
          // Clear queue of failed requests
          requestsToRefresh = [];
          isRefreshRequesting = false;
        });
    }
    // The request is waiting for a new token.. [${response.config.url}]
    return new Promise((resolve, reject) => {
      requestsToRefresh.push((token) => {
        if (token) {
          config.headers.Authorization = "Bearer " + token;
          resolve(axios(config));
        }
        reject(error);
      });
    });
  } else if (status === 401 && !isRefreshRequesting) {
    logout();
    window.location.href = "/";
  } else if (status <= 400 && status > 500) {
    message.error(_t("msgServerDisconnected")); /// قطع الاتصال
  } else {
    return Promise.reject(error);
  }
});

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
};
