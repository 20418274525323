import { _URL } from "../../config.json";
import http from "./../http";

const apiEndpoint = _URL + "/api/Report";

// .Inventory
export function getInventoryReport(query, obj) {
  return http.post(`${apiEndpoint}/Inventory?${query}`, obj);
}

export function getInventoryHtml(query, obj, lang) {
  return http.post(`${apiEndpoint}/InventoryHtml?Lang=${lang}`, obj);
}

export function getInventoryXls(query, obj, lang) {
  return http.post(apiEndpoint + `/InventoryXls`, obj);
}

export function getInventoryPdf(query, obj, lang) {
  return http.post(apiEndpoint + `/InventoryPdf?Lang=${lang}`, obj);
}

// .Item Movement
export function getItemMovmentReport(query, obj) {
  return http.post(`${apiEndpoint}/ItemMovment?${query}`, obj);
}

export function getItemMovementHtml(query, obj, lang) {
  return http.post(`${apiEndpoint}/ItemMovmentHtml?Lang=${lang}`, obj);
}

export function getItemMovmentXls(query, obj, lang) {
  return http.post(apiEndpoint + `/ItemMovmentXls`, obj);
}

export function getItemMovmentPdf(query, obj, lang) {
  return http.post(apiEndpoint + `/ItemMovmentPdf?Lang=${lang}`, obj);
}

// .Invoice
export function getInvoicesReport(query, obj) {
  return http.post(`${apiEndpoint}/Invoice?${query}`, obj);
}

export function getInvoiceHtml(query, obj, lang) {
  return http.post(`${apiEndpoint}/InvoiceHtml?Lang=${lang}`, obj);
}

export function getInvoiceXls(query, obj, lang) {
  return http.post(apiEndpoint + `/InvoiceXls`, obj);
}

export function getInvoicePdf(query, obj, lang) {
  return http.post(apiEndpoint + `/InvoicePdf?Lang=${lang}`, obj);
}

// .Order
export function getOrdersReport(query, obj) {
  return http.post(`${apiEndpoint}/Order?${query}`, obj);
}

export function getOrderHtml(query, obj, lang) {
  return http.post(`${apiEndpoint}/OrderHtml?Lang=${lang}`, obj);
}

export function getOrdersXls(query, obj, lang) {
  return http.post(apiEndpoint + `/OrderXls`, obj)
}

export function getOrdersPdf(query, obj, lang) {
  return http.post(apiEndpoint + `/OrderPdf?Lang=${lang}`, obj);
}

// .Munf
export function getMunfReport(query, obj) {
  return http.post(`${apiEndpoint}/Munf?${query}`, obj);
}

export function getMunfHtml(query, obj, lang) {
  return http.post(`${apiEndpoint}/MunfHtml?Lang=${lang}`, obj);
}

export function getMunfXls(query, obj, lang) {
  return http.post(apiEndpoint + `/MunfXls`, obj);
}

export function getMunfPdf(query, obj, lang) {
  return http.post(apiEndpoint + `/MunfPdf?Lang=${lang}`, obj);
}
