import React, { useContext } from "react";
import { Table } from "antd";
import ResizableTitle from "./../dataTable/resizableTitle";
import { LanguageContext } from "./../../../languages/Language";

const ResizableTable = ({ columns, onColumnsChange, ...rest }) => {
  const { direction } = useContext(LanguageContext);
  const components = {
    header: {
      cell: ResizableTitle,
    },
  };

  const handleResize = (index) => (e, { size }) => {
    onColumnsChange({
      ...columns[index],
      width: size.width,
    });
  };

  const columnsWithHeader = columns.map((col, index) => ({
    ...col,
    onHeaderCell: (headerColumn) => ({
      direction,
      width: headerColumn.width,
      onResize: handleResize(index),
    }),
  }));

  return (
    <Table columns={columnsWithHeader} components={components} {...rest} />
  );
};

export default ResizableTable;
