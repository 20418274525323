import React, { useState, useRef } from "react";
import { Form, Input, Table, Button, Space, Spin } from "antd";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
import _t from "../../../languages/translate";
const InvItemListSelectorForm = ({ onHandelData, onSearchData }) => {
  const [form] = Form.useForm();
  const [selected, setSelected] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  //******************************//
  const searchInput = useRef(null);
  const [loading, setLoading] = useState(false);

  //******************************// //*******************// Filter //*******************// //******************************//

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`${_t("strSearch")}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            {_t("strSearch")}
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            {_t("strReset")}
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
    setSearchedColumn("");
  };

  //******************************// //*******************// Form Code //*******************// //******************************//

  const columns = [
    {
      title: _t("strCode"),
      dataIndex: "code",
      key: "code",
      ...getColumnSearchProps("code"),
    },
    {
      title: _t("strName"),
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("name"),
    },

    {
      title: _t("strName2"),
      dataIndex: "name2",
      key: "name2",
      ...getColumnSearchProps("name2"),
    },
  ];

  const rowSelection = {
    onChange: (_, selectedRows) => {
      console.log("selectedRows: ", selectedRows);
      setSelected(selectedRows);
    },
  };

  const handleSubmit = () => {
    onHandelData(selected);
  };

  //******************************// //*******************// Form //*******************// //******************************//

  return (
    <Spin spinning={loading} tip={_t("strLoading")}>
      <Form form={form} onFinish={() => {}}>
        <Table
          rowSelection={rowSelection}
          columns={columns}
          dataSource={onSearchData}
          pagination={false}
          scroll={{ y: "60vh" }}
        />
        <br />
        <Form.Item>
          <Button
            disabled={loading}
            type="primary"
            style={{ width: 100 }}
            onClick={() => {
              setLoading(true);
              handleSubmit();
            }}
          >
            {_t("strInsert")}
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  );
};

export default InvItemListSelectorForm;
