import React, { useEffect, useState } from "react";
import { Menu, Modal, message } from "antd";
import { Link, useHistory } from "react-router-dom";
import {
  LogoutOutlined,
  AppstoreOutlined,
  FormOutlined,
  ContainerOutlined,
  FileSearchOutlined,
  ExperimentOutlined,
  DashboardOutlined,
  PicRightOutlined
} from "@ant-design/icons";
import { Text } from "../../languages/Language";
import { permissions } from "../../services/Authorization/authService";
import { getAllSTP } from "../../services/Transactions/STPService";
import _t from "../../languages/translate";

const { SubMenu } = Menu;
const { confirm } = Modal;

const SideMenu = () => {
  const [openKeys, setOpenKeys] = useState([]);
  const [dynamicDrawSTEP, setDynamicDrawSTEP] = useState([]);
  const rootSubmenuKeys = ["sub1", "sub2", "sub3", "sub4", "sub5"];
  let history = useHistory();
  let munfPatternId = "f6a3cdfa-adcb-456c-90e5-72802238aa6e";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await getAllSTP();
        setDynamicDrawSTEP(data.data);
      } catch (error) {
        if (error.response) {
          message.error(
            error.response.data?.message || _t("msgUnknownError"),
            3
          );
        } else if (error.request) {
          message.error(_t("msgServerDisconnected"), 3);
        } else {
          message.error(_t("msgUnknownError"), 3);
        }
      }
    };
    fetchData();
  }, [setDynamicDrawSTEP]);

  const transactionMenu = dynamicDrawSTEP.map((item) =>
    Object.keys(permissions).join().includes(item.id) ? (
      <Menu.Item key={item.id}>
        <Link to={`/transaction/${item.id}`}>
          <span>{_t("strName") === "Name" ? item.nameEn : item.nameAr}</span>
        </Link>
      </Menu.Item>
    ) : null
  );

  //
  const handelLogOut = () => {
    confirm({
      title: <span style={{ direction: "rtl" }}>{_t("msgAskLogOut")}</span>,
      icon: <LogoutOutlined style={{ color: "#1890ff" }} />,
      onOk() {
        history.push("/logout");
      },
      onCancel() { },
    });
  };

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const showItem =
    permissions._Item_GET || permissions._ItemUnit_GET || permissions._Store_GET || permissions._StoreSettlement
      ? true
      : false;

  const showDefinations =
    permissions._ClientSupplier_GET ||
      permissions._Branch_GET ||
      permissions._Company_GET ||
      permissions._Auth_ResetPassword ||
      permissions._UserRole_GET ||
      permissions._User_GET
      ? true
      : false;

  const showDashBoard =
    permissions._DashBoardRole_GET ||
      permissions._DashBoardTemp_GET
      ? true
      : false;


  const showSecurity =
    permissions._Auth_ResetPassword ||
      permissions._UserRole_GET ||
      permissions._User_GET
      ? true
      : false;

  const showManufacturing =
    permissions._MunfTemplate_GET ||
      permissions._UserRole_GET ||
      permissions._User_GET
      ? true
      : false;

  return (
    <Menu
      mode="inline"
      theme="dark"
      openKeys={openKeys}
      onOpenChange={onOpenChange}
      defaultSelectedKeys={["0"]}
      inlineIndent={20}
    >
      <Menu.Item
        key="0"
        icon={<DashboardOutlined style={{ fontSize: "20px" }} />}
      >
        <Link to="/dashboard">
          <span>
            <Text tid="strDashboard" />
          </span>
        </Link>
      </Menu.Item>

      {Object.keys(permissions).join().includes("_Report") && (
        <SubMenu
          key="sub1"
          title={
            <span>
              <FileSearchOutlined style={{ fontSize: "20px" }} />
              <span>
                <Text tid="strReports" />
              </span>
            </span>
          }
        >
          {permissions._Report_GetItemMovmentReport && (
            <Menu.Item key="1">
              <Link to="/ItemMovementReport">
                <span>
                  <Text tid="strItemMovementReport" />
                </span>
              </Link>
            </Menu.Item>
          )}

          {permissions._Report_GetInStockReport && (
            <Menu.Item key="2">
              <Link to="/StockReport">
                <span>
                  <Text tid="strStockReport" />
                </span>
              </Link>
            </Menu.Item>
          )}

          {permissions._Report_GetStoreTransactionMovmentReport && (
            <Menu.Item key="3">
              <Link to="/StoreTransactionReport">
                <span>
                  <Text tid="strStoreMovementReport" />
                </span>
              </Link>
            </Menu.Item>
          )}

          {permissions._Report_GetOrderTransactionMovmentReport && (
            <Menu.Item key="4">
              <Link to="/OrdersReport">
                <span>
                  <Text tid="strOrdersReport" />
                </span>
              </Link>
            </Menu.Item>
          )}

          {permissions._Report_GetMunfMovmentReport && (
            <Menu.Item key="5">
              <Link to="/ManufacturingOrdersReport">
                <span>
                  <Text tid="strManufacturingOrdersReport" />
                </span>
              </Link>
            </Menu.Item>
          )}
        </SubMenu>
      )}

      {Object.keys(permissions).join().includes("_StoreTransaction") && (
        <SubMenu
          key="sub2"
          title={
            <span>
              <ContainerOutlined style={{ fontSize: "20px" }} />
              <span>
                <Text tid="strInvoices" />
              </span>
            </span>
          }
        >
          {transactionMenu}
        </SubMenu>
      )}

      {Object.keys(permissions).join().includes("_Munf") && (
        <SubMenu
          key="sub3"
          title={
            <span>
              <ExperimentOutlined style={{ fontSize: "20px" }} />
              <span>
                <Text tid="strManufacturing" />
              </span>
            </span>
          }
        >
          {permissions._MunfTemplate_GET && (
            <Menu.Item key="6">
              <Link to="/manufacturingModel">
                <span>
                  <Text tid="strManufacturingModel" />
                </span>
              </Link>
            </Menu.Item>
          )}

          {permissions._Munf_GET && (
            <Menu.Item key="7">
              <Link to={`/manufacturingOrder/${munfPatternId}`}>
                <span>
                  <Text tid="strManufacturingOrder" />
                </span>
              </Link>
            </Menu.Item>
          )}
        </SubMenu>
      )}

      {showItem && (
        <SubMenu
          key="sub4"
          title={
            <span>
              <AppstoreOutlined style={{ fontSize: "20px" }} />
              <span>
                <Text tid="strItemsAndStocks" />
              </span>
            </span>
          }
        >
          {permissions._Item_GET && (
            <Menu.Item key="8">
              <Link to="/Item">
                <span>
                  <Text tid="strItems" />
                </span>
              </Link>
            </Menu.Item>
          )}

          {permissions._ItemUnit_GET && (
            <Menu.Item key="9">
              <Link to="/unit">
                <span>
                  <Text tid="strUnits" />
                </span>
              </Link>
            </Menu.Item>
          )}

          {permissions._Store_GET && (
            <Menu.Item key="10">
              <Link to="/stores">
                <span>
                  <Text tid="strStores" />
                </span>
              </Link>
            </Menu.Item>
          )}

          {permissions._StoreSettlement && (
            <Menu.Item key="17">
              <Link to="/inventoryMatching">
                <span>
                  <Text tid="strInventoryMatching" />
                </span>
              </Link>
            </Menu.Item>
          )}

        </SubMenu>
      )}
      {showDefinations && (
        <SubMenu
          key="sub5"
          title={
            <span>
              <FormOutlined style={{ fontSize: "20px" }} />{" "}
              <span>
                <Text tid="strDefinitions" />
              </span>
            </span>
          }
        >
          {permissions._ClientSupplier_GET && (
            <Menu.Item key="14">
              <Link to="/clientsSupp">
                <span>
                  <Text tid="strClientsSupp" />
                </span>
              </Link>
            </Menu.Item>
          )}

          {permissions._Branch_GET && (
            <Menu.Item key="15">
              <Link to="/branches">
                <span>
                  <Text tid="strBranches" />
                </span>
              </Link>
            </Menu.Item>
          )}
          {permissions._Company && (
            <Menu.Item key="16">
              <Link to="/company">
                <span>
                  <Text tid="strCompanies" />
                </span>
              </Link>
            </Menu.Item>
          )}


          {showDashBoard && (
            <SubMenu
              key="sub6"
              title={
                <span style={{fontSize:13}}>
                  <Text tid="strDashboardDefinition" />
                </span>
              }
            >
              {permissions._DashBoardTemp_GET && (
                <Menu.Item key="11">
                  <Link to="/dashboardTemp">
                    <span>
                      <Text tid="strAnalysisTemp" />
                    </span>
                  </Link>
                </Menu.Item>
              )}
              {permissions._DashBoardRole_GET && (
                <Menu.Item key="13">
                  <Link to="/dashboardRoles">
                    <span>
                      <Text tid="strRoles" />
                    </span>
                  </Link>
                </Menu.Item>
              )}
            </SubMenu>
          )}

          {showSecurity && (
            <SubMenu
              key="sub7"
              title={
                <span>
                  <Text tid="strAuthorization" />
                </span>
              }
            >
              {permissions._Auth_ResetPassword && (
                <Menu.Item key="18">
                  <Link to="/changePassword">
                    <span>
                      <Text tid="strChangePassword" />
                    </span>
                  </Link>
                </Menu.Item>
              )}
              {permissions._UserRole_GET && (
                <Menu.Item key="19">
                  <Link to="/roles">
                    <span>
                      <Text tid="strRoles" />
                    </span>
                  </Link>
                </Menu.Item>
              )}

              {permissions._User_GET && (
                <Menu.Item key="20">
                  <Link to="/users">
                    <span>
                      <Text tid="strUsers" />
                    </span>
                  </Link>
                </Menu.Item>
              )}
            </SubMenu>
          )}
        </SubMenu>
      )}
      {/* <Menu.Item key="20" >
       <Link to="/editableTable">
        <PicRightOutlined style={{ fontSize: "20px" }} />
        <span>
          <Text tid="strEdit" />
        </span>
        </Link>
      </Menu.Item> */}


      <Menu.Item key="21" onClick={handelLogOut}>
        <Link>
          <LogoutOutlined style={{ fontSize: "20px" }} />
          <span>
            <Text tid="strLogout" />
          </span>
        </Link>
      </Menu.Item>
    </Menu>
  );
};

export default SideMenu;
