import React, { useState, useEffect, useRef } from "react";
import { Prompt } from "react-router";
import { useHistory, useParams, Link, useLocation } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  Switch,
  Select,
  message,
  Empty,
  Modal,
  Radio,
  Space,
  Row,
  Col,
  Menu,
  Dropdown,
  Spin,
} from "antd";
import {
  getStoreById,
  editStore,
  getAllParents,
  getNewStoreCode,
} from "../../services/storeService";
import { getAllChildren,getActiveChildren } from "../../services/branchService";
import { ExclamationCircleOutlined, RetweetOutlined } from "@ant-design/icons";
import _t from "../../languages/translate";
import FormBranches from "./fr_Branches";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const StoreForm = ({ onNewStore }) => {
  const IX_Code = useRef(null);
  const IX_Name = useRef(null);
  const [visibility, setVisibility] = useState(false);
  const [loading] = useState(false);
  const [loadData, setLoadData] = useState(true);
  const [form] = Form.useForm();
  let history = useHistory();
  let { id } = useParams();
  let query = useQuery();
  const { Option } = Select;
  const [companyId] = useState(window.localStorage.getItem("comId"));
  const [parentChange, setParentChange] = useState(false);
  const [storeParentOpt, setStoreParentOpt] = useState([]);
  const [branchChildsOpt, setBranchChildsOpt] = useState([]);
  const [branchChildsList, setBranchChildsList] = useState([]);
  

  const [isGoParentId, setIsGoParentId] = useState(null);
  const [isGoBack, setIsGoBack] = useState(true);

  const showModal = () => {
    setVisibility(true);
  };

  const handleCancel = () => {
    setVisibility(false);
  };

  useEffect(() => {
    requestStatus(); // Request status | New | Child | Edit | 😆
    loadAllParents();
    handelBranch("","");
    IX_Name.current.focus();
  }, []);

  const codeGenerator = async (Id) => {
    const { data: newData } = await getNewStoreCode(Id);
    form.setFieldsValue({ code: newData.data.code });
  };

  const requestStatus = () => {
    if (id === "new") {
      codeGenerator(null);
    } // if New item
    if (id === "add") {
      const parentId = history.location.state?.parentId;
      if (!parentId) return;
      codeGenerator(parentId);
      form.setFieldsValue({ parentId: parentId });
      setIsGoParentId(parentId);
    } // if Add child
    if (id !== "new" && id !== "add") {
      setParentChange(true);
    } // if Edit item
  };

  const loadAllParents = async () => {
    try {
      const { data: storeParentData } = await getAllParents();
      setStoreParentOpt(storeParentData.data);
      setLoadData(false);
    } catch (error) {
      if (error.response) {
        message.error(error.response.data?.message || _t("msgUnknownError"), 3);
        setLoadData(false);
      } else if (error.request) {
        message.error(_t("msgServerDisconnected"), 3);
        setLoadData(false);
      } else {
        message.error(_t("msgUnknownError"), 3);
        setLoadData(false);
      }
    }
  };


  const branchChildsOptList =
  branchChildsList.length > 0 &&
  branchChildsList.map((item) => (
    <Option key={item.id} value={item.id}>
      {item.name}
    </Option>
  ));

  const handelBranch = async (search, active) => {
    try {
      const { data: responce } = await getActiveChildren(search, active);
      setBranchChildsList(responce.data);
    } catch (error) {
      if (error.request) {
        message.error(_t("msgServerDisconnected"), 3);
      } else {
        message.error(_t("msgUnknownError"), 3);
      }
    } finally {
     setLoadData(false);
    }
  };



  const onChangeParent = async (value) => {
    if (parentChange === true) {
      Modal.confirm({
        title: `${_t("msgAskGenerateANewCode")}`,
        icon: <ExclamationCircleOutlined />,
        content: `${_t("msgGenerateANewCode")}`,
        okText: `${_t("strYes")}`,
        cancelText: `${_t("strNo")}`,
        async onOk() {
          setParentChange(false);
          codeGenerator(value);
          setProChange(true);
        },
        onCancel() {
          setProChange(true);
        },
      });
    } else {
      codeGenerator(value);
      setIsGoParentId(value);
    }
  };

  //******************************// //*******************// Editing processes //*******************// //******************************//

  useEffect(() => {
    async function fetchData() {
      if (id === "new" || id === "add" || typeof onNewStore === "function") {
        return;
      }
      try {
        const { data } = await getStoreById(id);
        if (data.data.parentId === null) {
          data.data.parentId = undefined;
        }
        form.setFieldsValue({
          id: data.data.id,
          isMain: data.data.isMain,
          parentId: data.data.parentId,
          code: data.data.code,
          name: data.data.name,
          address: data.data.address,
          branchIds: data.data.branchIds,
          note: data.data.note,
          inactive: data.data.inactive,
          companyId: data.data.companyId,
        });
      } catch (error) {
        if (error.response) {
          message.error(
            error.response.data?.message || _t("msgUnknownError"),
            3
          );
          setLoadData(false);
        } else if (error.request) {
          message.error(_t("msgServerDisconnected"), 3);
          setLoadData(false);
        } else {
          message.error(_t("msgUnknownError"), 3);
          setLoadData(false);
        }
      }
    }
    fetchData();
  }, [id, history]);

  //******************************// //*******************// Back //*******************// //******************************//

  const menu = (
    <Menu>
      <Menu.Item onClick={() => setIsGoBack(true)}>
        {_t("strLeaveThePage")}
      </Menu.Item>
      <Menu.Item onClick={() => setIsGoBack(false)}>
        {_t("strStayInPage")}
      </Menu.Item>
    </Menu>
  );

  const goBack = () => {
    setProChange(false);
    if (isGoBack) {
      history.goBack();
    } else {
      if (id !== "new" && id !== "add") {
        history.replace({ ...history.location, pathname: "/stores/new" });
      }
      codeGenerator(isGoParentId);
      form.setFieldsValue({ name: "" });
      loadAllParents();
      IX_Name.current.focus();
    }
  };

  //******************************// //*******************// Save //*******************// //******************************//

  const onFinish = async (values) => {
    if (typeof onNewStore === "function") {
      values.id = "new";
    } else {
      values.id = id;
    }
    values.companyId = companyId;
    if (values.isMain === undefined) {
      values.isMain = false;
    }
    try {
      const data = await editStore(values);
      message.success(data.data.message, 3);
      if (typeof onNewStore === "function") {
        onNewStore(data);
      } else {
        goBack();
      }
    } catch (error) {
      console.error(error.response);
      if (error.response) {
        if (error.response?.data?.data?.ex.includes("IX_Code")) {
          IX_Code.current.focus();
          message.error(
            error.response?.data?.data?.message || _t("msgUnknownError"),
            3
          );
          setLoadData(false);
        } else if (error.response?.data?.data?.ex.includes("IX_Name")) {
          IX_Name.current.focus();
          message.error(
            error.response?.data?.data?.message || _t("msgUnknownError"),
            3
          );
          setLoadData(false);
        } else {
          message.error(
            error.response?.data?.message || _t("msgUnknownError"),
            3
          );
          setLoadData(false);
        }
      } else if (error.request) {
        message.error(_t("msgServerDisconnected"), 3);
        setLoadData(false);
      } else {
        message.error(_t("msgUnknownError"), 3);
        setLoadData(false);
      }
    }
  };

  const handelNewBranches = (branches) => {
    const newBranches = [...branchChildsOpt, branches.data.data];
    setBranchChildsOpt(newBranches);
    setVisibility(false);
    console.log(branches.data.data.id);
    const newList = [...form.getFieldValue().branchIds, branches.data.data.id];
    form.setFieldsValue({
      branchIds: newList,
    });
  };

  //******************************// //*******************// Form Code //*******************// //******************************//

  const storeParentOptList =
    storeParentOpt.length > 0 &&
    storeParentOpt.map((item) =>
      item.id !== id ? (
        <Select.Option key={item.id} value={item.id}>
          {item.name}
        </Select.Option>
      ) : null
    );

  const [proChange, setProChange] = useState(false);
  const promptForm = () => {
    setProChange(true);
  };
  const { TextArea } = Input;

  //******************************// //*******************// Form //*******************// //******************************//

  return (
    <div key="mainDiv">
      <Modal
        visible={visibility}
        onCancel={() => handleCancel()}
        title={_t("strBranches")}
        footer={null}
        destroyOnClose={true}
        width={1000}
      >
        <FormBranches onNewBranch={handelNewBranches} />
      </Modal>

      <React.Fragment>
        <Prompt when={proChange} message={_t("strUnsavedChanges")} />
        <div className="bg">
          <Spin spinning={loadData} tip={_t("strLoading")}>
            <Form
              form={form}
              name="storeform"
              onFinish={onFinish}
              layout="vertical"
              initialValues={{ isMain: false }}
            >
              <Row gutter={12}>
                <Col xs={24} md={12}>
                  <Form.Item
                    label={_t("strStoreClassification")}
                    name="isMain"
                    hidden={typeof onNewStore === "function"}
                  >
                    <Radio.Group buttonStyle="solid">
                      <Radio value={false} autoFocus>
                        {_t("strSub")}
                      </Radio>
                      <Radio value={true}>{_t("strMain")}</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} />
                <Col xs={24} md={12}>
                  <Form.Item
                    name="parentId"
                    label={_t("strMainStore")}
                    rules={[{ required: false }]}
                  >
                    <Select
                      allowClear
                      showSearch
                      optionFilterProp="children"
                      notFoundContent={
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}></Empty>
                      }
                      onChange={onChangeParent}
                      placeholder={_t("strNotSelected")}
                    >
                      {storeParentOptList}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="code"
                    label={_t("strCode")}
                    rules={[
                      {
                        required: true,
                        message: `${_t("strIsRequired")}`,
                      },
                      { min: 1, message: `${_t("strFrom3-200")}` },
                      { max: 200, message: `${_t("strFrom3-200")}` },
                    ]}
                  >
                    <Input
                      placeholder={_t("strCode")}
                      autoComplete="off"
                      maxLength={200}
                      onChange={promptForm}
                      ref={IX_Code}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="name"
                    label={_t("strName")}
                    rules={[
                      { required: true, message: `${_t("strIsRequired")}` },
                      { min: 1, message: `${_t("strFrom3-200")}` },
                      { max: 200, message: `${_t("strFrom3-200")}` },
                    ]}
                  >
                    <Input
                      placeholder={_t("strName")}
                      maxLength={200}
                      autoComplete="off"
                      onChange={promptForm}
                      ref={IX_Name}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="branchIds"
                    label={_t("strBranches")}
                    rules={[{ required: false }]}
                  >
                    <Select
                      allowClear
                      mode="multiple"
                      showSearch         
                      onSearch={(value) => {    
                        
                        handelBranch(value, "");
                      }}
                      optionFilterProp="children"
                      notFoundContent={
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}>
                          <Button
                            onClick={() => showModal()}
                            hidden={typeof onNewStore === "function"}
                          >
                            {" "}
                            {_t("strAddNew")}{" "}
                          </Button>{" "}
                        </Empty>
                      }
                      placeholder={_t("strNotSelected")}
                    >
                      {branchChildsOptList}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} md={24}>
                  <Form.Item name="address" label={_t("strAddress")}>
                    <Input
                      placeholder={_t("strAddress")}
                      maxLength={200}
                      autoComplete="off"
                      onChange={promptForm}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={24}>
                  <Form.Item
                    name="note"
                    label={_t("strNote")}
                    rules={[
                      { required: false, message: `${_t("strIsRequired")}` },
                      { min: 1, message: `${_t("strFrom1-1000")}` },
                      { max: 1000, message: `${_t("strFrom1-1000")}` },
                    ]}
                  >
                    <TextArea
                      placeholder={_t("strNote")}
                      maxLength={1000}
                      autoComplete="off"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="inactive"
                    valuePropName="checked"
                    label={_t("strInActive")}
                  >
                    <Switch />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} />
              </Row>
              <Form.Item>
                <Space size="large">
                  <Button type="primary" htmlType="submit" disabled={loading}>
                    {_t("strSave")}
                  </Button>
                  <Dropdown.Button
                    hidden={typeof onNewStore === "function"}
                    overlay={menu}
                    onClick={() => history.goBack()}
                  >
                    {" "}
                    {_t("strBack")}
                  </Dropdown.Button>
                  {isGoBack ? (
                    <i />
                  ) : (
                    <RetweetOutlined style={{ fontSize: 21, marginTop: 5 }} />
                  )}
                </Space>
              </Form.Item>
            </Form>
          </Spin>
        </div>
      </React.Fragment>
    </div>
  );
};

export default StoreForm;
