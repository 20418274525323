import React from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import _t from "../../../languages/translate";
import { Space, Button, Divider } from "antd";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";

const SortableList = SortableContainer(({ dataItems, onToggleShow }) => {
  return (
    dataItems.length && (
      <ul style={{ listStyle: "none", padding: 0, margin: 0 }}>
        {dataItems?.map((item, index) => (
          <SortableItem
            key={`item-${item.key}`}
            index={index}
            data={item}
            onToggleShow={onToggleShow}
          />
        ))}
      </ul>
    )
  );
});

const SortableItem = SortableElement(({ data, onToggleShow }) => (
  <>
    <li
      style={{
        listStyle: "none",
        padding: 0,
        margin: 0,
        zIndex: 1060,
        textAlign:
          window.localStorage.getItem("lang") === "en" ? "left" : "right",
      }}
    >
      <Space direction="horizontal">
        <Button
          shape="circle"
          type="link"
          style={{ cursor: "pointer" }}
          onClick={() => {
            onToggleShow(data);
          }}
        >
          {data.hidden ? (
            <EyeInvisibleOutlined className="eyeFalse" />
          ) : (
            <EyeOutlined className="eyeTrue" />
          )}
        </Button>{" "}
        <strong style={{ cursor: "move" }}>{data.title}</strong>
      </Space>
      <Divider style={{ marginBottom: 0, marginTop: 0 }} dashed />
    </li>
  </>
));

export default SortableList;
