import { _URL } from "../../config.json";
import http from "./../http";

const apiEndpoint = _URL + "/api/MunfTemplate";

export function getPagers(query) {
  return http.post(`${apiEndpoint}/GetPager?${query}`);
}

export function getManufacturingModelsById(Id) {
  return http.get(`${apiEndpoint}/${Id}`);
}

export function editManufacturingModels(obj) {
  if (obj.id !== "new" && obj.id !== "add") {
    return http.put(apiEndpoint, obj);
  } else {
    return http.post(apiEndpoint, obj);
  }
}

export function setDefaultItem(Id) {
  return http.post(
    `${apiEndpoint}/SetDefaultItem?MunfTemplateOutDetailId=${Id}`
  );
}

export function deleteManufacturingModels(Id) {
  return http.delete(`${apiEndpoint}/${Id}`);
}

export function getManufacturingModels() {
  return http.get(`${apiEndpoint}`);
}

export function getAllParents() {
  return http.post(`${apiEndpoint}/GetAllParent`);
}

export function getAllChildren() {
  return http.post(`${apiEndpoint}/GetAllChildren`);
}

export function getAllManufacturingModels() {
  return http.post(`${apiEndpoint}/GetAll`);
}

export function getNewManufacturingModelsCode() {
  return http.post(`${apiEndpoint}/GetNewCode/`);
}

export function getPagerManufacturingModels(current, pageSize) {
  return http.post(
    `${apiEndpoint}/GetPager?PageNumber=${current}&PageSize=${pageSize}`
  );
}

// export function searchManufacturingModels(code, name, isMatching) {
//   return http.post(
//     `${apiEndpoint}/GetChildrenActiveSearch?Code=${code}&Name=${name}&IsMatching=${isMatching}`
//   );
// }

export function searchManufacturingModels(obj) {
  return http.post(`${apiEndpoint}/GetPagerSearch/`, obj);
}

export function getActiveTreeManufacturingModels() {
  return http.post(`${apiEndpoint}/GetActiveTree`);
}
