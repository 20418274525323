import React, { useEffect, useState } from "react";
import _t from "../../../languages/translate";
import "../Print/style/printStyle.css";
import logoYb from "./image/Boledo.jpeg";
import { Table, Form, Col, Row } from "antd";

const ComponentToPrint = React.forwardRef(({ setObject }, ref) => {
  const tableConfig = [
    {
      title: _t("strCode"),
      dataIndex: "itemCode",
      key: "itemCode",
    },
    {
      title: _t("strName"),
      dataIndex: "itemName",
      key: "itemName",
    },
    {
      title: _t("strUnit"),
      dataIndex: "itemUnitName",
      key: "itemUnitName",
    },
    {
      title: _t("strQuantity"),
      dataIndex: "qty",
      key: "qty",
      render: (text, record) => (
        <div style={{ textAlign: "right", direction: "ltr" }}>
          {record.qty === 0 || record.qty === null
            ? "_"
            : record.qty.toFixed(3).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
        </div>
      ),
    },
    {
      title: _t("strNote"),
      dataIndex: "note",
      key: "note",
    },
  ];

  return (
    <div style={{ padding: 15 }} ref={ref}>
      <Form className="clearfix">
        <div id="logo">
          <img src={logoYb} />
        </div>

        <h1 id="Xh1">
          {_t("strName") === "Name"
            ? setObject.pattrenEn + " - " + setObject.code
            : setObject.pattrenAr + " - " + setObject.code}
        </h1>

        <Row gutter={12}>
          <Col span={12}>
            <table>
              {/* <tr style={{ height: 25 }}>
                <td>{_t("strCode")}:</td>
                <td>
                  <p style={{ margin: 5 }}>{setObject.code}</p>
                </td>
              </tr> */}
              {setObject.document !== null ? (
                <tr style={{ height: 25 }}>
                  <td>{_t("strDocument")}:</td>
                  <td>
                    <p style={{ margin: 5 }}>{setObject.document}</p>
                  </td>
                </tr>
              ) : (
                ""
              )}
              {setObject.client !== null ? (
                <tr style={{ height: 25 }}>
                  <td>{_t("strClientName")}:</td>
                  <td>
                    <p style={{ margin: 5 }}>{setObject.client}</p>
                  </td>
                </tr>
              ) : (
                ""
              )}
              <tr style={{ height: 25 }}>
                <td>{_t("strDate")}:</td>
                <td>
                  <p style={{ margin: 5 }}>{setObject.date}</p>
                </td>
              </tr>
              {/* 
              {setObject.statusEn !== null ? (
                <tr style={{ height: 25 }}>
                  <td>{_t("strStatus")}:</td>
                  <td>
                    <p style={{ margin: 5 }}>
                      {" "}
                      {_t("strName") === "Name"
                        ? setObject.statusEn
                        : setObject.statusAr}
                    </p>
                  </td>
                </tr>
              ) : (
                ""
              )} */}
            </table>
          </Col>
          <Col span={12}>
            <table>
              {setObject.fromStore !== null &&
              setObject.pattrenEn === "Transfer" ? (
                <tr style={{ height: 25 }}>
                  <td>{_t("strFromStore")}:</td>
                  <td>
                    <p style={{ margin: 5 }}>{setObject.fromStore}</p>
                  </td>
                </tr>
              ) : (
                ""
              )}
              {setObject.pattrenEn === "Transfer" ? (
                <tr style={{ height: 25 }}>
                  <td>{_t("strToStore")}:</td>
                  <td>
                    <p style={{ margin: 5 }}>{setObject.toStore}</p>
                  </td>
                </tr>
              ) : (
                ""
              )}

              {setObject.expectedDeliveryDate !== null &&
              setObject.expectedDeliveryDate !== "" ? (
                <tr style={{ height: 25 }}>
                  <td>{_t("strExpectedDeliveryDate")}:</td>
                  <td>
                    <p style={{ margin: 5 }}>
                      {setObject.expectedDeliveryDate}
                    </p>
                  </td>
                </tr>
              ) : (
                ""
              )}
              <tr style={{ height: 25 }}>
                <td>{_t("strNote")}:</td>
                <td>
                  {" "}
                  <p style={{ margin: 5 }}>{setObject.note}</p>
                </td>
              </tr>
            </table>
          </Col>
        </Row>
      </Form>
      <br />

      <Table
        bordered
        rowKey="id"
        columns={tableConfig}
        dataSource={setObject.table}
        pagination={false}
        size="small"
      />

      <br />
      <table
        style={{
          direction: _t("toolDiriction"),
          width: "100%",
          marginBottom: 10,
        }}
      >
        <tr>
          <td
            style={{
              border: 1,
              width: 60,
              height: 30,
              marginLeft: 5,
              marginRight: 5,
              borderStyle: "dashed",
              borderColor: "#d0d0d0",
              borderCollapse: "collapse",
            }}
          ></td>
          <td
            style={{
              border: 1,
              width: 40,
              height: 30,
              marginLeft: 5,
              marginRight: 5,
              textAlign: _t("drawerPlacement"),
              borderStyle: "dashed",
              borderColor: "#d0d0d0",
              borderCollapse: "collapse",
            }}
          >
            <p style={{ margin: 10 }}>{_t("strTotalQuantity")}</p>
          </td>
          <td
            style={{
              border: 1,
              width: 60,
              height: 30,
              marginLeft: 5,
              marginRight: 5,
              borderStyle: "dashed",
              borderColor: "#d0d0d0",
              borderCollapse: "collapse",
            }}
          ></td>
          <td
            style={{
              border: 1,
              width: 40,
              height: 30,
              marginLeft: 5,
              marginRight: 5,
              textAlign: _t("drawerPlacement"),
              borderStyle: "dashed",
              borderColor: "#d0d0d0",
              borderCollapse: "collapse",
            }}
          >
            <p style={{ margin: 10 }}>{_t("strTheRecipientsName")}</p>
          </td>
        </tr>
        <tr>
          <td
            style={{
              border: 1,
              width: 60,
              height: 30,
              marginLeft: 5,
              marginRight: 5,
              borderStyle: "dashed",
              borderColor: "#d0d0d0",
              borderCollapse: "collapse",
            }}
          ></td>
          <td
            style={{
              border: 1,
              width: 40,
              height: 30,
              marginLeft: 5,
              marginRight: 5,
              textAlign: _t("drawerPlacement"),
              borderStyle: "dashed",
              borderColor: "#d0d0d0",
              borderCollapse: "collapse",
            }}
          >
            <p style={{ margin: 10 }}>{_t("strSignatureOfStorekeeper")}</p>
          </td>
          <td
            style={{
              border: 1,
              width: 60,
              height: 30,
              marginLeft: 5,
              marginRight: 5,
              borderStyle: "dashed",
              borderColor: "#d0d0d0",
              borderCollapse: "collapse",
            }}
          ></td>
          <td
            style={{
              border: 1,
              width: 40,
              height: 30,
              marginLeft: 5,
              marginRight: 5,
              textAlign: _t("drawerPlacement"),
              borderStyle: "dashed",
              borderColor: "#d0d0d0",
              borderCollapse: "collapse",
            }}
          >
            <p style={{ margin: 10 }}>{_t("strSignature")}</p>
          </td>
        </tr>
      </table>
    </div>
  );
});

export default ComponentToPrint;

// {
//   <table id="Xtable">
//   <thead><tr>{renderTableHeader()}</tr></thead>
//   <tbody>{renderTableData()}</tbody>
// </table>
// <div id="notices"><div className="notice">{setObject.strCode}</div></div>

// const renderTableHeader = () => {
//
//   let header = Object.keys(setObject.table[0]);
//   return header.map((key, index) => { return <th key={index}>{_t(key.toUpperCase())}</th> });
// }

// const renderTableData = () => {
//
//   return setObject.table.map((data, index) => {
//     let col = Object.keys(data, index)
//     return (<tr key={index}>{col.map((val, index) => { return <td key={data[col[index]]}>{data[col[index]]}</td> })}</tr>)
//   })

// }
//  }
