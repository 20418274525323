import { _URL } from "../config.json";
import http from "./http";

const apiEndpoint = _URL + "/api/store";

export function getPagers(query) {
  return http.post(`${apiEndpoint}/GetPager?${query}`);
}

export function getStoreById(Id) {
  return http.get(`${apiEndpoint}/${Id}`);
}

export function editStore(obj) {
  if (obj.id !== "new" && obj.id !== "add") {
    return http.put(apiEndpoint, obj);
  } else {
    return http.post(apiEndpoint, obj);
  }
}

export function deleteStore(Id) {
  return http.delete(`${apiEndpoint}/${Id}`);
}

export function getStores() {
  return http.get(`${apiEndpoint}`);
}

export function getAllParents() {
  return http.post(`${apiEndpoint}/GetAllParent`);
}

export function getAllChildren() {
  return http.post(`${apiEndpoint}/GetAllChildren`);
}

export function getAllStore() {
  return http.post(`${apiEndpoint}/GetAll`);
}

export function getActiveTreeStore(search, addId) {
  return http.post(
    `${apiEndpoint}/GetActiveTree/?search=${search}&addId=${addId}`
  );
}


export function getActiveChildren(search, addId) {
  return http.post(
    `${apiEndpoint}/GetActiveChildren/?search=${search}&addId=${addId}`
  );
}




export function getNewStoreCode(Id) {
  return http.post(`${apiEndpoint}/GetNew/?parentId=${Id}`);
}

export function getPagerStore(current, pageSize) {
  return http.post(
    `${apiEndpoint}/GetPager?PageNumber=${current}&PageSize=${pageSize}`
  );
}
