import { _URL } from "../../config.json";
import http from "./../http";

const apiEndpoint = _URL + "/api/ItemUnit";

export function getPagers(query) {
  return http.post(`${apiEndpoint}/GetPager?${query}`);
}

export function getItemUnitById(Id) {
  return http.get(`${apiEndpoint}/${Id}`);
}

export function editItemUnit(obj) {
  if (obj.id !== "new") {
    return http.put(apiEndpoint, obj);
  } else {
    return http.post(apiEndpoint, obj);
  }
}

export function getAllUnit() {
  return http.get(`${apiEndpoint}`);
}
export function deleteItemUnit(Id) {
  return http.delete(`${apiEndpoint}/${Id}`);
}

export function getPagerItemUnit(current, pageSize) {
  return http.post(
    `${apiEndpoint}/GetPager?PageNumber=${current}&PageSize=${pageSize}`
  );
}

export function getNewItemUnitCode() {
  return http.post(`${apiEndpoint}/GetNewCode`);
}
