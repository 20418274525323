import React, { useState } from "react";
import { Layout, Menu, Col, Row } from "antd";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import Media from "react-media";
import LanguageSelector from "../../languages/LanguageSelector";

const MainHeader = ({ pageTitle }) => {
  const { Header } = Layout;
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const [collapsed, setCollapsed] = useState();
  const [titlePage, setTitlePage] = useState("");
  const [transAr, setTransAr] = useState("");
  const [transEn, setTransEn] = useState("");

  const toggle = () => {
    setCollapsed(!collapsed);
  };
  const showDrawer = () => {
    setVisibleDrawer(true);
  };

  const clear = () => {
    setTransEn("");
    setTransAr("");
  };

  const transactionTitle = (data) => {
    setTransEn(data.split(",")[0]);
    setTransAr(data.split(",")[1]);
  };

  const handelTitleLang = (lang) => {
    if (lang === "en" && transEn !== "") {
      setTitlePage(transEn);
    } else if (lang === "ar" && transAr !== "") {
      setTitlePage(transAr);
    } else {
      return;
    }
  };

  return (
    <Header className="site-layout-background" style={{ height: 50 }}>
      <Row style={{ backgroundColor: "white" }}>
        <Col span={12}>
          <Media query="(max-width: 1024px)">
            {(matches) =>
              matches
                ? React.createElement(MenuFoldOutlined, {
                    className: "trigger",
                    onClick: showDrawer,
                  })
                : React.createElement(
                    collapsed ? MenuFoldOutlined : MenuUnfoldOutlined,
                    { className: "trigger", onClick: toggle }
                  )
            }
          </Media>
          <label style={{ fontSize: 14 }}>{pageTitle}</label>
        </Col>
        <Col span={12} style={{ textAlign: "end" }}>
          <LanguageSelector
            onHandelLang={(lang) => {
              handelTitleLang(lang);
            }}
          />
        </Col>
      </Row>
    </Header>
  );
};
export default MainHeader;
