import React, { useState, useEffect } from "react";
import {
  Form,
  Spin,
  TreeSelect,
  Row,
  Col,
  DatePicker,
  message,
  Empty,
  Button,
  Select,
  Checkbox,
} from "antd";
import _t from "../../../languages/translate";
import { getClientSupp } from "../../../services/clientSuppService";
import { getAllSTSP } from "../../../services/Transactions/STSPService";
import { getActiveTreeStore } from "../../../services/storeService";
import { getActiveTreeItem } from "../../../services/Items/itemService";
import moment from "moment";

const FilterOrdersRep = ({ onHandelData, onFilterData, filterType }) => {
 
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [treeStocksId, setTreeStocksId] = useState([""]);

  const [treeItemsId, setTreeItemsId] = useState([""]);
  const [itemsOpt, setItemsOpt] = useState([]);
  const [storesOpt, setStoresOpt] = useState([]);

  const [
    storeTransactionStatePattrenOpt,
    setStoreTransactionStatePattrenOpt,
  ] = useState([]);
  const { SHOW_PARENT } = TreeSelect;
  const dateFormatList = [
    "DD/MM/YYYY HH:mm",
    "DDMMYYYY HH:mm",
    "DDMMYYYY HHmm",
    "DDMMYYYYHHmm",
    "DDMMYYYY",
    "DDMMYYHHmm",
    "DDMMYY",
  ];

  // const clientSupplireOptList =
  //   clientSupplireOpt.length > 0 &&
  //   clientSupplireOpt.map((item) => (
  //     <Select.Option key={item.id} value={item.id}>
  //       {item.name}
  //     </Select.Option>
  //   ));
  const storeTransactionStatePattrenOptList =
    storeTransactionStatePattrenOpt.length > 0 &&
    storeTransactionStatePattrenOpt.map((item) => (
      <Select.Option key={item.id} value={item.id}>
        {item.nameVM}
      </Select.Option>
    ));

  useEffect(() => {
    const fetchData = async () => {
      try {
        onSearchItems("");

        onSearchStocks("");
       
        getClientsSupp("");

        const { data: resultStoreTransactionStatePattren } = await getAllSTSP();
        setStoreTransactionStatePattrenOpt(
          resultStoreTransactionStatePattren.data
        );
        
        if (onFilterData !== undefined) {
          form.setFieldsValue({
            itemIds: onFilterData.itemIds,
            storeIds: onFilterData.storeIds,
            clientSuplierIds: onFilterData.clientSuplierIds,
            stateIds: onFilterData.stateIds,
            showDetails: onFilterData.showDetails,
          });
        }
      } catch (error) {
        if (error.response) {
          message.error(
            error.response.data?.message || _t("msgUnknownError"),
            3
          );
        } else if (error.request) {
          message.error(_t("msgServerDisconnected"), 3);
        } else {
          message.error(_t("msgUnknownError"), 3);
        }
      }
    };
    fetchData();
  }, [setItemsOpt, setStoresOpt, setStoreTransactionStatePattrenOpt]);

  const onChangeItems = (value) => {
    setTreeItemsId(value);
  };

  const onChangeStocks = (value) => {
    setTreeStocksId(value);
  };
  const onChangeClientSupp = (value) => {
    setTreeClientSuppId(value);
  };

  const [itemState, setItemState] = useState("");
  const onSearchItems = async(searchValue) => {
    try {
      setItemState(searchValue);
      const { data: result } = await getActiveTreeItem(searchValue,"");
      setItemsOpt(result.data);
    } catch (error) {
      if (error.response) {
        message.error(error.response.data?.message || _t("msgUnknownError"), 3);
      } else if (error.request) {
        message.error(_t("msgServerDisconnected"), 3);
      } else {
        message.error(_t("msgUnknownError"), 3);
      }
    }
  };
  const [stockState, setStockState] = useState("");

  const onSearchStocks = async(searchValue) => {
    try {
      setStockState(searchValue);
      const { data: result } = await getActiveTreeStore(searchValue,"");
      setStoresOpt(result.data);
    } catch (error) {
      if (error.response) {
        message.error(error.response.data?.message || _t("msgUnknownError"), 3);
      } else if (error.request) {
        message.error(_t("msgServerDisconnected"), 3);
      } else {
        message.error(_t("msgUnknownError"), 3);
      }
    }
  };

  const [clientSuppOpt, setClientSuppOpt] = useState([]);
  const [treeClientSuppId, setTreeClientSuppId] = useState([""]);
  const [clientSuppState, setClientSuppState] = useState("");
  const getClientsSupp = async (searchValue) => {
    try {
      setClientSuppState(searchValue);
      const { data: resultClientSupplire } = await getClientSupp(searchValue);
      setClientSuppOpt(resultClientSupplire.data);
    } catch (error) {
      if (error.response) {
        message.error(error.response.data?.message || _t("msgUnknownError"), 3);
      } else if (error.request) {
        message.error(_t("msgServerDisconnected"), 3);
      } else {
        message.error(_t("msgUnknownError"), 3);
      }
    }
  };

  const tPropsClientSupp = {
    treeData: clientSuppOpt,
    allowClear: true,
    showSearch: true,
    onSearch: getClientsSupp,
    onDropdownVisibleChange: () => setClientSuppState(""),
    searchValue: clientSuppState,
    treeNodeFilterProp: "title",
    value: treeClientSuppId,
    onChange: onChangeClientSupp,
    treeCheckable: true,
    treeDefaultExpandAll: false,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: _t("strNotSelected"),
    style: {
      width: "100%",
    },
  };

  const tPropsItems = {
    treeData: itemsOpt,
    allowClear: true,
    showSearch: true,
    onSearch: onSearchItems,
    onDropdownVisibleChange: () => setItemState(""),
    searchValue: itemState,
    treeNodeFilterProp: "title",
    value: treeItemsId,
    onChange: onChangeItems,
    treeCheckable: true,
    treeDefaultExpandAll: false,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: _t("strNotSelected"),
    style: {
      width: "100%",
    },
  };

  const tPropsStore = {
    treeData: storesOpt,
    allowClear: true,
    showSearch: true,
    onSearch: onSearchStocks,
    onDropdownVisibleChange: () => setStockState(""),
    searchValue: stockState,
    treeNodeFilterProp: "title",
    value: treeStocksId,
    onChange: onChangeStocks,
    treeCheckable: true,
    treeDefaultExpandAll: false,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: _t("strNotSelected"),
    style: {
      width: "100%",
    },
  };

  const onFinish = async (values) => {
    setLoading(true);
    if (!filterType) {
      if (values.fromDate !== "") {
        values.fromDate = values.fromDate?.toISOString();
      }
      if (values.toDate !== "") {
        values.toDate = values.toDate?.toISOString();
      }
      if (values.fromDeliveryDate !== "") {
        values.fromDeliveryDate = values.fromDeliveryDate?.toISOString();
      }
      if (values.toDeliveryDate !== "") {
        values.toDeliveryDate = values.toDeliveryDate?.toISOString();
      }
    }

    if (values.itemIds === undefined || values.itemIds === "") {
      values.itemIds = [];
    }
    if (values.storeIds === undefined || values.storeIds === "") {
      values.storeIds = [];
    }
    if (
      values.clientSuplierIds === undefined ||
      values.clientSuplierIds === ""
    ) {
      values.clientSuplierIds = [];
    }
    if (values.stateIds === undefined || values.stateIds === "") {
      values.stateIds = [];
    }

    let obj = {
      reportFilter: values,
      timeShift: new Date().getTimezoneOffset(),
      columns: [],
    };

    onHandelData(obj);
    setLoading(false);
  };

  return (
    <Spin spinning={loading} tip={_t("strLoading")}>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{ toDate: moment() }}
      >
        <Row gutter={12}>
          <Col span={24}>
            <Form.Item name="itemIds" label={_t("strItems")}>
              <TreeSelect {...tPropsItems} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="storeIds" label={_t("strStores")}>
              <TreeSelect {...tPropsStore} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="clientSuplierIds" label={_t("strClientsSupp")}>
              <TreeSelect {...tPropsClientSupp} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="stateIds" label={_t("strStatus")}>
              <Select
                allowClear
                mode="multiple"
                showSearch
                optionFilterProp="children"
                notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                placeholder={_t("strNotSelected")}
              >
                {storeTransactionStatePattrenOptList}
              </Select>
            </Form.Item>
          </Col>

          {!filterType && (
            <Col span={12}>
              <Form.Item name="fromDate" label={_t("strFromDate")}>
                <DatePicker
                  showTime={{ format: "HH:mm" }}
                  format={dateFormatList}
                  className="dateTimeStyle"
                />
              </Form.Item>
            </Col>
          )}
          {!filterType && (
            <Col span={12}>
              <Form.Item name="toDate" label={_t("strToDate")}>
                <DatePicker
                  showTime={{ format: "HH:mm" }}
                  format={dateFormatList}
                  className="dateTimeStyle"
                />
              </Form.Item>
            </Col>
          )}
          {!filterType && (
            <Col span={12}>
              <Form.Item
                name="fromDeliveryDate"
                label={_t("strFromDeliveryDate")}
              >
                <DatePicker
                  showTime={{ format: "HH:mm" }}
                  format={dateFormatList}
                  className="dateTimeStyle"
                />
              </Form.Item>
            </Col>
          )}
          {!filterType && (
            <Col span={12}>
              <Form.Item name="toDeliveryDate" label={_t("strToDeliveryDate")}>
                <DatePicker
                  showTime={{ format: "HH:mm" }}
                  format={dateFormatList}
                  className="dateTimeStyle"
                />
              </Form.Item>
            </Col>
          )}
          <Col span={12}>
            <Form.Item name="showDetails" valuePropName="checked">
              <Checkbox style={{ color: "#bd1873" }}>
                {_t("strShowDetails")}
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {filterType ? _t("strAdd") : _t("strSearch")}
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  );
};

export default FilterOrdersRep;
