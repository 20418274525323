import React, { useState, useEffect } from "react";
import {
  Form,
  Spin,
  TreeSelect,
  Row,
  Col,
  DatePicker,
  message,
  Checkbox,
  Button,
  Divider,
  Space,
} from "antd";
import _t from "../../../languages/translate";
import { getActiveTreeStore } from "../../../services/storeService";
import { getActiveTreeItem } from "../../../services/Items/itemService";
import moment from "moment";

const FilterManufacturingRep = ({ onHandelData, onFilterData, filterType }) => {
  debugger;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [treeStocksId, setTreeStocksId] = useState([""]);
  const [treeItemsId, setTreeItemsId] = useState([""]);
  const [itemsOpt, setItemsOpt] = useState([]);
  const [storesOpt, setStoresOpt] = useState([]);
  const { SHOW_PARENT } = TreeSelect;
  const dateFormatList = [
    "DD/MM/YYYY HH:mm",
    "DDMMYYYY HH:mm",
    "DDMMYYYY HHmm",
    "DDMMYYYYHHmm",
    "DDMMYYYY",
    "DDMMYYHHmm",
    "DDMMYY",
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        onSearchItems("");

        
        onSearchStocks("");

        if (onFilterData !== undefined) {
          form.setFieldsValue({
            inItemIds: onFilterData.inItemIds,
            outItemIds: onFilterData.outItemIds,
            fromStoreIds: onFilterData.fromStoreIds,
            toStoreIds: onFilterData.toStoreIds,
            fromDate:
              onFilterData.fromDate !== undefined
                ? moment(onFilterData.fromDate)
                : "",
            toDate:
              onFilterData.toDate !== undefined
                ? moment(onFilterData.toDate)
                : "",
            showMaster: onFilterData.showMaster,
            showDetail: onFilterData.showDetail,
            showDetailIn: onFilterData.showDetailIn,
            showDetailOut: onFilterData.showDetailOut,
          });
        }
      } catch (error) {
        if (error.response) {
          message.error(
            error.response.data?.message || _t("msgUnknownError"),
            3
          );
        } else if (error.request) {
          message.error(_t("msgServerDisconnected"), 3);
        } else {
          message.error(_t("msgUnknownError"), 3);
        }
      }
    };
    fetchData();
  }, [setItemsOpt, setStoresOpt]);

  const onChangeItems = (value) => {
    setTreeItemsId(value);
  };

  const onChangeStocks = (value) => {
    setTreeStocksId(value);
  };

  const [itemState, setItemState] = useState("");
  const onSearchItems = async(searchValue) => {
    try {
      setItemState(searchValue);
      const { data: result } = await getActiveTreeItem(searchValue,"");
      setItemsOpt(result.data);
    } catch (error) {
      if (error.response) {
        message.error(error.response.data?.message || _t("msgUnknownError"), 3);
      } else if (error.request) {
        message.error(_t("msgServerDisconnected"), 3);
      } else {
        message.error(_t("msgUnknownError"), 3);
      }
    }
  };
  const [stockState, setStockState] = useState("");
  const onSearchStocks = async(searchValue) => {
    try {
      setStockState(searchValue);
      const { data: result } = await getActiveTreeStore(searchValue,"");
      setStoresOpt(result.data);
    } catch (error) {
      if (error.response) {
        message.error(error.response.data?.message || _t("msgUnknownError"), 3);
      } else if (error.request) {
        message.error(_t("msgServerDisconnected"), 3);
      } else {
        message.error(_t("msgUnknownError"), 3);
      }
    }
  };

  const tPropsItems = {
    treeData: itemsOpt,
    allowClear: true,
    showSearch: true,
    onSearch: onSearchItems,
    onDropdownVisibleChange: () => setItemState(""),
    searchValue: itemState,
    treeNodeFilterProp: "title",
    value: treeItemsId,
    onChange: onChangeItems,
    treeCheckable: true,
    treeDefaultExpandAll: false,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: _t("strNotSelected"),
    style: {
      width: "100%",
    },
  };

  const tPropsStore = {
    treeData: storesOpt,
    allowClear: true,
    showSearch: true,
    onSearch: onSearchStocks,
    onDropdownVisibleChange: () => setStockState(""),
    searchValue: stockState,
    treeNodeFilterProp: "title",
    value: treeStocksId,
    onChange: onChangeStocks,
    treeCheckable: true,
    treeDefaultExpandAll: false,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: _t("strNotSelected"),
    style: {
      width: "100%",
    },
  };

  const onFinish = async (values) => {
    setLoading(true);
    if (values.inItemIds === undefined || values.inItemIds === "") {
      values.inItemIds = [];
    }
    if (values.outItemIds === undefined || values.outItemIds === "") {
      values.outItemIds = [];
    }
    if (values.fromStoreIds === undefined || values.fromStoreIds === "") {
      values.fromStoreIds = [];
    }
    if (values.toStoreIds === undefined || values.toStoreIds === "") {
      values.toStoreIds = [];
    }
    if (values.fromDate !== "") {
      values.fromDate = values.fromDate?.toISOString();
    }
    if (values.toDate !== "") {
      values.toDate = values.toDate?.toISOString();
    }

    let obj = {
      reportFilter: values,
      timeShift: new Date().getTimezoneOffset(),
      columns: [],
    };

    onHandelData(obj);
    setLoading(false);
  };

  return (
    <Spin spinning={loading} tip={_t("strLoading")}>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{ toDate: moment(), showMaster: true }}
      >
        <Row gutter={12}>
          <Col span={24}>
            <Form.Item name="inItemIds" label={_t("strInsItems")}>
              <TreeSelect {...tPropsItems} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="outItemIds" label={_t("strOutsItems")}>
              <TreeSelect {...tPropsItems} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="fromStoreIds" label={_t("strFromStore")}>
              <TreeSelect {...tPropsStore} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="toStoreIds" label={_t("strToStore")}>
              <TreeSelect {...tPropsStore} />
            </Form.Item>
          </Col>
          {!filterType && (
            <Col span={12}>
              <Form.Item name="fromDate" label={_t("strFromDate")}>
                <DatePicker
                  showTime={{ format: "HH:mm" }}
                  format={dateFormatList}
                  className="dateTimeStyle"
                />
              </Form.Item>
            </Col>
          )}
          {!filterType && (
            <Col span={12}>
              <Form.Item name="toDate" label={_t("strToDate")}>
                <DatePicker
                  showTime={{ format: "HH:mm" }}
                  format={dateFormatList}
                  className="dateTimeStyle"
                />
              </Form.Item>
            </Col>
          )}
          <Col span={24}>
            <Space>
              <Form.Item name="showMaster" valuePropName="checked">
                <Checkbox style={{ color: "#e91e63" }}>
                  {_t("strShowMaster")}
                </Checkbox>
              </Form.Item>
              <Form.Item>
                <Divider type="vertical" />
              </Form.Item>
              <Form.Item name="showDetail" valuePropName="checked">
                <Checkbox style={{ color: "#1e8ee9" }}>
                  {_t("strShowDetails")}
                </Checkbox>
              </Form.Item>
              <Form.Item>
                <Divider type="vertical" />
              </Form.Item>
              <Form.Item name="showDetailIn" valuePropName="checked">
                <Checkbox style={{ color: "#11a732" }}>
                  {_t("strShowDetailsIn")}
                </Checkbox>
              </Form.Item>
              <Form.Item>
                <Divider type="vertical" />
              </Form.Item>
              <Form.Item name="showDetailOut" valuePropName="checked">
                <Checkbox style={{ color: "#bd1873" }}>
                  {_t("strShowDetailsOut")}
                </Checkbox>
              </Form.Item>
            </Space>
          </Col>

          <Col span={24}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                {filterType ? _t("strAdd") : _t("strSearch")}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default FilterManufacturingRep;
