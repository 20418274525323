import React, { useState, useRef } from "react";
import { Form, Input, Table, Button, Space } from "antd";
import Highlighter from "react-highlight-words";
import { PaperClipOutlined, SearchOutlined } from "@ant-design/icons";
import _t from "../../../languages/translate";
const InvoiceSelectorForm = ({ onHandelData, onSearchData }) => {
  const [form] = Form.useForm();
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  //******************************//
  const searchInput = useRef(null);

  //******************************// //*******************// Filter //*******************// //******************************//

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`${_t("strSearch")}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            {_t("strSearch")}
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            {_t("strReset")}
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
    setSearchedColumn("");
  };

  //******************************// //*******************// Form Code //*******************// //******************************//

  const onSelectObj = (record) => {
    console.log("selectedRows: ", record);
    onHandelData(record);
  };

  const columns = [
    {
      title: <PaperClipOutlined style={{ fontSize: 20 }} />,
      render: (_, record) => (
        <Button
          onClick={() => {
            onSelectObj(record);
          }}
        >
          {_t("strInsert")}
        </Button>
      ),
    },
    {
      title: _t("strCode"),
      dataIndex: "code",
      key: "code",
      ...getColumnSearchProps("code"),
    },
    {
      title: _t("strDocument"),
      dataIndex: "documentCode",
      key: "documentCode",
      ...getColumnSearchProps("documentCode"),
    },
    {
      title: _t("strClientsSupp"),
      dataIndex: "clientSupplierName",
      key: "clientSupplierName",
      ...getColumnSearchProps("clientSupplierName"),
    },

    {
      title: _t("strDate"),
      dataIndex: "transactionDate",
      key: "transactionDate",
      render: (_, record) => convertIsoDate(record.transactionDate),
    },
  ];

  const convertIsoDate = (IsoDate) => {
    const date = new Date(IsoDate);
    return (
      date.getDate() +
      "/" +
      (date.getMonth() + 1) +
      "/" +
      date.getFullYear() +
      " - " +
      date.getHours() +
      ":" +
      date.getMinutes()
    );
  };

  //******************************// //*******************// Form //*******************// //******************************//

  return (
    <Form>
      <Table columns={columns} dataSource={onSearchData} />
    </Form>
  );
};

export default InvoiceSelectorForm;
