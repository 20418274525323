import React, { useState, useEffect, useRef } from "react";
import { Prompt } from "react-router";
import { useHistory, useParams, Link, useLocation } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  Switch,
  Select,
  message,
  Empty,
  Modal,
  Radio,
  Space,
  Row,
  Col,
  Menu,
  Dropdown,
  Spin,
} from "antd";
import {
  getBranchById,
  editBranch,
  getActiveParents,
  getNewBranchCode,
} from "../../services/branchService";
import { getAllChildren } from "../../services/storeService";
import { ExclamationCircleOutlined, RetweetOutlined } from "@ant-design/icons";
import UploadImage from "../upload/uploadImage";
import _t from "../../languages/translate";
import FormStores from "./fr_Stores";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const BranchForm = ({ onNewBranch }) => {
  const IX_Code = useRef(null);
  const IX_Name = useRef(null);
  const [visibility, setVisibility] = useState(false);
  const [form] = Form.useForm();
  const [avatarPath, setAvatarPath] = useState();
  const [loading, setLoading] = useState(false);
  const [loadData, setLoadData] = useState(true);
  let history = useHistory();
  let { id } = useParams();
  let query = useQuery();
  //******************************//
  const [companyId] = useState(window.localStorage.getItem("comId"));
  const [parentChange, setParentChange] = useState(false);
  const [branchParentOpt, setBranchParentOpt] = useState([]);
  const [storeChildsOpt, setStoreChildsOpt] = useState([]);
  const [isGoParentId, setIsGoParentId] = useState(null);
  const [stateParintId,setStateParintId] = useState("");
  const [isGoBack, setIsGoBack] = useState(true);
  //******************************//

  const showModal = () => {
    setVisibility(true);
  };

  const handleCancel = () => {
    setVisibility(false);
  };

  useEffect(() => {
    requestStatus(); // Request status | New | Child | Edit | 😆
    handelActiveParents("","")
    loadAllChild();
    IX_Name.current.focus();
  }, []);

  const codeGenerator = async (Id) => {
    const { data: newData } = await getNewBranchCode(Id);
    form.setFieldsValue({ code: newData.data.code });
    IX_Name.current.focus();
  };

  const requestStatus = () => {
    if (id === "new") {
      codeGenerator(null);
    } // if New item
    if (id === "add") {
      const parentId = history.location.state?.parentId;
      if (!parentId) return;
      codeGenerator(parentId);
      form.setFieldsValue({ parentId: parentId });
      setIsGoParentId(parentId);
    } // if Add child
    if (id !== "new" && id !== "add") {
      setParentChange(true);
    } // if Edit item
  };

  

  const loadAllChild = async () => {
    try {
      const { data: storeChildData } = await getAllChildren();
      setStoreChildsOpt(storeChildData.data);
      setLoadData(false);
    } catch (error) {
      if (error.response) {
        message.error(error.response.data?.message || _t("msgUnknownError"), 3);
        setLoadData(false);
      } else if (error.request) {
        message.error(_t("msgServerDisconnected"), 3);
        setLoadData(false);
      } else {
        message.error(_t("msgUnknownError"), 3);
        setLoadData(false);
      }
    }
  };

  const onChangeParent = async (value) => {
    if (parentChange === true) {
      Modal.confirm({
        title: `${_t("msgAskGenerateANewCode")}`,
        icon: <ExclamationCircleOutlined />,
        content: `${_t("msgGenerateANewCode")}`,
        okText: `${_t("strYes")}`,
        cancelText: `${_t("strNo")}`,
        async onOk() {
          setParentChange(false);
          codeGenerator(value);
          setProChange(true);
        },
        onCancel() {
          setProChange(true);
        },
      });
    } else {
      codeGenerator(value);
      setIsGoParentId(value);
      setProChange(true);
    }
  };

  useEffect(() => {
    async function fetchData() {
      if (id === "new" || id === "add" || typeof onNewBranch === "function") {
        return;
      }
      try {
        const { data } = await getBranchById(id);
        setAvatarPath(data.data.logo);
        if (data.data.parentId === null) {
          data.data.parentId = undefined;
        }
        
        setStateParintId(data.data.parentId);
        form.setFieldsValue({
          id: data.data.id,
          parentId: data.data.parentId,
          code: data.data.code,
          name: data.data.name,
          phone: data.data.phone,
          email: data.data.email,
          address: data.data.address,
          storeIds: data.data.storeIds,
          note: data.data.note,
          isMain: data.data.isMain,
          inactive: data.data.inactive,
          companyId: data.companyId,
        });
      } catch (error) {
        if (error.response) {
          message.error(
            error.response.data?.message || _t("msgUnknownError"),
            3
          );
          setLoadData(false);
        } else if (error.request) {
          message.error(_t("msgServerDisconnected"), 3);
          setLoadData(false);
        } else {
          message.error(_t("msgUnknownError"), 3);
          setLoadData(false);
        }
      }
    }
    fetchData();
  }, [id, history]);

  const menu = (
    <Menu>
      <Menu.Item onClick={() => setIsGoBack(true)}>
        {_t("strLeaveThePage")}
      </Menu.Item>
      <Menu.Item onClick={() => setIsGoBack(false)}>
        {_t("strStayInPage")}
      </Menu.Item>
    </Menu>
  );

  const goBack = () => {
    setProChange(false);
    if (isGoBack) {
      history.goBack();
    } else {
      if (id !== "new" && id !== "add") {
        history.replace({ ...history.location, pathname: "/stores/new" });
      }
      codeGenerator(isGoParentId);
      form.setFieldsValue({ name: "" });
      handelActiveParents("","");
      IX_Name.current.focus();
    }
  };



  // search client & supp by type num // AK~
  const handelActiveParents = async (search, active) => {
    try {
      const { data: responce } = await getActiveParents(search, active);
      setBranchParentOpt(responce.data);
    } catch (error) {
      if (error.request) {
        message.error(_t("msgServerDisconnected"), 3);
      } else {
        message.error(_t("msgUnknownError"), 3);     
      }
    } finally {
      setLoadData(false);
    }
  };

  //******************************// //*******************// Save //*******************// //******************************//

  const onFinish = async (values) => {
    if (typeof onNewBranch === "function") {
      values.id = "new";
    } else {
      values.id = id;
    }
    values.logo = avatarPath;
    values.companyId = companyId;
    values.branchSettingId = "b656c6f2-1a4e-4a06-bb3a-86bee829c145";
    if (values.isMain === undefined) {
      values.isMain = false;
    }
    try {
      const data = await editBranch(values);
      message.success(data.data.message, 3);
      if (typeof onNewBranch === "function") {
        onNewBranch(data);
      } else {
        goBack();
      }
    } catch (error) {
      console.error(error.response);
      if (error.response) {
        if (error.response?.data?.data?.ex.includes("IX_Code")) {
          IX_Code.current.focus();
          message.error(
            error.response?.data?.data?.message || _t("msgUnknownError"),
            3
          );
          setLoadData(false);
        } else if (error.response?.data?.data?.ex.includes("IX_Name")) {
          IX_Name.current.focus();
          message.error(
            error.response?.data?.data?.message || _t("msgUnknownError"),
            3
          );
          setLoadData(false);
        } else {
          message.error(
            error.response?.data?.message || _t("msgUnknownError"),
            3
          );
          setLoadData(false);
        }
      } else if (error.request) {
        message.error(_t("msgServerDisconnected"), 3);
        setLoadData(false);
      } else {
        message.error(_t("msgUnknownError"), 3);
        setLoadData(false);
      }
    }
  };

  const handelNewStores = (stores) => {
    const newStores = [...storeChildsOpt, stores.data.data];
    setStoreChildsOpt(newStores);
    setVisibility(false);
    console.log(stores.data.data.id);
    const newList = [...form.getFieldValue().storeIds, stores.data.data.id];
    form.setFieldsValue({
      storeIds: newList,
    });
  };

  //******************************// //*******************// Form Code //*******************// //******************************//
  const branchParentOptList =
    branchParentOpt.length > 0 &&
    branchParentOpt.map((item) =>
      item.id !== id ? (
        <Select.Option key={item.id} value={item.id}>
          {item.name}
        </Select.Option>
      ) : null
    );
  const storeChildsOptList =
    storeChildsOpt.length > 0 &&
    storeChildsOpt.map((item) => (
      <Select.Option key={item.id} value={item.id}>
        {item.name}
      </Select.Option>
    ));

  const [proChange, setProChange] = useState(false);
  const promptForm = () => {
    setProChange(true);
  };
  const { TextArea } = Input;

  //******************************// //*******************// Form //*******************// //******************************//

  return (
    <div key="mainDiv">
      <Modal
        visible={visibility}
        onCancel={() => handleCancel()}
        title={_t("strStores")}
        footer={null}
        destroyOnClose={true}
        width={1000}
      >
        <FormStores onNewStore={handelNewStores} />
      </Modal>

      <React.Fragment>
        <Prompt when={proChange} message={_t("strUnsavedChanges")} />

        <div className="bg">
          <Spin spinning={loadData} tip={_t("strLoading")}>
            <Form
              form={form}
              name="branchform"
              onFinish={onFinish}
              layout="vertical"
              initialValues={{ isMain: false }}
            >
              <Row gutter={12}>
                <Col xs={24} md={12}>
                  <Form.Item name="imagePath" label={_t("strLogo")}>
                    <UploadImage
                      previewImg={avatarPath}
                      setPreviewImg={setAvatarPath}
                      loading={loading}
                      setLoading={setLoading}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} />
                <Col xs={24} md={12}>
                  <Form.Item
                    label={_t("strBranchClassification")}
                    name="isMain"
                    hidden={typeof onNewBranch === "function"}
                  >
                    <Radio.Group buttonStyle="solid">
                      <Radio value={false} autoFocus>
                        {_t("strSub")}
                      </Radio>
                      <Radio value={true}>{_t("strMain")}</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} />

                <Col xs={24} md={12}>
                  <Form.Item
                    name="parentId"
                    label={_t("strMainBranchse")}
                    rules={[{ required: false }]}
                  >
                    <Select
                      allowClear
                      showSearch
                      onSearch={(value) => {
                        handelActiveParents(value, stateParintId);
                      }}
                      optionFilterProp="children"
                      notFoundContent={
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}></Empty>
                      }
                      onChange={onChangeParent}
                      placeholder={_t("strNotSelected")}
                    >
                      {branchParentOptList}
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24} md={12}>
                  <Form.Item
                    name="code"
                    label={_t("strCode")}
                    rules={[
                      {
                        required: true,
                        message: `${_t("strIsRequired")}`,
                      },
                      { min: 1, message: `${_t("strFrom3-200")}` },
                      { max: 200, message: `${_t("strFrom3-200")}` },
                    ]}
                  >
                    <Input
                      placeholder={_t("strCode")}
                      autoComplete="off"
                      maxLength={200}
                      onChange={promptForm}
                      ref={IX_Code}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} md={12}>
                  <Form.Item
                    name="name"
                    label={_t("strName")}
                    rules={[
                      { required: true, message: `${_t("strIsRequired")}` },
                      { min: 1, message: `${_t("strFrom3-200")}` },
                      { max: 200, message: `${_t("strFrom3-200")}` },
                    ]}
                  >
                    <Input
                      placeholder={_t("strName")}
                      maxLength={200}
                      autoComplete="off"
                      onChange={promptForm}
                      ref={IX_Name}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} md={12}>
                  <Form.Item name="phone" label={_t("strPhone")}>
                    <Input
                      placeholder={_t("strPhone")}
                      maxLength={20}
                      autoComplete="off"
                      onChange={promptForm}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="email"
                    label={_t("strEmail")}
                    rules={[{ type: "email", message: `${_t("strFixEmail")}` }]}
                  >
                    <Input
                      placeholder={_t("strEmail")}
                      maxLength={100}
                      autoComplete="off"
                      onChange={promptForm}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="storeIds"
                    label={_t("strStores")}
                    rules={[{ required: false }]}
                  >
                    <Select
                      allowClear
                      mode="multiple"
                      showSearch
                      optionFilterProp="children"
                      notFoundContent={
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}>
                          <Button
                            onClick={() => showModal()}
                            hidden={typeof onNewBranch === "function"}
                          >
                            {" "}
                            {_t("strAddNew")}{" "}
                          </Button>
                        </Empty>
                      }
                      placeholder={_t("strNotSelected")}
                    >
                      {storeChildsOptList}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} md={24}>
                  <Form.Item name="address" label={_t("strAddress")}>
                    <Input
                      placeholder={_t("strAddress")}
                      maxLength={200}
                      autoComplete="off"
                      onChange={promptForm}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} md={24}>
                  <Form.Item
                    name="note"
                    label={_t("strNote")}
                    rules={[
                      { required: false, message: `${_t("strIsRequired")}` },
                      { min: 1, message: `${_t("strFrom1-1000")}` },
                      { max: 1000, message: `${_t("strFrom1-1000")}` },
                    ]}
                  >
                    <TextArea
                      placeholder={_t("strNote")}
                      maxLength={1000}
                      autoComplete="off"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="inactive"
                    valuePropName="checked"
                    label={_t("strInActive")}
                  >
                    <Switch />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item>
                <Space size="large">
                  <Button type="primary" htmlType="submit" disabled={loading}>
                    {_t("strSave")}
                  </Button>
                  <Dropdown.Button
                    hidden={typeof onNewStore === "function"}
                    overlay={menu}
                    onClick={() => history.goBack()}
                  >
                    {" "}
                    {_t("strBack")}
                  </Dropdown.Button>
                  {isGoBack ? (
                    <i />
                  ) : (
                    <RetweetOutlined style={{ fontSize: 21, marginTop: 5 }} />
                  )}
                </Space>
              </Form.Item>
            </Form>
          </Spin>
        </div>
      </React.Fragment>
    </div>
  );
};

export default BranchForm;
