import React, { useEffect, useState } from "react";
import SortableList from "./sortableContainer";
import arrayMove from "array-move";
import { BarsOutlined } from "@ant-design/icons";
import { Dropdown, Button, message } from "antd";
import _t from "../../../languages/translate";

const SortableColumns = ({ value, onChange }) => {
  useEffect(() => {
    const fetchData = async () => {
      try {
        onChange && onChange(value);
      } catch (error) {
        if (error.response) {
          message.error(
            error.response.data?.message || _t("msgUnknownError"),
            3
          );
        } else if (error.request) {
          message.error(_t("msgServerDisconnected"), 3);
        } else {
          message.error(_t("msgUnknownError"), 3);
        }
      }
    };
    fetchData();
  }, [onChange]);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const sortedValue = arrayMove(value, oldIndex, newIndex);
    onChange && onChange(sortedValue);
  };

  const toggleShow = (data) => {
    const newValue = value.map((item) =>
      item.key === data.key ? { ...item, hidden: !item.hidden } : item
    );
    onChange && onChange(newValue);
  };

  return (
    <Dropdown
      overlay={
        <SortableList
          dataItems={value || []}
          onSortEnd={onSortEnd}
          onToggleShow={toggleShow}
        />
      }
      overlayClassName="sortable-dropdown"
      placement="bottomRight"
      arrow
    >
      <Button style={{ width: "100%" }} type="dashed" icon={<BarsOutlined />}>
        {_t("strSortColumns")}
      </Button>
    </Dropdown>
  );
};
export default SortableColumns;
