import React, { useState, useEffect } from "react";
import {
  Form,
  Spin,
  TreeSelect,
  Row,
  Col,
  DatePicker,
  message,
  Button,
  Divider,
  Checkbox,
  Space,
  Cascader,
  Select,
  Tooltip
} from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import _t from "../../../languages/translate";
import { getActiveTreeStore } from "../../../services/storeService";
import { getActiveTreeItem } from "../../../services/Items/itemService";
import { getStockTypes } from "../../../services/reportsTempService";

import moment from "moment";

const FilterInventoryRep = ({ onHandelData, onFilterData, filterType }) => {
  debugger;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [treeStocksId, setTreeStocksId] = useState([""]);
  const [treeItemsId, setTreeItemsId] = useState([""]);
  const [itemsOpt, setItemsOpt] = useState([]);
  const [storesOpt, setStoresOpt] = useState([]);
  const [stockTypesOpt, setStockTypesOpt] = useState([]);
  const { SHOW_PARENT } = TreeSelect;
  const { Option } = Select;
  const dateFormatList = [
    "DD/MM/YYYY HH:mm",
    "DDMMYYYY HH:mm",
    "DDMMYYYY HHmm",
    "DDMMYYYYHHmm",
    "DDMMYYYY",
    "DDMMYYHHmm",
    "DDMMYY",
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        onSearchItems("");
        onSearchStocks("");

        const { data: resultStockType } = await getStockTypes();
        setStockTypesOpt(resultStockType.data);


        if (onFilterData !== undefined) {
          form.setFieldsValue({
            itemIds: onFilterData.itemIds,
            storeIds: onFilterData.storeIds,
            toDate:
              onFilterData.toDate !== undefined
                ? moment(onFilterData.toDate)
                : "",
            showDetails: onFilterData.showDetails,
            showEmptyStore: onFilterData.showEmptyStore,
            showEmptyItem: onFilterData.showEmptyItem,
            showReservedQty: onFilterData.showReservedQty,
            showNegativeStoreOnly: onFilterData.showNegativeStoreOnly,
            showMinimumStoreOnly: onFilterData.showMinimumStoreOnly,
            showMaximumStoreOnly: onFilterData.showMaximumStoreOnly,
            subMinimumFromDefUnit: onFilterData.subMinimumFromDefUnit,
            type: onFilterData.type,
          });
        }
      } catch (error) {
        if (error.response) {
          message.error(
            error.response.data?.message || _t("msgUnknownError"),
            3
          );
        } else if (error.request) {
          message.error(_t("msgServerDisconnected"), 3);
        } else {
          message.error(_t("msgUnknownError"), 3);
        }
      }
    };
    fetchData();
  }, [setItemsOpt, setStoresOpt, setStockTypesOpt]);

  const onChangeItems = (value) => {
    setTreeItemsId(value);
  };

  const onChangeStocks = (value) => {
    setTreeStocksId(value);
  };

  const [itemState, setItemState] = useState("");
  const onSearchItems = async (searchValue) => {
    try {
      setItemState(searchValue);
      const { data: result } = await getActiveTreeItem(searchValue, "");
      setItemsOpt(result.data);
    } catch (error) {
      if (error.response) {
        message.error(error.response.data?.message || _t("msgUnknownError"), 3);
      } else if (error.request) {
        message.error(_t("msgServerDisconnected"), 3);
      } else {
        message.error(_t("msgUnknownError"), 3);
      }
    }
  };
  const [stockState, setStockState] = useState("");
  const onSearchStocks = async (searchValue) => {
    try {
      setStockState(searchValue);
      const { data: result } = await getActiveTreeStore(searchValue, "");
      setStoresOpt(result.data);
    } catch (error) {
      if (error.response) {
        message.error(error.response.data?.message || _t("msgUnknownError"), 3);
      } else if (error.request) {
        message.error(_t("msgServerDisconnected"), 3);
      } else {
        message.error(_t("msgUnknownError"), 3);
      }
    }
  };

  const tPropsItems = {
    treeData: itemsOpt,
    allowClear: true,
    showSearch: true,
    onSearch: onSearchItems,
    onDropdownVisibleChange: () => setItemState(""),
    searchValue: itemState,
    treeNodeFilterProp: "title",
    value: treeItemsId,
    onChange: onChangeItems,
    treeCheckable: true,
    treeDefaultExpandAll: false,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: _t("strNotSelected"),
    style: {
      width: "100%",
    },
  };

  const tPropsStore = {
    treeData: storesOpt,
    allowClear: true,
    showSearch: true,
    onSearch: onSearchStocks,
    onDropdownVisibleChange: () => setStockState(""),
    searchValue: stockState,
    treeNodeFilterProp: "title",
    value: treeStocksId,
    onChange: onChangeStocks,
    treeCheckable: true,
    treeDefaultExpandAll: false,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: _t("strNotSelected"),
    style: {
      width: "100%",
    },
  };

  const [vReserved, setVReserved] = useState(true);
  const [vSubMinimum, setVSubMinimum] = useState(true);
  const handelType = () => {
    const typeval = form.getFieldValue("type");
    if (typeval[0] === "0") {
      setVReserved(true);
      form.setFieldsValue({ showReservedQty: false })
    } else {
      setVReserved(false);
    }
    if (typeval[0] === "1") {
      setVSubMinimum(false);
    } else {
      setVSubMinimum(true);
      form.setFieldsValue({ subMinimumFromDefUnit: false })
    }
  };

  const onFinish = async (values) => {
    setLoading(true);
    if (values.toDate !== "") {
      values.toDate = values.toDate?.toISOString();
    }
    if (values.itemIds === undefined || values.itemIds === "") {
      values.itemIds = [];
    }
    if (values.storeIds === undefined || values.storeIds === "") {
      values.storeIds = [];
    }
    if (values.type === undefined || values.type === "") {
      values.type = ["0"];
    }

    let obj = {
      reportFilter: values,
      timeShift: new Date().getTimezoneOffset(),
      columns: [],
    };

    onHandelData(obj);
    setLoading(false);
  };

  return (
    <Spin spinning={loading} tip={_t("strLoading")}>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{ type: ["0"], toDate: moment() }}
      >
        <Row gutter={12}>
          <Col span={24}>
            <Form.Item name="itemIds" label={_t("strItems")}>
              <TreeSelect {...tPropsItems} size="small" />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item name="storeIds" label={_t("strStores")}>
              <TreeSelect {...tPropsStore} size="small" />
            </Form.Item>
          </Col>

          {!filterType && (
            <Col span={24}>
              <Form.Item name="toDate" label={_t("strToDate")}>
                <DatePicker
                  showTime={{ format: "HH:mm" }}
                  format={dateFormatList}
                  className="dateTimeStyle"
                  size="small"
                />
              </Form.Item>
            </Col>
          )}

          <Col span={24}>
            <Form.Item
              name="type"
              tooltip={{
                title: _t("msgItemUnitRepType"),
                icon: <InfoCircleOutlined />,
              }}
              label={_t("strReportType")}
              rules={[
                {
                  required: true,
                  message: `${_t("strIsRequired")}`,
                },
              ]}
            >
              <Cascader
                showSearch
                allowClear={false}
                optionFilterProp="children"
                options={stockTypesOpt}
                size="small"
                onChange={handelType}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Space size="small">
              <Form.Item name="showDetails" valuePropName="checked">
                <Checkbox>{_t("strShowDetails")}</Checkbox>
              </Form.Item>
              <Form.Item>
                <Divider type="vertical" />
              </Form.Item>

              <Form.Item name="showReservedQty" valuePropName="checked">
                <Checkbox disabled={vReserved}>{_t("strReservedQty")}</Checkbox>
              </Form.Item>

              <Form.Item>
                <Divider type="vertical" />
              </Form.Item>

              <Form.Item name="showEmptyStore" valuePropName="checked">
                <Checkbox>{_t("strShowEmptyStore")}</Checkbox>
              </Form.Item>

              <Form.Item>
                <Divider type="vertical" />
              </Form.Item>

              <Form.Item name="showEmptyItem" valuePropName="checked">
                <Checkbox>{_t("strShowEmptyItems")}</Checkbox>
              </Form.Item>
            </Space>
          </Col>

          <Col span={24}>
            <Space size="small">
              <Form.Item name="showNegativeStoreOnly" valuePropName="checked">
                <Checkbox>{_t("strShowNegativeStoreOnly")}</Checkbox>
              </Form.Item>

              <Form.Item>
                <Divider type="vertical" />
              </Form.Item>

              <Form.Item name="showMinimumStoreOnly" valuePropName="checked">
                <Checkbox >{_t("strShowMinimumStoreOnly")}</Checkbox>
              </Form.Item>

              <Form.Item>
                <Divider type="vertical" />
              </Form.Item>

              <Form.Item name="showMaximumStoreOnly" valuePropName="checked">
                <Checkbox>{_t("strShowMaximumStoreOnly")}</Checkbox>
              </Form.Item>

              <Form.Item name="subMinimumFromDefUnit" valuePropName="checked">
                <Checkbox disabled={vSubMinimum}><Tooltip title={_t("msgSubMinimumFromDefUnit")}>
                  <span>{_t("strSubMinimumFromDefUnit")}</span>
                </Tooltip></Checkbox>
              </Form.Item>


            </Space>
          </Col>

          <Col span={24}>
            <Form.Item style={{ marginBottom: 0, marginTop: 20 }}>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: 150 }}
                size="small"
              >
                {filterType ? _t("strAdd") : _t("strSearch")}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default FilterInventoryRep;
