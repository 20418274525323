import { _URL } from "../../config.json";
import http from "./../http";

const apiEndpoint = _URL + "/api/StoreSettlement";

export function getPagers(query) {
  return http.post(`${apiEndpoint}/GetPager?${query}`);
}

export function getById(Id) {
  return http.get(`${apiEndpoint}/${Id}`);
}

export function edit(obj) {
    return http.post(apiEndpoint, obj); 
}

export function getAll() {
  return http.get(`${apiEndpoint}`);
}

export function remove(Id) {
  return http.delete(`${apiEndpoint}/${Id}`);
}

export function getPager(current, pageSize) {
  return http.post(
    `${apiEndpoint}/GetPager?PageNumber=${current}&PageSize=${pageSize}`
  );
}

export function getNewCode() {
  return http.post(`${apiEndpoint}/GetNewCode`);
}
