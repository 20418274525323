import React, { useEffect, useState, useRef } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import { Form, Input, Button, Tree, Card, Row, Col, Spin, message } from "antd";
import { DownOutlined } from "@ant-design/icons";
import {
  getUserRoleById,
  editUserRole,
} from "../../../services/Authorization/userRoleService";
import { getRoles } from "../../../services/Authorization/RoleService";
import _t from "../../../languages/translate";

const UserRolesForm = () => {
  const [form] = Form.useForm();
  let { id } = useParams();
  let history = useHistory();
  const IX_Name = useRef(null);
  const [treePermissions, setTreePermissions] = useState([]);
  const [expandedKeys, setExpandedKeys] = useState(["0-0-0", "0-0-1"]);
  const [checkedKeys, setCheckedKeys] = useState([]);
  const [loadData, setLoadData] = useState(true);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const { TextArea } = Input;

  const onExpand = (expandedKeys) => {
    setExpandedKeys(expandedKeys);
    setAutoExpandParent(false);
  };

  const onCheck = (checkedKeys, checked) => {
    const newCheckedKeys = [...checkedKeys];

    setCheckedKeys(newCheckedKeys);
  };

  const onSelect = (selectedKeys, info) => {
    setSelectedKeys(selectedKeys);
  };

  useEffect(() => {
    IX_Name.current.focus();
    async function fetchData() {
      const treeObj = await getRoles();
      setTreePermissions(treeObj);
      if (id === "new") {
        return setLoadData(false);
      }
      try {
        const { data } = await getUserRoleById(id);
        const roles = data.data.roles.split(",");

        setCheckedKeys(roles);
        form.setFieldsValue({
          id: data.data.id,
          name: data.data.name,
          note: data.data.note,
        });
        setLoadData(false);
      } catch (error) {
        if (error.response) {
          message.error(
            error.response.data?.message || _t("msgUnknownError"),
            3
          );
          setLoadData(false);
        } else if (error.request) {
          message.error(_t("msgServerDisconnected"), 3);
          setLoadData(false);
        } else {
          message.error(_t("msgUnknownError"), 3);
          setLoadData(false);
        }
      }
    }
    fetchData();
  }, [id, history]);

  const goBack = () => {
    console.log(history);
    history.push("/roles");
  };

  const onFinish = async (values) => {
    values.id = id;
    values.roles = checkedKeys.join();

    console.log(values);
    try {
      await editUserRole(values);
      goBack();
    } catch (error) {
      if (error.response) {
        if (error.response.data.ex.includes("IX_Name")) {
          IX_Name.current.focus();
          message.error(
            error.response.data?.message || _t("msgUnknownError"),
            3
          );
          setLoadData(false);
        } else {
          message.error(
            error.response.data?.message || _t("msgUnknownError"),
            3
          );
          setLoadData(false);
        }
      } else if (error.request) {
        message.error(_t("msgServerDisconnected"), 3);
        setLoadData(false);
      } else {
        message.error(_t("msgUnknownError"), 3);
        setLoadData(false);
      }
    }
  };

  return (
    <div className="bg">
      <Spin spinning={loadData} tip={_t("strLoading")}>
        <Form
          form={form}
          name="nest-messages"
          layout="vertical"
          onFinish={onFinish}
        >
          <Row gutter={12}>
            <Col xs={24} md={12}>
              <Form.Item
                name="name"
                label={_t("strName")}
                rules={[
                  {
                    required: true,
                    message: `${_t("strIsRequired")}`,
                  },
                  { min: 1, message: `${_t("strFrom3-200")}` },
                  { max: 200, message: `${_t("strFrom3-200")}` },
                ]}
              >
                <Input ref={IX_Name} maxLength={200} />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name="note"
                label={_t("strNote")}
                rules={[
                  { required: false, message: `${_t("strIsRequired")}` },
                  { min: 1, message: `${_t("strFrom1-1000")}` },
                  { max: 1000, message: `${_t("strFrom1-1000")}` },
                ]}
              >
                <TextArea maxLength={1000} placeholder={_t("strNote")} />
              </Form.Item>
            </Col>
            <Col xs={24} md={24}>
              <Form.Item name="roles" label={_t("strPermissions")}>
                <Card>
                  <Tree
                    checkable
                    onExpand={onExpand}
                    expandedKeys={expandedKeys}
                    autoExpandParent={autoExpandParent}
                    onCheck={onCheck}
                    checkedKeys={checkedKeys}
                    onSelect={onSelect}
                    selectedKeys={selectedKeys}
                    height={300}
                    showLine
                    switcherIcon={<DownOutlined />}
                    treeData={treePermissions}
                  />
                </Card>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Button type="primary" htmlType="submit" className="buttonForms">
              {_t("strSubmit")}
            </Button>
            <Button className="buttonForms">
              <Link to="/roles">{_t("strBack")}</Link>
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};

export default UserRolesForm;
