import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import moment from "moment";
import {
  Form,
  message,
  Card,
  Row,
  Col,
  DatePicker,
  Space,
  Tabs,
  Popover,
  Button,
  Select,
  Tooltip,
  Divider,
  Skeleton,
  Table,
} from "antd";
import DataTable, { pageSizeKey } from "../helper/dataTable/dataTable";
import {
  ReloadOutlined,
  PartitionOutlined,
  LinkOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import {
  getActiveByUser,
  getViewDashboardByObj,
} from "../../services/dashboardTempService";
import _t from "../../languages/translate";
const savedPageSize = localStorage.getItem(pageSizeKey);

const PageDashboard = () => {
  const [form] = Form.useForm();
  const [tabs, setTabs] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [detailColumns, setDetailColumns] = useState([]);
  const [loadData, setLoadData] = useState(false);
  const [columns, setColumns] = useState([]);
  const [reportKey, setReportKey] = useState("");
  const [reportFilter, setReportFilter] = useState({});
  const [resId, setResId] = useState("");
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [meta, setMeta] = useState("");
  const [spin, setSpin] = useState(false);
  const location = useLocation();
  const millisecond = 60000;
  const { Option } = Select;
  const { TabPane } = Tabs;
  const dateFormatList = [
    "DD/MM/YYYY HH:mm",
    "DDMMYYYY HH:mm",
    "DDMMYYYY HHmm",
    "DDMMYYYYHHmm",
    "DDMMYYYY",
    "DDMMYYHHmm",
    "DDMMYY",
  ];
  //** It calls data evry 2 min
  useEffect(() => {
    const interval = setInterval(() => {
      getView(resId); // init page
    }, millisecond);
    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, []);

  //** Sync data button .
  const reload = (
    <>
      <Tooltip placement="topLeft" title={_t("strSyncData")} color="#2db7f5">
        <ReloadOutlined
          style={{ fontSize: 16, marginTop: 5 }}
          onClick={() => {
            setSpin(true);
            setLoadData(true);
            getView(resId);
          }}
          spin={spin}
        />
      </Tooltip>
      <Divider type="vertical" />
    </>
  );

  const options = (
    <>
      <Option value="0">{_t("strToday")}</Option>
      <Option value="1">{_t("strWeek")}</Option>
      <Option value="2">{_t("strMonth")}</Option>
      <Option value="3">{_t("strYear")}</Option>
    </>
  );

  const handelTimePeriod = (val, type) => {
    setLoadData(true);
    if (val === "0") {
      let start = moment(moment().startOf("day").toDate());
      let end = moment(moment().endOf("day").toDate());
      if (type === "def") {
        form.setFieldsValue({ fromDate: start, toDate: end });
      } else {
        form.setFieldsValue({ fromDeliveryDate: start, toDeliveryDate: end });
      }
    }
    if (val === "1") {
      let start = moment(moment().startOf("week").toDate());
      let end = moment(moment().endOf("week").toDate());
      if (type === "def") {
        form.setFieldsValue({ fromDate: start, toDate: end });
      } else {
        form.setFieldsValue({ fromDeliveryDate: start, toDeliveryDate: end });
      }
    }
    if (val === "2") {
      let start = moment(moment().startOf("month").toDate());
      let end = moment(moment().endOf("month").toDate());
      if (type === "def") {
        form.setFieldsValue({ fromDate: start, toDate: end });
      } else {
        form.setFieldsValue({ fromDeliveryDate: start, toDeliveryDate: end });
      }
    }
    if (val === "3") {
      let start = moment(moment().startOf("year").toDate());
      let end = moment(moment().endOf("year").toDate());
      if (type === "def") {
        form.setFieldsValue({ fromDate: start, toDate: end });
      } else {
        form.setFieldsValue({ fromDeliveryDate: start, toDeliveryDate: end });
      }
    }
    if (val === undefined) {
      if (type === "def") {
        form.setFieldsValue({ fromDate: "", toDate: "" });
      } else {
        form.setFieldsValue({ fromDeliveryDate: "", toDeliveryDate: "" });
      }
    }
  };

  const formValuesChange = (value) => {
    if (value.hasOwnProperty("timePeriod")) {
      handelTimePeriod(value.timePeriod, "def");
    } else if (value.hasOwnProperty("timePeriodDeliveryDate")) {
      handelTimePeriod(value.timePeriodDeliveryDate, "del");
    }
    handelDateObj();
  };

  const handelDateObj = () => {
    let allValues = form.getFieldsValue();

    let dateobj = [
      {
        from:
          allValues.fromDate !== "" ? allValues.fromDate?.toISOString() : "",
        to: allValues.toDate !== "" ? allValues.toDate?.toISOString() : "",
      },
      {
        from:
          allValues.fromDeliveryDate !== ""
            ? allValues.fromDeliveryDate?.toISOString()
            : "",
        to:
          allValues.toDeliveryDate !== ""
            ? allValues.toDeliveryDate?.toISOString()
            : "",
      },
    ];
    let dataObj = JSON.parse(sessionStorage.getItem("dsh_object_" + resId));
    dataObj.dateFilters = dateobj;
    dataObj.timeShift = new Date().getTimezoneOffset();
    dataObj.timePeriod = allValues.timePeriod;
    dataObj.timePeriodDeliveryDate = allValues.timePeriodDeliveryDate;
    sessionStorage.setItem("dsh_object_" + resId, JSON.stringify(dataObj));
    getView(resId);
  };

  const filter = (
    <Skeleton loading={loadData} active>
      <Form
        form={form}
        name="itemform"
        layout="vertical"
        onValuesChange={formValuesChange}
      >
        <Row gutter={12} style={{ marginBottom: -20 }}>
          <Col spin={12}>
            <Space>
              <Form.Item name="timePeriod">
                <Select
                  style={{ width: 120 }}
                  placeholder={_t("strTimePeriod")}
                  bordered={false}
                  onChange={(val) => {
                    handelTimePeriod(val, "def");
                  }}
                  allowClear
                >
                  {options}
                </Select>
              </Form.Item>
              {reportKey !== "Inventory" ? (
                <Form.Item name="fromDate">
                  <DatePicker
                    size="small"
                    showTime={{ format: "HH:mm" }}
                    format={dateFormatList}
                    className="dateTimeStyle"
                    placeholder={_t("strFromDate")}
                  />
                </Form.Item>
              ) : undefined}
              <Form.Item name="toDate">
                <DatePicker
                  size="small"
                  showTime={{ format: "HH:mm" }}
                  format={dateFormatList}
                  className="dateTimeStyle"
                  placeholder={_t("strToDate")}
                />
              </Form.Item>
            </Space>
          </Col>
          {reportKey === "Orders" && (
            <Col spin={12}>
              <Space>
                <Form.Item name="timePeriodDeliveryDate">
                  <Select
                    style={{ width: 120 }}
                    placeholder={_t("strTimePeriod")}
                    bordered={false}
                    onChange={(val) => {
                      handelTimePeriod(val, "del");
                    }}
                    allowClear
                  >
                    {options}
                  </Select>
                </Form.Item>
                <Form.Item name="fromDeliveryDate">
                  <DatePicker
                    size="small"
                    showTime={{ format: "HH:mm" }}
                    format={dateFormatList}
                    className="dateTimeStyle"
                    placeholder={_t("strFromDeliveryDate")}
                  />
                </Form.Item>
                <Form.Item name="toDeliveryDate">
                  <DatePicker
                    size="small"
                    showTime={{ format: "HH:mm" }}
                    format={dateFormatList}
                    className="dateTimeStyle"
                    placeholder={_t("strToDeliveryDate")}
                  />
                </Form.Item>
              </Space>
            </Col>
          )}
        </Row>
      </Form>
    </Skeleton>
  );

  const customExpandIcon = (props) => {
    if (props.record?.children?.length < 1) {
      return <span></span>;
    }
    if (props.expanded) {
      return (
        <button
          type="button"
          className="ant-table-row-expand-icon ant-table-row-expand-icon-expanded"
          onClick={(e) => {
            props.onExpand(props.record, e);
          }}
        />
      );
    } else {
      return (
        <button
          type="button"
          className="ant-table-row-expand-icon ant-table-row-expand-icon-collapsed"
          onClick={(e) => {
            props.onExpand(props.record, e);
          }}
        />
      );
    }
  };

  const expandAll = (
    <>
      <Tooltip
        placement="topLeft"
        title={_t("strShowAllDetails")}
        color="#2db7f5"
      >
        <PartitionOutlined
          style={{ fontSize: 16, marginTop: 5 }}
          onClick={() => {
            setExpandedRowKeys(
              expandedRowKeys.length === dataSource.length
                ? []
                : dataSource.map((d) => d.key)
            );
          }}
        />
      </Tooltip>
      <Divider type="vertical" />
    </>
  );

  //**.start >> Drawing links ********************
  const linksColumns = [
    {
      key: "reportTempMenu",
      //  dataIndex: "reportTempMenu",
      align: "center",
      title: <LinkOutlined />,
      width: 50,
      fixed: "left",
      render: (item) => {
        return (
          <Popover placement="bottomRight" content={() => linkCont(item)}>
            <Button
              type="link"
              icon={<MoreOutlined style={{ color: "#3a7df2", fontSize: 16 }} />}
            />
          </Popover>
        );
      },
    },
  ];

  const linkCont = (item) => (
    <div>
      {item.reportTempMenu.map((e) =>
        e.type === 0 ? (
          <p key={e.key}>
            <Link target="_blank" rel="noopener noreferrer" to={e.url}>
              <label style={{ cursor: "pointer" }}>{e.name}</label>
            </Link>
          </p>
        ) : (
          <p key={e.key}>
            <Link
              target="_blank"
              rel="noopener noreferrer"
              to={e.url}
              onClick={() => handelLinkObj(item, e.reportKey)}
            >
              <label style={{ cursor: "pointer" }}>{e.name}</label>
            </Link>
          </p>
        )
      )}
    </div>
  );

  const handelLinkObj = (item, repKey) => {
    let obj = {
      reportFilter: reportFilter,
      row: JSON.stringify(item),
      reportKey: repKey,
    };
    localStorage.setItem("linkObj", JSON.stringify(obj));
  };
  //**.end >> Drawing links ********************

  const [hasDetails, setHasDetails] = useState(false);

  const expandedRowRender = (reqord) => {
    const data = [...reqord.details];
    if (data.length)
      return (
        <Table
          columns={[...linksColumns, ...detailColumns]}
          dataSource={data}
          pagination={{
            hideOnSinglePage: true,
            size: "small",
            showSizeChanger: false,
          }}
        />
      );

    return false;
  };
  const isRowExpandable = (reqord) => !!reqord.details?.length;

  //** Common table for all templates.
  const table = (
    <>
      <DataTable
        key={resId}
        loading={loadData}
        expandIconColumnIndex={1}
        columns={[...linksColumns, ...columns]}
        saveColumnsProps={"dsh_t" + resId}
        dataSource={dataSource}
     
        extraActions={expandAll}
        expandedRowKeys={expandedRowKeys}
        pageSize={["10", "20", "30", "40", "50", "100"]}
        reload={reload}
        extra={filter}
        bordered
        rowExpandable={isRowExpandable}
        expandedRowRender={hasDetails && expandedRowRender}
        extraActions={expandAll}
        expandIcon={(x) => customExpandIcon(x)}
        meta={meta}
        onExpand={(expanded, record) => {
          if (!expanded)
            setExpandedRowKeys((state) =>
              state.filter((e) => e !== record.key)
            );
          else setExpandedRowKeys((state) => [...state, record.key]);
        }}
        rowClassName={(record, index) =>
          record.rowType === "Total"
            ? "treeMain-row"
            : record.rowType === "Detail"
            ? "treeDetails-row"
            : index % 2 === 0
            ? "table-row-light"
            : "table-row-dark"
        }
        hideStyle
      />
    </>
  );

  //** To get the template object by id.
  const eventTabs = (id) => {
    setLoadData(true);
    form.resetFields();

    setResId(id);

    if (JSON.parse(sessionStorage.getItem("dsh_object_" + id))) {
      let dataObj = JSON.parse(sessionStorage.getItem("dsh_object_" + id));
      form.setFieldsValue({
        fromDate:
          dataObj.dateFilters[0].from !== "" &&
          dataObj.dateFilters[0].from !== undefined
            ? moment(dataObj.dateFilters[0].from)
            : "",
        toDate:
          dataObj.dateFilters[0].to !== "" &&
          dataObj.dateFilters[0].to !== undefined
            ? moment(dataObj.dateFilters[0].to)
            : "",
        fromDeliveryDate:
          dataObj.dateFilters[1].from !== "" &&
          dataObj.dateFilters[1].from !== undefined
            ? moment(dataObj.dateFilters[1].from)
            : "",
        toDeliveryDate:
          dataObj.dateFilters[1].to !== "" &&
          dataObj.dateFilters[1].to !== undefined
            ? moment(dataObj.dateFilters[1].to)
            : "",

        timePeriod: dataObj.timePeriod,

        timePeriodDeliveryDate: dataObj.timePeriodDeliveryDate,
      });
    } else {
      sessionStorage.setItem(
        "dsh_object_" + id,
        JSON.stringify({
          id: id,
          dateFilters: [
            { from: "", to: "" },
            { from: "", to: "" },
          ],
          timeShift: 0,
        })
      );
    }

    getView(id);
  };

  //** Page init
  useEffect(() => {
    setLoadData(true);
    async function fetchData() {
      const { data: objTabs } = await getActiveByUser();

      if (objTabs.data.length !== 0) {
        setResId(objTabs.data[0].id);
      }

      setTabs(objTabs.data);
      if (objTabs.data.length === 0) return;
      eventTabs(objTabs.data[0].id);
      getView(objTabs.data[0].id);
    }
    fetchData();
  }, []);

  useEffect(() => {
    setLoadData(true);
    getView(resId);
  }, [savedPageSize, location.search]);

  //** end point
  const getView = async (_Id) => {
    const query = new URLSearchParams(location.search);

    if (savedPageSize && !query.has(pageSizeKey))
      query.set(pageSizeKey, savedPageSize);
    try {
      let dataObj = JSON.parse(sessionStorage.getItem("dsh_object_" + _Id));

      if (dataObj !== null) {
        const { data: responce } = await getViewDashboardByObj(
          query.toString(),
          dataObj
        );
        
        if (responce.data.columns.length > 0) {
          setReportKey(responce.data.reportKey);
          setColumns(responce.data.columns);
          setDetailColumns(responce.data.detailColumns);
          setHasDetails(
            responce.data.detailColumns.length === 0 ? false : true
          );
          setDataSource(responce.data.dataSource);
          setReportFilter(responce.data.reportFilter);
          setMeta(responce.meta);
        } else {
          setColumns([]);
          setDataSource([]);
          message.error(_t("msgThereIsNoData"), 3);
        }
      }
    } catch (error) {
      if (error.response) {
        message.error(error.response.data?.message || _t("msgUnknownError"), 3);
      } else if (error.request) {
        message.error(_t("msgServerDisconnected"), 3);
      } else {
        message.error(_t("msgUnknownError"), 3);
      }
    } finally {
      setLoadData(false);
      setSpin(false);
    }
  };

  return (
    <Row gutter={12}>
      <Col span={24} style={{ marginBottom: 15 }}>
        <Card bodyStyle={{ padding: "0px 15px" }}>
          <Tabs defaultActiveKey="1" onChange={eventTabs}>
            {tabs.map((item, _) => {
              return (
                <TabPane
                  tab={item.name}
                  key={item.id}
                  style={{ marginTop: -8 }}
                >
                  {table}
                </TabPane>
              );
            })}
          </Tabs>
        </Card>
      </Col>
    </Row>
  );
};

export default PageDashboard;
