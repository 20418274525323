import React, { useState, useEffect, useRef } from "react";
import { permissions } from "../../../services/Authorization/authService";
import moment from "moment";
import { Prompt } from "react-router";
import { useHistory, useParams, Link } from "react-router-dom";
import {
  Form,
  Spin,
  Row,
  Col,
  Button,
  Input,
  Select,
  DatePicker,
  Popconfirm,
  InputNumber,
  Card,
  Space,
  Switch,
  Modal,
  message,
  Drawer,
  Dropdown,
  Menu,
  Checkbox,
} from "antd";
import {
  getNewManufacturingModelsCode,
  editManufacturingModels,
  getManufacturingModelsById,
} from "../../../services/Items/manufacturingModelsService";
import { getActiveChildren } from "../../../services/storeService";
import InvoicePrint from "../../helper/Print/InvoicePrint";
import { searchItem } from "../../../services/Items/itemService";
import {
  DeleteTwoTone,
  PlusOutlined,
  ExclamationCircleOutlined,
  RetweetOutlined,
} from "@ant-design/icons";
import InvItemListSelectorForm from "./../../helper/Modal/InvItemListSelector";
import _t from "../../../languages/translate";
import "moment/locale/ar-ly";

const ManufacturingModelsForm = () => {
  const [proChange, setProChange] = useState(false);
  const [loadData, setLoadData] = useState(true);
  const [isGoBack, setIsGoBack] = useState(true);
  const [form] = Form.useForm();
  const IX_Code = useRef(null);
  const toStoreInput = useRef(null);
  const { Option } = Select;
  let history = useHistory();
  const { TextArea, Search } = Input;
  const { confirm } = Modal;
  //******************************//
  const [companyId] = useState(window.localStorage.getItem("comId"));
  const [toStore, setToStore] = useState(false);
  const [listState_IN, setListState_IN] = useState([]);
  const [listState_Out, setListState_Out] = useState([]);
  //******************************//
  const [storeChildsOpt, setStoreChildsOpt] = useState([]);
  const [searchData_IN, setSearchData_IN] = useState([]);
  const [searchData_Out, setSearchData_Out] = useState([]);
  const [dataPrint, strDataPrint] = useState({});
  const [searchIndex, setSearchIndex] = useState();
  const [visibilityInvItemList_IN, setVisibilityInvItemList_IN] = useState(
    false
  );
  const [visibilityInvItemList_Out, setVisibilityInvItemList_Out] = useState(
    false
  );
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const dateFormatList = [
    "DD/MM/YYYY HH:mm",
    "DDMMYYYY HH:mm",
    "DDMMYYYY HHmm",
    "DDMMYYYYHHmm",
    "DDMMYYYY",
    "DDMMYYHHmm",
    "DDMMYY",
  ];

  const menu = (
    <Menu>
      <Menu.Item onClick={() => setIsGoBack(true)}>
        {_t("strLeaveThePage")}
      </Menu.Item>
      <Menu.Item onClick={() => setIsGoBack(false)}>
        {_t("strStayInPage")}
      </Menu.Item>
    </Menu>
  );
  //******************************//
  let { id } = useParams();


  //******************************//

  const IX_Name = useRef(null);
 

  //******************************// //*******************// Modal List IN //*******************// //******************************//

  const showModalInvItemList_IN = () => {
    setVisibilityInvItemList_IN(true);
  };

  const handleCancelInvItemList_IN = () => {
    setVisibilityInvItemList_IN(false);
  };

  //******************************// //*******************// Modal List Out //*******************// //******************************//

  const showModalInvItemList_Out = () => {
    setVisibilityInvItemList_Out(true);
  };

  const handleCancelInvItemList_Out = () => {
    setVisibilityInvItemList_Out(false);
  };

  //******************************// //*******************// Initialize Page //*******************// //******************************//

  useEffect(() => {
    IX_Name.current.focus();

    async function fetchData() {
      try {
        setLoadData(true);
        form.resetFields();
        setListState_IN([]);
        setListState_Out([]);


        onSearchFromStore("","");

        onSearchToStore("","");
   

        form.setFieldsValue({
          munfTemplateInDetails: [{}],
          munfTemplateOutDetails: [{}],
        });

        setProChange(false);
        setLoadData(false);
      } catch (error) {
        if (error.response) {
          message.error(
            error.response.data?.message || _t("msgUnknownError"),
            3
          );
          setLoadData(false);
        } else if (error.request) {
          message.error(_t("msgServerDisconnected"), 3);
          setLoadData(false);
        } else {
          message.error(_t("msgUnknownError"), 3);
          setLoadData(false);
        }
      }
    }
    fetchData();
  }, [id, setToStore, setStoreChildsOpt]);

  const codeGenerator = async () => {
    try {
      const { data: newData } = await getNewManufacturingModelsCode();
      form.setFieldsValue({ code: newData.data });
      setLoadData(false);
    } catch (error) {
      if (error.response) {
        message.error(error.response.data?.message || _t("msgUnknownError"), 3);
        setLoadData(false);
      } else if (error.request) {
        message.error(_t("msgServerDisconnected"), 3);
        setLoadData(false);
      } else {
        message.error(_t("msgUnknownError"), 3);
        setLoadData(false);
      }
    }
  };

  useEffect(() => {
    setLoadData(true);
    async function fetchData() {
      if (id === "new" || id === "add") {
        return codeGenerator();
      }
      try {
        const { data } = await getManufacturingModelsById(id);
        form.setFieldsValue({
          id: data.data.id,
          code: data.data.code,
          documentCode: data.data.documentCode,
          name: data.data.name,
          note: data.data.note,
          inactive: data.data.inactive,
          companyId: data.data.companyId,
          fromStoreId: data.data.fromStoreId,
          toStoreId: data.data.toStoreId,
          munfTemplateDate: moment(data.data.munfTemplateDate),
          munfTemplateInDetails: data.data.munfTemplateInDetails,
          munfTemplateOutDetails: data.data.munfTemplateOutDetails,
        });
        setLoadData(false);
      } catch (error) {
        if (error.response) {
          message.error(
            error.response.data?.message || _t("msgUnknownError"),
            3
          );
          setLoadData(false);
        } else if (error.request) {
          message.error(_t("msgServerDisconnected"), 3);
          setLoadData(false);
        } else {
          message.error(_t("msgUnknownError"), 3);
          setLoadData(false);
        }
      }
    }
    fetchData();
  }, [id, history]);

  const goBack = () => {
    setProChange(false);
    if (isGoBack) {
      history.goBack();
    } else {
      onNew(true);
      history.replace({
        ...history.location,
        pathname: "/manufacturingModel/new",
      });
    }
  };

  //******************************// //*******************// Save //*******************// //******************************//

  const onFinish = async (values) => {
    const checkList_IN = onCheckList_IN(values.munfTemplateInDetails);
    const checkList_Out = onCheckList_Out(values.munfTemplateOutDetails);
    if (checkList_IN) {
      values.munfTemplateInDetails = checkList_IN;
    } else {
      return;
    }
    if (checkList_Out) {
      values.munfTemplateOutDetails = checkList_Out;
    } else {
      return;
    }

    if (values.id !== "new") {
      values.id = id;
    }

    values.companyId = companyId;
    if (values.munfTemplateDate !== "") {
      values.munfTemplateDate = values.munfTemplateDate.toISOString();
    }

    try {
      const { data: response } = await editManufacturingModels(values);
      message.success(response.message, 3);
    
      goBack();
    } catch (error) {
      if (error.response?.data?.ex.includes("IX_Code")) {
        IX_Code.current.focus();
        message.error(error.response?.data?.message, 3);
        setLoadData(false);
      } else if (error.response?.data?.ex.includes("IX_Name")) {
        IX_Name.current.focus();
        message.error(error.response?.data?.message, 3);
        setLoadData(false);
      } else if (error.request) {
        message.error(_t("msgServerDisconnected"), 3);
        setLoadData(false);
      } else {
        message.error(_t("msgUnknownError"), 3);
        setLoadData(false);
      }
    }
  };

  //******************************// //*******************// New //*******************// //******************************//

  const onNew = (onSave) => {
    if (proChange && onSave === false) {
      confirm({
        icon: <ExclamationCircleOutlined />,
        content: _t("msSureToNew"),
        onOk() {
          setProChange(false);
          form.resetFields();
          codeGenerator();
          onSearchFromStore("","");
          onSearchToStore("","");
          form.setFieldsValue({
            munfTemplateInDetails: [{}],
            munfTemplateOutDetails: [{}],
          });
        },
        onCancel() {},
      });
    } else {
      setProChange(false);
      form.resetFields();
      codeGenerator();
      onSearchFromStore("","");
      onSearchToStore("","");
      form.setFieldsValue({
        munfTemplateInDetails: [{}],
        munfTemplateOutDetails: [{}],
      });
    }
  };

  //******************************// //*******************// Search List IN//*******************// //******************************//

  const onCheckList_IN = (values) => {
    let start = false;
    const removingItemEmpty = values.filter(function (item) {
      return item.itemId !== undefined;
    });

    if (removingItemEmpty.length === 0) {
      message.error(_t("msgListItemsReq"), 5);
      return false;
    }

    removingItemEmpty.map((items, index) => {
      if (items.qty === null || items.qty === 0 || items.qty === undefined) {
        message.error(_t("msgQTYreqOne") + (index + 1) + _t("msgQTYreqTwo"), 3);
        return false;
      }
      start = true;
    });
    if (start) {
      return removingItemEmpty;
    } else {
      return false;
    }
  };

  const codeSearch_IN = async (value, i, add) => {
    try {
      const { data: response } = await searchItem(value, "", false);

      setSearchData_IN(response.data);
      setSearchIndex(i);
      if (response.data.length > 1) {
        showModalInvItemList_IN();
      } else if (response.data.length === 1) {
        const newList = form.getFieldValue("munfTemplateInDetails");
        const OldId = newList[i].id;
        response.data.map((_, i) => {
          response.data[i].itemUnit_MapId =
            response.data[i].itemUnit_Maps[0].id;
          response.data[i].itemId = response.data[i].id;
          response.data[i].itemCode = response.data[i].code;
          response.data[i].itemName = response.data[i].name;
          response.data[i].qty = "1";
          response.data[i].note = "";
          delete response.data[i].id;
        });
        newList.splice(i, 1, ...response.data);
        newList[i].id = OldId;
        form.setFieldsValue({ munfTemplateInDetails: newList });
        setListState_IN(newList);

        let lastItem = newList?.length - 1;
        if (newList[lastItem].itemId !== 0) {
          add();
        }
        setProChange(true);
      } else {
        if (listState_IN.length !== 0) {
          form.setFieldsValue({ munfTemplateInDetails: listState_IN });

          let lastItem = listState_IN.length - 1;
          if (listState_IN[lastItem].itemId !== 0) {
            add();
          }
        }
        return message.error(_t("msgThereIsNoValue"), 3);
      }
    } catch (error) {
      if (error.response !== undefined) {
        if (error.response) {
          message.error(
            error.response.data?.message || _t("msgUnknownError"),
            3
          );
          setLoadData(false);
        } else if (error.request) {
          message.error(_t("msgServerDisconnected"), 3);
          setLoadData(false);
        } else {
          message.error(_t("msgUnknownError"), 3);
          setLoadData(false);
        }
      }
    }
  };

  const nameSearch_IN = async (value, i, add) => {
    try {
      const { data: response } = await searchItem("", value, false);
      setSearchData_IN(response.data);
      setSearchIndex(i);
      if (response.data.length > 1) {
        showModalInvItemList_IN();
      } else if (response.data.length === 1) {
        const newList = form.getFieldValue("munfTemplateInDetails");
        const OldId = newList[i].id;
        response.data.map((_, i) => {
          response.data[i].itemUnit_MapId =
            response.data[i].itemUnit_Maps[0].id;
          response.data[i].itemId = response.data[i].id;
          response.data[i].itemCode = response.data[i].code;
          response.data[i].itemName = response.data[i].name;
          response.data[i].qty = "1";
          response.data[i].note = "";
          delete response.data[i].id;
        });
        newList.splice(i, 1, ...response.data);
        newList[i].id = OldId;
        form.setFieldsValue({ munfTemplateInDetails: newList });
        setListState_IN(newList);
        let lastItem = newList.length - 1;
        if (newList[lastItem].itemId !== 0) {
          add();
        }
        setProChange(true);
      } else {
        if (listState_IN.length !== 0) {
          form.setFieldsValue({ munfTemplateInDetails: listState_IN });
          let lastItem = listState_IN.length - 1;
          if (listState_IN[lastItem].itemId !== 0) {
            add();
          }
        }
        return message.error(_t("msgThereIsNoValue"), 3);
      }
    } catch (error) {
      if (error.response) {
        if (error.response) {
          message.error(
            error.response.data?.message || _t("msgUnknownError"),
            3
          );
          setLoadData(false);
        } else if (error.request) {
          message.error(_t("msgServerDisconnected"), 3);
          setLoadData(false);
        } else {
          message.error(_t("msgUnknownError"), 3);
          setLoadData(false);
        }
      }
    }
  };

  const handelDataInvItemList_IN = (data, add) => {
    const newList = form.getFieldValue("munfTemplateInDetails");
    debugger;
    const OldId = newList[searchIndex]?.id;
    data.forEach((_, i) => {
      data[i].itemUnit_MapId = data[i].itemUnit_Maps[0].id;
      data[i].itemId = data[i].id;
      data[i].itemCode = data[i].code;
      data[i].itemName = data[i].name;
      data[i].qty = "1";
      data[i].note = "";
      delete data[i].id;
    });
    newList.splice(searchIndex, 1, ...data);
    newList[searchIndex].id = OldId;
    form.setFieldsValue({ munfTemplateInDetails: newList });
    let lastItem = newList.length - 1;
    if (newList[lastItem]?.itemId !== 0) {
      add();
    }
    setListState_IN(newList);
    setProChange(true);
  };

  //******************************// //*******************// Search List Out//*******************// //******************************//

  const onCheckList_Out = (values) => {
    let start = true;
    let totalDistribution = 0;
    const removingItemEmpty = values.filter(function (item) {
      return item.itemId !== undefined;
    });

    if (removingItemEmpty.length === 0) {
      message.error(_t("msgListItemsReq"), 5);
      start = false;
    }
    if (start) {
      removingItemEmpty.map((items, index) => {
        if (items.qty === null || items.qty === "" || items.qty === undefined) {
          message.error(
            _t("msgQTYreqOne") + (index + 1) + _t("msgQTYreqTwo"),
            3
          );
          start = false;
        }
      });
    }
    if (start) {
      removingItemEmpty.map((items, index) => {
        totalDistribution = totalDistribution + items.distributionRatio;
        if (
          items.distributionRatio === null ||
          items.distributionRatio === 0 ||
          items.distributionRatio === undefined
        ) {
          message.error(
            _t("msgDistributionReqOne") +
              (index + 1) +
              _t("msgDistributionReqTwo"),
            5
          );
          start = false;
        }
      });
    }
    if (start) {
      if (totalDistribution > 100 || totalDistribution < 100) {
        message.error(
          _t("msgDistributionRatio") +
            " " +
            totalDistribution +
            "%" +
            " " +
            _t("msgDistributionRatio2"),
          5
        );
        start = false;
      }
    }

    if (start) {
      return removingItemEmpty;
    } else {
      return false;
    }
  };

  const codeSearch_Out = async (value, i, add) => {
    try {
      const { data: response } = await searchItem(value, "", false);

      setSearchData_Out(response.data);
      setSearchIndex(i);
      if (response.data.length > 1) {
        showModalInvItemList_Out();
      } else if (response.data.length === 1) {
        const newList = form.getFieldValue("munfTemplateOutDetails");
        const OldId = newList[i].id;

        response.data.map((_, i) => {
          response.data[i].itemUnit_MapId =
            response.data[i].itemUnit_Maps[0].id;
          response.data[i].itemId = response.data[i].id;
          response.data[i].itemCode = response.data[i].code;
          response.data[i].itemName = response.data[i].name;
          response.data[i].qty = "1";
          response.data[i].note = "";
          delete response.data[i].id;
        });
        newList.splice(i, 1, ...response.data);
        newList[i].id = OldId;
        form.setFieldsValue({ munfTemplateOutDetails: newList });
        setListState_Out(newList);

        let lastItem = newList?.length - 1;
        if (newList[lastItem].itemId !== 0) {
          add();
        }
        setProChange(true);
      } else {
        if (listState_Out.length !== 0) {
          form.setFieldsValue({ munfTemplateOutDetails: listState_Out });

          let lastItem = listState_Out.length - 1;
          if (listState_Out[lastItem].itemId !== 0) {
            add();
          }
        }
        return message.error(_t("msgThereIsNoValue"), 3);
      }
    } catch (error) {
      if (error.response !== undefined) {
        if (error.response) {
          message.error(
            error.response.data?.message || _t("msgUnknownError"),
            3
          );
          setLoadData(false);
        } else if (error.request) {
          message.error(_t("msgServerDisconnected"), 3);
          setLoadData(false);
        } else {
          message.error(_t("msgUnknownError"), 3);
          setLoadData(false);
        }
      }
    }
  };

  const nameSearch_Out = async (value, i, add) => {
    try {
      const { data: response } = await searchItem("", value, false);
      setSearchData_Out(response.data);
      setSearchIndex(i);
      if (response.data.length > 1) {
        showModalInvItemList_Out();
      } else if (response.data.length === 1) {
        const newList = form.getFieldValue("munfTemplateOutDetails");
        const OldId = newList[i].id;

        response.data.map((_, i) => {
          response.data[i].itemUnit_MapId =
            response.data[i].itemUnit_Maps[0].id;
          response.data[i].itemId = response.data[i].id;
          response.data[i].itemCode = response.data[i].code;
          response.data[i].itemName = response.data[i].name;
          response.data[i].qty = "1";
          response.data[i].note = "";
          delete response.data[i].id;
        });
        newList.splice(i, 1, ...response.data);
        newList[i].id = OldId;
        form.setFieldsValue({ munfTemplateOutDetails: newList });
        setListState_Out(newList);
        let lastItem = newList.length - 1;
        if (newList[lastItem].itemId !== 0) {
          add();
        }
        setProChange(true);
      } else {
        if (listState_Out.length !== 0) {
          form.setFieldsValue({ munfTemplateOutDetails: listState_Out });
          let lastItem = listState_Out.length - 1;
          if (listState_Out[lastItem].itemId !== 0) {
            add();
          }
        }
        return message.error(_t("msgThereIsNoValue"), 3);
      }
    } catch (error) {
      if (error.response) {
        if (error.response) {
          message.error(
            error.response.data?.message || _t("msgUnknownError"),
            3
          );
          setLoadData(false);
        } else if (error.request) {
          message.error(_t("msgServerDisconnected"), 3);
          setLoadData(false);
        } else {
          message.error(_t("msgUnknownError"), 3);
          setLoadData(false);
        }
      }
    }
  };

  const handelDataInvItemList_Out = (data, add) => {
    setVisibilityInvItemList_Out(false);
    const newList = form.getFieldValue("munfTemplateOutDetails");
    const OldId = newList[searchIndex]?.id;
    data.map((_, i) => {
      data[i].itemUnit_MapId = data[i].itemUnit_Maps[0].id;
      data[i].itemId = data[i].id;
      data[i].itemCode = data[i].code;
      data[i].itemName = data[i].name;
      data[i].qty = "1";
      data[i].note = "";
      delete data[i].id;
    });
    newList.splice(searchIndex, 1, ...data);
    newList[searchIndex].id = OldId;
    form.setFieldsValue({ munfTemplateOutDetails: newList });
    let lastItem = newList.length - 1;
    if (newList[lastItem]?.itemId !== 0) {
      add();
    }
    setListState_Out(newList);
    setProChange(true);
  };

  //******************************// //*******************// List State//*******************// //******************************//

  const onDeleteList = (i, remove, typeList) => {
    remove(i);
    if (typeList == "In") {
      const newList = form.getFieldValue("munfTemplateInDetails");
      setListState_IN(newList);
    } else {
      const newList = form.getFieldValue("munfTemplateOutDetails");
      setListState_Out(newList);
    }
  };

  const onChangelist_In = () => {
    const newList = form.getFieldValue("munfTemplateInDetails");
    setListState_IN(newList);
  };

  const onChangelist_Out = () => {
    const newList = form.getFieldValue("munfTemplateOutDetails");
    setListState_Out(newList);
  };

  //******************************// //*******************// Print //*******************// //******************************//

  const invoiceData = {
    pattrenAr: null,
    pattrenEn: null,
    code: null,
    document: null,
    client: null,
    statusAr: null,
    statusEn: null,
    fromStore: null,
    toStore: null,
    date: null,
    expectedDeliveryDate: null,
    table: [],
  };

  const onPrint = () => {
    const dataObj = form.getFieldsValue();

    invoiceData.pattrenAr = dataObj.storeTransactionPatternNameAr;
    invoiceData.pattrenEn = dataObj.storeTransactionPatternNameEn;
    invoiceData.code = dataObj.code;
    invoiceData.document = dataObj.documentCode;
    invoiceData.client = dataObj.clientSupplierName;
    invoiceData.statusAr = dataObj.storeTransactionStatePatternNameAr;
    invoiceData.statusEn = dataObj.storeTransactionStatePatternNameEn;
    invoiceData.fromStore = dataObj.fromStoreName;
    invoiceData.toStore = dataObj.toStoreName;
    invoiceData.date = convertIsoDate(dataObj.transactionDate);
    invoiceData.expectedDeliveryDate = convertIsoDate(
      dataObj.expectedDeliveryDate
    );
    invoiceData.tableIn = dataObj.munfTemplateInDetails;
    invoiceData.tableOut = dataObj.munfTemplateOutDetails;
    strDataPrint(invoiceData);
    setVisibleDrawer(true);
  };

  const convertIsoDate = (IsoDate) => {
    if (IsoDate !== null && IsoDate !== undefined && IsoDate !== "") {
      const date = new Date(IsoDate);
      return (
        date.getDate() +
        "/" +
        (date.getMonth() + 1) +
        "/" +
        date.getFullYear() +
        " - " +
        date.getHours() +
        ":" +
        date.getMinutes()
      );
    } else {
      return "";
    }
  };

  const onCloseDrawer = () => {
    setVisibleDrawer(false);
  };





  const [fromStoreList, setFromStoreList] = useState([]);
  const [toStoreList, setToStoreList] = useState([]);
  const [fromStoreId, setFromStoreId] = useState("");
  const [toStoreId, setToStoreId] = useState("");


const fromStoreChildsOptList =
  fromStoreList.length > 0 &&
  fromStoreList.map((item) => (
    <Option key={item.id} value={item.id}>
      {item.name}
    </Option>
  ));

const toStoreChildsOptList =
  toStoreList.length > 0 &&
  toStoreList.map((item) => (
    <Option key={item.id} value={item.id}>
      {item.name}
    </Option>
  ));


  const onSearchFromStore = async (search, active) => {
    try {
      const { data: responce } = await getActiveChildren(search, active);
      setFromStoreList(responce.data);
      if (search === "" && active === "") {
        form.setFieldsValue({ fromStoreId: responce.data[1].id });
      }
    } catch (error) {
      if (error.request) {
        message.error(_t("msgServerDisconnected"), 3);
        
      } else {
        message.error(_t("msgUnknownError"), 3);
        
      }
    } finally {
      setLoadData(false);
     // setLoadSkeleton(false);
    }
  };

  const onSearchToStore = async (search, active) => {
    try {
      const { data: responce } = await getActiveChildren(search, active);
      setToStoreList(responce.data);
      if (search === "" && active === "") {
        form.setFieldsValue({ toStoreId: responce.data[0].id });
      }
    } catch (error) {
      if (error.request) {
        message.error(_t("msgServerDisconnected"), 3);
     
      } else {
        message.error(_t("msgUnknownError"), 3);
     
      }
    } finally {
      setLoadData(false);
     // setLoadSkeleton(false);
    }
  };



  //******************************// //*******************// Form //*******************// //******************************//

  return (
    <React.Fragment>
      <Prompt when={proChange} message={_t("strUnsavedChanges")} />

      <Drawer
        width={720}
        onClose={onCloseDrawer}
        visible={visibleDrawer}
        title={_t("strPrintPreview")}
      >
        <InvoicePrint objToPrint={dataPrint} />
      </Drawer>

      <div>
        <Spin spinning={loadData} tip={_t("strLoading")}>
          <Form
            form={form}
            name="mainform"
            onFinish={onFinish}
            layout="vertical"
            initialValues={{ munfTemplateDate: moment() }}
          >
            <Row gutter={12} className="frHeader">
              <Col xs={24} md={12} lg={6}>
                <Form.Item
                  name="code"
                  label={_t("strCode")}
                  rules={[
                    { required: true, message: `${_t("strIsRequired")}` },
                    { min: 1, message: `${_t("strFrom3-200")}` },
                    { max: 200, message: `${_t("strFrom3-200")}` },
                  ]}
                >
                  <Input
                    size="small"
                    placeholder={_t("strCode")}
                    autoComplete="off"
                    maxLength={200}
                    ref={IX_Code}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} md={12} lg={6}>
                <Form.Item
                  name="name"
                  label={_t("strName")}
                  rules={[
                    { required: true, message: `${_t("strIsRequired")}` },
                    { min: 1, message: `${_t("strFrom3-200")}` },
                    { max: 200, message: `${_t("strFrom3-200")}` },
                  ]}
                >
                  <Input
                    size="small"
                    placeholder={_t("strName")}
                    maxLength={200}
                    onChange={() => setProChange(true)}
                    ref={IX_Name}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} md={12} lg={6}>
                <Form.Item
                  name="documentCode"
                  label={_t("strDocument")}
                  rules={[
                    { required: false, message: `${_t("strIsRequired")}` },
                    { min: 1, message: `${_t("strFrom3-200")}` },
                    { max: 200, message: `${_t("strFrom3-200")}` },
                  ]}
                >
                  <Input
                    size="small"
                    placeholder={_t("strDocument")}
                    autoComplete="off"
                    maxLength={200}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} lg={6}>
                <Form.Item
                  name="munfTemplateDate"
                  label={_t("strDate")}
                  rules={[
                    { required: false, message: `${_t("strIsRequired")}` },
                  ]}
                >
                  <DatePicker
                    size="small"
                    showTime={{ format: "HH:mm" }}
                    format={dateFormatList}
                    className="dateTimeStyle"
                    onChange={() => {
                      setProChange(true);
                    }}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} md={12} lg={6}>
                <Form.Item
                  name="fromStoreId"
                  label={_t("strFromStore")}
                  rules={[
                    { required: true, message: `${_t("strIsRequired")}` },
                  ]}
                >
                  <Select
                    size="small"
                    placeholder={_t("strFromStore")}
                    autoComplete="off"
                    onSearch={(value) => { onSearchFromStore(value, fromStoreId) }}
                    onChange={() => {
                      setProChange(true);
                    }}
                    showSearch
                    optionFilterProp="children"
                  >
                    {fromStoreChildsOptList}
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} md={12} lg={6}>
                <Form.Item
                  name="toStoreId"
                  label={toStore === true ? _t("strStore") : _t("strToStore")}
                  rules={[
                    { required: true, message: `${_t("strIsRequired")}` },
                  ]}
                >
                  <Select
                    size="small"
                    placeholder={
                      toStore === true ? _t("strStore") : _t("strToStore")
                    }
                    autoComplete="off"
                    ref={toStoreInput}
                    onSearch={(value) => { onSearchToStore(value, toStoreId) }}
                    onChange={() => {
                      setProChange(true);
                    }}
                    showSearch
                    optionFilterProp="children"
                  >
                    {toStoreChildsOptList}
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} md={12} lg={6}>
                <Form.Item
                  name="note"
                  label={_t("strNote")}
                  rules={[
                    { required: false, message: `${_t("strIsRequired")}` },
                    { min: 1, message: `${_t("strFrom1-1000")}` },
                    { max: 1000, message: `${_t("strFrom1-1000")}` },
                  ]}
                >
                  <TextArea
                    size="small"
                    placeholder={_t("strNote")}
                    maxLength={1000}
                    autoComplete="off"
                    onChange={() => {
                      setProChange(true);
                    }}
                    autoSize={{ minRows: 1, maxRows: 3 }}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} md={12} lg={6}>
                <Form.Item
                  name="inactive"
                  valuePropName="checked"
                  label={_t("strInActive")}
                >
                  <Switch />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={12} className="frTabX" style={{ marginBottom: 5 }}>
              <Col xs={24} md={24}>
                <Form.Item label={_t("strTheInput")}>
                  <Card bodyStyle={{ padding: 10 }} className="scrollCard">
                    <Form.List name="munfTemplateInDetails">
                      {(fields, { add, remove }) => {
                        return (
                          <div>
                            <Modal
                              visible={visibilityInvItemList_IN}
                              onCancel={() => handleCancelInvItemList_IN()}
                              title={_t("strUnit")}
                              footer={null}
                              destroyOnClose={true}
                              width={1000}
                            >
                              <InvItemListSelectorForm
                                onHandelData={(data) => {
                                  setVisibilityInvItemList_IN(false);
                                  handelDataInvItemList_IN(data, add);
                                }}
                                onSearchData={searchData_IN}
                              />
                            </Modal>

                            {fields.map((field, i) => (
                              <Row gutter={[8, 8]} key={field.key}>
                                <Col hidden>
                                  <Form.Item
                                    {...field}
                                    defaultValue={0}
                                    name={[field.name, "id"]}
                                    fieldKey={[field.fieldKey, "id"]}
                                    hidden
                                  >
                                    <Input
                                      size="small"
                                      autoComplete="off"
                                      onChange={() => {
                                        setProChange(true);
                                      }}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col hidden>
                                  <Form.Item
                                    {...field}
                                    defaultValue={0}
                                    name={[field.name, "itemId"]}
                                    fieldKey={[field.fieldKey, "itemId"]}
                                    hidden
                                  >
                                    <Input
                                      size="small"
                                      autoComplete="off"
                                      onChange={() => {
                                        setProChange(true);
                                      }}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col xs={1} md={1} sm={1}>
                                  <Form.Item
                                    style={{ textAlign: "center" }}
                                    fieldKey={[field.fieldKey, "rowCount"]}
                                    style={{ marginBottom: -25 }}
                                  >
                                    <label>{i + 1}</label>
                                  </Form.Item>
                                </Col>
                                <Col xs={7} md={4} sm={4}>
                                  <Form.Item
                                    {...field}
                                    name={[field.name, "itemCode"]}
                                    fieldKey={[field.fieldKey, "itemCode"]}
                                    style={{ marginBottom: -25 }}
                                  >
                                    <Search
                                      min={0}
                                      max={9999}
                                      placeholder={_t("strCode")}
                                      size="small"
                                      autoComplete="off"
                                      onSearch={(v) =>
                                        codeSearch_IN(v, field.name, add)
                                      }
                                    />
                                  </Form.Item>
                                </Col>
                                <Col xs={8} md={6} sm={6}>
                                  <Form.Item
                                    {...field}
                                    name={[field.name, "itemName"]}
                                    fieldKey={[field.fieldKey, "itemName"]}
                                    style={{ marginBottom: -25 }}
                                    rules={[
                                      {
                                        required: false,
                                        message: _t("strFieldRequired"),
                                      },
                                    ]}
                                  >
                                    <Search
                                      placeholder={_t("strName")}
                                      autoComplete="off"
                                      size="small"
                                      onSearch={(v) =>
                                        nameSearch_IN(v, field.name, add)
                                      }
                                    />
                                  </Form.Item>
                                </Col>
                                <Col hidden>
                                  <Form.Item
                                    {...field}
                                    name={[field.name, "itemUnit_Maps"]}
                                    fieldKey={[field.fieldKey, "itemUnit_Maps"]}
                                    hidden
                                  >
                                    <Input size="small" />
                                  </Form.Item>
                                </Col>
                                <Col xs={8} md={3} sm={3}>
                                  <Form.Item
                                    key={field.key}
                                    shouldUpdate={(prev, curr) =>
                                      prev.munfTemplateInDetails?.length !==
                                      curr.munfTemplateInDetails?.length
                                    }
                                    fieldKey={[
                                      field.fieldKey,
                                      "itemUnit_MapId",
                                    ]}
                                    style={{ marginBottom: -25 }}
                                  >
                                    {({ getFieldValue }) => {
                                      const list = getFieldValue(
                                        "munfTemplateInDetails"
                                      );
                                      const unitList =
                                        Array.isArray(list) &&
                                        list[field.name]?.itemUnit_Maps;
                                      return (
                                        <Form.Item
                                          {...field}
                                          name={[field.name, "itemUnit_MapId"]}
                                          rules={[
                                            { required: false, message: " " },
                                          ]}
                                        >
                                          <Select
                                            showSearch
                                            optionFilterProp="children"
                                            placeholder={_t("strUnit")}
                                            autoComplete="off"
                                            size="small"
                                            onChange={() => {
                                              setProChange(true);
                                              onChangelist_In();
                                            }}
                                          >
                                            {unitList &&
                                              unitList.map((item) => (
                                                <Option
                                                  key={item.id}
                                                  value={item.id}
                                                >
                                                  {item.itemUnitName}
                                                </Option>
                                              ))}
                                          </Select>
                                        </Form.Item>
                                      );
                                    }}
                                  </Form.Item>
                                </Col>
                                <Col xs={10} md={2} sm={2}>
                                  <Form.Item
                                    {...field}
                                    name={[field.name, "qty"]}
                                    fieldKey={[field.fieldKey, "qty"]}
                                    rules={[{ required: false, message: " " }]}
                                    style={{ marginBottom: -25 }}
                                  >
                                    <InputNumber
                                      style={{ width: "100%" }}
                                      min={0}
                                      step={0.1}
                                      size="small"
                                      placeholder={_t("strQuantity")}
                                      autoComplete="off"
                                      onChange={() => {
                                        setProChange(true);
                                        onChangelist_In();
                                      }}
                                    />
                                  </Form.Item>
                                </Col>

                                <Col xs={10} md={2} sm={2}>
                                  <Form.Item
                                    {...field}
                                    name={[field.name, "wasteRatio"]}
                                    fieldKey={[field.fieldKey, "wasteRatio"]}
                                    rules={[{ required: false, message: " " }]}
                                    style={{ marginBottom: -25 }}
                                  >
                                    <InputNumber
                                      style={{ width: "100%" }}
                                      step={0.1}
                                      size="small"
                                      min={0}
                                      max={100}
                                      placeholder={"%" + _t("strWasteRatio")}
                                      autoComplete="off"
                                      onChange={() => {
                                        setProChange(true);
                                        onChangelist_In();
                                      }}
                                    />
                                  </Form.Item>
                                </Col>

                                <Col xs={12} md={5} sm={5}>
                                  <Form.Item
                                    {...field}
                                    name={[field.name, "note"]}
                                    fieldKey={[field.fieldKey, "note"]}
                                    rules={[
                                      {
                                        required: false,
                                        message: _t("strFieldRequired"),
                                      },
                                    ]}
                                    style={{ marginBottom: -25 }}
                                  >
                                    <Input
                                      min={0}
                                      max={9999}
                                      size="small"
                                      placeholder={_t("strNote")}
                                      autoComplete="off"
                                      onChange={() => {
                                        setProChange(true);
                                        onChangelist_In();
                                      }}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col
                                  xs={2}
                                  md={1}
                                  style={{ paddingTop: 6 }}
                                  sm={1}
                                >
                                  <Popconfirm
                                    title={_t("strSureToDelete")}
                                    onConfirm={() => onDeleteList(i, remove)}
                                  >
                                    <DeleteTwoTone
                                      style={{ fontSize: 20 }}
                                      twoToneColor="#ff5252"
                                    />
                                  </Popconfirm>
                                </Col>
                              </Row>
                            ))}

                            <Form.Item style={{ marginBottom: 0 }}>
                              <Button
                                type="dashed"
                                style={{ marginTop: 5 }}
                                onClick={() => {
                                  add();
                                }}
                                block
                              >
                                <PlusOutlined /> {_t("strAdd")}
                              </Button>
                            </Form.Item>
                          </div>
                        );
                      }}
                    </Form.List>
                  </Card>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={12} className="frTabX">
              <Col xs={24} md={24}>
                <Form.Item label={_t("strTheOutput")}>
                  <Card bodyStyle={{ padding: 10 }} className="scrollCard">
                    <Form.List name="munfTemplateOutDetails">
                      {(fields, { add, remove }) => {
                        return (
                          <div>
                            <Modal
                              visible={visibilityInvItemList_Out}
                              onCancel={() => handleCancelInvItemList_Out()}
                              title={_t("strUnit")}
                              footer={null}
                              destroyOnClose={true}
                              width={1000}
                            >
                              <InvItemListSelectorForm
                                onHandelData={(data) =>
                                  handelDataInvItemList_Out(data, add)
                                }
                                onSearchData={searchData_Out}
                              />
                            </Modal>

                            {fields.map((field, i) => (
                              <Row gutter={[8, 8]} key={field.key}>
                                <Col hidden>
                                  <Form.Item
                                    {...field}
                                    defaultValue={0}
                                    name={[field.name, "id"]}
                                    fieldKey={[field.fieldKey, "id"]}
                                    hidden
                                  >
                                    <Input
                                      autoComplete="off"
                                      onChange={() => {
                                        setProChange(true);
                                      }}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col hidden>
                                  <Form.Item
                                    {...field}
                                    defaultValue={0}
                                    name={[field.name, "itemId"]}
                                    fieldKey={[field.fieldKey, "itemId"]}
                                    hidden
                                  >
                                    <Input
                                      autoComplete="off"
                                      onChange={() => {
                                        setProChange(true);
                                      }}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col xs={1} md={1} sm={1}>
                                  <Form.Item
                                    style={{ textAlign: "center" }}
                                    fieldKey={[field.fieldKey, "rowCount"]}
                                    style={{ marginBottom: -25 }}
                                  >
                                    <label>{i + 1}</label>
                                  </Form.Item>
                                </Col>
                                <Col xs={4} md={4} sm={4}>
                                  <Form.Item
                                    {...field}
                                    name={[field.name, "itemCode"]}
                                    fieldKey={[field.fieldKey, "itemCode"]}
                                    style={{ marginBottom: -25 }}
                                  >
                                    <Search
                                      min={0}
                                      max={9999}
                                      placeholder={_t("strCode")}
                                      size="small"
                                      autoComplete="off"
                                      onSearch={(v) =>
                                        codeSearch_Out(v, field.name, add)
                                      }
                                    />
                                  </Form.Item>
                                </Col>
                                <Col xs={4} md={4} sm={4}>
                                  <Form.Item
                                    {...field}
                                    name={[field.name, "itemName"]}
                                    fieldKey={[field.fieldKey, "itemName"]}
                                    style={{ marginBottom: -25 }}
                                    rules={[
                                      {
                                        required: false,
                                        message: _t("strFieldRequired"),
                                      },
                                    ]}
                                  >
                                    <Search
                                      placeholder={_t("strName")}
                                      autoComplete="off"
                                      size="small"
                                      onSearch={(v) =>
                                        nameSearch_Out(v, field.name, add)
                                      }
                                    />
                                  </Form.Item>
                                </Col>
                                <Col hidden>
                                  <Form.Item
                                    {...field}
                                    name={[field.name, "itemUnit_Maps"]}
                                    fieldKey={[field.fieldKey, "itemUnit_Maps"]}
                                    hidden
                                  >
                                    <Input size="small" />
                                  </Form.Item>
                                </Col>
                                <Col xs={3} md={3} sm={3}>
                                  <Form.Item
                                    key={field.key}
                                    shouldUpdate={(prev, curr) =>
                                      prev.munfTemplateOutDetails?.length !==
                                      curr.munfTemplateOutDetails?.length
                                    }
                                    fieldKey={[
                                      field.fieldKey,
                                      "itemUnit_MapId",
                                    ]}
                                    style={{ marginBottom: -25 }}
                                  >
                                    {({ getFieldValue }) => {
                                      const list = getFieldValue(
                                        "munfTemplateOutDetails"
                                      );
                                      const unitList =
                                        Array.isArray(list) &&
                                        list[field.name]?.itemUnit_Maps;
                                      return (
                                        <Form.Item
                                          {...field}
                                          name={[field.name, "itemUnit_MapId"]}
                                          rules={[
                                            { required: false, message: " " },
                                          ]}
                                        >
                                          <Select
                                            showSearch
                                            optionFilterProp="children"
                                            placeholder={_t("strUnit")}
                                            autoComplete="off"
                                            size="small"
                                            onChange={() => {
                                              setProChange(true);
                                              onChangelist_Out();
                                            }}
                                          >
                                            {unitList &&
                                              unitList.map((item) => (
                                                <Option
                                                  key={item.id}
                                                  value={item.id}
                                                >
                                                  {item.itemUnitName}
                                                </Option>
                                              ))}
                                          </Select>
                                        </Form.Item>
                                      );
                                    }}
                                  </Form.Item>
                                </Col>
                                <Col xs={2} md={2} sm={2}>
                                  <Form.Item
                                    {...field}
                                    name={[field.name, "qty"]}
                                    fieldKey={[field.fieldKey, "qty"]}
                                    rules={[{ required: false, message: " " }]}
                                    style={{ marginBottom: -25 }}
                                  >
                                    <InputNumber
                                      style={{ width: "100%" }}
                                      min={0}
                                      step={0.1}
                                      size="small"
                                      placeholder={_t("strQuantity")}
                                      autoComplete="off"
                                      onChange={() => {
                                        setProChange(true);
                                        onChangelist_Out();
                                      }}
                                    />
                                  </Form.Item>
                                </Col>

                                <Col xs={2} md={2} sm={2}>
                                  <Form.Item
                                    {...field}
                                    name={[field.name, "distributionRatio"]}
                                    fieldKey={[
                                      field.fieldKey,
                                      "distributionRatio",
                                    ]}
                                    rules={[{ required: false, message: " " }]}
                                    style={{ marginBottom: -25 }}
                                  >
                                    <InputNumber
                                      style={{ width: "100%" }}
                                      step={0.1}
                                      max={100}
                                      min={0}
                                      size="small"
                                      placeholder={"%" + _t("strDistribution")}
                                      autoComplete="off"
                                      onChange={() => {
                                        setProChange(true);
                                        onChangelist_Out();
                                      }}
                                    />
                                  </Form.Item>
                                </Col>

                                <Col xs={4} md={4} sm={4}>
                                  <Form.Item
                                    {...field}
                                    name={[field.name, "note"]}
                                    fieldKey={[field.fieldKey, "note"]}
                                    rules={[
                                      {
                                        required: false,
                                        message: _t("strFieldRequired"),
                                      },
                                    ]}
                                    style={{ marginBottom: -25 }}
                                  >
                                    <Input
                                      min={0}
                                      max={9999}
                                      size="small"
                                      placeholder={_t("strNote")}
                                      autoComplete="off"
                                      onChange={() => {
                                        setProChange(true);
                                        onChangelist_Out();
                                      }}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col xs={2} md={3} sm={3}>
                                  <Form.Item
                                    {...field}
                                    name={[field.name, "isDefault"]}
                                    fieldKey={[field.fieldKey, "isDefault"]}
                                    style={{ marginBottom: -25 }}
                                    valuePropName="checked"
                                  >
                                    <Checkbox
                                      onChange={() => {
                                        setProChange(true);
                                        onChangelist_Out();
                                      }}
                                    >
                                      {_t("strIsDefault")}
                                    </Checkbox>
                                  </Form.Item>
                                </Col>
                                <Col
                                  xs={2}
                                  md={1}
                                  style={{ paddingTop: 6 }}
                                  sm={1}
                                >
                                  <Popconfirm
                                    title={_t("strSureToDelete")}
                                    onConfirm={() => onDeleteList(i, remove)}
                                  >
                                    <DeleteTwoTone
                                      style={{ fontSize: 20 }}
                                      twoToneColor="#ff5252"
                                    />
                                  </Popconfirm>
                                </Col>
                              </Row>
                            ))}

                            <Form.Item style={{ marginBottom: 0 }}>
                              <Button
                                type="dashed"
                                style={{ marginTop: 5 }}
                                onClick={() => {
                                  add();
                                }}
                                block
                              >
                                <PlusOutlined /> {_t("strAdd")}
                              </Button>
                            </Form.Item>
                          </div>
                        );
                      }}
                    </Form.List>
                  </Card>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={12} className="frFooter">
              <Col lg={12} md={12} xs={24} style={{ textAlign: "right" }}>
                <Space size="large" style={{ marginBottom: -20 }}>
                  <Form.Item>
                    <Button
                      onClick={() => form.submit()}
                      type="primary"
                      style={{ width: 100 }}
                    >
                      {_t("strSave")}
                    </Button>
                  </Form.Item>

                  <Form.Item>
                    <Button
                      style={{ width: 100 }}
                      type="dashed"
                      onClick={() => onNew(false)}
                    >
                      {_t("strNew")}
                    </Button>
                  </Form.Item>

                  <Form.Item>
                    <Space>
                      <Dropdown.Button
                        hidden={typeof onNewStore === "function"}
                        overlay={menu}
                        onClick={() => history.goBack()}
                      >
                        {" "}
                        {_t("strBack")}
                      </Dropdown.Button>
                      {isGoBack ? (
                        <i />
                      ) : (
                        <RetweetOutlined
                          style={{ fontSize: 21, marginTop: 5 }}
                        />
                      )}
                    </Space>
                  </Form.Item>
                </Space>
              </Col>
            </Row>
          </Form>
        </Spin>
      </div>
    </React.Fragment>
  );
};

export default ManufacturingModelsForm;
