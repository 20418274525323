import { _URL } from "../config.json";
import http from "./http";

const apiEndpoint = _URL + "/api/DashboardTempRole";

export function getPagers(query) {
  return http.post(`${apiEndpoint}/GetPager?${query}`);
}

export function getTempRoleById(Id) {
  return http.get(`${apiEndpoint}/${Id}`);
}

export function addTempRole(obj) {
  if (obj.id !== "new") {
    return http.put(apiEndpoint, obj);
  } else {
    return http.post(apiEndpoint, obj);
  }
}

export function getAllTempRole() {
  return http.get(`${apiEndpoint}`);
}

export function deleteTempRole(Id) {
  return http.delete(`${apiEndpoint}/${Id}`);
}

export function getPagerTempRole(current, pageSize) {
  return http.post(
    `${apiEndpoint}/GetPager?PageNumber=${current}&PageSize=${pageSize}`
  );
}

export function getNewTempRoleCode() {
  return http.post(`${apiEndpoint}/GetNewCode`);
}
