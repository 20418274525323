import React, { useState, useEffect } from "react";
import { permissions } from "../../../services/Authorization/authService";
import {
  Button,
  Table,
  Modal,
  Form,
  Input,
  Popconfirm,
  notification,
  message,
} from "antd";
import { Link } from "react-router-dom";
import { EditTwoTone, DeleteTwoTone, UnlockTwoTone } from "@ant-design/icons";
import _t from "../../../languages/translate";
import {
  getAllUsers,
  deleteUsers,
} from "../../../services/Authorization/usersService";
import { ResetPassword } from "../../../services/Authorization/ResetPasswordService";

const PageUsers = () => {
  const [form] = Form.useForm();
  const [dataTable, setDataTable] = useState([]);
  const [loadData, setLoadData] = useState(false);
  const [visibility, setVisibility] = useState(false);
  const [columnData, setColumnData] = useState([]);

  const showModal = (ID) => {
    
    form.setFieldsValue({
      id: ID,
    });
    setVisibility(true);
  };

  const handleOk = async(values) => {
    try{
     delete values.confirmNewPassword;
     const res = await ResetPassword(values);
     
     form.resetFields();
     message.success(res.data?.message);
     setVisibility(false);
    } catch (error) {
      if (error.response) {
        message.error(error.response.data?.message || _t("msgUnknownError"), 3);    
      } else if (error.request) {
        message.error(_t("msgServerDisconnected"), 3);   
      } else {
        message.error(_t("msgUnknownError"), 3);  
      }
    }
  };

  const handleCancel = () => {
    setVisibility(false);
  };

  const tableConfig = [
    {
      title: _t("strFirstName"),
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: _t("strLastName"),
      dataIndex: "lastName",
      key: "lastName",
    },
    {
      title: _t("strEmail"),
      dataIndex: "email",
      key: "email",
    },
    {
      title: _t("strUserName"),
      dataIndex: "userName",
      key: "userName",
    },

    {
      title: _t("strRoles"),
      dataIndex: "userRoleName",
      key: "userRoleName",
    },

    {
      title: _t("strResetPassword"),
      key: "resetPassword",
      width: "130px",
      hidden: permissions._Auth_User_ResetPassword ? false : true,
      render: (text, record) => (
        <Link style={{ fontSize: "20px" }} onClick={() => showModal(record.id)}>
          <UnlockTwoTone />
        </Link>
      ),
    },

    {
      title: _t("strEdit"),
      key: "edit",
      width: "70px",
      hidden: permissions._User_Put ? false : true,
      render: (text, record) => (
        <Link style={{ fontSize: "20px" }} to={`/users/${record.id}`}>
          <EditTwoTone />
        </Link>
      ),
    },

    {
      title: _t("strDelete"),
      key: "delete",
      width: "70px",
      hidden: permissions._User_Delete ? false : true,
      render: (text, record) => (
        <Popconfirm
          title={_t("strSureToDelete")}
          onConfirm={() => onDelete(record.id, record.parentId)}
        >
          <DeleteTwoTone twoToneColor="#eb2f96" style={{ fontSize: "20px" }} />
        </Popconfirm>
      ),
    },
  ];

  useEffect(() => {
    tableColumn(tableConfig);
    setLoadData(true);
    const fetchData = async () => {
      try {
        const { data: newData } = await getAllUsers();
        setDataTable(newData.data);
        setLoadData(false);
      } catch (error) {
        if (error.response) {
          message.error(
            error.response.data?.message || _t("msgUnknownError"),
            3
          );
          setLoadData(false);
        } else if (error.request) {
          message.error(_t("msgServerDisconnected"), 3);
          setLoadData(false);
        } else {
          message.error(_t("msgUnknownError"), 3);
          setLoadData(false);
        }
      }
    };

    fetchData();
  }, [setDataTable]);

  const tableColumn = (columns) => {
    const datafilter = columns.filter((i) => i.hidden !== true);
    setColumnData(datafilter);
  };

  const onDelete = async (id) => {
    try {
      const response = await deleteUsers(id);
      const { data } = await getAllUsers();
      setDataTable(data.data);
      //  const newData = dataTable.filter((e) => e.id !== id);
      //  setDataTable(newData);
      notification.success({
        message: `${response.data.message}`,
        placement: `${_t("strNotificationDirection")}`,
      });
    } catch (error) {
      if (error.response) {
        message.error(error.response.data?.message || _t("msgUnknownError"), 3);
        setLoadData(false);
      } else if (error.request) {
        message.error(_t("msgServerDisconnected"), 3);
        setLoadData(false);
      } else {
        message.error(_t("msgUnknownError"), 3);
        setLoadData(false);
      }
    }
  };

  return (
    <>
      <div>
        <Modal
          title={_t("strResetPassword")}
          visible={visibility}
          okText={_t("strSubmit")}
          cancelText={_t("strCancel")}
          onOk={() => {
            form.validateFields().then((values) => { handleOk(values)})
          }}
          onCancel={() => handleCancel()}
        >
          <Form
            layout="vertical"
            form={form}
            name="nest-messages"
            onFinish={handleOk}
          >
            <Form.Item name="id" style={{ display: "none" }}>
              <Input />
            </Form.Item>

            <Form.Item
              name="newPassword"
              label={_t("strPassword")}
              rules={[{ required: true, message: _t("msgInputYourPass") }]}
              hasFeedback
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              name="confirmNewPassword"
              label={_t("strConfirmPassword")}
              dependencies={["password"]}
              hasFeedback
              rules={[
                { required: true, message: _t("msgInputYourConPass") },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue("newPassword") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(_t("msgPassNotMatch"));
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
          </Form>
        </Modal>
      </div>
      <div className="bgTab">
        {permissions._User_Post && (
          <Button style={{ marginBottom: 20 }}>
            <Link to={`/users/new`}>{_t("strAddNew")}</Link>
          </Button>
        )}

        <Table
          rowKey="id"
          columns={columnData}
          dataSource={dataTable}
          loading={loadData}
          scroll={{ x: 1500, y: 400 }}
          size="small"
          bordered
          pagination={false}
        />
      </div>
    </>
  );
};

export default PageUsers;
