import React, { useEffect, useState } from "react";
import { Upload, message, Button ,Space} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { _URL } from "../../../config.json";
import uploadTmpFile from "../../../services/uploadFileService";
import _t from "../../../languages/translate";


function beforeUpload(file) {
    const isJpgOrPng = file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || file.type === "application/vnd.ms-excel" ;
    if (!isJpgOrPng) { message.error(_t("strOnlyXLS"));}
    return isJpgOrPng;
}

function UploadFile({ setPreviewFile }) {
    const [fileName, setFileName] = useState("");

    const uploadFiles = async (fileObj) => {
        try {
            debugger
            const res = await uploadTmpFile(fileObj);
            setFileName(fileObj.name);
            setPreviewFile(res.data.data);
        } catch (error) {
            if (error.response) {
                message.error(error.response.data?.message || _t("msgUnknownError"), 3); 
            } else if (error.request) {
                message.error(_t("msgServerDisconnected"), 3);   
            } else {
                message.error(_t("msgUnknownError"), 3);  
            }
        }
    };

    return (
        <Space>
            <Upload
                name="avatar"
                listType="text"
                showUploadList={false}
                action={(file) => uploadFiles(file)}
                beforeUpload={beforeUpload}
                maxCount={1}
            >
                <Button icon={<UploadOutlined />}>{_t("strUploadExcel")}</Button>
            </Upload>
            
            <span>{fileName}</span>           
        </Space>

    );
}
export default UploadFile;
