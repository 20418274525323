import React, { useState, useEffect, useRef } from "react";
import { Prompt } from "react-router";
import { useHistory, useParams, Link } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  message,
  Space,
  Row,
  Col,
  Menu,
  Dropdown,
  Spin,
  Select,
  Empty,
  Checkbox,
} from "antd";
import {
  getNewTempRoleCode,
  getTempRoleById,
  addTempRole,
} from "../../services/dashboardRolesService";
import { getAllDashboardTemp } from "../../services/dashboardTempService";
import { getAllUsers } from "../../services/Authorization/usersService";
import { RetweetOutlined } from "@ant-design/icons";
import _t from "../../languages/translate";

const DashboardRolesForm = ({ onNewUnit }) => {
  const IX_Code = useRef(null);
  const IX_Name = useRef(null);
  const [loading] = useState(false);
  const [loadData, setLoadData] = useState(false);
  const [form] = Form.useForm();
  const { TextArea } = Input;
  let history = useHistory();
  let { id } = useParams();
  const [usersOpt, setUsersOpt] = useState([]);
  const [analysisTempOpt, setAnalysisTempOpt] = useState([]);
  const [isGoBack, setIsGoBack] = useState(true);
  const [companyId] = useState(window.localStorage.getItem("comId"));
  //******************************//

  const usersOptList =
    usersOpt.length > 0 &&
    usersOpt.map((item) => (
      <Select.Option key={item.id} value={item.id}>
        {item.userName}
      </Select.Option>
    ));

  const analysisTempOptList =
    analysisTempOpt.length > 0 &&
    analysisTempOpt.map((item) => (
      <Select.Option key={item.id} value={item.id}>
        {item.name}
      </Select.Option>
    ));

  const init = async () => {
    try {
      const { data: usersData } = await getAllUsers();
      setUsersOpt(usersData.data);
      const { data: DashboardTempData } = await getAllDashboardTemp();
      setAnalysisTempOpt(DashboardTempData.data);
    } catch (error) {
      if (error.response) {
        message.error(error.response.data?.message || _t("msgUnknownError"), 3);
        setLoadData(false);
      } else if (error.request) {
        message.error(_t("msgServerDisconnected"), 3);
        setLoadData(false);
      } else {
        message.error(_t("msgUnknownError"), 3);
        setLoadData(false);
      }
    }
  };

  useEffect(() => {
    init();
    async function fetchData() {
      if (id === "new" || typeof onNewUnit === "function") {
        return await codeGenerator();
      } else {
        try {
          const { data } = await getTempRoleById(id);
          form.setFieldsValue({
            id: data.data.Id,
            code: data.data.code,
            name: data.data.name,
            companyId: data.data.companyId,
            usersIds: data.data.usersIds,
            dashboardTempIds: data.data.dashboardTempIds,
            note: data.data.note,
            inactive: data.data.inactive,
          });
          IX_Name.current.focus();
          setLoadData(false);
        } catch (error) {
          if (error.response) {
            message.error(
              error.response.data?.message || _t("msgUnknownError"),
              3
            );
            setLoadData(false);
          } else if (error.request) {
            message.error(_t("msgServerDisconnected"), 3);
            setLoadData(false);
          } else {
            message.error(_t("msgUnknownError"), 3);
            setLoadData(false);
          }
        }
      }
    }
    fetchData();
  }, [id, history, setLoadData]);

  const codeGenerator = async () => {
    const { data: newData } = await getNewTempRoleCode();
    form.setFieldsValue({ code: newData.data });
    IX_Name.current.focus();
    setLoadData(false);
  }; //  To generate code by id  🚀

  //******************************// //*******************// Back //*******************// //******************************//

  const menu = (
    <Menu>
      <Menu.Item onClick={() => setIsGoBack(true)}>
        {_t("strLeaveThePage")}
      </Menu.Item>
      <Menu.Item onClick={() => setIsGoBack(false)}>
        {_t("strStayInPage")}
      </Menu.Item>
    </Menu>
  );

  const goBack = () => {
    setProChange(false);
    if (isGoBack) {
      history.goBack();
    } else {
      if (id !== "new") {
        history.replace({
          ...history.location,
          pathname: "/dashboardRoles/new",
        });
      }
      form.resetFields();
      codeGenerator();
      IX_Name.current.focus();
    }
  };

  //******************************// //*******************// Save //*******************// //******************************//

  const onFinish = async (values) => {
    if (typeof onNewUnit === "function") {
      values.id = "new";
    } else {
      values.id = id;
    }
    values.companyId = companyId;
    try {
      const data = await addTempRole(values);
      message.success(data.data.message, 3);
      if (typeof onNewUnit === "function") {
        onNewUnit(data);
      } else {
        goBack();
      }
    } catch (error) {
      if (error.response) {
        if (error.response.data.ex.includes("IX_Code")) {
          IX_Code.current.focus();
          message.error(
            error.response.data?.message || _t("msgUnknownError"),
            3
          );
          setLoadData(false);
        } else if (error.response.data.ex.includes("IX_Name")) {
          IX_Name.current.focus();
          message.error(
            error.response.data?.message || _t("msgUnknownError"),
            3
          );
          setLoadData(false);
        } else {
          message.error(
            error.response.data?.message || _t("msgUnknownError"),
            3
          );
          setLoadData(false);
        }
      } else if (error.request) {
        message.error(_t("msgServerDisconnected"), 3);
        setLoadData(false);
      } else {
        message.error(_t("msgUnknownError"), 3);
        setLoadData(false);
      }
    }
  };

  //******************************// //*******************// Form Code //*******************// //******************************//

  const [proChange, setProChange] = useState(false);
  const promptForm = () => {
    setProChange(true);
  };

  //******************************// //*******************// Form //*******************// //******************************//

  return (
    <React.Fragment>
      <Prompt when={proChange} message={_t("strUnsavedChanges")} />

      <div className="bg">
        <Spin spinning={loadData} tip={_t("strLoading")}>
          <Form
            form={form}
            name="itemUnitform"
            onFinish={onFinish}
            layout="vertical"
          >
            <Row gutter={12}>
              <Col xs={24} md={12}>
                <Form.Item
                  name="code"
                  label={_t("strCode")}
                  rules={[
                    {
                      required: true,
                      message: `${_t("strIsRequired")}`,
                    },
                    { min: 1, message: `${_t("strFrom3-200")}` },
                    { max: 200, message: `${_t("strFrom3-200")}` },
                  ]}
                >
                  <Input
                    placeholder={_t("strCode")}
                    autoComplete="off"
                    maxLength={200}
                    onChange={promptForm}
                    ref={IX_Code}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  name="name"
                  label={_t("strName")}
                  rules={[
                    { required: true, message: `${_t("strIsRequired")}` },
                    { min: 1, message: `${_t("strFrom3-200")}` },
                    { max: 200, message: `${_t("strFrom3-200")}` },
                  ]}
                >
                  <Input
                    placeholder={_t("strName")}
                    maxLength={200}
                    autoComplete="off"
                    onChange={promptForm}
                    ref={IX_Name}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  name="usersIds"
                  label={_t("strUsers")}
                  rules={[{ required: false }]}
                >
                  <Select
                    allowClear
                    mode="multiple"
                    showSearch
                    optionFilterProp="children"
                    notFoundContent={
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    }
                    placeholder={_t("strNotSelected")}
                  >
                    {usersOptList}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  name="dashboardTempIds"
                  label={_t("strAnalysisTemp")}
                  rules={[{ required: false }]}
                >
                  <Select
                    allowClear
                    mode="multiple"
                    showSearch
                    optionFilterProp="children"
                    notFoundContent={
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    }
                    placeholder={_t("strNotSelected")}
                  >
                    {analysisTempOptList}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} md={24}>
                <Form.Item
                  name="note"
                  label={_t("strNote")}
                  rules={[
                    { required: false, message: `${_t("strIsRequired")}` },
                    { min: 1, message: `${_t("strFrom1-1000")}` },
                    { max: 1000, message: `${_t("strFrom1-1000")}` },
                  ]}
                >
                  <TextArea
                    placeholder={_t("strNote")}
                    maxLength={1000}
                    autoComplete="off"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item name="inactive" valuePropName="checked">
                  <Checkbox>{_t("strInActive")}</Checkbox>
                </Form.Item>
              </Col>
              <Col xs={24} md={12} />
            </Row>
            <Form.Item>
              <Space size="large">
                <Button type="primary" htmlType="submit" disabled={loading}>
                  {_t("strSave")}
                </Button>
                <Dropdown.Button
                  hidden={typeof onNewStore === "function"}
                  overlay={menu}
                  onClick={() => history.goBack()}
                >
                  {" "}
                  {_t("strBack")}
                </Dropdown.Button>
                {isGoBack ? (
                  <i />
                ) : (
                  <RetweetOutlined style={{ fontSize: 21, marginTop: 5 }} />
                )}
              </Space>
            </Form.Item>
          </Form>
        </Spin>
      </div>
    </React.Fragment>
  );
};

export default DashboardRolesForm;
