import { _URL } from "../config.json";
import http from "./http";

const apiEndpoint = _URL + "/api/ClientSupplier";

export function getPagers(query) {
  return http.post(`${apiEndpoint}/GetPager?${query}`);
}

export function getClientSupp(search) {
  return http.post(`${apiEndpoint}/GetActiveTree/?search=${search}`);
}

export function getClientSuppById(Id) {
  return http.get(`${apiEndpoint}/${Id}`);
}

export function getClientSuppType(search, addId, num) {
  return http.post(
    `${apiEndpoint}/GetActiveChildren/?search=${search}&addId=${addId??""}&type=${num}`
  );
}

export function editClientSupp(obj) {
  if (obj.id !== "new" && obj.id !== "add") {
    return http.put(apiEndpoint, obj);
  } else {
    return http.post(apiEndpoint, obj);
  }
}

export function getAllParents() {
  return http.post(`${apiEndpoint}/GetAllParent`);
}

export function deleteClientSupp(Id) {
  return http.delete(`${apiEndpoint}/${Id}`);
}

export function getNewClientSuppCode(Id) {
  return http.post(`${apiEndpoint}/GetNew/?parentId=${Id}`);
}
