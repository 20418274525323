import { _URL } from "../../config.json";
import http from "./../http";

const apiEndpoint = _URL + "/api/Munf";

export function getManufacturingOrdersById(Id) {
  return http.get(`${apiEndpoint}/${Id}`);
}

export function editManufacturingOrders(obj) {
  if (obj.id !== "new") {
    return http.put(apiEndpoint, obj);
  } else {
    return http.post(apiEndpoint, obj);
  }
}

export function getNext(PatternId, Id) {
  return http.post(`${apiEndpoint}/GetNext/?PatternId=${PatternId}&Id=${Id}`);
}

export function getPrev(PatternId, Id) {
  return http.post(`${apiEndpoint}/GetPrev/?PatternId=${PatternId}&Id=${Id}`);
}

export function searchByCode(patternId, code) {
  return http.post(
    `${apiEndpoint}/GetByCode/?patternId=${patternId}&code=${code}`
  );
}

export function searchByDocument(patternId, document) {
  return http.post(
    `${apiEndpoint}/GetByDocument/?patternId=${patternId}&document=${document}`
  );
}

export function setDefaultItem(Id) {
  return http.post(
    `${apiEndpoint}/SetDefaultItem?MunfTemplateOutDetailId=${Id}`
  );
}

export function deleteManufacturingOrders(Id) {
  return http.delete(`${apiEndpoint}/${Id}`);
}

export function getManufacturingOrders() {
  return http.get(`${apiEndpoint}`);
}

export function getAllParents() {
  return http.post(`${apiEndpoint}/GetAllParent`);
}

export function getAllChildren() {
  return http.post(`${apiEndpoint}/GetAllChildren`);
}

export function getAllManufacturingOrders() {
  return http.post(`${apiEndpoint}/GetAll`);
}

export function getNewManufacturingOrdersCode(patternId) {
  return http.post(`${apiEndpoint}/GetNewCode?PatternId=${patternId}`);
}
export function searchMunf(obj) {
  return http.post(_URL + `/api/MunfTemplate/GetActiveSearch/`, obj);
}

export function getPagerManufacturingOrders(current, pageSize) {
  return http.post(
    `${apiEndpoint}/GetPager?PageNumber=${current}&PageSize=${pageSize}`
  );
}

export function searchManufacturingOrders(obj) {
  return http.post(`${apiEndpoint}/GetPagerSearch/`, obj);
}

export function getActiveTreeManufacturingOrders() {
  return http.post(`${apiEndpoint}/GetActiveTree`);
}
