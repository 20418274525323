import React, { useState, useEffect } from "react";
import {
  Form,
  Spin,
  TreeSelect,
  Row,
  Col,
  DatePicker,
  message,
  Empty,
  Button,
  Select,
  Divider,
  Space,
  Checkbox,
} from "antd";
import _t from "../../../languages/translate";
import { getClientSupp } from "../../../services/clientSuppService";
import { getAllSTSP } from "../../../services/Transactions/STSPService";
import { getAllSTP } from "../../../services/Transactions/STPService";
import { getActiveTreeStore } from "../../../services/storeService";
import { getActiveTreeItem } from "../../../services/Items/itemService";
import moment from "moment";

const FilterItemMovementRep = ({ onHandelData, onFilterData, filterType }) => {
  debugger;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [treeStocksId, setTreeStocksId] = useState([""]);
  const [treeItemsId, setTreeItemsId] = useState([""]);
  const [itemsOpt, setItemsOpt] = useState([]);
  const [storesOpt, setStoresOpt] = useState([]);
  const [storeTransactionPattrenOpt, setStoreTransactionPattrenOpt] = useState(
    []
  );
  const [
    storeTransactionStatePattrenOpt,
    setStoreTransactionStatePattrenOpt,
  ] = useState([]);

  const { SHOW_PARENT } = TreeSelect;
  const dateFormatList = [
    "DD/MM/YYYY HH:mm",
    "DDMMYYYY HH:mm",
    "DDMMYYYY HHmm",
    "DDMMYYYYHHmm",
    "DDMMYYYY",
    "DDMMYYHHmm",
    "DDMMYY",
  ];

  const storeTransactionPattrenOptList =
    storeTransactionPattrenOpt.length > 0 &&
    storeTransactionPattrenOpt.map((item) => (
      <Select.Option key={item.id} value={item.id}>
        {item.nameVM}
      </Select.Option>
    ));
  const storeTransactionStatePattrenOptList =
    storeTransactionStatePattrenOpt.length > 0 &&
    storeTransactionStatePattrenOpt.map((item) => (
      <Select.Option key={item.id} value={item.id}>
        {item.nameVM}
      </Select.Option>
    ));

  useEffect(() => {
    const fetchData = async () => {
      try {
        onSearchItems("");

        onSearchStocks("");

        getClientsSupp("");

        const { data: resultStoreTransactionPattren } = await getAllSTP();
        setStoreTransactionPattrenOpt(resultStoreTransactionPattren.data);

        const { data: resultStoreTransactionStatePattren } = await getAllSTSP();
        setStoreTransactionStatePattrenOpt(
          resultStoreTransactionStatePattren.data
        );

        if (onFilterData !== undefined) {
          form.setFieldsValue({
            itemIds: onFilterData.itemIds,
            storeIds: onFilterData.storeIds,
            clientSuplierIds: onFilterData.clientSuplierIds,
            stateIds: onFilterData.stateIds,
            patternIds: onFilterData.patternIds,
            fromDate:
              onFilterData.fromDate !== undefined &&
              onFilterData.fromDate !== null
                ? moment(onFilterData.fromDate)
                : "",
            toDate:
              onFilterData.toDate !== undefined &&
              onFilterData.toDate !== null
                ? moment(onFilterData.toDate)
                : "",
            withTotal: onFilterData.withTotal,
            withPreviousQty: onFilterData.withPreviousQty,
          });
        }
      } catch (error) {
        if (error.response) {
          message.error(
            error.response.data?.message || _t("msgUnknownError"),
            3
          );
        } else if (error.request) {
          message.error(_t("msgServerDisconnected"), 3);
        } else {
          message.error(_t("msgUnknownError"), 3);
        }
      }
    };
    fetchData();
  }, [setItemsOpt, setStoresOpt, setStoreTransactionStatePattrenOpt]);

  const onChangeItems = (value) => {
    setTreeItemsId(value);
  };

  const onChangeStocks = (value) => {
    setTreeStocksId(value);
  };
  
  const [itemState, setItemState] = useState("");
  const onSearchItems = async(searchValue) => {
    try {
     
      setItemState(searchValue);
      const { data: result } = await getActiveTreeItem(searchValue,"");
      
      setItemsOpt(result.data);
    } catch (error) {
      if (error.response) {
        message.error(error.response.data?.message || _t("msgUnknownError"), 3);
      } else if (error.request) {
        message.error(_t("msgServerDisconnected"), 3);
      } else {
        message.error(_t("msgUnknownError"), 3);
      }
    }
  };


  const [stockState, setStockState] = useState("");
  const onSearchStocks = async(searchValue) => {
    try {
      setStockState(searchValue);
      const { data: result } = await getActiveTreeStore(searchValue,"");
      
      setStoresOpt(result.data);
    } catch (error) {
      if (error.response) {
        message.error(error.response.data?.message || _t("msgUnknownError"), 3);
      } else if (error.request) {
        message.error(_t("msgServerDisconnected"), 3);
      } else {
        message.error(_t("msgUnknownError"), 3);
      }
    }
  };
  const onChangeClientSupp = (value) => {
    setTreeClientSuppId(value);
  };

  const [clientSuppOpt, setClientSuppOpt] = useState([]);
  const [treeClientSuppId, setTreeClientSuppId] = useState([""]);
  const [clientSuppState, setClientSuppState] = useState("");
  const getClientsSupp = async (searchValue) => {
    try {
      setClientSuppState(searchValue);
      const { data: resultClientSupplire } = await getClientSupp(searchValue);
      setClientSuppOpt(resultClientSupplire.data);
    } catch (error) {
      if (error.response) {
        message.error(error.response.data?.message || _t("msgUnknownError"), 3);
      } else if (error.request) {
        message.error(_t("msgServerDisconnected"), 3);
      } else {
        message.error(_t("msgUnknownError"), 3);
      }
    }
  };

  const tPropsClientSupp = {
    treeData: clientSuppOpt,
    allowClear: true,
    showSearch: true,
    onSearch: getClientsSupp,
    onDropdownVisibleChange: () => setClientSuppState(""),
    searchValue: clientSuppState,
    treeNodeFilterProp: "title",
    value: treeClientSuppId,
    onChange: onChangeClientSupp,
    treeCheckable: true,
    treeDefaultExpandAll: false,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: _t("strNotSelected"),
    style: {
      width: "100%",
    },
  };

  const tPropsItems = {
    treeData: itemsOpt,
    allowClear: true,
    showSearch: true,
    onSearch: onSearchItems,
    onDropdownVisibleChange: () => setItemState(""),
    searchValue: itemState,
    treeNodeFilterProp: "title",
    value: treeItemsId,
    onChange: onChangeItems,
    treeCheckable: true,
    treeDefaultExpandAll: false,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: _t("strNotSelected"),
    style: {
      width: "100%",
    },
  };

  const tPropsStore = {
    treeData: storesOpt,
    allowClear: true,
    showSearch: true,
    onSearch: onSearchStocks,
    onDropdownVisibleChange: () => setStockState(""),
    searchValue: stockState,
    treeNodeFilterProp: "title",
    value: treeStocksId,
    onChange: onChangeStocks,
    treeCheckable: true,
    treeDefaultExpandAll: false,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: _t("strNotSelected"),
    style: {
      width: "100%",
    },
  };

  const onFinish = async (values) => {
    setLoading(true);
    if (values.fromDate !== "") {
      values.fromDate = values.fromDate?.toISOString();
    }
    if (values.toDate !== "") {
      values.toDate = values.toDate?.toISOString();
    }
    if (values.itemIds === undefined || values.itemIds === "") {
      values.itemIds = [];
    }
    if (values.storeIds === undefined || values.storeIds === "") {
      values.storeIds = [];
    }
    if (
      values.clientSuplierIds === undefined ||
      values.clientSuplierIds === ""
    ) {
      values.clientSuplierIds = [];
    }
    if (values.patternIds === undefined || values.patternIds === "") {
      values.patternIds = [];
    }
    if (values.stateIds === undefined || values.stateIds === "") {
      values.stateIds = [];
    }
    let obj = {
      reportFilter: values,
      timeShift: new Date().getTimezoneOffset(),
      columns: [],
    };

    onHandelData(obj);
    setLoading(false);
  };

  return (
    <Spin spinning={loading} tip={_t("strLoading")}>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{ toDate: moment() }}
      >
        <Row gutter={12}>
          <Col span={24}>
            <Form.Item name="itemIds" label={_t("strItems")}>
              <TreeSelect {...tPropsItems} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="storeIds" label={_t("strStores")}>
              <TreeSelect {...tPropsStore} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="clientSuplierIds" label={_t("strClientsSupp")}>
              <TreeSelect {...tPropsClientSupp} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="patternIds" label={_t("strInvoicePattern")}>
              <Select
                allowClear
                mode="multiple"
                showSearch
                optionFilterProp="children"
                notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                placeholder={_t("strNotSelected")}
              >
                {storeTransactionPattrenOptList}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="stateIds" label={_t("strStatus")}>
              <Select
                allowClear
                mode="multiple"
                showSearch
                optionFilterProp="children"
                notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                placeholder={_t("strNotSelected")}
              >
                {storeTransactionStatePattrenOptList}
              </Select>
            </Form.Item>
          </Col>
          {!filterType && (
            <Col span={12}>
              <Form.Item name="fromDate" label={_t("strFromDate")}>
                <DatePicker
                  showTime={{ format: "HH:mm" }}
                  format={dateFormatList}
                  className="dateTimeStyle"
                />
              </Form.Item>
            </Col>
          )}
          {!filterType && (
            <Col span={12}>
              <Form.Item name="toDate" label={_t("strToDate")}>
                <DatePicker
                  showTime={{ format: "HH:mm" }}
                  format={dateFormatList}
                  className="dateTimeStyle"
                />
              </Form.Item>
            </Col>
          )}
          <Col span={24}>
            <Space size="small">
              <Form.Item name="withTotal" valuePropName="checked">
                <Checkbox>{_t("strWithTotal")}</Checkbox>
              </Form.Item>
              <Form.Item>
                <Divider type="vertical" />
              </Form.Item>
              <Form.Item name="withPreviousQty" valuePropName="checked">
                <Checkbox>{_t("strWithPreviousQty")}</Checkbox>
              </Form.Item>
            </Space>
          </Col>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              {filterType ? _t("strAdd") : _t("strSearch")}
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </Spin>
  );
};

export default FilterItemMovementRep;
