import React, { useState, useEffect } from "react";
import { Button, Table, message, notification, Popconfirm } from "antd";
import { Link } from "react-router-dom";
import { EditTwoTone, DeleteTwoTone } from "@ant-design/icons";
import { permissions } from "../../../services/Authorization/authService";
import {
  getAllUserRole,
  deleteUserRole,
} from "../../../services/Authorization/userRoleService";
import _t from "../../../languages/translate";

const PageRoless = () => {
  const [dataTable, setDataTable] = useState([]);
  const [loadData, setLoadData] = useState(false);
  const [columnData, setColumnData] = useState([]);

  const tableConfig = [
    {
      title: _t("strName"),
      dataIndex: "name",
      key: "name",
    },

    {
      title: _t("strEdit"),
      key: "edit",
      width: "70px",
      hidden: permissions._UserRole_Put ? false : true,
      render: (text, record) => (
        <Link style={{ fontSize: "20px" }} to={`/roles/${record.id}`}>
          <EditTwoTone />
        </Link>
      ),
    },

    {
      title: _t("strDelete"),
      key: "delete",
      width: "70px",
      hidden: permissions._UserRole_Delete ? false : true,
      render: (text, record) => (
        <Popconfirm
          title={_t("strSureToDelete")}
          onConfirm={() => onDelete(record.id, record.parentId)}
        >
          <DeleteTwoTone twoToneColor="#eb2f96" style={{ fontSize: "20px" }} />
        </Popconfirm>
      ),
    },
  ];

  useEffect(() => {
    tableColumn(tableConfig);
    setLoadData(true);
    const fetchData = async () => {
      try {
        const { data: newData } = await getAllUserRole();

        setDataTable(newData.data);
        setLoadData(false);
      } catch (error) {
        if (error.response) {
          message.error(
            error.response.data?.message || _t("msgUnknownError"),
            3
          );
          setLoadData(false);
        } else if (error.request) {
          message.error(_t("msgServerDisconnected"), 3);
          setLoadData(false);
        } else {
          message.error(_t("msgUnknownError"), 3);
          setLoadData(false);
        }
      }
    };
    fetchData();
  }, [setDataTable]);

  const tableColumn = (columns) => {
    const datafilter = columns.filter((i) => i.hidden !== true);
    setColumnData(datafilter);
  };

  const onDelete = async (id) => {
    try {
      const response = await deleteUserRole(id);
      //   const newData = dataTable.filter((e) => e.id !== id);
      //  setDataTable(newData);
      const { data } = await getAllUserRole();
      setDataTable(data.data);
      notification.success({
        message: `${response.data.message}`,
        placement: `${_t("strNotificationDirection")}`,
      });
    } catch (error) {
      if (error.response) {
        message.error(error.response.data?.message || _t("msgUnknownError"), 3);
        setLoadData(false);
      } else if (error.request) {
        message.error(_t("msgServerDisconnected"), 3);
        setLoadData(false);
      } else {
        message.error(_t("msgUnknownError"), 3);
        setLoadData(false);
      }
    }
  };

  return (
    <div className="bgTab">
      {permissions._UserRole_Post && (
        <Button style={{ marginBottom: 20 }}>
          <Link to={`/roles/new`}>{_t("strAddNew")}</Link>
        </Button>
      )}
      <Table
        rowKey="id"
        columns={columnData}
        dataSource={dataTable}
        scroll={{ x: 700, y: 400 }}
        size="small"
        loading={loadData}
        pagination={false}
        bordered
      />
    </div>
  );
};

export default PageRoless;
