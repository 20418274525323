import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Button } from "antd";
import { PrinterOutlined } from "@ant-design/icons";
import { useReactToPrint } from "react-to-print";
import ComponentToPrint from "../../helper/Print/ComponentToPrint";
import _t from "../../../languages/translate";

const InvoicePrint = ({ objToPrint }) => {
  const componentRef = useRef();

  useEffect(() => {
    try {
      console.log(objToPrint);
    } catch (error) {
      console.log(error.response);
    }
  });

  const handlePrint = useReactToPrint({ content: () => componentRef.current });

  return (
    <div>
      <Row style={{ marginTop: -5 }}>
        <Col>
          <Button onClick={handlePrint}>
            <PrinterOutlined style={{ fontSize: 18 }} />
            {_t("strPrint")}
          </Button>
        </Col>
      </Row>
      <ComponentToPrint setObject={objToPrint} ref={componentRef} />
    </div>
  );
};

export default InvoicePrint;
