import React, { useEffect, useState, useRef } from "react";
import { Prompt } from "react-router";
import { useParams } from "react-router-dom";
import { Form, Input, Button, Space, message, Row, Col, Spin } from "antd";
import { editCompany, getCompany } from "../../services/companyService";
import UploadImage from "../upload/uploadImage";
import _t from "../../languages/translate";

const CompanyForm = () => {
  const [form] = Form.useForm();
  let { id } = useParams();
  const IX_Name = useRef(null);
  const [companyId] = useState(window.localStorage.getItem("comId"));
  const [avatarPath, setAvatarPath] = useState();
  const [loading, setLoading] = useState(false);
  const [loadData, setLoadData] = useState(true);
  //******************************//

  //******************************// //*******************// Editing processes //*******************// //******************************//

  useEffect(() => {
    IX_Name.current.focus();
    async function fetchData() {
      try {
        const { data } = await getCompany();
        setAvatarPath(data.data.logo); // guid.jpg

        form.setFieldsValue({
          id: data.data.id,
          logo: data.data.logo,
          name: data.data.name,
          phone: data.data.phone,
          email: data.data.email,
          address: data.data.address,
          note: data.data.note,
        });
        setLoadData(false);
      } catch (error) {
        if (error.response) {
          message.error(
            error.response.data?.message || _t("msgUnknownError"),
            3
          );
          setLoadData(false);
        } else if (error.request) {
          message.error(_t("msgServerDisconnected"), 3);
          setLoadData(false);
        } else {
          message.error(_t("msgUnknownError"), 3);
          setLoadData(false);
        }
      }
    }
    fetchData();
  }, [id]);

  //******************************// //*******************// Save //*******************// //******************************//

  const onFinish = async (values) => {
    values.id = companyId;
    values.logo = avatarPath;
    try {
      const data = await editCompany(values);
      setProChange(false);
      message.success(data.data.message, 3);
    } catch (error) {
      if (error.response) {
        if (error.response.data.ex.includes("IX_Name")) {
          IX_Name.current.focus();
          message.error(
            error.response.data?.message || _t("msgUnknownError"),
            3
          );
          setLoadData(false);
        } else {
          message.error(
            error.response.data?.message || _t("msgUnknownError"),
            3
          );
          setLoadData(false);
        }
      } else if (error.request) {
        message.error(_t("msgServerDisconnected"), 3);
        setLoadData(false);
      } else {
        message.error(_t("msgUnknownError"), 3);
        setLoadData(false);
      }
    }
  };

  //******************************// //*******************// Form Code //*******************// //******************************//
  const [proChange, setProChange] = useState(false);
  const promptForm = () => {
    setProChange(true);
  };
  const { TextArea } = Input;

  //******************************// //*******************// Form //*******************// //******************************//

  return (
    <React.Fragment>
      <Prompt when={proChange} message={_t("strUnsavedChanges")} />
      <div className="bg">
        <Spin spinning={loadData} tip={_t("strLoading")}>
          <Form
            form={form}
            name="companyform"
            onFinish={onFinish}
            layout="vertical"
          >
            <Row gutter={12}>
              <Col xs={24} md={12}>
                <Form.Item name="imagePath" label={_t("strLogo")}>
                  <UploadImage
                    previewImg={avatarPath}
                    setPreviewImg={setAvatarPath}
                    loading={loading}
                    setLoading={setLoading}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} />
              <Col xs={24} md={12}>
                <Form.Item
                  name="name"
                  label={_t("strName")}
                  rules={[
                    { required: true, message: `${_t("strIsRequired")}` },
                    { min: 1, message: `${_t("strFrom3-200")}` },
                    { max: 200, message: `${_t("strFrom3-200")}` },
                  ]}
                >
                  <Input
                    placeholder={_t("strName")}
                    maxLength={200}
                    autoComplete="off"
                    onChange={promptForm}
                    ref={IX_Name}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} md={12}>
                <Form.Item name="phone" label={_t("strPhone")}>
                  <Input
                    placeholder={_t("strPhone")}
                    maxLength={20}
                    autoComplete="off"
                    onChange={promptForm}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  name="email"
                  label={_t("strEmail")}
                  rules={[{ type: "email", message: `${_t("strFixEmail")}` }]}
                >
                  <Input
                    placeholder={_t("strEmail")}
                    maxLength={100}
                    autoComplete="off"
                    onChange={promptForm}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item name="address" label={_t("strAddress")}>
                  <Input
                    placeholder={_t("strAddress")}
                    maxLength={200}
                    autoComplete="off"
                    onChange={promptForm}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={24}>
                <Form.Item
                  name="note"
                  label={_t("strNote")}
                  rules={[
                    { required: false, message: `${_t("strIsRequired")}` },
                    { min: 1, message: `${_t("strFrom1-1000")}` },
                    { max: 1000, message: `${_t("strFrom1-1000")}` },
                  ]}
                >
                  <TextArea
                    placeholder={_t("strNote")}
                    maxLength={1000}
                    autoComplete="off"
                    onChange={promptForm}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item>
              <Space>
                <Button type="primary" htmlType="submit" disabled={loading}>
                  {_t("strSave")}
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </Spin>
      </div>
    </React.Fragment>
  );
};

export default CompanyForm;
