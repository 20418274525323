import { _URL } from "../../config.json";
import http from "../http";

const apiEndpoint = _URL + "/api/StoreTransactionPattern";

export function getAllSTP() {
  return http.get(`${apiEndpoint}`);
}

export function getSTPById(Id) {
  return http.get(`${apiEndpoint}/${Id}`);
}

export function editSTP(obj) {
  if (obj.id !== "new" && obj.id !== "add") {
    return http.put(apiEndpoint, obj);
  } else {
    return http.post(apiEndpoint, obj);
  }
}

export function deleteSTP(Id) {
  return http.delete(`${apiEndpoint}/${Id}`);
}
