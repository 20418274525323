import React from "react";

const PrintingForm = React.forwardRef(({ html, lang }, ref) => {
  
  return (
    <div style={{ padding: 15 }} ref={ref}>
      <div
        dir={lang === "ar" ? "rtl" : "ltr"}
        dangerouslySetInnerHTML={{ __html: html }}
      />
    </div>
  );
});

export default PrintingForm;
