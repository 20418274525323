import React, { useEffect, useState } from "react";
import { permissions } from "../../services/Authorization/authService";
import DataTable, { pageSizeKey } from "../helper/dataTable/dataTable";
import { useLocation, useHistory } from "react-router-dom";
import {
  Button,
  Popconfirm,
  message,
  Space,
  Input,
  Tooltip,
  Divider,
  Tag,
} from "antd";
import { Link } from "react-router-dom";
import _t from "../../languages/translate";
import {
  EditTwoTone,
  DeleteTwoTone,
  FileAddTwoTone,
  CloseCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { getPagers, deleteStore } from "../../services/storeService";

const PageStores = () => {
  const searchKey = "search";
  let history = useHistory();
  const location = useLocation();
  const [meta, setMeta] = useState("");
  const [dataSource, setDataSource] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const savedPageSize = localStorage.getItem(pageSizeKey);

  const tableConfig = [
    {
      title: _t("strCode"),
      dataIndex: "code",
      key: "code",
      width: 50,
      fixed: "left",
    },
    {
      title: _t("strName"),
      dataIndex: "name",
      width: 150,
      key: "name",
    },
    {
      title: _t("strStatus"),
      key: "inactive",
      width: 75,
      render: (_, record) =>
        record.inactive ? (
          <Tag color="volcano">{_t("strInActive")}</Tag>
        ) : (
          <Tag color="green">{_t("strActive")}</Tag>
        ),
    },
    {
      title: _t("strActions"),
      key: "edit",
      width: 40,
      align: "center",

      hidden:
        permissions._Store_Delete &&
        permissions._Store_Put &&
        permissions._Store_Post
          ? false
          : true,

      render: (_, record) => (
        <Space size="large">
          {permissions._Store_Post ? (
            <Tooltip title={_t("strAddSubItem")} color="blue">
              <Link
                style={{
                  fontSize: "20px",
                  visibility: `${record.isMain ? "visible" : "hidden"}`,
                }}
                to={{
                  pathname: "/stores/add",
                  state: { parentId: record.id },
                }}
              >
                <FileAddTwoTone />
              </Link>
            </Tooltip>
          ) : undefined}

          {permissions._Store_Put ? (
            <Tooltip title={_t("strEdit")} color="green">
              <Link style={{ fontSize: "20px" }} to={`/stores/${record.id}`}>
                <EditTwoTone twoToneColor="#52c41a" />
              </Link>
            </Tooltip>
          ) : undefined}

          {permissions._Store_Delete ? (
            <Tooltip title={_t("strDelete")} color="red">
              <Popconfirm
                title={_t("strSureToDelete")}
                onConfirm={() => onDelete(record.id, record.parentId)}
              >
                <DeleteTwoTone
                  twoToneColor="#eb2f96"
                  style={{ fontSize: "20px" }}
                />
              </Popconfirm>
            </Tooltip>
          ) : undefined}
        </Space>
      ),
    },
  ];

  useEffect(() => {
    getView(true);
    getSearch();
  }, [location.search, savedPageSize]);

  const getView = async (load) => {
    setIsLoading(load);
    const query = new URLSearchParams(location.search);
    if (savedPageSize && !query.has(pageSizeKey))
      query.set(pageSizeKey, savedPageSize);
    try {
      const { data: responce } = await getPagers(query.toString());

      if (responce.data.length > 0) {
        setDataSource(responce.data);
        setMeta(responce.meta);
      } else {
        setDataSource([]);
        // message.info(_t("msgThereIsNoData"), 3);
      }
    } catch (error) {
      if (error.response) {
        message.error(error.response.data?.message || _t("msgUnknownError"), 3);
      } else if (error.request) {
        message.error(_t("msgServerDisconnected"), 3);
      } else {
        message.error(_t("msgUnknownError"), 3);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const onDelete = async (id) => {
    try {
      const response = await deleteStore(id);
      getView(false);
      message.success(response.data.message);
    } catch (ex) {
      message.error(ex.response?.data?.message || _t("msg.not_deleted"));
    }
  };

  const customExpandIcon = (props) => {
    if (props.record.children.length < 1) {
      return <span></span>;
    }
    if (props.expanded) {
      return (
        <button
          type="button"
          className="ant-table-row-expand-icon ant-table-row-expand-icon-expanded"
          onClick={(e) => {
            props.onExpand(props.record, e);
          }}
        />
      );
    } else {
      return (
        <button
          type="button"
          className="ant-table-row-expand-icon ant-table-row-expand-icon-collapsed"
          onClick={(e) => {
            props.onExpand(props.record, e);
          }}
        />
      );
    }
  };

  const getSearch = () => {
    const query = new URLSearchParams(history.location.search);
    setSearchValue(query.get(searchKey));
  };

  const handleSearch = async (value) => {
    const query = new URLSearchParams(history.location.search);
    if (!value) query.delete(searchKey);
    else query.set(searchKey, value);
    history.push({
      pathname: history.location.pathname,
      search: query.toString(),
    });
  };

  const handleSearchClear = () => {
    setSearchValue("");
    const query = new URLSearchParams(history.location.search);
    query.delete(searchKey);
    history.push({
      pathname: history.location.pathname,
      search: query.toString(),
    });
  };

  return (
    <>
      <DataTable
        rowKey="id"
        hideSearch
        expandIconColumnIndex={0}
        saveColumnsProps="Stores"
        columns={tableConfig}
        loading={isLoading}
        dataSource={dataSource}
  
        meta={meta}
        expandIcon={(treeBran) => customExpandIcon(treeBran)}
        size="small"
        bordered
        rowClassName={(record, index) =>
          record.rowType === "Total"
            ? "treeMain-row"
            : record.rowType === "Detail"
            ? "treeDetails-row"
            : index % 2 === 0
            ? "table-row-light"
            : "table-row-dark"
        }
        extra={
          <Space>
            {permissions._ClientSupplier_Post && (
              <Button
                style={{ width: 150, borderRadius: 5 }}
                type="primary"
                size="small"
              >
                <Link to={`/stores/new`}>{_t("strAddNew")}</Link>
              </Button>
            )}

            <Divider type="vertical" />
            <Input
              defaultValue={
                new URLSearchParams(history.location.search).get("search") ||
                undefined
              }
              onChange={(e) => setSearchValue(e.target?.value)}
              placeholder={_t("strSearch")}
              onPressEnter={(e) => handleSearch(e.currentTarget.value)}
              bordered={false}
              size="small"
              value={searchValue}
              prefix={
                searchValue === "" || searchValue === null ? (
                  <SearchOutlined style={{ color: "#a5a5a5" }} />
                ) : (
                  <CloseCircleOutlined
                    onClick={() => handleSearchClear()}
                    style={{ color: "#a5a5a5" }}
                  />
                )
              }
            />
            <Divider type="vertical" />
          </Space>
        }
      />
    </>
  );
};

export default PageStores;
