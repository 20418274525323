import React from "react";
import _t from "../../../languages/translate";
import "../Print/style/printStyle.css";
import logoYb from "./image/Boledo.jpeg";
import { Table, Form } from "antd";

const DrawingReportsPrint = React.forwardRef(
  ({ setObject, setColumn, setTitle }, ref) => {
    return (
      <div
        style={{
          padding: 15,
        }}
        ref={ref}
      >
        <Form className="clearfix">
          <div id="logo">
            <img src={logoYb} />
          </div>
          <h1 id="Xh1">{setTitle}</h1>
          <br />
          <Table
            id="Ytable"
            columns={setColumn}
            dataSource={setObject}
            pagination={false}
            size="small"
            rowClassName={(record) =>
              record.rowType === "First"
                ? "firstActive-row"
                : record.rowType === "Total"
                ? "active-row"
                : "data-row"
            }
            bordered
          />
        </Form>
      </div>
    );
  }
);

export default DrawingReportsPrint;
