import React, { useRef, useState, useEffect } from "react";
import { Prompt } from "react-router";
import { useHistory, useParams, Link, useLocation } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  Switch,
  Select,
  message,
  Empty,
  Modal,
  Radio,
  Space,
  Row,
  Col,
  Menu,
  Dropdown,
  Card,
  Divider,
  Checkbox,
  Spin,
  Popconfirm,
  InputNumber,
} from "antd";
import {
  getItemById,
  editItem,
  getAllParents,
  getNewItemCode,
} from "../../../services/Items/itemService";
import { getAllUnit } from "../../../services/Items/itemUnitService";
import {
  ExclamationCircleOutlined,
  RetweetOutlined,
  DeleteOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { deleteItemUnitMap } from "../../../services/Items/itemUnitMapService";

import _t from "../../../languages/translate";
import FormUnit from "./../Items/fr_ItemUnit";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ItemForm = ({ onNewItem }) => {
  const IX_Code = useRef(null);
  const IX_Name = useRef(null);
  const [loading] = useState(false);
  const [form] = Form.useForm();
  let history = useHistory();
  let { id } = useParams();
  let query = useQuery();
  const { Option } = Select;
  const { TextArea } = Input;
  //******************************//
  const [companyId] = useState(window.localStorage.getItem("comId"));
  const [isGoBack, setIsGoBack] = useState(true);
  const [visibility, setVisibility] = useState(false);
  const [parentChange, setParentChange] = useState(false);
  const [goBackDis, setGoBackDis] = useState(false);
  const [itemParentOpt, setItemParentOpt] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState([]);
  const [isStockCount, setIsStockCount] = useState([]);
  const [valuesChange, setValuesChange] = useState([]);
  const [proChange, setProChange] = useState(false);
  const [showUnit, setShowUnit] = useState(true);
  const [unitOpt, setUnitOpt] = useState([]);
  const [loadData, setLoadData] = useState(true);
  const [isGoParentId, setIsGoParentId] = useState(null);
  //******************************//

  const optionsWithDisabled = [
    { label: _t("strSub"), value: false },
    { label: _t("strMain"), value: true },
  ];
  const itemParentOptList =
    itemParentOpt.length > 0 &&
    itemParentOpt.map((item) =>
      item.id !== id ? (
        <Select.Option key={item.id} value={item.id}>
          {item.name}
        </Select.Option>
      ) : null
    );

  const showModal = () => {
    setVisibility(true);
  };

  const handleCancel = () => {
    setVisibility(false);
  };

  useEffect(() => {
    requestStatus(); // Request status | New | Child | Edit | 😆
    loadAllParents();
    loadAllUnit();
    IX_Name.current.focus();
  }, []);

  const requestStatus = () => {
    if (id === "new") {
      codeGenerator(null);
    } // if New item
    if (id === "add") {
      const parentId = history.location.state?.parentId;
      if (!parentId) return;
      codeGenerator(parentId);
      form.setFieldsValue({ parentId: parentId });
      setIsGoParentId(parentId);
    } // if Add child
    if (id !== "new" && id !== "add") {
      setParentChange(true);
    } // if Edit item
  };

  const loadAllParents = async () => {
    try {
      const { data: itemParentData } = await getAllParents();
      setItemParentOpt(itemParentData.data);
      setLoadData(false);
    } catch (error) {
      if (error.response) {
        message.error(error.response.data?.message || _t("msgUnknownError"), 3);
        setLoadData(false);
      } else if (error.request) {
        message.error(_t("msgServerDisconnected"), 3);
        setLoadData(false);
      } else {
        message.error(_t("msgUnknownError"), 3);
        setLoadData(false);
      }
    }
  };

  const loadAllUnit = async () => {
    try {
      const { data: unitData } = await getAllUnit();
      setUnitOpt(unitData.data);
      setLoadData(false);
    } catch (error) {
      if (error.response) {
        message.error(error.response.data?.message || _t("msgUnknownError"), 3);
        setLoadData(false);
      } else if (error.request) {
        message.error(_t("msgServerDisconnected"), 3);
        setLoadData(false);
      } else {
        message.error(_t("msgUnknownError"), 3);
        setLoadData(false);
      }
    }
  };

  //******************************// //*******************// code number processes //*******************// //******************************//

  const codeGenerator = async (Id) => {
    const { data: newData } = await getNewItemCode(Id);
    form.setFieldsValue({ code: newData.data.code });
  }; //  To generate code by id  🚀

  const onChangeParent = async (value) => {
    if (parentChange === true) {
      Modal.confirm({
        title: `${_t("msgAskGenerateANewCode")}`,
        icon: <ExclamationCircleOutlined />,
        content: `${_t("msgGenerateANewCode")}`,
        okText: `${_t("strYes")}`,
        cancelText: `${_t("strNo")}`,
        async onOk() {
          setParentChange(false);
          codeGenerator(value);
          setProChange(true);
        },
        onCancel() {
          setProChange(true);
        },
      });
    } else {
      codeGenerator(value);
      setIsGoParentId(value);
      setProChange(true);
    }
  };

  //******************************// //*******************// Editing processes //*******************// //******************************//

  useEffect(() => {
    async function fetchData() {
      if (id === "new" || id === "add") {
        return;
      }
      try {
        const { data } = await getItemById(id);
        if (data.data.parentId === null) {
          data.data.parentId = undefined;
        }
        setValuesChange(data.data);
        setShowUnit(data.data.isMain ? false : true);
        if (
          data.data.defaultUnitId === "00000000-0000-0000-0000-000000000000"
        ) {
        } else {
          form.setFieldsValue({ defaultUnitId: data.data.defaultUnitId });
        }
        debugger;
        form.setFieldsValue({
          id: data.data.id,
          isMain: data.data.isMain,
          parentId: data.data.parentId,
          code: data.data.code,
          name: data.data.name,
          name2: data.data.name2,

          minimumStore:data.data.minimumStore,
          maximumStore:data.data.maximumStore,

          note: data.data.note,
          inactive: data.data.inactive,
          companyId: data.data.companyId,
          itemUnit_Maps: data.data.itemUnit_Maps,
        });
        if (history.length === 1) {
          setGoBackDis(true);
        }
        formValuesChange();
      } catch (error) {
        if (error.response) {
          message.error(
            error.response.data?.message || _t("msgUnknownError"),
            3
          );
          setLoadData(false);
        } else if (error.request) {
          message.error(_t("msgServerDisconnected"), 3);
          setLoadData(false);
        } else {
          message.error(_t("msgUnknownError"), 3);
          setLoadData(false);
        }
      }
    }
    fetchData();
  }, [id, history]);

  //******************************// //*******************// Back //*******************// //******************************//

  const menu = (
    <Menu>
      <Menu.Item onClick={() => setIsGoBack(true)}>
        {_t("strLeaveThePage")}
      </Menu.Item>
      <Menu.Item onClick={() => setIsGoBack(false)}>
        {_t("strStayInPage")}
      </Menu.Item>
    </Menu>
  );

  const goBack = () => {
    setProChange(false);

    if (isGoBack) {

      history.goBack();
    } else {
      if (id !== "new" && id !== "add") {
        history.replace({ ...history.location, pathname: "/item/new" });
      }
      codeGenerator(isGoParentId);
      form.setFieldsValue({ name: "" });
      loadAllParents();
      IX_Name.current.focus();
    }
  };

  //******************************// //*******************// Save //*******************// //******************************//

  const onFinish = async (values) => {
    values.id = id;
    values.companyId = companyId;
    if (values.isMain === undefined) {
      values.isMain = false;
    }
    try {
      const data = await editItem(values);
      message.success(data.data.message, 3);
      if (typeof onNewItem === "function") {
        onNewItem(data);
      } else {
        goBack();
      }
    } catch (error) {
      console.error(error.response);
      if (error.response) {
        if (error.response?.data?.data?.ex.includes("IX_Code")) {
          IX_Code.current.focus();
          message.error(
            error.response?.data?.data?.message || _t("msgUnknownError"),
            3
          );
          setLoadData(false);
        } else if (error.response?.data?.data?.ex.includes("IX_Name")) {
          IX_Name.current.focus();
          message.error(
            error.response?.data?.data?.message || _t("msgUnknownError"),
            3
          );
          setLoadData(false);
        } else {
          message.error(
            error.response?.data?.message || _t("msgUnknownError"),
            3
          );
          setLoadData(false);
        }
      } else if (error.request) {
        message.error(_t("msgServerDisconnected"), 3);
        setLoadData(false);
      } else {
        message.error(_t("msgUnknownError"), 3);
        setLoadData(false);
      }
    }
  };

  const handelNewUnit = (units) => {
    const newUnit = [...unitOpt, units.data.data];
    setUnitOpt(newUnit);
    setVisibility(false);
    const newList = [...form.getFieldValue().unitsIds, units.data.data.id];
    form.setFieldsValue({ unitsIds: newList });
  };

  const isMainChange = (e) => {
    setShowUnit(e.target.value ? false : true);
    setProChange(true);
  };

  const promptForm = () => {
    setProChange(true);
  };

  //******************************// //*******************// Form List //*******************// //******************************//

  const oChSelectedMainUnit = () => {
    return unitOpt.map((item) => (
      <Select.Option
        disabled={selectedUnit.includes(item.id)}
        key={item.id}
        value={item.id}
      >
        {item.name}
      </Select.Option>
    ));
  };

  const formValuesChange = (value) => {
    if (value !== undefined) {
      const parentId = value.parentId;
      if (parentId) {
        history.replace({ ...history.location, state: { parentId } });
      }
    }

    const allValue = form.getFieldsValue();
    setValuesChange(allValue);
    if (allValue && allValue.itemUnit_Maps && allValue.itemUnit_Maps.length) {
      let result = allValue.itemUnit_Maps.map((item) =>
        item ? item.itemUnitId : "0"
      );
      if (allValue.defaultUnitId) result.push(allValue.defaultUnitId);
      setSelectedUnit(result);
      let inStockCount = [];
      allValue.itemUnit_Maps.forEach((item, i) => {
        if (item && item.isStock) inStockCount.push(i);
      });
      setIsStockCount(inStockCount);
    }
  };

  const onDelete = async (index, remove) => {
    const allValue = form.getFieldsValue();
    const iMap = allValue.itemUnit_Maps[index];
    if (iMap === undefined) {
      remove(index);
    } else {
      if (iMap.id !== undefined && "id" in iMap) {
        try {
          const res = await deleteItemUnitMap(iMap.id);
          message.success(res.data.message, 3);
          remove(index);
        } catch (error) {
          if (error.response) {
            message.error(
              error.response.data?.message || _t("msgUnknownError"),
              3
            );
            setLoadData(false);
          } else if (error.request) {
            message.error(_t("msgServerDisconnected"), 3);
            setLoadData(false);
          } else {
            message.error(_t("msgUnknownError"), 3);
            setLoadData(false);
          }
        }
      } else {
        remove(index);
        message.success(_t("strDoneSuccessfully"), 3);
      }
    }
  };

  //******************************// //*******************// Form //*******************// //******************************//
  return (
    <div key="mainDiv">
      <Modal
        visible={visibility}
        onCancel={() => handleCancel()}
        title={_t("strUnit")}
        footer={null}
        destroyOnClose={true}
        width={1000}
      >
        <FormUnit onNewUnit={handelNewUnit} />
      </Modal>
      <React.Fragment>
        <Prompt when={proChange} message={_t("strUnsavedChanges")} />

        <div className="bg">
          <Spin spinning={loadData} tip={_t("strLoading")}>
            <Form
              form={form}
              name="itemform"
              onFinish={onFinish}
              layout="vertical"
              onValuesChange={formValuesChange}
              initialValues={{ isMain: false }}
            >
              <Row gutter={12}>
                <Col xs={24} md={12}>
                  <Form.Item
                    label={_t("strItemClassification")}
                    name="isMain"
                    hidden={typeof onNewItem === "function"}
                  >
                    <Radio.Group
                      buttonStyle="solid"
                      options={optionsWithDisabled}
                      onChange={isMainChange}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} />
                <Col xs={24} md={12}>
                  <Form.Item
                    name="parentId"
                    label={_t("strMainItem")}
                    rules={[{ required: false }]}
                  >
                    <Select
                      allowClear
                      showSearch
                      optionFilterProp="children"
                      notFoundContent={
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}></Empty>
                      }
                      onChange={onChangeParent}
                      placeholder={_t("strNotSelected")}
                    >
                      {itemParentOptList}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="code"
                    label={_t("strCode")}
                    rules={[
                      {
                        required: true,
                        message: `${_t("strIsRequired")}`,
                      },
                      { min: 1, message: `${_t("strFrom3-200")}` },
                      { max: 200, message: `${_t("strFrom3-200")}` },
                    ]}
                  >
                    <Input
                      placeholder={_t("strCode")}
                      autoComplete="off"
                      maxLength={200}
                      onChange={promptForm}
                      ref={IX_Code}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="name"
                    label={_t("strName")}
                    rules={[
                      { required: true, message: `${_t("strIsRequired")}` },
                      { min: 1, message: `${_t("strFrom3-200")}` },
                      { max: 200, message: `${_t("strFrom3-200")}` },
                    ]}
                  >
                    <Input
                      placeholder={_t("strName")}
                      maxLength={200}
                      autoComplete="off"
                      onChange={promptForm}
                      ref={IX_Name}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="name2"
                    label={_t("strName2")}
                    rules={[
                      { required: false, message: `${_t("strIsRequired")}` },
                      { min: 1, message: `${_t("strFrom3-200")}` },
                      { max: 200, message: `${_t("strFrom3-200")}` },
                    ]}
                  >
                    <Input
                      placeholder={_t("strName2")}
                      maxLength={200}
                      autoComplete="off"
                      onChange={promptForm}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} md={12}  style={showUnit ? {} : { display: "none" }}>
                  <Form.Item
                    name="minimumStore"
                    label={_t("strMinimum")}

                
                  >
                    <InputNumber
                      placeholder={_t("strMinimum")}               
                      step="1.000"
                      min="0"
                      max="999999999999"
                      autoComplete="off"
                      onChange={promptForm}
                      style={{width:"100%"}}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} md={12}  style={showUnit ? {} : { display: "none" }}>
                  <Form.Item
                    name="maximumStore"
                    label={_t("strMaximum")}
                 
                  >
                    <InputNumber
                      placeholder={_t("strMaximum")}             
                      step="1.000"
                      min="0"
                      max="999999999999"
                      autoComplete="off"
                      onChange={promptForm}
                      style={{width:"100%"}}
                    />
                  </Form.Item>
                </Col>

                <Col
                  xs={24}
                  md={24}
                  style={showUnit ? {} : { display: "none" }}
                >
                  <Card
                    type="inner"
                    title={_t("strUnits")}
                    className="scrollCard"
                  >
                    <Form.Item
                      name="defaultUnitId"
                      label={_t("strDefaultUnit")}
                      rules={[{ required: showUnit, message: "Missing area" }]}
                    >
                      <Select
                        showSearch
                        optionFilterProp="children"
                        notFoundContent={
                          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}>
                            <Button onClick={() => showModal()}>
                              {" "}
                              {_t("strAddNew")}{" "}
                            </Button>
                          </Empty>
                        }
                        onChange={promptForm}
                      >
                        {oChSelectedMainUnit()}
                      </Select>
                    </Form.Item>
                    <Form.List name="itemUnit_Maps">
                      {(fields, { add, remove }) => {
                        return (
                          <div>
                            {fields.map((field, i) => (
                              <div key={i}>
                                <Form.Item
                                  {...field}
                                  name={[field.name, "id"]}
                                  hidden
                                >
                                  <input autoComplete="off" />
                                </Form.Item>

                                <Row gutter={12} style={{ marginBottom: -25 }}>
                                  <Col xs={12} md={8}>
                                    <Form.Item
                                      {...field}
                                      name={[field.name, "itemUnitId"]}
                                      fieldKey={[field.fieldKey, "itemUnitId"]}
                                      rules={[
                                        {
                                          required: true,
                                          message: _t("strFieldRequired"),
                                        },
                                      ]}
                                    >
                                      <Select
                                        showSearch
                                        optionFilterProp="children"
                                        notFoundContent={
                                          <label
                                            style={{
                                              verticalAlign: "center",
                                              color: "#b1b1b1",
                                            }}
                                          >
                                            {_t("strPleaseSelectDefaultUnit")}
                                          </label>
                                        }
                                        onChange={promptForm}
                                        placeholder={_t("strUnit")}
                                        autoComplete="off"
                                      >
                                        {oChSelectedMainUnit()}
                                      </Select>
                                    </Form.Item>
                                  </Col>
                                  <Col xs={12} md={6}>
                                    <Form.Item
                                      {...field}
                                      name={[field.name, "type"]}
                                      fieldKey={[field.fieldKey, "type"]}
                                      rules={[
                                        {
                                          required: true,
                                          message: _t("strFieldRequired"),
                                        },
                                      ]}
                                    >
                                      <Select
                                        showSearch
                                        optionFilterProp="children"
                                        placeholder={_t("strType")}
                                        autoComplete="off"
                                        onChange={promptForm}
                                      >
                                        <Option value={1}>
                                          {_t("strBigger")}
                                        </Option>
                                        <Option value={2}>
                                          {_t("strSmallerThan")}
                                        </Option>
                                      </Select>
                                    </Form.Item>
                                  </Col>
                                  <Col xs={12} md={4}>
                                    <Form.Item
                                      {...field}
                                      name={[field.name, "value"]}
                                      fieldKey={[field.fieldKey, "value"]}
                                      rules={[
                                        {
                                          required: true,
                                          message: _t("strFieldRequired"),
                                        },
                                      ]}
                                    >
                                      <InputNumber
                                        min={1}
                                        step={0.1}
                                        max={9999}
                                        placeholder={_t("strValue")}
                                        autoComplete="off"
                                        onChange={promptForm}
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col xs={6} md={3}>
                                    <Form.Item
                                      {...field}
                                      name={[field.name, "isStock"]}
                                      fieldKey={[field.fieldKey, "isStock"]}
                                      valuePropName="checked"
                                    >
                                      <Checkbox
                                        disabled={
                                          isStockCount.length >= 2 &&
                                            !isStockCount.includes(i)
                                            ? true
                                            : false
                                        }
                                        onChange={promptForm}
                                      >
                                        {_t("strStocked")}
                                      </Checkbox>
                                    </Form.Item>
                                  </Col>
                                  <Col xs={6} md={3} style={{ paddingTop: 6 }}>
                                    <Popconfirm
                                      title={_t("strSureToDelete")}
                                      onConfirm={() => onDelete(i, remove)}
                                    >
                                      <DeleteOutlined
                                        style={{
                                          fontSize: 20,
                                          color: "#ff5252",
                                        }}
                                      />
                                    </Popconfirm>
                                  </Col>
                                </Row>
                                <Divider
                                  dashed
                                  style={{ background: "#c7c7c7" }}
                                />
                              </div>
                            ))}
                            <Form.Item>
                              <Button
                                type="dashed"
                                onClick={() => {
                                  add();
                                }}
                                block
                              >
                                <PlusOutlined /> {_t("strAddUnit")}
                              </Button>
                            </Form.Item>
                          </div>
                        );
                      }}
                    </Form.List>
                  </Card>
                </Col>
                <Col xs={24} md={24}>
                  <Form.Item
                    name="note"
                    label={_t("strNote")}
                    rules={[
                      { required: false, message: `${_t("strIsRequired")}` },
                      { min: 1, message: `${_t("strFrom1-1000")}` },
                      { max: 1000, message: `${_t("strFrom1-1000")}` },
                    ]}
                  >
                    <TextArea
                      placeholder={_t("strNote")}
                      maxLength={1000}
                      autoComplete="off"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="inactive"
                    valuePropName="checked"
                    label={_t("strInActive")}
                  >
                    <Switch />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} />
              </Row>
              <Form.Item>
                <Space size="large">
                  <Button type="primary" htmlType="submit" disabled={loading}>
                    {_t("strSave")}
                  </Button>
                  <Dropdown.Button
                    hidden={typeof onNewStore === "function"}
                    overlay={menu}
                    onClick={() => history.goBack()}
                    disabled={goBackDis}
                  >
                    {" "}
                    {_t("strBack")}
                  </Dropdown.Button>
                  {isGoBack ? (
                    <i />
                  ) : (
                    <RetweetOutlined style={{ fontSize: 21, marginTop: 5 }} />
                  )}
                </Space>
              </Form.Item>
            </Form>
          </Spin>
        </div>
      </React.Fragment>
    </div>
  );
};

export default ItemForm;
