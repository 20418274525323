export const saveColumns = (columns, type) => {
  let columnsToSave = [];

  for (let i = 0; i < columns.length; i++) {
    columnsToSave.push({
      key: columns[i].key,
      hidden: columns[i].hidden,
      width: columns[i].width,
      type: columns[i].type,
    });
  }

  localStorage.setItem(type, JSON.stringify(columnsToSave));
};

export const getColumns = (columns, type) => {
  const savedColumns = localStorage.getItem(type);
  const parsedColumns = savedColumns !== null ? JSON.parse(savedColumns) : null;
  const parsedColumnsArray =
    parsedColumns !== null ? Object.values(parsedColumns) : null;
  const newColumns =
    parsedColumnsArray !== null && parsedColumnsArray.length === columns.length
      ? parsedColumnsArray.map((col) => {
          let originalCol = columns.find((c) => c.key === col.key);
          return {
            ...originalCol,
            hidden: col.hidden,
            width: col.width,
            type: col.type,
          };
        })
      : columns;
  return newColumns !== null ? newColumns : columns;
};
