import { _URL } from "../../config.json";
import http from "../http";

const apiEndpoint = _URL + "/api/StoreTransaction";

export function getAllST() {
  return http.get(`${apiEndpoint}`);
}

export function getSTById(Id) {
  return http.get(`${apiEndpoint}/${Id}`);
}

export function editST(addState, obj) {
  if (obj.id !== "new") {
    return http.put(`${apiEndpoint}/?ValidateQty=${addState}`, obj);
  } else {
    return http.post(`${apiEndpoint}/?ValidateQty=${addState}`, obj);
  }
}

export function deleteST(Id) {
  return http.delete(`${apiEndpoint}/${Id}`);
}

export function getNewSTCode(Id) {
  return http.post(`${apiEndpoint}/GetNewCode?PatternId=${Id}`);
}

export function searchByCode(patternId, code) {
  return http.post(
    `${apiEndpoint}/GetByCode/?patternId=${patternId}&code=${code}`
  );
}

export function searchByDocument(patternId, document) {
  return http.post(
    `${apiEndpoint}/GetByDocument/?patternId=${patternId}&document=${document}`
  );
}

export function searchSoByCode(code) {
  return http.post(`${apiEndpoint}/GetSalesOrderByCode/?code=${code}`);
}

export function searchSoByDocument(document) {
  return http.post(
    `${apiEndpoint}/GetSalesOrderByDocument/?document=${document}`
  );
}

export function searchSoByCustomer(customer) {
  return http.post(
    `${apiEndpoint}/GetSalesOrderByCustomer/?customer=${customer}`
  );
}

export function getNext(PatternId, Id) {
  return http.post(`${apiEndpoint}/GetNext/?PatternId=${PatternId}&Id=${Id}`);
}
export function getPrev(PatternId, Id) {
  return http.post(`${apiEndpoint}/GetPrev/?PatternId=${PatternId}&Id=${Id}`);
}

export function getPagerST(current, pageSize) {
  return http.post(
    `${apiEndpoint}/GetPager?PageNumber=${current}&PageSize=${pageSize}`
  );
}

export function uploadExcel(obj) {
  let formData = new FormData();
  formData.append("file", obj);
  return http.post(`${apiEndpoint}/ImportFromExcel`, formData);
}

export function getQtyTotal(obj) {
  return http.post(`${apiEndpoint}/GetQtyTotal`, obj);
}


