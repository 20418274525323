import React, { useRef } from 'react';
import { Row, Col, Button } from 'antd';
import { PrinterOutlined } from '@ant-design/icons';
import { useReactToPrint } from 'react-to-print';
import DrawingReportsPrint from '../../helper/Print/DrawingReportsPrint';
import _t from '../../../languages/translate';

const ReportsPrint = ({ objToPrint, setColumn, setTitle }) => {
    const componentRef = useRef();
    const handlePrint = useReactToPrint(
        { content: () => componentRef.current });

    return (
        <div>
            <Row style={{marginTop:-5}}>
                <Col>
                    <Button onClick={handlePrint}>
                        <PrinterOutlined style={{ fontSize: 18 }} />
                        {_t("strPrint")}
                    </Button>
                </Col>
            </Row>
            <DrawingReportsPrint setObject={objToPrint} setColumn={setColumn} setTitle={setTitle} ref={componentRef} />
        </div>
    )
}

export default ReportsPrint;