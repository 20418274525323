import { _URL } from "../config.json";
import http from "./http";

const apiEndpoint = _URL + "/api/branch";

export function getPagers(query) {
  return http.post(`${apiEndpoint}/GetPager?${query}`);
}

export function getBranchById(Id) {
  return http.get(`${apiEndpoint}/${Id}`);
}

export function editBranch(obj) {
  if (obj.id !== "new" && obj.id !== "add") {
    return http.put(apiEndpoint, obj);
  } else {
    return http.post(apiEndpoint, obj);
  }
}

export function deleteBranch(Id) {
  return http.delete(`${apiEndpoint}/${Id}`);
}

export function getBranches() {
  return http.get(`${apiEndpoint}`);
}

export function getAllParents() {
  return http.post(`${apiEndpoint}/GetAllParent`);
}

export function getAllChildren() {
  return http.post(`${apiEndpoint}/GetAllChildren`);
}


export function getActiveChildren(search, addId) {
  return http.post(
    `${apiEndpoint}/GetActiveChildren/?search=${search}&addId=${addId}`
  );
}
export function getActiveParents(search, addId) {
  return http.post(
    `${apiEndpoint}/GetActiveParent/?search=${search}&addId=${addId}`
  );
}

export function getActiveParentsURL(search, addId) {
   const params = new URLSearchParams()
  search && params.append("search", search);
  //addId && params.append("addId", addId); 
 
  return `${apiEndpoint}/GetActiveChildrenTest/?${params.toString()}`;
}




export function getAllBranch() {
  return http.post(`${apiEndpoint}/GetAll`);
}

export function getNewBranchCode(Id) {
  return http.post(`${apiEndpoint}/GetNew/?parentId=${Id}`);
}

export function getPagerBranch(current, pageSize) {
  return http.post(
    `${apiEndpoint}/GetPager?PageNumber=${current}&PageSize=${pageSize}`
  );
}
