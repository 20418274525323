import { _URL } from "../../config.json";
import http from "../http";

const apiEndpoint = _URL + "/api/Role";

export async function getRoles() {
  try {
    const { data } = await http.get(`${apiEndpoint}`);
    console.log(data.data);
    return data.data;
  } catch (ex) {
    return null;
  }
}
