import React, { useEffect } from "react";
import { Upload, message } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { _URL } from "../../config.json";
import uploadImg from "../../services/uploadService";
import _t from "../../languages/translate";

const imagePath = _URL + "/images/";

function beforeUpload(file) {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error(_t("strOnlyPNG&JPG"));
  }
  const isLt2M = file.size / 1024 / 1024 < 1;
  if (!isLt2M) {
    message.error(_t("strMustSmaller1MB"));
  }
  return isJpgOrPng && isLt2M;
}

function UploadImage({ previewImg, setPreviewImg, loading, setLoading }) {
  useEffect(() => {
    setLoading(true);

    setPreviewImg(previewImg);
    setLoading(false);
  });

  const uploadButton = previewImg ? (
    <img src={previewImg} alt="avatar" className="heroImageprv" />
  ) : (
    <div>
      <PlusOutlined />
      <div className="ant-upload-text">{_t("strUploadFile")}</div>
    </div>
  );

  const uploadImgs = async (fileObj) => {
    setLoading(true);
    try {
      const res = await uploadImg(fileObj);
      setPreviewImg(imagePath + res.data.data);
      setLoading(false);
    } catch (error) {
      if (error.response) {
        message.error(error.response.data?.message || _t("msgUnknownError"), 3);
        setLoading(false);
      } else if (error.request) {
        message.error(_t("msgServerDisconnected"), 3);
        setLoading(false);
      } else {
        message.error(_t("msgUnknownError"), 3);
        setLoading(false);
      }
    }
  };

  return (
    <Upload
      name="avatar"
      listType="picture-card"
      className="avatar-uploader"
      showUploadList={false}
      action={(file) => uploadImgs(file)}
      beforeUpload={beforeUpload}
    >
      {loading ? <LoadingOutlined /> : uploadButton}
    </Upload>
  );
}
export default UploadImage;
