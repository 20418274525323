import { _URL } from "../config.json";
import http from "./http";

const apiEndpoint = _URL + "/api/Pdf";

export function getPrintHtml(id, timeZone, lang) {
  return http.get(
    `${apiEndpoint}/GetInvHtml/?Id=${id}&timeOffset=${timeZone}&lang=${lang}`
  );
}

export function getPrintHtmlMunf(id, timeZone, lang) {
  return http.get(
    `${apiEndpoint}/GetMunfHtml/?Id=${id}&timeOffset=${timeZone}&lang=${lang}`
  );
}