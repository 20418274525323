import { _URL } from "../config.json";
import http from "./http";

const apiEndpoint = _URL + "/api/Upload/UploadFile/";

export default function uploadImg(obj) {
  let formData = new FormData();
  formData.append("file", obj);
  return http.post(apiEndpoint, formData);
}
