import React, { useEffect, useState } from "react";
import { Table, message } from "antd";
import _t from "../../../languages/translate";
import { getQtyTotal } from "../../../services/Transactions/STService";
const TotalUnitDetailsForm = ({ unitList }) => {
  const [dataList, setDataList] = useState([]);
  const columns = [
    {
      dataIndex: "unitName",
    },
    {
      dataIndex: "qty",
      align: "right",
      render: (_, record) => (
        <div style={{ direction: "ltr" }}>
          {record.qty === 0 || record.qty === null
            ? "_"
            : record.qty.toFixed(3).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
        </div>
      ),
    },
  ];

  useEffect(() => {
    async function fetchData() {
      let filtered = unitList.filter(function (e) {
        return e != null;
      });
      try {
        const { data: newData } = await getQtyTotal(filtered);
        setDataList(newData.data);
      } catch (error) {
        if (error.response) {
          message.error(
            error.response.data?.message || _t("msgUnknownError"),
            3
          );
        } else if (error.request) {
          message.error(_t("msgServerDisconnected"), 3);
        } else {
          message.error(_t("msgUnknownError"), 3);
        }
      }
    }
    fetchData();
  }, [unitList]);

  return (
    <Table
      dataSource={dataList}
      columns={columns}
      showHeader={false}
      pagination={false}
      scroll={false}
      size="middle"
    />
  );
};

export default TotalUnitDetailsForm;
