import React, { useState, useEffect, useRef, useContext } from "react";
import { Prompt } from "react-router";
import { useHistory, useParams, Link } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  message,
  Space,
  Row,
  Col,
  Spin,
  Select,
  Empty,
  Menu,
  Dropdown,
  Modal,
  Checkbox,
} from "antd";
import {
  editDashboardTemp,
  getNewDashboardTempCode,
} from "../../services/dashboardTempService";
import { SearchOutlined, RetweetOutlined } from "@ant-design/icons";
import FilterOrderRep from "../helper/Modal/FilterOrdersRep";
import FilterItemMovementRep from "../helper/Modal/FilterItemMovementRep";
import FilterInventoryRep from "../helper/Modal/FilterInventoryRep";
import FilterInvoicesRep from "../helper/Modal/FilterInvoicesRep";
import FilterManufacturingRep from "../helper/Modal/FilterManufacturingRep";
import _t from "../../languages/translate";
import {
  getAllReportTemp,
  reportTempColumnsByKey,
} from "../../services/reportsTempService";
import SortableColumns from "../helper/Table/SortableColumns";
import { getDashboardTemptById } from "./../../services/dashboardTempService";
import { LanguageContext } from "../../languages/Language";

const DashboardTempForm = ({ onNewUnit }) => {
  const { userLanguage } = useContext(LanguageContext);
  const IX_Code = useRef(null);
  const IX_Name = useRef(null);
  const [loading] = useState(false);
  const [loadData, setLoadData] = useState(true);
  const [isGoBack, setIsGoBack] = useState(true);
  const [filterTypeKey, setFilterTypeKey] = useState("ItemMovement");
  const [filterObj, setFilterObj] = useState(undefined);
  const [proChange, setProChange] = useState(false);
  const [visibilityFilter, setVisibilityFilter] = useState(false);
  const [objRep, setObjRep] = useState([]);
  const [form] = Form.useForm();
  let history = useHistory();
  let { id } = useParams();
  const [companyId] = useState(window.localStorage.getItem("comId"));
  const { Option } = Select;
  const { TextArea } = Input;
  //******************************//

  const showModalFilter = () => {
    setVisibilityFilter(true);
  };

  const handleCancelFilter = () => {
    setVisibilityFilter(false);
  };

  //******************************//

  const menu = (
    <Menu>
      <Menu.Item onClick={() => setIsGoBack(true)}>
        {_t("strLeaveThePage")}
      </Menu.Item>
      <Menu.Item onClick={() => setIsGoBack(false)}>
        {_t("strStayInPage")}
      </Menu.Item>
    </Menu>
  );

  const objRepOptions =
    objRep.length > 0 &&
    objRep.map((item) => (
      <Option key={item.key} value={item.key.toString()}>
        {window.localStorage.getItem("lang") === "en"
          ? item.nameEn
          : item.nameAr}
      </Option>
    ));

  //******************************//

  useEffect(() => {
    setLoadData(false);
    getAllReports();
    async function fetchData() {
      try {
        if (id === "new") {
          codeGenerator();
          getRepColumns(0);
        } else {
          const { data } = await getDashboardTemptById(id);
          debugger;
          form.setFieldsValue({
            id: data.data.Id,
            code: data.data.code,
            name: data.data.name,
            companyId: data.data.companyId,
            reportKey: data.data.reportKey,
            columns: data.data.columns,
            inactive: data.data.inactive,
            note: data.data.note,
          });

          setFilterObj(data.data.reportFilter);
          setFilterTypeKey(data.data.reportKey);
          IX_Name.current.focus();
          setLoadData(false);
        }
      } catch (error) {
        if (error.response) {
          message.error(
            error.response.data?.message || _t("msgUnknownError"),
            3
          );
          setLoadData(false);
        } else if (error.request) {
          message.error(_t("msgServerDisconnected"), 3);
          setLoadData(false);
        } else {
          message.error(_t("msgUnknownError"), 3);
          setLoadData(false);
        }
      }
    }

    fetchData();
  }, [id, history, setLoadData, setObjRep, setFilterTypeKey, setFilterObj]);

  const codeGenerator = async () => {
    const { data: newData } = await getNewDashboardTempCode();
    form.setFieldsValue({ code: newData.data });
    setLoadData(false);
  };

  const repKey = { key: 0 };
  const getRepColumns = async (keyNum) => {
    try {
      repKey.key = keyNum;
      const { data: response } = await reportTempColumnsByKey(repKey);

      form.setFieldsValue({ columns: response.data });
    } catch (error) {
      if (error.response) {
        message.error(error.response.data?.message || _t("msgUnknownError"), 3);
        setLoadData(false);
      } else if (error.request) {
        message.error(_t("msgServerDisconnected"), 3);
        setLoadData(false);
      } else {
        message.error(_t("msgUnknownError"), 3);
        setLoadData(false);
      }
    }
  };

  const getAllReports = async () => {
    try {
      const { data: dataRepTemp } = await getAllReportTemp();
      setObjRep(dataRepTemp.data);
    } catch (error) {
      if (error.response) {
        message.error(error.response.data?.message || _t("msgUnknownError"), 3);
        setLoadData(false);
      } else if (error.request) {
        message.error(_t("msgServerDisconnected"), 3);
        setLoadData(false);
      } else {
        message.error(_t("msgUnknownError"), 3);
        setLoadData(false);
      }
    }
  };

  //******************************// //*******************// Save //*******************// //******************************//
  const ItemMovement = {
    clientSuplierIds: [],
    fromDate: undefined,
    itemIds: [],
    patternIds: [],
    stateIds: [],
    storeIds: [],
    toDate: undefined,
    withPreviousQty: undefined,
    withTotal: undefined,
  };
  const Inventory = {
    itemIds: [],
    showDetails: undefined,
    showEmptyItem: undefined,
    showEmptyStore: undefined,
    showReservedQty: undefined,
    storeIds: [],
    toDate: undefined,
    type: ["0"],
  };
  const Invoices = {
    clientSuplierIds: [],
    fromDate: undefined,
    itemIds: [],
    patternIds: [],
    showDetails: undefined,
    stateIds: [],
    storeIds: [],
    toDate: undefined,
  };
  const Orders = {
    clientSuplierIds: [],
    itemIds: [],
    showDetails: undefined,
    stateIds: [],
    storeIds: [],
  };
  const Manufacturing = {
    fromDate: undefined,
    fromStoreIds: [],
    inItemIds: [],
    outItemIds: [],
    showDetail: undefined,
    showDetailIn: undefined,
    showDetailOut: undefined,
    showMaster: true,
    toDate: undefined,
    toStoreIds: [],
  };

  const checkObj = (obj, key) => {
    if (obj !== undefined) {
      return obj;
    } else {
      if (key === "ItemMovement") {
        return ItemMovement;
      } else if (key === "Inventory") {
        return Inventory;
      } else if (key === "Invoices") {
        return Invoices;
      } else if (key === "Orders") {
        return Orders;
      } else if (key === "Manufacturing") {
        return Manufacturing;
      }
    }
  };

  const onFinish = async (values) => {
    if (typeof onNewUnit === "function") {
      values.id = "new";
    } else {
      values.id = id;
    }
    values.reportFilter = checkObj(filterObj, values.reportKey);
    values.companyId = companyId;

    
    try {
      const data = await editDashboardTemp(values);
      message.success(data.data.message, 3);
      if (typeof onNewUnit === "function") {
        onNewUnit(data);
      } else {
        goBack();
      }
    } catch (error) {
      if (error.response) {
        if (error.response.data.ex.includes("IX_Code")) {
          IX_Code.current.focus();
          message.error(
            error.response.data?.message || _t("msgUnknownError"),
            3
          );
          setLoadData(false);
        } else if (error.response.data.ex.includes("IX_Name")) {
          IX_Name.current.focus();
          message.error(
            error.response.data?.message || _t("msgUnknownError"),
            3
          );
          setLoadData(false);
        } else {
          message.error(
            error.response.data?.message || _t("msgUnknownError"),
            3
          );
          setLoadData(false);
        }
      } else if (error.request) {
        message.error(_t("msgServerDisconnected"), 3);
        setLoadData(false);
      } else {
        message.error(_t("msgUnknownError"), 3);
        setLoadData(false);
      }
    }
  };

  //******************************// //*******************// Form Code //*******************// //******************************//

  const promptForm = () => {
    setProChange(true);
  };

  const handelFilterRep = (data) => {
    setVisibilityFilter(false);
    setFilterObj(data.reportFilter);
  };

  const modalProps = {
    onHandelData: handelFilterRep,
    onFilterData: filterObj,
    filterType: true,
  };

  const goBack = () => {
    setProChange(false);
    if (isGoBack) {
      history.goBack();
    } else {
      if (id !== "new") {
        history.replace({
          ...history.location,
          pathname: "/dashboardTemp/new",
        });
      }
      form.resetFields();
      codeGenerator();
      IX_Name.current.focus();
    }
  };

  //******************************// //*******************// Form //*******************// //******************************//

  return (
    <React.Fragment>
      <Prompt when={proChange} message={_t("strUnsavedChanges")} />
      <Modal
        visible={visibilityFilter}
        onCancel={() => handleCancelFilter()}
        title={_t("strReportFilter")}
        footer={null}
        destroyOnClose={true}
        width={1000}
      >
        {filterTypeKey === "ItemMovement" && (
          <FilterItemMovementRep {...modalProps} />
        )}
        {filterTypeKey === "Inventory" && (
          <FilterInventoryRep {...modalProps} />
        )}
        {filterTypeKey === "Invoices" && <FilterInvoicesRep {...modalProps} />}
        {filterTypeKey === "Orders" && <FilterOrderRep {...modalProps} />}
        {filterTypeKey === "Manufacturing" && (
          <FilterManufacturingRep {...modalProps} />
        )}
      </Modal>

      <div className="bg">
        <Spin spinning={loadData} tip={_t("strLoading")}>
          <Form
            form={form}
            name="itemUnitform"
            onFinish={onFinish}
            layout="vertical"
            initialValues={{ reportKey: "ItemMovement" }}
          >
            <Row gutter={12}>
              <Col xs={24} md={12}>
                <Form.Item
                  name="code"
                  label={_t("strCode")}
                  rules={[
                    {
                      required: true,
                      message: `${_t("strIsRequired")}`,
                    },
                    { min: 1, message: `${_t("strFrom3-200")}` },
                    { max: 200, message: `${_t("strFrom3-200")}` },
                  ]}
                >
                  <Input
                    placeholder={_t("strCode")}
                    autoComplete="off"
                    maxLength={200}
                    onChange={promptForm}
                    ref={IX_Code}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} md={12}>
                <Form.Item
                  name="name"
                  label={_t("strName")}
                  rules={[
                    { required: true, message: `${_t("strIsRequired")}` },
                    { min: 1, message: `${_t("strFrom3-200")}` },
                    { max: 200, message: `${_t("strFrom3-200")}` },
                  ]}
                >
                  <Input
                    placeholder={_t("strName")}
                    maxLength={200}
                    autoComplete="off"
                    onChange={promptForm}
                    ref={IX_Name}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} md={12}>
                <Form.Item
                  name="reportKey"
                  label={_t("strReport")}
                  rules={[{ required: true }]}
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    notFoundContent={
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}></Empty>
                    }
                    placeholder={_t("strNotSelected")}
                    onChange={(val) => {
                      setFilterObj(undefined);
                      getRepColumns(val);
                    }}
                  >
                    {objRepOptions}
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} md={6}>
                <Form.Item
                  noStyle
                  shouldUpdate={(prev, next) =>
                    prev.reportKey !== next.reportKey
                  }
                >
                  {({ getFieldValue }) => (
                    <Form.Item label=" " rules={[{ required: false }]}>
                      <Button
                        style={{ width: "100%" }}
                        type="dashed"
                        icon={<SearchOutlined />}
                        onClick={() => {
                          let val = getFieldValue("reportKey");
                          setFilterTypeKey(val);
                          showModalFilter();
                        }}
                      >
                        {_t("strReportFilter")}
                      </Button>
                    </Form.Item>
                  )}
                </Form.Item>
              </Col>

              <Col xs={24} md={6}>
                <Form.Item
                  label=" "
                  name="columns"
                  rules={[{ required: false }]}
                >
                  <SortableColumns />
                </Form.Item>
              </Col>

              <Col xs={24} md={24}>
                <Form.Item
                  name="note"
                  label={_t("strNote")}
                  rules={[
                    { required: false, message: `${_t("strIsRequired")}` },
                    { min: 1, message: `${_t("strFrom1-1000")}` },
                    { max: 1000, message: `${_t("strFrom1-1000")}` },
                  ]}
                >
                  <TextArea
                    placeholder={_t("strNote")}
                    maxLength={1000}
                    autoComplete="off"
                  />
                </Form.Item>
              </Col>

              <Col xs={24} md={12}>
                <Form.Item name="inactive" valuePropName="checked">
                  <Checkbox>{_t("strInActive")}</Checkbox>
                </Form.Item>
              </Col>
            </Row>

            <Space size="large">
              <Button type="primary" htmlType="submit" disabled={loading}>
                {_t("strSave")}
              </Button>
              <Dropdown.Button
                hidden={typeof onNewStore === "function"}
                overlay={menu}
                onClick={() => history.goBack()}
              >
                {" "}
                {_t("strBack")}
              </Dropdown.Button>
              {isGoBack ? (
                <i />
              ) : (
                <RetweetOutlined style={{ fontSize: 21, marginTop: 5 }} />
              )}
            </Space>
          </Form>
        </Spin>
      </div>
    </React.Fragment>
  );
};

export default DashboardTempForm;
