import React, { useState, useEffect, useRef } from "react";
import { Prompt } from "react-router";
import { useHistory, useParams } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  message,
  Space,
  Row,
  Col,
  Menu,
  Dropdown,
  DatePicker,
  Spin,
  Select,
  Divider
} from "antd";
import { edit, getNewCode, getById } from "../../services/Items/InventoryMatchingService";
import { LinkOutlined } from "@ant-design/icons";
import _t from "../../languages/translate";
import { getActiveChildren } from "../../services/storeService";
import UploadFile from "../helper/UploadFile/UploadFile";
import moment from "moment";
const InventoryMatchingForm = () => {
  const IX_Code = useRef(null);
  const [loading, setLoading] = useState(false);
  const [loadData, setLoadData] = useState(true);
  const [filePath, setFilePath] = useState();
  const [form] = Form.useForm();
  let history = useHistory();
  let { id } = useParams();
  const { Option } = Select;
  const { TextArea } = Input;
  const [isGoBack, setIsGoBack] = useState(true);
  const [companyId] = useState(window.localStorage.getItem("comId"));
  const [storeList, setStoreList] = useState([]);
  const dateFormatList = [
    "DD/MM/YYYY HH:mm",
    "DDMMYYYY HH:mm",
    "DDMMYYYY HHmm",
    "DDMMYYYYHHmm",
    "DDMMYYYY",
    "DDMMYYHHmm",
    "DDMMYY",
  ];

  const storeChildsOptList =
    storeList.length > 0 &&
    storeList.map((item) => (
      <Option key={item.id} value={item.id}>
        {item.name}
      </Option>
    ));

  const codeGenerator = async () => {
    try {
      const { data: newData } = await getNewCode();
      form.setFieldsValue({ code: newData.data });
      setLoadData(false);
    } catch (error) {
      if (error.response) {
        message.error(error.response.data?.message || _t("msgUnknownError"), 3);
        setLoadData(false);
      } else if (error.request) {
        message.error(_t("msgServerDisconnected"), 3);
        setLoadData(false);
      } else {
        message.error(_t("msgUnknownError"), 3);
        setLoadData(false);
      }
    }
  };

  const [incomeStore, setIncomeStore] = useState("");
  const [takeoutStore, setTakeoutStore] = useState("");

  const GetById = async () => {
    try {

      const { data } = await getById(id);
      setIncomeStore(data.data.incomeStoreTransactionId);
      setTakeoutStore(data.data.takeoutStoreTransactionId);

      form.setFieldsValue({
        code: data.data.code,
        documentCode: data.data.documentCode,
        date: data.data.date !== undefined && data.data.date !== null ? moment(data.data.date) : "",
        note: data.data.note,
        companyId: data.data.companyId,
        storeId: data.data.storeId,
      });
      setLoadData(false);
    } catch (error) {
      if (error.response) {
        message.error(error.response.data?.message || _t("msgUnknownError"), 3);
        setLoadData(false);
      } else if (error.request) {
        message.error(_t("msgServerDisconnected"), 3);
        setLoadData(false);
      } else {
        message.error(_t("msgUnknownError"), 3);
        setLoadData(false);
      }
    }
  }


  useEffect(() => {
    async function fetchData() {
      onSearchFromStore("", "");
      if (id !== "new") {
        GetById();
      } else {
        codeGenerator();
        IX_Code.current.focus();
        setLoadData(false);
      }
    }
    fetchData();
  }, [id, history, setLoadData]);

  //******************************// //*******************// Back //*******************// //******************************//

  const menu = (
    <Menu>
      <Menu.Item onClick={() => setIsGoBack(true)}>
        {_t("strLeaveThePage")}
      </Menu.Item>
      <Menu.Item onClick={() => setIsGoBack(false)}>
        {_t("strStayInPage")}
      </Menu.Item>
    </Menu>
  );

  const goBack = () => {
    setProChange(false);
    if (isGoBack) {
      history.goBack();
    } else {
      form.resetFields();
      history.replace({ ...history.location, pathname: "/unit/new" });
      codeGenerator(); onSearchFromStore("", "");
      IX_Code.current.focus();
    }
  };

  //******************************// //*******************// Save //*******************// //******************************//

  const onFinish = async (values) => {
    values.companyId = companyId;
    values.fileName = filePath;
    if (values.date !== "") {
      values.date = values.date?.toISOString();
    }

    try {
      const data = await edit(values);
      message.success(data.data.message, 3);
      goBack();
    } catch (error) {
      if (error.response?.data?.ex === null) {
        message.error(
          error.response.data?.message || _t("msgUnknownError"),
          3
        );
        setLoadData(false);
      }
      else {
        if (error.response) {
          if (error.response.data.ex.includes("IX_Code")) {
            IX_Code.current.focus();
            message.error(
              error.response.data?.message || _t("msgUnknownError"),
              3
            );
            setLoadData(false);
          } else {
            message.error(
              error.response.data?.message || _t("msgUnknownError"),
              3
            );
            setLoadData(false);
          }
        } else if (error.request) {
          message.error(_t("msgServerDisconnected"), 3);
          setLoadData(false);
        } else {
          message.error(_t("msgUnknownError"), 3);
          setLoadData(false);
        }
      }

    }
  };

  //******************************// //*******************// Form Code //*******************// //******************************//

  const [proChange, setProChange] = useState(false);
  const promptForm = () => {
    setProChange(true);
  };
  const [storeId] = useState("");

  const onSearchFromStore = async (search, active) => {
    try {
      const { data: responce } = await getActiveChildren(search, active);
      setStoreList(responce.data);
      if (search === "" && active === "") {
        form.setFieldsValue({ storeId: responce.data[0].id });
      }
    } catch (error) {
      if (error.request) {
        message.error(_t("msgServerDisconnected"), 3);

      } else {
        message.error(_t("msgUnknownError"), 3);

      }
    } finally {
      setLoadData(false);
      // setLoadSkeleton(false);
    }
  };

  const ShowEntryInvoice = () => {
    window.open('/transaction/b9f33cc0-ccae-4a9f-81e8-37c5f75ac35c?storeTransactionId=' + incomeStore, "_blank");
  }


  const ShowTakeOutInvoice = () => {
    window.open('/transaction/4abf7dda-f723-4b72-8154-51437b2f0fc9?storeTransactionId=' + takeoutStore, "_blank");
  }

  //******************************// //*******************// Form //*******************// //******************************//

  return (
    <React.Fragment>
      <Prompt when={proChange} message={_t("strUnsavedChanges")} />

      <div className="bg">
        <Spin spinning={loadData} tip={_t("strLoading")}>
          <Form
            form={form}
            name="itemUnitform"
            onFinish={onFinish}
            layout="vertical"
          >
            <Row gutter={12}>
              <Col xs={24} md={12}>
                <Form.Item
                  name="code"
                  label={_t("strCode")}
                  rules={[
                    {
                      required: true,
                      message: `${_t("strIsRequired")}`,
                    },
                    { min: 1, message: `${_t("strFrom3-200")}` },
                    { max: 200, message: `${_t("strFrom3-200")}` },
                  ]}
                >
                  <Input
                    size="small"
                    placeholder={_t("strCode")}
                    autoComplete="off"
                    maxLength={200}
                    onChange={promptForm}
                    ref={IX_Code}
                    disabled={id !== "new"}
                  />

                </Form.Item>
              </Col>

              <Col xs={24} md={12} >
                <Form.Item
                  name="documentCode"
                  label={_t("strDocument")}
                  rules={[
                    { required: false, message: `${_t("strIsRequired")}` },
                    { min: 1, message: `${_t("strFrom3-200")}` },
                    { max: 200, message: `${_t("strFrom3-200")}` },
                  ]}
                >
                  <Input
                    size="small"
                    placeholder={_t("strDocument")}
                    autoComplete="off"
                    maxLength={200}
                    disabled={id !== "new"}
                  />
                </Form.Item>
              </Col>


              <Col xs={24} md={12} >
                <Form.Item
                  name="date"
                  label={_t("strDate")}
                  rules={[
                    { required: true, message: `${_t("strIsRequired")}` },
                  ]}
                >
                  <DatePicker
                    size="small"
                    showTime={{ format: "HH:mm" }}
                    format={dateFormatList}
                    className="dateTimeStyle"
                    onChange={() => {
                      setProChange(true);
                    }}
                    disabled={id !== "new"}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} md={12}>
                <Form.Item
                  name="storeId"
                  label={_t("strStore")}
                  rules={[
                    { required: true, message: `${_t("strIsRequired")}` },
                  ]}
                >
                  <Select
                    size="small"
                    placeholder={_t("strStore")}
                    autoComplete="off"
                    onSearch={(value) => { onSearchFromStore(value, storeId) }}
                    onChange={() => {
                      setProChange(true);
                    }}
                    showSearch
                    optionFilterProp="children"
                    disabled={id !== "new"}
                  >
                    {storeChildsOptList}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Form.Item
                  name="note"
                  label={_t("strNote")}
                  rules={[
                    { required: false, message: `${_t("strIsRequired")}` },
                    { min: 1, message: `${_t("strFrom1-1000")}` },
                    { max: 1000, message: `${_t("strFrom1-1000")}` },
                  ]}
                >
                  <TextArea
                    size="small"
                    placeholder={_t("strNote")}
                    maxLength={1000}
                    autoComplete="off"
                    onChange={() => {
                      setProChange(true);
                    }}
                    autoSize={{ minRows: 2, maxRows: 5 }}
                    disabled={id !== "new"}
                  />
                </Form.Item>
              </Col>

              {id === "new" && <Col xs={24}>
                <Form.Item extra={_t("msgTypeExcel")}>
                  <UploadFile
                    setPreviewFile={setFilePath}
                  />
                </Form.Item>
              </Col>}

              {id !== "new" && <Col xs={24}>
                <Form.Item >
                  <Space>
                    <Button type="text" size="small" icon={<LinkOutlined />} onClick={ShowEntryInvoice} disabled={incomeStore ==="" || incomeStore ===null}>{_t("strShowEntryInvoice")}</Button>
                    <Divider type="vertical" />
                    <Button type="text" size="small" icon={<LinkOutlined />} onClick={ShowTakeOutInvoice} disabled={takeoutStore ==="" || takeoutStore ===null}>{_t("strShowTakeOutInvoice")}</Button>
                  </Space>
                </Form.Item>
              </Col>}

            </Row>

            <Form.Item>
              <Space size="large">

                {id === "new" && <Button type="primary" htmlType="submit" disabled={loading} >
                  {_t("strSave")}
                </Button>}

                <Button onClick={() => history.goBack()} >
                  {_t("strBack")}
                </Button>


              </Space>
            </Form.Item>
          </Form>
        </Spin>
      </div>
    </React.Fragment>
  );
};

export default InventoryMatchingForm;
