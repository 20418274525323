import React, { useEffect, useState, useRef, useContext } from "react";
import { Row, Col, Button } from "antd";
import { PrinterOutlined } from "@ant-design/icons";
import { useReactToPrint } from "react-to-print";
import PrintingForm from "../../helper/Print/PrintingForm";
import _t from "../../../languages/translate";
import { getPrintHtml, getPrintHtmlMunf } from "../../../services/printServices";
import { LanguageContext } from "../../../languages/Language";
const Printing = ({ id, htmlRep, sender }) => {
 
  const componentRef = useRef();
  const { userLanguage } = useContext(LanguageContext);
  const [offset, setOffset] = useState(0);
  const [html, setHtml] = useState("");
 
  useEffect(() => {
    async function fetchData() {
      if (id !== undefined && sender === "Munf") {
     
        setOffset(new Date().getTimezoneOffset());
        const dataHtml = await getPrintHtmlMunf(id, offset, userLanguage);
        setHtml(dataHtml.data);
      } else if (id !== undefined && sender !== "Munf") {
        setOffset(new Date().getTimezoneOffset());
      
        const dataHtml = await getPrintHtml(id, offset, userLanguage);
        setHtml(dataHtml.data);
      }
    }
    fetchData();
  }, [id]);

  const handlePrint = useReactToPrint({ content: () => componentRef.current });

  return (
    <div>
      <Row style={{ marginTop: -5 }}>
        <Col>
          <Button onClick={handlePrint}>
            <PrinterOutlined style={{ fontSize: 18 }} />
            {_t("strPrint")}
          </Button>
        </Col>
      </Row>
      <PrintingForm
        html={id !== undefined ? html : htmlRep}
        lang={userLanguage}
        ref={componentRef}
      />
    </div>
  );
};

export default Printing;
