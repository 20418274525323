import { _URL } from "./../config.json";
import http from "./http";

const apiEndpoint = _URL + "/api/DashboardTemp";

export function getPagers(query) {
  return http.post(`${apiEndpoint}/GetPager?${query}`);
}

export function getDashboardTemptById(Id) {
  return http.get(`${apiEndpoint}/${Id}`);
}

export function editDashboardTemp(obj) {
  if (obj.id !== "new") {
    return http.put(apiEndpoint, obj);
  } else {
    return http.post(apiEndpoint, obj);
  }
}

export function getAllDashboardTemp() {
  return http.get(`${apiEndpoint}`);
}

export function deleteDashboardTemp(Id) {
  return http.delete(`${apiEndpoint}/${Id}`);
}

export function getPagerDashboardTemp(current, pageSize) {
  return http.post(
    `${apiEndpoint}/GetPager?PageNumber=${current}&PageSize=${pageSize}`
  );
}

export function getNewDashboardTempCode() {
  return http.post(`${apiEndpoint}/GetNewCode`);
}

// to get all templates by user role.
export function getActiveByUser() {
  return http.post(`${apiEndpoint}/GetActiveByUser`);
}

// to get all templates by user role.
export function getViewDashboardByObj(query, obj) {
  
  return http.post(`${apiEndpoint}/GetView?${query}`, obj);
}
