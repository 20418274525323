import React, { useEffect, useState, useRef } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import { Form, Input, Button, Select, Row, Col, Spin, message } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import _t from "../../../languages/translate";
import {
  getUsersById,
  editUsers,
} from "../../../services/Authorization/usersService";
import { getAllUserRole } from "../../../services/Authorization/userRoleService";

const UsersForm = () => {
  const [form] = Form.useForm();
  let { id } = useParams();
  let history = useHistory();
  const IX_FirstName = useRef(null);
  const IX_UserName = useRef(null);
  const IX_Email = useRef(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isRequired, setIsRequired] = useState(true);
  const [loadData, setLoadData] = useState(true);
  const [roleOpt, setRoleOpt] = useState([]);
  const roleOptList =
    roleOpt.length > 0 &&
    roleOpt.map((item) => (
      <option key={item.id} value={item.id}>
        {item.name}
      </option>
    ));

  useEffect(() => {
    IX_FirstName.current.focus();
    async function fetchData() {
      try {
        if (id === "new") {
          const { data: newData } = await getAllUserRole();
          setRoleOpt(newData.data);
          setLoadData(false);
        } else {
          const { data: newData } = await getAllUserRole();
          setRoleOpt(newData.data);
          const { data } = await getUsersById(id);
          setIsDisabled(true);
          setIsRequired(false);

          form.setFieldsValue({
            id: data.data.id,
            firstName: data.data.firstName,
            lastName: data.data.lastName,
            email: data.data.email,
            userName: data.data.userName,
            password: data.data.password,
            confirmPassword: data.data.confirmPassword,
            userRoleId: data.data.userRoleId,
          });
          setLoadData(false);
        }
      } catch (error) {
        if (error.response) {
          message.error(
            error.response.data?.message || _t("msgUnknownError"),
            3
          );
          setLoadData(false);
        } else if (error.request) {
          message.error(_t("msgServerDisconnected"), 3);
          setLoadData(false);
        } else {
          message.error(_t("msgUnknownError"), 3);
          setLoadData(false);
        }
      }
    }
    fetchData();
  }, [id, history, setRoleOpt]);

  const goBack = () => {
    history.push("/users");
  };

  const onFinish = async (values) => {
    values.id = id;
    values.language = "en";
    console.log(values);
    try {
      await editUsers(values);
      goBack();
      setLoadData(false);
    } catch (error) {
      if (error.response) {
        if (error.response.data.ex.includes("UserName")) {
          IX_UserName.current.focus();
          message.error(
            error.response.data?.message || _t("msgUnknownError"),
            3
          );
          setLoadData(false);
        } else {
          message.error(
            error.response.data?.message || _t("msgUnknownError"),
            3
          );
          setLoadData(false);
        }
      } else if (error.request) {
        message.error(_t("msgServerDisconnected"), 3);
        setLoadData(false);
      } else {
        message.error(_t("msgUnknownError"), 3);
        setLoadData(false);
      }
    }
  };

  return (
    <div key="mainDiv" className="bg">
      <Spin spinning={loadData} tip={_t("strLoading")}>
        <Form
          layout="vertical"
          form={form}
          name="nest-messages"
          onFinish={onFinish}
        >
          <Row gutter={12}>
            <Col xs={24} md={12}>
              <Form.Item
                name="firstName"
                label={_t("strFirstName")}
                rules={[
                  {
                    required: true,
                    message: `${_t("strIsRequired")}`,
                  },
                  { min: 1, message: `${_t("strFrom3-200")}` },
                  { max: 200, message: `${_t("strFrom3-200")}` },
                ]}
                autoComplete="off"
              >
                <Input ref={IX_FirstName} maxLength={200} />
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                name="lastName"
                label={_t("strLastName")}
                rules={[
                  {
                    required: false,
                    message: `${_t("strIsRequired")}`,
                  },
                  { min: 1, message: `${_t("strFrom3-200")}` },
                  { max: 200, message: `${_t("strFrom3-200")}` },
                ]}
                autoComplete="off"
              >
                <Input maxLength={200} />
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                name="userName"
                label={_t("strUserName")}
                rules={[
                  {
                    required: true,
                    message: `${_t("strIsRequired")}`,
                  },
                  { min: 1, message: `${_t("strFrom3-200")}` },
                  { max: 200, message: `${_t("strFrom3-200")}` },
                ]}
                autoComplete="off"
              >
                <Input
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  maxLength={200}
                  ref={IX_UserName}
                />
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                name="userRoleId"
                label={_t("strSelectRole")}
                rules={[{ required: true, message: _t("msgPleaseSelectRole") }]}
                autoComplete="off"
              >
                <Select allowClear>{roleOptList}</Select>
              </Form.Item>
            </Col>

            <Col xs={24} md={24}>
              <Form.Item
                name="email"
                label={_t("strEmail")}
                rules={[
                  {
                    required: true,
                    min: 3,
                    max: 200,
                    message: _t("msgEmailIsRequired"),
                  },
                ]}
                autoComplete="off"
                ref={IX_Email}
              >
                <Input maxLength={200} />
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                prefix={<LockOutlined className="site-form-item-icon" />}
                label={_t("strPassword")}
                name="password"
                autoComplete="off"
                rules={[
                  {
                    required: isRequired,
                    min: 6,
                    max: 200,
                    message: _t("msgInputYourPass"),
                  },
                ]}
              >
                <Input.Password
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  disabled={isDisabled}
                  autoComplete="off"
                  maxLength={200}
                />
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                prefix={<LockOutlined className="site-form-item-icon" />}
                label={_t("strConfirmPassword")}
                name="confirmPassword"
                autoCompletee={false}
                rules={[
                  {
                    required: isRequired,
                    min: 6,
                    max: 200,
                    message: _t("msgInputYourConPass"),
                  },
                ]}
              >
                <Input.Password
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  disabled={isDisabled}
                  maxLength={200}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Button type="primary" htmlType="submit" className="buttonForms">
              {_t("strSubmit")}
            </Button>
            <Button className="buttonForms">
              <Link to="/users">{_t("strBack")}</Link>
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};

export default UsersForm;
