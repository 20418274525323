import React, { useState, useEffect, useRef } from "react";
import { permissions } from "../../../services/Authorization/authService";
import moment from "moment";
import { Prompt } from "react-router";
import { useParams, useLocation, Link } from "react-router-dom";
import {
  Form,
  Spin,
  Row,
  Col,
  Button,
  Input,
  Select,
  DatePicker,
  Popconfirm,
  InputNumber,
  Card,
  Space,
  Modal,
  message,
  notification,
  Collapse,
  Table,
  Drawer,
  Dropdown,
  Menu,
  Upload,
  Skeleton,
  Tooltip
} from "antd";
import {
  getNewSTCode,
  editST,
  searchByCode,
  searchByDocument,
  deleteST,
  getNext,
  getPrev,
  searchSoByCode,
  searchSoByDocument,
  searchSoByCustomer,
  uploadExcel,
  getSTById,
} from "../../../services/Transactions/STService";
import { getSTPById } from "../../../services/Transactions/STPService";
import { getAllSTSP } from "../../../services/Transactions/STSPService";
import { getClientSuppType } from "../../../services/clientSuppService";
import { getActiveChildren } from "../../../services/storeService";
import Printing from "../../helper/Print/Printing";
import ReportsPrint from "../../helper/Print/ReportsPrint";
import { searchItem } from "../../../services/Items/itemService";
import {
  DeleteTwoTone,
  PlusOutlined,
  ExclamationCircleOutlined,
  PrinterOutlined,
  LeftOutlined,
  RightOutlined,
  UploadOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import InvItemListSelectorForm from "./../../helper/Modal/InvItemListSelector";
import InvoiceSelectorForm from "./../../helper/Modal/InvoiceSelector";
import TotalUnitDetailsForm from "./../../helper/Modal/totalUnitDetails";

import _t from "../../../languages/translate";
import "moment/locale/ar-ly";
//import DateSelect from "../../helper/Input/DateSelect";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const TransactionForm = ({ onHandel }) => {
  const [proChange, setProChange] = useState(false);
  const [loadData, setLoadData] = useState(true);
  const [form] = Form.useForm();
  const IX_Code = useRef(null);
  const toStoreInput = useRef(null);
  let query = useQuery();
  const { Option } = Select;
  const { TextArea, Search } = Input;
  const { Panel } = Collapse;
  const { confirm } = Modal;
  //******************************//
  const [companyId] = useState(window.localStorage.getItem("comId"));
  const [clientSuppTyp, setClientSuppTyp] = useState();
  const [toStore, setToStore] = useState(false);
  const [status, setStatus] = useState(false);
  const [exDate, setExDate] = useState(false);
  const [editing, setEditing] = useState(true);
  //******************************//
  const [sTSpSuppList, setSTSpSuppList] = useState([]);
  const [clientSuppList, setClientSuppList] = useState([]);
  const [fromStoreList, setFromStoreList] = useState([]);
  const [toStoreList, setToStoreList] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [searchDataState, setSearchDataState] = useState([]);
  const [listState, setListState] = useState([]);
  const [searchIndex, setSearchIndex] = useState();
  const [visibilityInvItemList, setVisibilityInvItemList] = useState(false);
  const [visibilityInvoice, setVisibilityInvoice] = useState(false);
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const [salesOrder, setSalesOrder] = useState(false);
  const [importExcelVisibility, setImportExcelVisibility] = useState(false);
  const [clientSuppHidden, setClientSuppHidden] = useState(true);
  const dateFormatList = [
    "DD/MM/YYYY HH:mm",
    "DDMMYYYY HH:mm",
    "DDMMYYYY HHmm",
    "DDMMYYYYHHmm",
    "DDMMYYYY",
    "DDMMYYHHmm",
    "DDMMYY",
  ];

  //******************************//
  let { id } = useParams();
  const [salesOrderStatus, setSalesOrderStatus] = useState(false);
  //******************************//
  const [viewPer, setViewPer] = useState(false);
  const [addPer, setAddPer] = useState(false);
  const [editPer, setEditPer] = useState(false);
  const [deletePer, setDeletePer] = useState(false);
  //******************************//
  const [saveDis, setSaveDis] = useState(false);
  const [newDis, setNewDis] = useState(false);
  const [deleteDis, setDeleteDis] = useState(true);
  const [addRowListDis, setAddRowListDis] = useState(false);
  const [orderDis, setOrderDis] = useState(false);
  const [addOnlyDis, setAddOnlyDis] = useState(false);
  const [totalUnitsCount, setTotalUnitsCount] = useState(0);
  //******************************//
  const [dataInStockPrinting, setDataInStockPrinting] = useState([]);
  const [visibleInStockDrawer, setVisibleInStockDrawer] = useState(false);
  const [showTotalQtyDetails, setShowTotalQtyDetails] = useState(false);
  const [clientSuppId, setClientSuppId] = useState("");
  const [fromStoreId, setFromStoreId] = useState("");
  const [toStoreId, setToStoreId] = useState("");
  const [loadSkeleton, setLoadSkeleton] = useState(false);
  const [prentAfterSaveId, setPrentAfterSaveId] = useState("");
  //******************************//

  const stspOptList =
    sTSpSuppList.length > 0 &&
    sTSpSuppList.map((item) => (
      <Option
        key={item.id}
        value={item.id}
        disabled={
          item.id === "04fae0e1-bd6e-4229-b950-acd680116886" ||
            item.id === "90b5e778-4f69-4a20-ad52-bc4d27248280"
            ? false
            : true
        }
      >
        {item.nameVM}
      </Option>
    ));


  const clientSuppOptList =
    clientSuppList.length > 0 &&
    clientSuppList.map((item) => (
      <Option key={item.id} value={item.id}>
        {item.name}
      </Option>
    ));


  const fromStoreChildsOptList =
    fromStoreList.length > 0 &&
    fromStoreList.map((item) => (
      <Option key={item.id} value={item.id}>
        {item.name}
      </Option>
    ));

  const toStoreChildsOptList =
    toStoreList.length > 0 &&
    toStoreList.map((item) => (
      <Option key={item.id} value={item.id}>
        {item.name}
      </Option>
    ));
  //******************************// //*******************// Modal Object //*******************// //******************************//

  const showModalInvoice = () => {
    setVisibilityInvoice(true);
  };

  const handleCancelInvoice = () => {
    setVisibilityInvoice(false);
  };

  //******************************// //*******************// Modal List //*******************// //******************************//

  const showModalInvItemList = () => {
    setVisibilityInvItemList(true);
  };

  const handleCancelInvItemList = () => {
    setVisibilityInvItemList(false);
  };

  //******************************// //*******************// Initialize Page //*******************// //******************************//
  useEffect(() => {
    const recourdId = query.get("storeTransactionId");
    async function fetchData() {
      try {
        setLoadData(true);

        setViewPer(
          Object.keys(permissions)
            .join()
            .includes("_StoreTransaction_Get_" + id)
        );
        setAddPer(
          Object.keys(permissions)
            .join()
            .includes("_StoreTransaction_Post_" + id)
        );
        setEditPer(
          Object.keys(permissions)
            .join()
            .includes("_StoreTransaction_Put_" + id)
        );
        setDeletePer(
          Object.keys(permissions)
            .join()
            .includes("_StoreTransaction_Delete_" + id)
        );

        form.resetFields();
        setListState([]);
        const { data } = await getSTPById(id);
        onHandel(data.data.nameEn + "," + data.data.nameAr);
        setClientSuppTyp(data.data.storeTransactionType.clientSupplierType);
        if (data.data.storeTransactionType.storeType === 3) {
          setToStore(false);
        } else {
          setToStore(true);
        }
        if (id === "d1057a89-4275-427f-ab86-1d971be52917") {
          setStatus(true);
          setExDate(true);
        } else {
          setStatus(false);
          setExDate(false);
        }
        if (id === "30ba184c-8df8-4745-8127-701ce85fa0d2") {
          setSalesOrder(true);
        } else {
          setSalesOrder(false);
        }

        if (id === "778ec664-9455-4c68-8d2c-6ca3f4a0ae8c") {
          setImportExcelVisibility(false);
        } else {
          setImportExcelVisibility(true);
        }

        setClientSuppHidden(
          !data.data.storeTransactionType.clientSupplierRequired
        );

        if (data.data.storeTransactionType.clientSupplierRequired) {
          handelClientSupp(
            "",
            "",
            data.data.storeTransactionType.clientSupplierType
          );
        }

        const { data: stspList } = await getAllSTSP();
        setSTSpSuppList(stspList.data);

        onSearchFromStore("", "");

        onSearchToStore("", "");

        form.setFieldsValue({
          storeTransactionDetails: [{}],
        });

        codeGenerator(id);
        setProChange(false);

        checkPer(
          Object.keys(permissions)
            .join()
            .includes("_StoreTransaction_Post_" + id),
          Object.keys(permissions)
            .join()
            .includes("_StoreTransaction_Put_" + id)
        );
        setEditing(true);
        if (recourdId !== null) {
          const { data: data } = await getSTById(recourdId);

          form.setFieldsValue({
            id: data.data.id,
            code: data.data.code,
            relatedId: data.data.relatedId,
            relatedCode: data.data.relatedCode,
            relatedDocumentCode: data.data.relatedDocumentCode,
            relatedNote: data.data.relatedNote,
            documentCode: data.data.documentCode,
            note: data.data.note,
            fromStoreId: data.data.fromStoreId,
            toStoreId: data.data.toStoreId,
            transactionDate:
              data.data.transactionDate !== null
                ? moment(data.data.transactionDate)
                : "",
            expectedDeliveryDate:
              data.data.expectedDeliveryDate !== null
                ? moment(data.data.expectedDeliveryDate)
                : "",
            clientSupplierId: data.data.clientSupplierId,
            storeTransactionStatePatternId:
              data.data.storeTransactionStatePatternId,
            storeTransactionDetails: data.data.storeTransactionDetails,
          });
          handelQtyChange();
          setDeleteDis(false);
          setEditing(false);
        }
        setLoadData(false);
      } catch (error) {
        if (error.response) {
          message.error(
            error.response.data?.message || _t("msgUnknownError"),
            3
          );
          setLoadData(false);
        } else if (error.request) {
          message.error(_t("msgServerDisconnected"), 3);
          setLoadData(false);
        } else {
          message.error(_t("msgUnknownError"), 3);
          setLoadData(false);
        }
      }
    }
    fetchData();
  }, [
    id,
    setClientSuppTyp,
    setClientSuppHidden,
    // setClientSuppList,
    setSTSpSuppList,
    setStatus,
    setDeletePer,
    setEditPer,
    setAddPer,
    setViewPer,
  ]);

  const codeGenerator = async (_Id) => {
    try {
      const { data: newData } = await getNewSTCode(_Id);
      form.setFieldsValue({ code: newData.data });
      setLoadData(false);
    } catch (error) {
      if (error.response) {
        message.error(error.response.data?.message || _t("msgUnknownError"), 3);
        setLoadData(false);
      } else if (error.request) {
        message.error(_t("msgServerDisconnected"), 3);
        setLoadData(false);
      } else {
        message.error(_t("msgUnknownError"), 3);
        setLoadData(false);
      }
    }
  };
  const checkPer = (add, edit) => {
    if (add === false && edit === true) {
      setSaveDis(true);
      setNewDis(true);
      setDeleteDis(true);
      setOrderDis(true);
    } else if (add === true && edit === false) {
      setSaveDis(false);
      setNewDis(false);
      setDeleteDis(true);
      setOrderDis(true);
    } else if (add === false && edit === false) {
      setSaveDis(true);
      setNewDis(true);
      setDeleteDis(true);
      setOrderDis(true);
    } else {
      setSaveDis(false);
      setNewDis(false);
      setDeleteDis(true);
    }
  };

  //******************************// //*******************// Save //*******************// //******************************//
  const tableConfig = [
    {
      title: _t("strCode"),
      dataIndex: "itemCode",
      key: "itemCode",
      width: 100,
      fixed: "left",
    },
    {
      title: _t("strName"),
      dataIndex: "itemName",
      key: "itemName",
      width: 150,
    },
    {
      title: _t("strQTY1"),
      dataIndex: "qty1",
      key: "qty1",
      width: 100,
      textAlign: "right",

      render: (text, record) => (
        <div style={{ textAlign: "right", direction: "ltr" }}>
          {record.qty1 === 0 || record.qty1 === null
            ? "_"
            : record.qty1.toFixed(3).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
        </div>
      ),
    },
    {
      title: _t("strUnit1"),
      dataIndex: "unit1",
      key: "unit1",
      width: 100,
    },
    {
      title: _t("strQTY2"),
      dataIndex: "qty2",
      key: "qty2",
      width: 100,
      render: (text, record) => (
        <div style={{ textAlign: "right", direction: "ltr" }}>
          {record.qty2 === 0 || record.qty2 === null
            ? "_"
            : record.qty2.toFixed(3).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
        </div>
      ),
    },
    {
      title: _t("strUnit2"),
      dataIndex: "unit2",
      key: "unit2",
      width: 100,
    },
    {
      title: _t("strQTY3"),
      dataIndex: "qty3",
      key: "qty3",
      width: 100,
      render: (text, record) => (
        <div style={{ textAlign: "right", direction: "ltr" }}>
          {record.qty3 === 0 || record.qty3 === null
            ? "_"
            : record.qty3.toFixed(3).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
        </div>
      ),
    },
    {
      title: _t("strUnit3"),
      dataIndex: "unit3",
      key: "unit3",
      width: 100,
    },
  ];
  const [printingAfterSave, setPrintingAfterSave] = useState(false);
  const menu = (
    <Menu>
      <Menu.Item onClick={() => setPrintingAfterSave(false)}>
        {_t("strJustSave")}
      </Menu.Item>
      <Menu.Item onClick={() => setPrintingAfterSave(true)}>
        {_t("strPrintAfterSaving")}
      </Menu.Item>
    </Menu>
  );

  const onFinish = async (values) => {
    const checkList = onCheckList(values.storeTransactionDetails);
    if (values.fromStoreId === values.toStoreId) {
      message.error(_t("msgSameStore"), 5);
      return toStoreInput.current.focus();
    } else {
      if (checkList) {
        values.storeTransactionDetails = checkList;
      } else {
        return;
      }
      if (values.id === undefined) {
        values.id = "new";
      }
      values.companyId = companyId;
      values.storeTransactionPatternId = id;
      if (status === false) {
        values.storeTransactionStatePatternId =
          "d8a41fc2-3203-4cc8-9519-48cc0a990c2c";
      }
      if (values.expectedDeliveryDate !== "") {
        values.expectedDeliveryDate = values.expectedDeliveryDate?.toISOString();
      }
      if (values.transactionDate !== "") {
        values.transactionDate = values.transactionDate?.toISOString();
      }

      try {
        const { data: response } = await editST(true, values);
        setDataInStockPrinting(response.data);
        if (response.code === 417) {
          confirm({
            title: (
              <p style={{ direction: _t("tDiriction") }}>
                <Space size="small">
                  {_t("strStockQuantity")} :
                  <Button
                    type="link"
                    style={{ marginRight: -15, marginLeft: -15 }}
                    onClick={() => onPrintInStock(response.data)}
                  >
                    <Space size="small">
                      {_t("strPrint")} <PrinterOutlined />
                    </Space>
                  </Button>
                </Space>
              </p>
            ),
            width: 850,
            okText: _t("strContinue"),

            content: (
              <Table
                rowKey="id"
                columns={tableConfig}
                scroll={{ x: 750, y: 400 }}
                style={{ marginTop: 25, direction: _t("tDiriction") }}
                pagination={false}
                dataSource={response.data}
                size="small"
              />
            ),
            async onOk() {
              try {
                const { data: response } = await editST(false, values);
                onNew(true);
                message.success(response.message, 3);
                if (printingAfterSave === true) {
                  handelPrintAfterSave(response.data.id);
                 // onPrint(response.data);
                }
              } catch (error) {
                if (error.response) {
                  if (error.response.data.ex.includes("IX_Code")) {
                    IX_Code.current.focus();
                    message.error(
                      error.response.data?.message || _t("msgUnknownError"),
                      3
                    );
                    setLoadData(false);
                  }
                } else if (error.request) {
                  message.error(_t("msgServerDisconnected"), 3);
                  setLoadData(false);
                } else {
                  message.error(_t("msgUnknownError"), 3);
                  setLoadData(false);
                }
              }
            },
            onCancel() { },
          });
        } else if (response.code === 200) {
          onNew(true);
          message.success(response.message, 3);
          if (printingAfterSave === true) {
            handelPrintAfterSave(response.data.id);
            // onPrint(response.data);
          }
        }
      } catch (error) {
        if (error.response.data?.ex.includes("IX_Code")) {
          IX_Code.current.focus();
          message.error(error.response.data?.message, 3);
          setLoadData(false);
        } else if (error.request) {
          message.error(_t("msgServerDisconnected"), 3);
          setLoadData(false);
        } else {
          message.error(_t("msgUnknownError"), 3);
          setLoadData(false);
        }
      }
    }
  };

  const handelPrintAfterSave = (_Id) => {
    setPrentAfterSaveId(_Id);
    setVisibleDrawer(true)
  }

  const onCheckList = (values) => {
    let start = false;
    const removingItemEmpty = values.filter(function (item) {
      return item.itemId !== undefined;
    });

    if (removingItemEmpty.length === 0) {
      message.error(_t("msgListItemsReq"), 5);
      return false;
    }

    removingItemEmpty.map((items, index) => {
      if (items.qty === null || items.qty === 0 || items.qty === undefined) {
        message.error(_t("msgQTYreqOne") + (index + 1) + _t("msgQTYreqTwo"), 3);
        return false;
      }
      start = true;
    });
    if (start) {
      return removingItemEmpty;
    } else {
      return false;
    }
  };

  // search client & supp by type num // AK~
  const handelClientSupp = async (search, active, type) => {
    try {

      const { data: responce } = await getClientSuppType(search, active, type);
      setClientSuppList(responce.data);
    } catch (error) {
      if (error.request) {
        message.error(_t("msgServerDisconnected"), 3);
        setLoadData(false);
      } else {
        message.error(_t("msgUnknownError"), 3);
        setLoadData(false);
      }
    } finally {
      setLoadSkeleton(false);
    }
  };

  const onSearchFromStore = async (search, active) => {
    try {
      if (active === null) {
        active = "";
      }
      const { data: responce } = await getActiveChildren(search, active);
      setFromStoreList(responce.data);
      if (search === "" && active === "") {
        form.setFieldsValue({ fromStoreId: responce.data[1].id });
      }
    } catch (error) {
      if (error.request) {
        message.error(_t("msgServerDisconnected"), 3);
        setLoadData(false);
      } else {
        message.error(_t("msgUnknownError"), 3);
        setLoadData(false);
      }
    } finally {
      setLoadSkeleton(false);
    }
  };

  const onSearchToStore = async (search, active) => {
    try {
      if (active === null) {
        active = "";
      }
      const { data: responce } = await getActiveChildren(search, active);
      setToStoreList(responce.data);
      if (search === "" && active === "") {
        form.setFieldsValue({ toStoreId: responce.data[0].id });
      }
    } catch (error) {
      if (error.request) {
        message.error(_t("msgServerDisconnected"), 3);
        setLoadData(false);
      } else {
        message.error(_t("msgUnknownError"), 3);
        setLoadData(false);
      }
    } finally {
      setLoadSkeleton(false);
    }
  };



  //******************************// //*******************// New //*******************// //******************************//

  const onNew = (onSave) => {
    if (proChange && !onSave) {
      confirm({
        icon: <ExclamationCircleOutlined />,
        content: _t("msSureToNew"),
        onOk() {
          try {
            setProChange(false);

            form.resetFields();

            onSearchFromStore("", "");

            onSearchToStore("", "");

            form.setFieldsValue({
              storeTransactionDetails: [{}],
            });
            checkCleanForm();
          } catch (error) {
            if (error.response) {
              message.error(
                error.response.data?.message || _t("msgUnknownError"),
                3
              );
              setLoadData(false);
            } else if (error.request) {
              message.error(_t("msgServerDisconnected"), 3);
              setLoadData(false);
            } else {
              message.error(_t("msgUnknownError"), 3);
              setLoadData(false);
            }
          }
        },
        onCancel() { },
      });
    } else {
      setProChange(false);

      form.resetFields();
      onSearchFromStore("", "");
      onSearchToStore("", "");
      form.setFieldsValue({
        storeTransactionDetails: [{}],
      });
      checkCleanForm();
    }
  };

  const checkCleanForm = () => {
    codeGenerator(id);
    setListState([]);
    if (!editPer && addPer) {
      setSaveDis(false);
      setDeleteDis(true);
      setAddRowListDis(false);
      setAddOnlyDis(false);
      setEditing(true);
      setProChange(false);
    } else if (editPer && !addPer) {
      setDeleteDis(true);
    } else {
      setEditing(true);
      setDeleteDis(true);
      setProChange(false);
    }
  };

  //******************************// //*******************// Delete //*******************// //******************************//

  const onDelete = async () => {
    try {
      setLoadData(true);
      let _id = form.getFieldValue("id");
      const response = await deleteST(_id);
      setLoadData(false);
      notification.success({
        message: `${response.data.message}`,
        placement: `${_t("strNotificationDirection")}`,
      });
      onNew(true);
    } catch (error) {
      if (error.response) {
        message.error(error.response.data?.message || _t("msgUnknownError"), 3);
        setLoadData(false);
      } else if (error.request) {
        message.error(_t("msgServerDisconnected"), 3);
        setLoadData(false);
      } else {
        message.error(_t("msgUnknownError"), 3);
        setLoadData(false);
      }
    }
  };

  //******************************// //*******************// Search Obj //*******************// //******************************//

  const codeMainSearch = async (value, sO) => {
    if (value !== "" && value !== null && value !== undefined) {
      if (proChange) {
        confirm({
          icon: <ExclamationCircleOutlined />,
          content: _t("msSureToNew"),
          onOk() {
            codeMS(value, sO);
          },
          onCancel() { },
        });
      } else {
        codeMS(value, sO);
      }
    }
  };

  const documentMainSearch = async (value, sO) => {
    if (value !== "" && value !== null && value !== undefined) {
      if (proChange) {
        confirm({
          icon: <ExclamationCircleOutlined />,
          content: _t("msSureToNew"),
          onOk() {
            documentMS(value, sO);
          },
          onCancel() { },
        });
      } else {
        documentMS(value, sO);
      }
    }
  };

  const clientsMainSearch = async (value, sO) => {
    if (value !== "" && value !== null && value !== undefined) {
      if (proChange) {
        confirm({
          icon: <ExclamationCircleOutlined />,
          content: _t("msSureToNew"),
          onOk() {
            clientsMS(value, sO);
          },
          onCancel() { },
        });
      } else {
        clientsMS(value, sO);
      }
    }
  };

  const codeMS = async (value, sO) => {
    try {
      setProChange(false);

      if (sO) {
        const { data: response } = await searchSoByCode(value);

        setMainSearch(response, sO);
      } else {
        const { data: response } = await searchByCode(id, value);

        setMainSearch(response, sO);
      }
    } catch (error) {
      if (error.response) {
        message.error(error.response.data?.message || _t("msgUnknownError"), 3);
        setLoadData(false);
      } else if (error.request) {
        message.error(_t("msgServerDisconnected"), 3);
        setLoadData(false);
      } else {
        message.error(_t("msgUnknownError"), 3);
        setLoadData(false);
      }
    }
  };

  const documentMS = async (value, sO) => {
    try {
      if (sO) {
        const { data: response } = await searchSoByDocument(value);

        setMainSearch(response, sO);
      } else {
        const { data: response } = await searchByDocument(id, value);

        setMainSearch(response, sO);
      }
    } catch (error) {
      if (error.response) {
        message.error(error.response.data?.message || _t("msgUnknownError"), 3);
        setLoadData(false);
      } else if (error.request) {
        message.error(_t("msgServerDisconnected"), 3);
        setLoadData(false);
      } else {
        message.error(_t("msgUnknownError"), 3);
        setLoadData(false);
      }
    }
  };

  const clientsMS = async (value, sO) => {
    try {
      const { data: response } = await searchSoByCustomer(value);
      setMainSearch(response, sO);
    } catch (error) {
      if (error.response) {
        message.error(error.response.data?.message || _t("msgUnknownError"), 3);
        setLoadData(false);
      } else if (error.request) {
        message.error(_t("msgServerDisconnected"), 3);
        setLoadData(false);
      } else {
        message.error(_t("msgUnknownError"), 3);
        setLoadData(false);
      }
    }
  };

  const setMainSearch = (response, sO) => {
    setSearchData(response.data);
    setSearchDataState(response.data);
    if (response.data.length > 1) {
      setSalesOrderStatus(sO);
      showModalInvoice();
    } else if (response.data.length === 1) {
      const list = response.data[0];
      if (response.data !== null) {
        setLoadSkeleton(true);
        handelClientSupp("", list.clientSupplierId, clientSuppTyp);
        setClientSuppId(list.clientSupplierId);
        onSearchFromStore("", list.fromStoreId);
        setFromStoreId(list.fromStoreId);
        onSearchToStore("", list.toStoreId);
        setToStoreId(list.toStoreId);
      } else {
        setLoadSkeleton(false);
      }
      if (!sO) {
        form.setFieldsValue({
          id: list.id,
          code: list.code,
          relatedId: list.relatedId,
          relatedCode: list.relatedCode,
          relatedDocumentCode: list.relatedDocumentCode,
          relatedNote: list.relatedNote,
          documentCode: list.documentCode,
          note: list.note,
          fromStoreId: list.fromStoreId,
          toStoreId: list.toStoreId,
          transactionDate:
            list.transactionDate !== null ? moment(list.transactionDate) : "",
          expectedDeliveryDate:
            list.expectedDeliveryDate !== null
              ? moment(list.expectedDeliveryDate)
              : "",
          clientSupplierId: list.clientSupplierId,
          storeTransactionStatePatternId: list.storeTransactionStatePatternId,
          storeTransactionDetails: list.storeTransactionDetails,
        });
      } else {
        form.setFieldsValue({
          relatedId: list.relatedId,
          relatedCode: list.relatedCode,
          relatedDocumentCode: list.relatedDocumentCode,
          relatedClientSupplier: list.clientSupplierName,
          relatedNote: list.relatedNote,
          fromStoreId: list.fromStoreId,
          toStoreId: list.toStoreId,

          // transactionDate:
          //   list.transactionDate !== null ? moment(list.transactionDate) : "",

          // expectedDeliveryDate:
          //   list.expectedDeliveryDate !== null
          //     ? moment(list.expectedDeliveryDate)
          //     : "",

          clientSupplierId: list.clientSupplierId,
          storeTransactionStatePatternId: list.storeTransactionStatePatternId,
          storeTransactionDetails: list.storeTransactionDetails,
        });

        // codeGenerator(id);
      }
      if (!editPer && addPer) {
        setSaveDis(true);
        setDeleteDis(false);
        setAddRowListDis(true);
        setAddOnlyDis(true);
        setEditing(false);
        setProChange(false);
      } else if (editPer && !addPer) {
        setSaveDis(false);
        setDeleteDis(false);
        setEditing(false);
        setProChange(false);
      } else {
        setDeleteDis(false);
        setEditing(false);
        setProChange(false);
      }
    } else {
      return message.error(_t("msgThereIsNoValue"), 3);
    }
  };

  const handelDataInvoice = (data) => {
    setVisibilityInvoice(false);
    if (!salesOrderStatus) {
      form.setFieldsValue({
        id: data.id,
        code: data.code,
        relatedId: data.relatedId,
        relatedCode: data.relatedCode,
        relatedDocumentCode: data.relatedDocumentCode,
        relatedNote: data.relatedNote,
        documentCode: data.documentCode,
        note: data.note,
        fromStoreId: data.fromStoreId,
        toStoreId: data.toStoreId,
        transactionDate:
          data.transactionDate !== null ? moment(data.transactionDate) : "",
        expectedDeliveryDate:
          data.expectedDeliveryDate !== null
            ? moment(data.expectedDeliveryDate)
            : "",
        clientSupplierId: data.clientSupplierId,
        storeTransactionStatePatternId: data.storeTransactionStatePatternId,
        storeTransactionDetails: data.storeTransactionDetails,
      });
    } else {
      form.setFieldsValue({
        relatedId: data.relatedId,
        relatedCode: data.relatedCode,
        relatedDocumentCode: data.relatedDocumentCode,
        relatedClientSupplier: data.clientSupplierName,
        relatedNote: data.relatedNote,
        fromStoreId: data.fromStoreId,
        toStoreId: data.toStoreId,
        transactionDate:
          data.transactionDate !== null ? moment(data.transactionDate) : "",
        expectedDeliveryDate:
          data.expectedDeliveryDate !== null
            ? moment(data.expectedDeliveryDate)
            : "",
        clientSupplierId: data.clientSupplierId,
        storeTransactionStatePatternId: data.storeTransactionStatePatternId,
        storeTransactionDetails: data.storeTransactionDetails,
      });
      codeGenerator(id);
    }
    setEditing(false);
    setProChange(false);
  };

  //******************************// //*******************// Search List //*******************// //******************************//

  const codeSearch = async (value, i, add) => {
    try {
      const { data: response } = await searchItem(value, "", false);

      setSearchData(response.data);
      setSearchIndex(i);

      if (response.data.length > 1) {
        showModalInvItemList();
      } else if (response.data.length === 1) {
        const newList = form.getFieldValue("storeTransactionDetails");
        const OldId = newList[i].id;

        response.data.map((_, i) => {
          response.data[i].itemUnit_MapId =
            response.data[i].itemUnit_Maps[0].id;
          response.data[i].itemId = response.data[i].id;
          response.data[i].itemCode = response.data[i].code;
          response.data[i].itemName = response.data[i].name;
          response.data[i].qty = "1";
          response.data[i].note = "";
          delete response.data[i].id;
        });
        newList.splice(i, 1, ...response.data);
        newList[i].id = OldId;
        form.setFieldsValue({ storeTransactionDetails: newList });
        setListState(newList);

        let lastItem = newList?.length - 1;
        if (newList[lastItem].itemId !== 0) {
          add();
        }
        setProChange(true);
      } else {
        if (listState.length !== 0) {
          form.setFieldsValue({ storeTransactionDetails: listState });

          let lastItem = listState.length - 1;
          if (listState[lastItem].itemId !== 0) {
            add();
          }
        }
        return message.error(_t("msgThereIsNoValue"), 3);
      }
    } catch (error) {
      if (error.response !== undefined) {
        if (error.response) {
          message.error(
            error.response.data?.message || _t("msgUnknownError"),
            3
          );
          setLoadData(false);
        } else if (error.request) {
          message.error(_t("msgServerDisconnected"), 3);
          setLoadData(false);
        } else {
          message.error(_t("msgUnknownError"), 3);
          setLoadData(false);
        }
      }
    }
  };

  const nameSearch = async (value, i, add) => {
    try {
      const { data: response } = await searchItem("", value, false);
      setSearchData(response.data);
      setSearchIndex(i);
      if (response.data.length > 1) {
        showModalInvItemList();
      } else if (response.data.length === 1) {
        const newList = form.getFieldValue("storeTransactionDetails");
        const OldId = newList[i].id;
        response.data.map((_, i) => {
          response.data[i].itemUnit_MapId =
            response.data[i].itemUnit_Maps[0].id;
          response.data[i].itemId = response.data[i].id;
          response.data[i].itemCode = response.data[i].code;
          response.data[i].itemName = response.data[i].name;
          response.data[i].qty = "1";
          response.data[i].note = "";
          delete response.data[i].id;
        });
        newList.splice(i, 1, ...response.data);
        newList[i].id = OldId;
        form.setFieldsValue({ storeTransactionDetails: newList });
        setListState(newList);

        let lastItem = newList.length - 1;
        if (newList[lastItem].itemId !== 0) {
          add();
        }
        setProChange(true);
      } else {
        if (listState.length !== 0) {
          form.setFieldsValue({ storeTransactionDetails: listState });
          let lastItem = listState.length - 1;
          if (listState[lastItem].itemId !== 0) {
            add();
          }
        }
        return message.error(_t("msgThereIsNoValue"), 3);
      }
    } catch (error) {
      if (error.response) {
        if (error.response) {
          message.error(
            error.response.data?.message || _t("msgUnknownError"),
            3
          );
          setLoadData(false);
        } else if (error.request) {
          message.error(_t("msgServerDisconnected"), 3);
          setLoadData(false);
        } else {
          message.error(_t("msgUnknownError"), 3);
          setLoadData(false);
        }
      }
    }
  };

  const handelDataInvItemList = (data, add) => {
    let OldId = "";
    setVisibilityInvItemList(false);
    const newList = form.getFieldValue("storeTransactionDetails");
    if (newList[searchIndex] !== undefined) {
      OldId = newList[searchIndex].id;
    }

    data.map((_, i) => {
      data[i].itemUnit_MapId = data[i].itemUnit_Maps[0].id;
      data[i].itemId = data[i].id;
      data[i].itemCode = data[i].code;
      data[i].itemName = data[i].name;
      data[i].qty = "1";
      data[i].note = "";
      delete data[i].id;
    });
    newList.splice(searchIndex, 1, ...data);
    newList[searchIndex].id = OldId;
    form.setFieldsValue({ storeTransactionDetails: newList });
    let lastItem = newList.length - 1;

    if (newList[lastItem]?.itemId !== 0) {
      add();
    }
    setListState(newList);
    setProChange(true);
  };

  //******************************// //*******************// Next & Prev //*******************// //******************************//

  const onClickNext = async () => {
    if (proChange) {
      confirm({
        icon: <ExclamationCircleOutlined />,
        content: _t("msSureToNew"),
        onOk() {
          try {
            setProChange(false);
            nextAction();
            setEditing(true);
          } catch (error) {
            if (error.response) {
              message.error(
                error.response.data?.message || _t("msgUnknownError"),
                3
              );
              setLoadData(false);
            } else if (error.request) {
              message.error(_t("msgServerDisconnected"), 3);
              setLoadData(false);
            } else {
              message.error(_t("msgUnknownError"), 3);
              setLoadData(false);
            }
          }
        },
        onCancel() { },
      });
    } else {
      nextAction();
    }
  };

  const nextAction = async () => {
    try {
      const itemId = form.getFieldValue("id");
      const { data: response } = await getNext(
        id,
        itemId === undefined ? "" : itemId
      );
      if (response.data !== null) {
        setLoadSkeleton(true);

        handelClientSupp("", response.data.clientSupplierId === null ? "" : response.data.clientSupplierId, clientSuppTyp);
        setClientSuppId(response.data.clientSupplierId);
        onSearchFromStore("", response.data.fromStoreId === null ? "" : response.data.fromStoreId);
        setFromStoreId(response.data.fromStoreId);
        onSearchToStore("", response.data.toStoreId === null ? "" : response.data.toStoreId);
        setToStoreId(response.data.toStoreId);
      } else {
        setLoadSkeleton(false);
      }

      setSearchDataState(response.data);
      if (response.data !== null) {
        form.setFieldsValue({
          id: response.data.id,
          code: response.data.code,
          relatedId: response.data.relatedId,
          relatedCode: response.data.relatedCode,
          relatedDocumentCode: response.data.relatedDocumentCode,
          relatedNote: response.data.relatedNote,
          documentCode: response.data.documentCode,
          note: response.data.note,
          fromStoreId: response.data.fromStoreId,
          toStoreId: response.data.toStoreId,
          transactionDate:
            response.data.transactionDate !== null
              ? moment(response.data.transactionDate)
              : "",
          expectedDeliveryDate:
            response.data.expectedDeliveryDate !== null
              ? moment(response.data.expectedDeliveryDate)
              : "",
          clientSupplierId: response.data.clientSupplierId,
          storeTransactionStatePatternId:
            response.data.storeTransactionStatePatternId,
          storeTransactionDetails: response.data.storeTransactionDetails,
        });
        if (!editPer && addPer) {
          setSaveDis(true);
          setDeleteDis(false);
          setAddRowListDis(true);
          setAddOnlyDis(true);
          setEditing(false);
          setProChange(false);
        } else if (editPer && !addPer) {
          setSaveDis(false);
          setDeleteDis(false);
          setEditing(false);
          setProChange(false);
        } else {
          setDeleteDis(false);
          setEditing(false);
          setProChange(false);
        }
      }
    } catch (error) {
      if (error.response) {
        message.error(error.response.data?.message || _t("msgUnknownError"), 3);
        setLoadData(false);
      } else if (error.request) {
        message.error(_t("msgServerDisconnected"), 3);
        setLoadData(false);
      } else {
        message.error(_t("msgUnknownError"), 3);
        setLoadData(false);
      }
    }
  };

  const onClickPrev = async () => {
    if (proChange) {
      confirm({
        icon: <ExclamationCircleOutlined />,
        content: _t("msSureToNew"),
        onOk() {
          try {
            setProChange(false);
            prevAction();
            setEditing(true);
          } catch (error) {
            if (error.response) {
              message.error(
                error.response.data?.message || _t("msgUnknownError"),
                3
              );
              setLoadData(false);
            } else if (error.request) {
              message.error(_t("msgServerDisconnected"), 3);
              setLoadData(false);
            } else {
              message.error(_t("msgUnknownError"), 3);
              setLoadData(false);
            }
          }
        },
        onCancel() { },
      });
    } else {
      prevAction();
    }
  };

  const prevAction = async () => {
    try {
      const itemId = form.getFieldValue("id");
      const { data: response } = await getPrev(
        id,
        itemId === undefined ? "" : itemId
      );

      if (response.data !== null) {
        setLoadSkeleton(true);

        handelClientSupp("", response.data.clientSupplierId === null ? "" : response.data.clientSupplierId, clientSuppTyp);
        setClientSuppId(response.data.clientSupplierId);
        onSearchFromStore("", response.data.fromStoreId === null ? "" : response.data.fromStoreId);
        setFromStoreId(response.data.fromStoreId);
        onSearchToStore("", response.data.toStoreId === null ? "" : response.data.toStoreId);
        setToStoreId(response.data.toStoreId);
      } else {
        setLoadSkeleton(false);
      }

      setSearchDataState(response.data);
      if (response.data !== null) {
        form.setFieldsValue({
          id: response.data.id,
          code: response.data.code,
          relatedId: response.data.relatedId,
          relatedCode: response.data.relatedCode,
          relatedDocumentCode: response.data.relatedDocumentCode,
          relatedNote: response.data.relatedNote,
          documentCode: response.data.documentCode,
          note: response.data.note,
          fromStoreId: response.data.fromStoreId,
          toStoreId: response.data.toStoreId,
          transactionDate:
            response.data.transactionDate !== null
              ? moment(response.data.transactionDate)
              : "",
          expectedDeliveryDate:
            response.data.expectedDeliveryDate !== null
              ? moment(response.data.expectedDeliveryDate)
              : "",
          clientSupplierId: response.data.clientSupplierId,
          storeTransactionStatePatternId:
            response.data.storeTransactionStatePatternId,
          storeTransactionDetails: response.data.storeTransactionDetails,
        });
        if (!editPer && addPer) {
          setSaveDis(true);
          setDeleteDis(false);
          setAddRowListDis(true);
          setAddOnlyDis(true);
          setEditing(false);
          setProChange(false);
        } else if (editPer && !addPer) {
          setSaveDis(false);
          setDeleteDis(false);
          setEditing(false);
          setProChange(false);
        } else {
          setDeleteDis(false);
          setEditing(false);
          setProChange(false);
        }
      }
    } catch (error) {
      if (error.response) {
        message.error(error.response.data?.message || _t("msgUnknownError"), 3);
        setLoadData(false);
      } else if (error.request) {
        message.error(_t("msgServerDisconnected"), 3);
        setLoadData(false);
      } else {
        message.error(_t("msgUnknownError"), 3);
        setLoadData(false);
      }
    }
  };

  //******************************// //*******************// List State//*******************// //******************************//

  const onDeleteList = (i, remove) => {
    remove(i);
    const newList = form.getFieldValue("storeTransactionDetails");
    setListState(newList);
  };

  const onChangelist = () => {
    const newList = form.getFieldValue("storeTransactionDetails");
    setListState(newList);
  };
  //******************************// //*******************// Print //*******************// //******************************//
  const [dataPrint, strDataPrint] = useState({});

  const invoiceData = {
    pattrenAr: null,
    pattrenEn: null,
    code: null,
    document: null,
    client: null,
    statusAr: null,
    statusEn: null,
    fromStore: null,
    toStore: null,
    date: null,
    expectedDeliveryDate: null,
    table: [],
  };

  const onPrintInStock = (dataObj) => {
    setDataInStockPrinting(dataObj);
    setVisibleInStockDrawer(true);
  };

  const onPrint = (dataObj) => {
    console.log(searchData);
    invoiceData.pattrenAr = dataObj.storeTransactionPatternNameAr;
    invoiceData.pattrenEn = dataObj.storeTransactionPatternNameEn;
    invoiceData.code = dataObj.code;
    invoiceData.document = dataObj.documentCode;
    invoiceData.client = dataObj.clientSupplierName;
    invoiceData.statusAr = dataObj.storeTransactionStatePatternNameAr;
    invoiceData.statusEn = dataObj.storeTransactionStatePatternNameEn;
    invoiceData.fromStore = dataObj.fromStoreName;
    invoiceData.toStore = dataObj.toStoreName;
    invoiceData.note = dataObj.note;
    invoiceData.date = convertIsoDate(dataObj.transactionDate);
    invoiceData.expectedDeliveryDate = convertIsoDate(
      dataObj.expectedDeliveryDate
    );
    invoiceData.table = dataObj.storeTransactionDetails;
    strDataPrint(invoiceData);
    setVisibleDrawer(true);
  };

  const convertIsoDate = (IsoDate) => {
    if (IsoDate !== null && IsoDate !== undefined && IsoDate !== "") {
      const date = new Date(IsoDate);
      return (
        date.getDate() +
        "/" +
        (date.getMonth() + 1) +
        "/" +
        date.getFullYear() +
        " - " +
        date.getHours() +
        ":" +
        date.getMinutes()
      );
    } else {
      return "";
    }
  };

  const onCloseDrawer = () => {
    setVisibleDrawer(false);
  };
  const onCloseInStockDrawer = () => {
    setVisibleInStockDrawer(false);
  };

  //******************************// //*******************// Import Excel //*******************// //******************************//

  const onImportExcel = async (file) => {
    setLoadData(true);
    try {
      const responce = await uploadExcel(file);
      onNew(true);
      form.setFieldsValue({ storeTransactionDetails: responce.data.data });
      setLoadData(false);
    } catch (error) {
      if (error.response) {
        message.error(error.response.data?.message || _t("msgUnknownError"), 3);
        setLoadData(false);
      } else if (error.request) {
        message.error(_t("msgServerDisconnected"), 3);
        setLoadData(false);
      } else {
        message.error(_t("msgUnknownError"), 3);
        setLoadData(false);
      }
    }
  };

  const handelBeforeUpload = (file) => {
    if (
      file.type !==
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      return message.error(`${file.name} is not a png file`);
    }
  };

  useEffect(() => {
    handelQtyChange();
  }, [listState, searchData, searchDataState]);

  const handelQtyChange = () => {
    const dataList = form.getFieldValue("storeTransactionDetails");

    if (dataList !== undefined) {
      const dataAsStringsTotal = dataList.reduce(
        (x, y) =>
          y !== undefined && y.hasOwnProperty("itemUnit_MapId")
            ? x + parseFloat(y.qty, 10)
            : x,
        0
      );
      setTotalUnitsCount(dataAsStringsTotal);
    } else {
      setTotalUnitsCount(0);
    }
  };






  //******************************// //*******************// Form //*******************// //******************************//

  return (
    <React.Fragment>
      <Prompt when={proChange} message={_t("strUnsavedChanges")} />

      <Drawer
        width={720}
        onClose={onCloseInStockDrawer}
        visible={visibleInStockDrawer}
        title={_t("strPrintPreview")}
      >
        <ReportsPrint
          objToPrint={dataInStockPrinting}
          setColumn={tableConfig}
          setTitle={_t("strStockQuantity")}
        />
      </Drawer>
      <Drawer
        width={720}
        onClose={onCloseDrawer}
        visible={visibleDrawer}
        title={_t("strPrintPreview")}
      >
        <Printing id={ form.getFieldValue("id") === undefined ? prentAfterSaveId : form.getFieldValue("id")} />
      </Drawer>

      <Modal
        visible={visibilityInvoice}
        onCancel={() => handleCancelInvoice()}
        footer={null}
        destroyOnClose={true}
        width={1000}
      >
        <br />
        <br />
        <InvoiceSelectorForm
          onHandelData={(data) => handelDataInvoice(data)}
          onSearchData={searchData}
        />
      </Modal>

      <Modal
        visible={showTotalQtyDetails}
        onCancel={() => setShowTotalQtyDetails(false)}
        footer={null}
        title={_t("strTotalQtyDetails")}
        destroyOnClose={true}
        width={500}
      >
        <TotalUnitDetailsForm
          unitList={form.getFieldValue("storeTransactionDetails")}
        />
      </Modal>

      <Spin spinning={loadData} tip={_t("strLoading")}>
        <Form
          form={form}
          name="mainform"
          onFinish={onFinish}
          layout="vertical"
          initialValues={{ transactionDate: moment() }}
        >
          <div className="frHeader">

            <Row gutter={12}>
              <Form.Item name="id" hidden>
                <Input autoComplete="off" />
              </Form.Item>
              <Form.Item name="relatedId" hidden>
                <Input autoComplete="off" />
              </Form.Item>
              {salesOrder && (
                <Col span={24} style={{ marginBottom: 20 }}>
                  <Collapse ghost>
                    <Panel
                      header={_t("strSalesOrder")}
                      style={{ backgroundColor: "#ffffff" }}
                    >
                      <Row gutter={12}>
                        <Col xs={24} md={12} lg={6}>
                          <Form.Item
                            name="relatedCode"
                            label={_t("strCode")}
                            rules={[
                              {
                                required: false,
                                message: `${_t("strIsRequired")}`,
                              },
                              { min: 1, message: `${_t("strFrom3-200")}` },
                              { max: 200, message: `${_t("strFrom3-200")}` },
                            ]}
                          >
                            <Search
                              size="small"
                              readOnly={orderDis}
                              placeholder={_t("strCode")}
                              maxLength={200}
                              autoComplete="off"
                              onSearch={(value) =>
                                codeMainSearch(value, true)
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} md={12} lg={6}>
                          <Form.Item
                            name="relatedDocumentCode"
                            label={_t("strDocument")}
                            rules={[
                              {
                                required: false,
                                message: `${_t("strIsRequired")}`,
                              },
                            ]}
                          >
                            <Search
                              size="small"
                              readOnly={orderDis}
                              placeholder={_t("strDocument")}
                              autoComplete="off"
                              onSearch={(value) =>
                                documentMainSearch(value, true)
                              }
                            />
                          </Form.Item>
                        </Col>

                        <Col xs={24} md={12} lg={6}>
                          <Form.Item
                            name="relatedClientSupplier"
                            label={_t("strClient")}
                            rules={[
                              {
                                required: false,
                                message: `${_t("strIsRequired")}`,
                              },
                            ]}
                          >
                            <Search
                              size="small"
                              readOnly={orderDis}
                              placeholder={_t("strClient")}
                              autoComplete="off"
                              onSearch={(value) =>
                                clientsMainSearch(value, true)
                              }
                            />
                          </Form.Item>
                        </Col>

                        <Col xs={24} md={12} lg={6}>
                          <Form.Item
                            name="relatedNote"
                            label={_t("strNote")}
                            tooltip={{
                              title: _t("msgReadOnly"),
                              icon: <InfoCircleOutlined />,
                            }}
                          >
                            <TextArea
                              size="small"
                              placeholder={_t("strNote")}
                              readOnly
                              maxLength={1000}
                              autoComplete="off"
                              onChange={() => {
                                setProChange(true);
                              }}
                              autoSize={{ minRows: 1, maxRows: 3 }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Panel>
                  </Collapse>
                </Col>
              )}

              <Col xs={24} md={12} lg={6}>
                <Form.Item
                  name="code"
                  label={_t("strCode")}
                  rules={[
                    { required: true, message: `${_t("strIsRequired")}` },
                    { min: 1, message: `${_t("strFrom3-200")}` },
                    { max: 200, message: `${_t("strFrom3-200")}` },
                  ]}
                >
                  <Search
                    size="small"
                    placeholder={_t("strCode")}
                    autoComplete="off"
                    maxLength={200}
                    onSearch={(value) => codeMainSearch(value, false)}
                    ref={IX_Code}
                    suffix={
                      _t("strName") !== "Name" ? (
                        <LeftOutlined
                          style={{ color: "#b3b3b3" }}
                          onClick={() => onClickNext()}
                        />
                      ) : (
                        <RightOutlined
                          style={{ color: "#b3b3b3" }}
                          onClick={() => onClickNext()}
                        />
                      )
                    }
                    prefix={
                      _t("strName") !== "Name" ? (
                        <RightOutlined
                          style={{ color: "#b3b3b3" }}
                          onClick={() => onClickPrev()}
                        />
                      ) : (
                        <LeftOutlined
                          style={{ color: "#b3b3b3" }}
                          onClick={() => onClickPrev()}
                        />
                      )
                    }
                  />
                </Form.Item>

                {/* <Space size="small">
                  <Button
                    onClick={() => onClickPrev()}
                    type="link"
                    style={{ marginTop: 16, color: "#8c8c8c" }}
                    size="small"
                  >
                    {_t("strPrev") === "Prev" ? (
                      <DoubleLeftOutlined style={{ fontSize: 16 }} />
                    ) : (
                      <DoubleRightOutlined style={{ fontSize: 16 }} />
                    )}
                  </Button>

                  <Form.Item
                    name="code"
                    label={_t("strCode")}
                    rules={[
                      { required: true, message: `${_t("strIsRequired")}` },
                      { min: 1, message: `${_t("strFrom3-200")}` },
                      { max: 200, message: `${_t("strFrom3-200")}` },
                    ]}
                  >
                    <Search
                      size="small"
                      placeholder={_t("strCode")}
                      autoComplete="off"
                      maxLength={200}
                      onSearch={(value) => codeMainSearch(value, false)}
                      ref={IX_Code}
                    />
                  </Form.Item>
                  <Button
                    onClick={() => onClickNext()}
                    type="link"
                    style={{ marginTop: 16, color: "#8c8c8c" }}
                    size="small"
                  >
                    {_t("strNext") === "Next" ? (
                      <DoubleRightOutlined style={{ fontSize: 16 }} />
                    ) : (
                      <DoubleLeftOutlined style={{ fontSize: 16 }} />
                    )}
                  </Button>
                </Space> */}
              </Col>
              <Col xs={24} md={12} lg={6}>
                <Form.Item
                  name="documentCode"
                  label={_t("strDocument")}
                  rules={[
                    { required: false, message: `${_t("strIsRequired")}` },
                    { min: 1, message: `${_t("strFrom3-200")}` },
                    { max: 200, message: `${_t("strFrom3-200")}` },
                  ]}
                >
                  <Search
                    size="small"
                    placeholder={_t("strDocument")}
                    autoComplete="off"
                    maxLength={200}
                    onSearch={(value) => documentMainSearch(value, false)}
                  />
                </Form.Item>
              </Col>

              {!clientSuppHidden && (
                <Col xs={24} md={12} lg={6}>
                  <Form.Item
                    name="clientSupplierId"
                    label={
                      clientSuppTyp === 1
                        ? _t("strClient")
                        : clientSuppTyp === 2
                          ? _t("strSupplier")
                          : _t("strClientsSupp")
                    }
                    rules={[
                      { required: true, message: `${_t("strIsRequired")}` },
                    ]}
                  >
                    <Select
                      size="small"
                      placeholder={
                        clientSuppTyp === 1
                          ? _t("strClient")
                          : clientSuppTyp === 2
                            ? _t("strSupplier")
                            : _t("strClientsSupp")
                      }
                      autoComplete="off"
                      onSearch={(value) => {
                        handelClientSupp(value, clientSuppId, clientSuppTyp);
                      }}
                      onChange={() => {
                        setProChange(true);
                      }}
                      showSearch
                      optionFilterProp="children"
                      disabled={addOnlyDis}
                    >
                      {clientSuppOptList}
                    </Select>
                  </Form.Item>
                </Col>
              )}

              {status && (
                <Col xs={24} md={12} lg={6}>
                  <Form.Item
                    name="storeTransactionStatePatternId"
                    label={_t("strStatus")}
                    rules={[
                      { required: false, message: `${_t("strIsRequired")}` },
                    ]}
                  >
                    <Select
                      size="small"
                      placeholder={_t("strStatus")}
                      allowClear
                      autoComplete="off"
                      onChange={() => {
                        setProChange(true);
                      }}
                      showSearch
                      optionFilterProp="children"
                      disabled={addOnlyDis}
                    >
                      {stspOptList}
                    </Select>
                  </Form.Item>
                </Col>
              )}

              {!toStore && (
                <Col xs={24} md={12} lg={6}>
                  <Form.Item
                    name="fromStoreId"
                    label={_t("strFromStore")}
                    rules={[
                      { required: true, message: `${_t("strIsRequired")}` },
                    ]}
                  >
                    <Select
                      size="small"
                      placeholder={_t("strFromStore")}
                      autoComplete="off"
                      onChange={() => {
                        setProChange(true);
                      }}
                      onSearch={(value) => { onSearchFromStore(value, fromStoreId) }}
                      showSearch
                      optionFilterProp="children"
                      disabled={addOnlyDis}
                    >
                      {fromStoreChildsOptList}
                    </Select>
                  </Form.Item>
                </Col>
              )}

              <Col xs={24} md={12} lg={6}>
                <Form.Item
                  name="toStoreId"
                  label={toStore === true ? _t("strStore") : _t("strToStore")}
                  rules={[
                    { required: true, message: `${_t("strIsRequired")}` },
                  ]}
                >
                  <Select
                    size="small"
                    placeholder={
                      toStore === true ? _t("strStore") : _t("strToStore")
                    }
                    autoComplete="off"
                    ref={toStoreInput}
                    onChange={() => {
                      setProChange(true);
                    }}
                    onSearch={(value) => { onSearchToStore(value, toStoreId) }}
                    showSearch
                    optionFilterProp="children"
                    disabled={addOnlyDis}
                  >
                    {toStoreChildsOptList}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} md={12} lg={6}>
                <Form.Item
                  name="transactionDate"
                  label={_t("strDate")}
                  rules={[
                    { required: true, message: `${_t("strIsRequired")}` },
                  ]}
                >
                  <DatePicker
                    size="small"
                    showTime={{ format: "HH:mm" }}
                    disabled={addOnlyDis}
                    format={dateFormatList}
                    className="dateTimeStyle"
                    onChange={() => {
                      setProChange(true);
                    }}
                  />
                </Form.Item>
              </Col>
              {exDate && (
                <Col xs={24} md={12} lg={6}>
                  <Form.Item
                    name="expectedDeliveryDate"
                    label={_t("strExpectedDeliveryDate")}
                    rules={[
                      { required: false, message: `${_t("strIsRequired")}` },
                    ]}
                  >
                    <DatePicker
                      size="small"
                      disabled={addOnlyDis}
                      showTime={{ format: "HH:mm" }}
                      format={dateFormatList}
                      className="dateTimeStyle"
                      onChange={() => {
                        setProChange(true);
                      }}
                    />
                  </Form.Item>
                </Col>
              )}
              <Col xs={24} md={12} lg={6}>
                <Form.Item
                  name="note"
                  label={_t("strNote")}
                  rules={[
                    { required: false, message: `${_t("strIsRequired")}` },
                    { min: 1, message: `${_t("strFrom1-1000")}` },
                    { max: 1000, message: `${_t("strFrom1-1000")}` },
                  ]}
                >
                  <TextArea
                    placeholder={_t("strNote")}
                    readOnly={addOnlyDis}
                    maxLength={1000}
                    size="small"
                    showCount
                    autoComplete="off"
                    onChange={() => {
                      setProChange(true);
                    }}
                    autoSize={{ minRows: 1, maxRows: 3 }}
                  />
                </Form.Item>
              </Col>

              <Col xs={12} md={12} sm={6} hidden={importExcelVisibility}>
                <Form.Item label=" " style={{ marginTop: -6 }}>
                  <Upload
                    action={(file) => onImportExcel(file)}
                    beforeUpload={(file) => {
                      handelBeforeUpload(file);
                    }}
                    maxCount={1}
                    showUploadList={false}
                  >
                    <Button icon={<UploadOutlined />} type="dashed">
                      {_t("strImportItemsFromExcel")}
                    </Button>
                  </Upload>
                </Form.Item>
              </Col>
            </Row>

          </div>

          <div className="frTabX">
            <Skeleton loading={loadSkeleton} active>
              <Col xs={24} md={24}>
                <Card bodyStyle={{ padding: 10 }} className="scrollCard">
                  <Form.List name="storeTransactionDetails">
                    {(fields, { add, remove }) => {
                      return (
                        <div>
                          <Modal
                            visible={visibilityInvItemList}
                            onCancel={() => handleCancelInvItemList()}
                            footer={null}
                            destroyOnClose={true}
                            width={1000}
                          >
                            <br />
                            <br />
                            <InvItemListSelectorForm
                              onHandelData={(data) =>
                                handelDataInvItemList(data, add)
                              }
                              onSearchData={searchData}
                            />
                          </Modal>
                          {fields.map((field, i) => (
                            <Row gutter={[8, 8]} key={field.key}>
                              <Col hidden>
                                <Form.Item
                                  {...field}
                                  defaultValue={0}
                                  name={[field.name, "id"]}
                                  fieldKey={[field.fieldKey, "id"]}
                                  hidden
                                >
                                  <Input
                                    autoComplete="off"
                                    onChange={() => {
                                      setProChange(true);
                                    }}
                                  />
                                </Form.Item>
                              </Col>
                              <Col hidden>
                                <Form.Item
                                  {...field}
                                  defaultValue={0}
                                  name={[field.name, "itemId"]}
                                  fieldKey={[field.fieldKey, "itemId"]}
                                  hidden
                                >
                                  <Input
                                    autoComplete="off"
                                    onChange={() => {
                                      setProChange(true);
                                    }}
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={1} md={1} sm={1}>
                                <Form.Item
                                  style={{ textAlign: "center" }}
                                  fieldKey={[field.fieldKey, "rowCount"]}
                                  style={{ marginBottom: -25 }}
                                >
                                  <label>{i + 1}</label>
                                </Form.Item>
                              </Col>
                              <Col xs={7} md={4} sm={4}>
                                <Form.Item
                                  {...field}
                                  name={[field.name, "itemCode"]}
                                  fieldKey={[field.fieldKey, "itemCode"]}
                                  style={{ marginBottom: -25 }}
                                >
                                  <Search
                                    readOnly={addOnlyDis}
                                    min={0}
                                    max={9999}
                                    placeholder={_t("strCode")}
                                    size="small"
                                    autoComplete="off"
                                    onSearch={(v) =>
                                      codeSearch(v, field.name, add)
                                    }
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={8} md={6} sm={6}>
                                <Form.Item
                                  {...field}
                                  name={[field.name, "itemName"]}
                                  fieldKey={[field.fieldKey, "itemName"]}
                                  style={{ marginBottom: -25 }}
                                  rules={[
                                    {
                                      required: false,
                                      message: _t("strFieldRequired"),
                                    },
                                  ]}
                                >
                                  <Search
                                    readOnly={addOnlyDis}
                                    placeholder={_t("strName")}
                                    autoComplete="off"
                                    size="small"
                                    onSearch={(v) =>
                                      nameSearch(v, field.name, add)
                                    }
                                  />
                                </Form.Item>
                              </Col>
                              <Col hidden>
                                <Form.Item
                                  {...field}
                                  name={[field.name, "itemUnit_Maps"]}
                                  fieldKey={[field.fieldKey, "itemUnit_Maps"]}
                                  hidden
                                >
                                  <Input size="small" />
                                </Form.Item>
                              </Col>
                              <Col xs={8} md={3} sm={3}>
                                <Form.Item
                                  key={field.key}
                                  shouldUpdate={(prev, curr) =>
                                    prev.storeTransactionDetails?.length !==
                                    curr.storeTransactionDetails?.length
                                  }
                                  fieldKey={[field.fieldKey, "itemUnit_MapId"]}
                                  style={{ marginBottom: -25 }}
                                >
                                  {({ getFieldValue }) => {
                                    const list = getFieldValue(
                                      "storeTransactionDetails"
                                    );
                                    const unitList =
                                      Array.isArray(list) &&
                                      list[field.name]?.itemUnit_Maps;
                                    return (
                                      <Form.Item
                                        {...field}
                                        name={[field.name, "itemUnit_MapId"]}
                                        rules={[
                                          { required: false, message: " " },
                                        ]}
                                      >
                                        <Select
                                          disabled={addOnlyDis}
                                          showSearch
                                          optionFilterProp="children"
                                          placeholder={_t("strUnit")}
                                          autoComplete="off"
                                          size="small"
                                          onChange={() => {
                                            setProChange(true);
                                            onChangelist();
                                          }}
                                        >
                                          {unitList &&
                                            unitList.map((item) => (
                                              <Option
                                                key={item.id}
                                                value={item.id}
                                              >
                                                {item.itemUnitName}
                                              </Option>
                                            ))}
                                        </Select>
                                      </Form.Item>
                                    );
                                  }}
                                </Form.Item>
                              </Col>
                              <Col xs={10} md={2} sm={2}>
                                <Form.Item
                                  {...field}
                                  name={[field.name, "qty"]}
                                  fieldKey={[field.fieldKey, "qty"]}
                                  rules={[{ required: false, message: " " }]}
                                  style={{ marginBottom: -25 }}
                                >
                                  <InputNumber
                                    readOnly={addOnlyDis}
                                    style={{ width: "100%" }}
                                    min={-999999999999999}
                                    step={0.1}
                                    max={999999999999999}
                                    size="small"
                                    placeholder={_t("strQuantity")}
                                    autoComplete="off"
                                    onChange={(e) => {
                                      handelQtyChange();
                                      setProChange(true);
                                      onChangelist();
                                    }}
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={12} md={7} sm={7}>
                                <Form.Item
                                  {...field}
                                  name={[field.name, "note"]}
                                  fieldKey={[field.fieldKey, "note"]}
                                  rules={[
                                    {
                                      required: false,
                                      message: _t("strFieldRequired"),
                                    },
                                  ]}
                                  style={{ marginBottom: -25 }}
                                >
                                  <Input
                                    readOnly={addOnlyDis}
                                    min={0}
                                    max={9999}
                                    size="small"
                                    placeholder={_t("strNote")}
                                    autoComplete="off"
                                    onChange={() => {
                                      setProChange(true);
                                      onChangelist();
                                    }}
                                  />
                                </Form.Item>
                              </Col>

                              <Col
                                xs={2}
                                md={1}
                                style={{ paddingTop: 6 }}
                                sm={1}
                              >
                                <Popconfirm
                                  title={_t("strSureToDelete")}
                                  onConfirm={() => onDeleteList(i, remove)}
                                  disabled={addRowListDis}
                                >
                                  <DeleteTwoTone
                                    twoToneColor={
                                      addRowListDis === true
                                        ? "#fff"
                                        : "#ff5252"
                                    }
                                    style={{ fontSize: 20 }}
                                  />
                                </Popconfirm>
                              </Col>
                            </Row>
                          ))}

                          <Form.Item style={{ marginBottom: 0 }}>
                            <Button
                              type="dashed"
                              disabled={addRowListDis}
                              style={{ marginTop: 5 }}
                              onClick={() => {
                                add();
                              }}
                              block
                            >
                              <PlusOutlined /> {_t("strAdd")}
                            </Button>
                          </Form.Item>
                        </div>
                      );
                    }}
                  </Form.List>
                </Card>
              </Col>
            </Skeleton>
          </div>

          <div className="frTabX" style={{ marginTop: 3 }}>
            <Tooltip
              title={
                totalUnitsCount === 0
                  ? _t("strThereAreNoQuantities")
                  : _t("strTotalQtyDetails")
              }
              color={totalUnitsCount === 0 ? undefined : "#2db7f5"}
              style={{ cursor: "pointer" }}
            >
              <Button
                type="link"
                onClick={() => {
                  setShowTotalQtyDetails(true);
                }}
                disabled={totalUnitsCount === 0 ? true : false}
              >
                {_t("strTotalQtyUnits")}{" "}
                {totalUnitsCount
                  .toFixed(3)
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
              </Button>
            </Tooltip>
          </div>

          <div className="frFooter">
            <Row style={{ marginBottom: -20 }}>
              <Col md={6} xs={24} style={{ textAlign: "center" }}>
                <Space size="large">
                  <Form.Item>
                    <Dropdown.Button
                      onClick={() => form.submit()}
                      overlay={menu}
                      style={{ width: 100 }}
                      disabled={saveDis}
                      type={saveDis ? "" : "primary"}
                    >
                      {_t("strSave")}
                    </Dropdown.Button>
                    {printingAfterSave ? (
                      <PrinterOutlined style={{ fontSize: 16 }} />
                    ) : (
                      ""
                    )}
                  </Form.Item>

                  <Form.Item>
                    <Button
                      disabled={newDis}
                      style={{ width: 100 }}
                      type="dashed"
                      onClick={() => onNew()}
                    >
                      {_t("strNew")}
                    </Button>
                  </Form.Item>
                </Space>
              </Col>

              <Col md={6} xs={0} style={{ textAlign: "center" }} />
              <Col md={6} xs={0} style={{ textAlign: "center" }} />

              <Col md={6} xs={24} style={{ textAlign: "center" }}>
                <Space size="large">
                  <Form.Item>
                    <Button
                      style={{ width: 100 }}
                      type="dashed"
                      onClick={() =>
                        setVisibleDrawer(true)
                      }
                      disabled={editing}
                    >
                      {_t("strPrint")}
                    </Button>
                  </Form.Item>



                  {deletePer && (
                    <Form.Item>
                      <Popconfirm
                        title={_t("strSureToDelete")}
                        placement={_t("ntfoPlacement")}
                        onConfirm={() => onDelete()}
                        disabled={deleteDis}
                      >
                        <Button
                          style={{ width: 100 }}
                          type="dashed"
                          danger
                          disabled={deleteDis}
                        >
                          {_t("strDelete")}
                        </Button>
                      </Popconfirm>
                    </Form.Item>
                  )}
                </Space>
              </Col>
              <Col xs={0} md={6} lg={12} />
            </Row>
          </div>
        </Form>
      </Spin>
    </React.Fragment>
  );
};

export default TransactionForm;
