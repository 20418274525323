import React, { useContext, useState } from "react";
import { Switch, Route, Redirect, Link } from "react-router-dom";
/********************| forms |********************/
import FormUsers from "./components/forms/Authorization/fr_Users";
import FormRoles from "./components/forms/Authorization/fr_Roles";
import FormCompany from "./components/forms/fr_Company";
import FormBranches from "./components/forms/fr_Branches";
import FormInventoryMatching from "./components/forms/fr_InventoryMatching";
import FormStores from "./components/forms/fr_Stores";
import FormItem from "./components/forms/Items/fr_Item";
import ManufacturingModelsForm from "./components/forms/Items/fr_ManufacturingModels";
import ManufacturingOrdersForm from "./components/forms/Items/fr_ManufacturingOrders";
import FormClientsSupp from "./components/forms/fr_ClientsSupp";
import FormUnit from "./components/forms/Items/fr_ItemUnit";
import FormTransaction from "./components/forms/Transaction/fr_Transaction";
import FormDashboardTemp from "./components/forms/fr_DashboardTemp";
import FormDashboardRoles from "./components/forms/fr_DashboardRoles";

/********************| pages |********************/

import ChangePassword from "./components/pages/Authorization/pg_ChangePassword";
import PageUsers from "./components/pages/Authorization/pg_Users";
import PageRoles from "./components/pages/Authorization/pg_Roles";
import Login from "./components/pages/Authorization/pg_Login";
import PageStores from "./components/pages/pg_Stores";
import PageBranches from "./components/pages/pg_Branches";
import PageInventoryMatching from "./components/pages/pg_InventoryMatching";
import PageItem from "./components/pages/Items/pg_Item";
import PageManufacturingModels from "./components/pages/Items/pg_ManufacturingModels";
import PageClientsSupp from "./components/pages/pg_ClientsSupp";
import PageUnit from "./components/pages/Items/pg_ItemUnit";
import PageInventoryReport from "./components/pages/Reports/pg_InventoryReport";
import PageItemMovementReport from "./components/pages/Reports/pg_ItemMovmentReport";
import PageMunfReport from "./components/pages/Reports/pg_MunfReport";
import PageDashboard from "./components/pages/pg_Dashboard";
import PageStoreTransactionReport from "./components/pages/Reports/pg_InvoiceReport";
import PageOrdersReport from "./components/pages/Reports/pg_OrderReport";
import SideMenu from "./components/layout/menu";
import Logout from "./components/pages/Authorization/esc_logout";
import PageEditableTable from "./components/pages/pg_editableTable";
import PageDashboardTemp from "./components/pages/pg_DashboardTemps";
import PageDashboardRoles from "./components/pages/pg_DashboardRoles";

import { LanguageContext } from "./languages/Language";

import MainHeader from "./components/layout/MainHeader";
/********************| languages |********************/
import LanguageSelector from "./languages/LanguageSelector";

import { LanguageProvider } from "./languages/Language";
/********************| antd |********************/
import { Layout, Row, Col, BackTop, Drawer, Space, Breadcrumb } from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UpOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
/********************| css |********************/
import "./app.less";
/********************| auth |********************/
import ProdtectedRoute from "./components/route/protectedRoute";
import {
  getCurrentUser,
  getRolesJwt,
} from "./services/Authorization/authService";
import { homePath, demo, virsion, ourName } from "./config.json";
/********************| Media respo |********************/
import Media from "react-media";
import _t from "./languages/translate";
/********************
 * ****************
 * *********
 * *******
 * ***
 * *
 */
const { Header, Content, Footer, Sider } = Layout;

const App = () => {

  const { userLanguage } = useContext(LanguageContext);
  const [collapsed, setCollapsed] = useState();
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const user = getCurrentUser();
  const [direction, setDirection] = useState("left");
  const [
    /*roles, setRoles*/
  ] = useState(getRolesJwt());
  const toggle = () => {
    setCollapsed(!collapsed);
  };
  const onCloseDrawer = () => {
    setVisibleDrawer(false);
  };
  const showDrawer = () => {
    setVisibleDrawer(true);
  };
  const newDirection = (direction) => {
    setDirection(direction);
  };
  const [transAr, setTransAr] = useState("");
  const [transEn, setTransEn] = useState("");
  const [titlePage, setTitlePage] = useState("");

  const clear = () => {
    setTransEn("");
    setTransAr("");
  };

  const transactionTitle = (data) => {
    //
    // if (userLanguage === "en") {
    //   setTitlePage(data.split(",")[0]);
    // } else {
    //   setTitlePage(data.split(",")[1]);
    // }

    setTransEn(data.split(",")[0]);
    setTransAr(data.split(",")[1]);
  };

  const handelTitleLang = (lang) => {
    if (lang === "en" && transEn !== "") {
      setTitlePage(transEn);
    } else if (lang === "ar" && transAr !== "") {
      setTitlePage(transAr);
    } else {
      return;
    }
  };

  return (
    <LanguageProvider userDirection={newDirection}>
      <Layout style={{ minHeight: "100vh" }}>
        {user && (
          <Media query="(max-width: 1024px)">
            {(matches) =>
              matches ? (
                <Drawer
                  placement={direction}
                  closable={false}
                  onClose={onCloseDrawer}
                  visible={visibleDrawer}
                  bodyStyle={{ backgroundColor: "#001529", padding: 0 }}
                >
                  <div className="logo" />
                  <SideMenu extraItem="About" toggleCollapsed={toggle} />
                </Drawer>
              ) : (
                <Sider trigger={null} collapsible collapsed={collapsed}>
                  <div className="logo" />
                  <SideMenu extraItem="About" toggleCollapsed={toggle} />
                  <div className="virsionDiv">
                    <small className="virsionText">
                      {ourName} {virsion}
                    </small>
                  </div>
                </Sider>
              )
            }
          </Media>
        )}

        <Layout className="site-layout">
          {user && (
            <>
              {/* <MainHeader pageTitle={titlePage} /> */}

              <Header className="site-layout-background" style={{ height: 50 }}>
                <Row style={{ backgroundColor: "white" }}>
                  <Col span={12}>
                    <Media query="(max-width: 1024px)">
                      {(matches) =>
                        matches
                          ? React.createElement(MenuFoldOutlined, {
                            className: "trigger",
                            onClick: showDrawer,
                          })
                          : React.createElement(
                            collapsed ? MenuFoldOutlined : MenuUnfoldOutlined,
                            { className: "trigger", onClick: toggle }
                          )
                      }
                    </Media>
                    <label style={{ fontSize: 14 }}>{titlePage}</label>
                  </Col>
                  <Col span={12} style={{ textAlign: "end" }}>
                    <LanguageSelector
                      onHandelLang={(lang) => {
                        handelTitleLang(lang);
                      }}
                    />
                  </Col>
                </Row>
              </Header>
            </>
          )}

          <Content
            className={
              !user
                ? "login-site-layout-background log-wave"
                : "site-layout-background"
            }
            style={
              !user
                ? {
                  margin: "0px",
                  padding: 0,
                  boxSizing: "border-box",
                  zIndex: 2,
                }
                : {
                  paddingTop: 10,
                  paddingLeft: 24,
                  paddingRight: 24,
                  paddingBottom: 24,
                  minHeight: 280,
                }
            }
          >
            <Switch>
              <ProdtectedRoute
                path="/dashboard"
                render={(props) => {
                  clear();
                  setTitlePage(_t("strDashboard"));
                  return <PageDashboard />;
                }}
              />

              <ProdtectedRoute
                path="/dashboardTemp/:id"
                render={(props) => {
                  clear();
                  setTitlePage(_t("strAnalysisTemp"));
                  return <FormDashboardTemp />;
                }}
              />

              <ProdtectedRoute
                path="/dashboardTemp"
                render={(props) => {
                  clear();
                  setTitlePage(_t("strAnalysisTemp"));
                  return <PageDashboardTemp />;
                }}
              />

              <ProdtectedRoute
                path="/company"
                render={(props) => {
                  clear();
                  setTitlePage(_t("strCompany"));
                  return <FormCompany />;
                }}
              />

              <ProdtectedRoute
                path="/branches/:id"
                render={(props) => {
                  clear();
                  setTitlePage(_t("strBranches"));
                  return <FormBranches />;
                }}
              />
              <ProdtectedRoute
                path="/branches"
                render={(props) => {
                  clear();
                  setTitlePage(_t("strBranches"));
                  return <PageBranches />;
                }}
              />

              <ProdtectedRoute
                path="/inventoryMatching/:id"
                render={(props) => {
                  clear();
                  setTitlePage(_t("strInventoryMatching"));
                  return <FormInventoryMatching />;
                }}
              />
              <ProdtectedRoute
                path="/inventoryMatching"
                render={(props) => {
                  clear();
                  setTitlePage(_t("strInventoryMatching"));
                  return <PageInventoryMatching />;
                }}
              />

              <ProdtectedRoute
                path="/stores/:id"
                render={(props) => {
                  clear();
                  setTitlePage(_t("strStores"));
                  return <FormStores />;
                }}
              />
              <ProdtectedRoute
                path="/stores"
                render={(props) => {
                  clear();
                  setTitlePage(_t("strStores"));
                  return <PageStores />;
                }}
              />

              <ProdtectedRoute
                path="/item/:id"
                render={(props) => {
                  clear();
                  setTitlePage(_t("strItems"));
                  return <FormItem />;
                }}
              />
              <ProdtectedRoute
                path="/item"
                render={(props) => {
                  clear();
                  setTitlePage(_t("strItems"));
                  return <PageItem />;
                }}
              />

              <ProdtectedRoute
                path="/unit/:id"
                render={(props) => {
                  clear();
                  setTitlePage(_t("strUnits"));
                  return <FormUnit />;
                }}
              />
              <ProdtectedRoute
                path="/unit"
                render={(props) => {
                  clear();
                  setTitlePage(_t("strUnits"));
                  return <PageUnit />;
                }}
              />

              <ProdtectedRoute
                path="/transaction/:id"
                render={(props) => (
                  <FormTransaction
                    onHandel={(data) => {
                      transactionTitle(data);
                    }}
                  />
                )}
              />

              <ProdtectedRoute
                path="/manufacturingModel/:id"
                render={(props) => {
                  clear();
                  setTitlePage(_t("strManufacturingModel"));
                  return <ManufacturingModelsForm />;
                }}
              />
              <ProdtectedRoute
                path="/manufacturingModel"
                render={(props) => {
                  clear();
                  setTitlePage(_t("strManufacturingModel"));
                  return <PageManufacturingModels />;
                }}
              />

              <ProdtectedRoute
                path="/manufacturingOrder/:id"
                render={(props) => {
                  clear();
                  setTitlePage(_t("strManufacturingOrder"));
                  return <ManufacturingOrdersForm />;
                }}
              />

              <ProdtectedRoute
                path="/clientsSupp/:id"
                render={(props) => {
                  clear();
                  setTitlePage(_t("strClientsSupp"));
                  return <FormClientsSupp />;
                }}
              />
              <ProdtectedRoute
                path="/clientsSupp"
                render={(props) => {
                  clear();
                  setTitlePage(_t("strClientsSupp"));
                  return <PageClientsSupp />;
                }}
              />

              <ProdtectedRoute
                path="/StockReport"
                render={(props) => {
                  clear();
                  setTitlePage(_t("strStockReport"));
                  return <PageInventoryReport />;
                }}
              />

              <ProdtectedRoute
                path="/ItemMovementReport"
                render={(props) => {
                  clear();
                  setTitlePage(_t("strItemMovementReport"));
                  return <PageItemMovementReport />;
                }}
              />

              <ProdtectedRoute
                path="/StoreTransactionReport"
                render={(props) => {
                  clear();
                  setTitlePage(_t("strStoreMovementReport"));
                  return <PageStoreTransactionReport />;
                }}
              />

              <ProdtectedRoute
                path="/OrdersReport"
                render={(props) => {
                  clear();
                  setTitlePage(_t("strOrdersReport"));
                  return <PageOrdersReport />;
                }}
              />

              <ProdtectedRoute
                path="/ManufacturingOrdersReport"
                render={(props) => {
                  clear();
                  setTitlePage(_t("strManufacturingOrdersReport"));
                  return <PageMunfReport />;
                }}
              />

              <ProdtectedRoute
                path="/users/:id"
                render={(props) => {
                  clear();
                  setTitlePage(_t("strUsers"));
                  return <FormUsers />;
                }}
              />
              <ProdtectedRoute
                path="/users"
                render={(props) => {
                  clear();
                  setTitlePage(_t("strUsers"));
                  return <PageUsers />;
                }}
              />

              <ProdtectedRoute
                path="/roles/:id"
                render={(props) => {
                  clear();
                  setTitlePage(_t("strRoles"));
                  return <FormRoles />;
                }}
              />
              <ProdtectedRoute
                path="/roles"
                render={(props) => {
                  clear();
                  setTitlePage(_t("strRoles"));
                  return <PageRoles />;
                }}
              />

              <ProdtectedRoute
                path="/dashboardRoles/:id"
                render={(props) => {
                  clear();
                  setTitlePage(_t("strRoles"));
                  return <FormDashboardRoles />;
                }}
              />
              <ProdtectedRoute
                path="/dashboardRoles"
                render={(props) => {
                  clear();
                  setTitlePage(_t("strRoles"));
                  return <PageDashboardRoles />;
                }}
              />

              <ProdtectedRoute
                path="/changePassword"
                render={(props) => {
                  clear();
                  setTitlePage(_t("strChangePassword"));
                  return <ChangePassword />;
                }}
              />

              {/* <ProdtectedRoute
                path="/editableTable"
                render={(props) => {
                  clear();
                  setTitlePage(_t("strEdit"));
                  return <PageEditableTable />;
                }}
              /> */}


              {!user && (
                <Route exact path="/login">
                  <Login />
                </Route>
              )}

              <Route exact path="/logout">
                <Logout />
              </Route>

              <Redirect from="/" exact to={user ? homePath : "/login"} />

              <Redirect to={user ? "/not-found" : "/login"} />
            </Switch>
            <BackTop>
              <div className="backTop">
                <UpOutlined />
              </div>
            </BackTop>
          </Content>
          {!user ? (
            <div class="area">
              <ul class="circles">
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
              </ul>{" "}
            </div>
          ) : (
            <></>
          )}
          {user && (
            <>
              {demo && (
                <Footer
                  style={{
                    textAlign: "center",
                    paddingTop: "12px",
                    paddingBottom: "15px",
                    backgroundColor: "yellow",
                  }}
                >
                  <span style={{ color: "red", fontWeight: "bold" }}>
                    {virsion} - {_t("strDemo")}
                  </span>
                </Footer>
              )}
            </>
          )}
        </Layout>
      </Layout>
    </LanguageProvider>
  );
};
export default App;
