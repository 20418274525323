import React, { useState, useEffect, useRef } from "react";
import { permissions } from "../../../services/Authorization/authService";
import moment from "moment";
import { Prompt } from "react-router";
import { useHistory, useParams, useLocation } from "react-router-dom";
import {
  Form,
  Spin,
  Row,
  Col,
  Button,
  Input,
  Select,
  DatePicker,
  Popconfirm,
  InputNumber,
  Card,
  Space,
  Tabs,
  Modal,
  message,
  Drawer,
  Divider,
} from "antd";
import {
  getNewManufacturingOrdersCode,
  getManufacturingOrdersById,
  editManufacturingOrders,
  deleteManufacturingOrders,
  searchMunf,
  getNext,
  getPrev,
  searchByCode,
  searchByDocument,
} from "../../../services/Items/manufacturingOrdersService";
import { getAllChildren } from "../../../services/storeService";
import Printing from "../../helper/Print/Printing";
import { searchItem } from "../../../services/Items/itemService";
import {
  DeleteTwoTone,
  PlusOutlined,
  ExclamationCircleOutlined,
  LeftOutlined,
  RightOutlined,
  EditTwoTone,
} from "@ant-design/icons";
import InvItemListSelectorForm from "./../../helper/Modal/InvItemListSelector";
import MunfTemplateListSelectorForm from "./../../helper/Modal/munfTemplateListSelector";
import MunfEditInOutForm from "./../../helper/Modal/munf_Edit_IN_OUT";
import MunfOrderSelectorForm from "./../../helper/Modal/munfOrderSelector";
import _t from "../../../languages/translate";
import "moment/locale/ar-ly";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}



const ManufacturingOrdersForm = () => {
  const [proChange, setProChange] = useState(false);
  const [loadData, setLoadData] = useState(true);
  const [deleteDis, setDeleteDis] = useState(true);
  const [form] = Form.useForm();
  const IX_Code = useRef(null);
  const toStoreInput = useRef(null);
  const { Option } = Select;
  let history = useHistory();
  const { TextArea, Search } = Input;
  const { confirm } = Modal;
  const { TabPane } = Tabs;
  let query = useQuery();
  //******************************//
  const [companyId] = useState(window.localStorage.getItem("comId"));
  const [toStore, setToStore] = useState(false);
  const [listState_IN, setListState_IN] = useState([]);
  const [listState_Out, setListState_Out] = useState([]);
  const [typeNumber, setTypeNumber] = useState("1");
  //******************************//
  const [storeChildsOpt, setStoreChildsOpt] = useState([]);
  const [searchData_IN, setSearchData_IN] = useState([]);
  const [searchData_Out, setSearchData_Out] = useState([]);

  const [searchIndex, setSearchIndex] = useState();
  const [visibilityMunfOredr, setVisibilityMunfOredr] = useState(false);
  const [visibilityMunfEditInOut, setVisibilityMunfEditInOut] = useState(false);
  const [visibilityMunfList, setVisibilityMunfList] = useState(false);
  const [searchDataMunf, setSearchDataMunf] = useState([]);
  const [manualTabDisabled, setManualTabDisabled] = useState(false);
  const [complexTabDisabled, setComplexTabDisabled] = useState(false);
  const [listStateMnufComplex, setListStateMnufComplex] = useState([]);
  const [stateMnufComplex, setStateMnufComplex] = useState([]);
  const [indexEdit, setIndexEdit] = useState(0);
  const [mUNF, setMUNF] = useState([]);

  const [visibilityInvItemList_IN, setVisibilityInvItemList_IN] = useState(
    false
  );

  const [visibilityInvItemList_Out, setVisibilityInvItemList_Out] = useState(
    false
  );
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const dateFormatList = [
    "DD/MM/YYYY HH:mm",
    "DDMMYYYY HH:mm",
    "DDMMYYYY HHmm",
    "DDMMYYYYHHmm",
    "DDMMYYYY",
    "DDMMYYHHmm",
    "DDMMYY",
  ];

  //******************************//
  let { id } = useParams();
  const [stateStoreId, setStateStoreId] = useState([]);
  //******************************//

  const storeChildsOptList =
    storeChildsOpt.length > 0 &&
    storeChildsOpt.map((item) => (
      <Option key={item.id} value={item.id}>
        {item.name}
      </Option>
    ));

  //******************************// //*******************// Modal List Munf //*******************// //******************************//

  const showModalMunfList = () => {
    setVisibilityMunfList(true);
  };

  const handleCancelMunfList = () => {
    setVisibilityMunfList(false);
  };

  const handleCancelMunfEditInOut = () => {
    setVisibilityMunfEditInOut(false);
  };

  const showModalMunfOrder = () => {
    setVisibilityMunfOredr(true);
  };

  const handleCancelMunfOredr = () => {
    setVisibilityMunfOredr(false);
  };

  //******************************// //*******************// Modal List IN //*******************// //******************************//

  const showModalInvItemList_IN = () => {
    setVisibilityInvItemList_IN(true);
  };

  const handleCancelInvItemList_IN = () => {
    setVisibilityInvItemList_IN(false);
  };

  //******************************// //*******************// Modal List Out //*******************// //******************************//

  const showModalInvItemList_Out = () => {
    setVisibilityInvItemList_Out(true);
  };

  const handleCancelInvItemList_Out = () => {
    setVisibilityInvItemList_Out(false);
  };

  //******************************// //*******************// Initialize Page //*******************// //******************************//

  useEffect(() => {
    const recourdId = query.get("munfId");
    async function fetchData() {
      try {
        setLoadData(true);
        form.resetFields();
        setListState_IN([]);
        setListState_Out([]);
        const { data: storeChildData } = await getAllChildren();
        setStoreChildsOpt(storeChildData.data);

        const selectedStores = [
          ...stateStoreId,
          storeChildData.data[0].id,
          storeChildData.data[1].id,
        ];

        setStateStoreId(selectedStores);

        form.setFieldsValue({
          munfDetails: [{}],
          munfDetailManualIns: [{}],
          munfDetailManualOuts: [{}],
        });

        codeGenerator();
        initPermissions(true, 1); // Set bool if new record or not.
        setProChange(false);
        setLoadData(false);



        if (recourdId !== null) {
          const { data: data } = await getManufacturingOrdersById(recourdId);
          setTypeNumber(data.data.type.toString());
          if (data.data.type === 1) {
            setComplexTabDisabled(false);
            setManualTabDisabled(true);
          } else if (data.data.type === 2) {
            setManualTabDisabled(false);
            setComplexTabDisabled(true);
          }
          form.setFieldsValue({
            id: data.data.id,
            code: data.data.code,
            type: data.data.type,
            documentCode: data.data.documentCode,
            fromStoreId: data.data.fromStoreId,
            toStoreId: data.data.toStoreId,
            munfDate: data.data.munfDate !== null ? moment(data.data.munfDate) : "",
            note: data.data.note,
            munfDetails: data.data.munfDetails,
            munfDetailManualIns: data.data.munfDetailManualIns,
            munfDetailManualOuts: data.data.munfDetailManualOuts,
          });
          setListStateMnufComplex(data.data.munfDetails);
          setListState_IN(data.data.munfDetailManualIns);
          setListState_Out(data.data.munfDetailManualOuts);
          initPermissions(false, data.data.type); // Set bool if new record or not, Set type of record.
          setDeleteDis(false);
        }


      } catch (error) {
        if (error.response) {
          message.error(
            error.response.data?.message || _t("msgUnknownError"),
            3
          );
          setLoadData(false);
        } else if (error.request) {
          message.error(_t("msgServerDisconnected"), 3);
          setLoadData(false);
        } else {
          message.error(_t("msgUnknownError"), 3);
          setLoadData(false);
        }
      }
    }
    fetchData();
  }, [id, setToStore, setStoreChildsOpt]);

  const codeGenerator = async () => {
    try {
      const { data: newData } = await getNewManufacturingOrdersCode(id);

      form.setFieldsValue({ code: newData.data });
      setLoadData(false);
    } catch (error) {
      if (error.response) {
        message.error(error.response.data?.message || _t("msgUnknownError"), 3);
        setLoadData(false);
      } else if (error.request) {
        message.error(_t("msgServerDisconnected"), 3);
        setLoadData(false);
      } else {
        message.error(_t("msgUnknownError"), 3);
        setLoadData(false);
      }
    }
  };
  const [showSaveButton, setShowSaveButton] = useState(true);
  const [showNewButton, setShowNewButton] = useState(true);
  const [showSimple, setShowSimple] = useState(true);
  const [editMainSimple, setEditMainSimple] = useState(false);
  const [editSimple, setEditSimple] = useState(false);

  const initPermissions = (newRecord, type) => {
    if (newRecord) {
      if (permissions._Munf_Post) {
        setShowSaveButton(true);
        setShowNewButton(true);
      } else {
        setShowSaveButton(false);
        setShowNewButton(false);
      }
    } else {
      if (permissions._Munf_Put) {
        setShowSaveButton(true);
        if (permissions._Munf_Post) {
          setShowNewButton(true);
        } else {
          setShowNewButton(false);
        }
      } else {
        setShowSaveButton(false);
        if (permissions._Munf_Post) {
          setShowNewButton(true);
        } else {
          setShowNewButton(false);
        }
      }
    }

    if (newRecord) {
      if (permissions._MunfSimple_Post) {
        setShowSimple(true);
        setEditSimple(false);
        setEditMainSimple(false);
      } else {
        setShowSimple(false);
        setEditSimple(false);
        setEditMainSimple(false);
      }
    } else {
      if (type === 2) {
        if (permissions._MunfSimple_Put) {
          setShowSimple(true);
          setEditSimple(false);
          setEditMainSimple(false);
        } else {
          setShowSimple(true);
          setEditSimple(true);
          setEditMainSimple(true);
        }
      } else {
        setEditMainSimple(false);
      }
    }
  };

  //******************************// //*******************// Save //*******************// //******************************//

  const onFinish = async (values) => {
    if (values.id === undefined || values.id === null) {
      values.id = "new";
    }
    if (typeNumber === "1") {
      values.type = 1;
      delete values.munfDetailManualIns;
      delete values.munfDetailManualOuts;
      const checkListMunf = onCheckListMunf(values.munfDetails);
      if (checkListMunf) {
        values.munfDetails = checkListMunf;
      } else {
        return;
      }
    }

    if (typeNumber === "2") {
      values.type = 2;
      delete values.munfDetails;
      const checkList_IN = onCheckList_IN(values.munfDetailManualIns);
      const checkList_Out = onCheckList_Out(values.munfDetailManualOuts);

      if (checkList_IN) {
        values.munfDetailManualIns = checkList_IN;
      } else {
        return;
      }

      if (checkList_Out) {
        values.munfDetailManualOuts = checkList_Out;
      } else {
        return;
      }
    }

    values.munfPatternId = id;
    values.companyId = companyId;
    if (values.munfDate !== "") {
      values.munfDate = values.munfDate.toISOString();
    }

    try {
      const { data: response } = await editManufacturingOrders(values);
      message.success(response.message, 3);
      onNew(true);
    } catch (error) {
      console.error(error.response);
      if (error.response?.data?.ex.includes("IX_Code")) {
        IX_Code.current.focus();
        message.error(error.response?.data?.message, 3);
        setLoadData(false);
      } else if (error.response) {
        message.error(error.response.data.message, 3);
        setLoadData(false);
      } else if (error.request) {
        message.error(_t("msgServerDisconnected"), 3);
        setLoadData(false);
      } else {
        message.error(_t("msgUnknownError"), 3);
        setLoadData(false);
      }
    }
  };

  //******************************// //*******************// New //*******************// //******************************//

  const onNew = (onSave) => {
    if (proChange && onSave === false) {
      confirm({
        icon: <ExclamationCircleOutlined />,
        content: _t("msSureToNew"),
        onOk() {
          try {
            setProChange(false);
            form.resetFields();
            codeGenerator();
            initPermissions(true, 1); // Set bool if new record or not, Set type of record.
            setManualTabDisabled(false);
            setComplexTabDisabled(false);
            setListStateMnufComplex([]);
            setListState_IN([]);
            setListState_Out([]);
            setStateMnufComplex([]);
            setListStateMnufComplex([]);

            form.setFieldsValue({
              munfDetails: [{}],
              munfDetailManualIns: [{}],
              munfDetailManualOuts: [{}],
            });
            setTypeNumber("1");
            setDeleteDis(true);
          } catch (error) {
            if (error.response) {
              message.error(
                error.response.data?.message || _t("msgUnknownError"),
                3
              );
              setLoadData(false);
            } else if (error.request) {
              message.error(_t("msgServerDisconnected"), 3);
              setLoadData(false);
            } else {
              message.error(_t("msgUnknownError"), 3);
              setLoadData(false);
            }
          }
        },
        onCancel() { },
      });
    } else {
      setProChange(false);
      form.resetFields();
      codeGenerator();
      initPermissions(true, 1); // Set bool if new record or not, Set type of record.
      setManualTabDisabled(false);
      setComplexTabDisabled(false);

      form.setFieldsValue({
        munfDetails: [{}],
        munfDetailManualIns: [{}],
        munfDetailManualOuts: [{}],
      });
      setListStateMnufComplex([]);
      setListState_IN([]);
      setListState_Out([]);
      setStateMnufComplex([]);
      setListStateMnufComplex([]);
      setTypeNumber("1");
      setDeleteDis(true);
    }
  };

  //******************************// //*******************// Delete //*******************// //******************************//

  const onDelete = async () => {
    try {
      setLoadData(true);
      let _id = form.getFieldValue("id");
      const response = await deleteManufacturingOrders(_id);
      setLoadData(false);
      message.success(`${response.data.message}`, 3);
      onNew(true);
    } catch (error) {
      if (error.response) {
        message.error(error.response.data?.message || _t("msgUnknownError"), 3);
        setLoadData(false);
      } else if (error.request) {
        message.error(_t("msgServerDisconnected"), 3);
        setLoadData(false);
      } else {
        message.error(_t("msgUnknownError"), 3);
        setLoadData(false);
      }
    }
  };

  //******************************// //*******************// Search Main Obj //*******************// //******************************//

  const codeMainSearch = async (value) => {
    if (value !== "" && value !== null && value !== undefined) {
      if (proChange) {
        confirm({
          icon: <ExclamationCircleOutlined />,
          content: _t("msSureToNew"),
          onOk() {
            codeMS(value);
          },
          onCancel() { },
        });
      } else {
        codeMS(value);
      }
    }
  };

  const documentMainSearch = async (value) => {
    if (value !== "" && value !== null && value !== undefined) {
      if (proChange) {
        confirm({
          icon: <ExclamationCircleOutlined />,
          content: _t("msSureToNew"),
          onOk() {
            documentMS(value);
          },
          onCancel() { },
        });
      } else {
        documentMS(value);
      }
    }
  };

  const codeMS = async (value) => {
    try {
      setProChange(false);
      const { data: response } = await searchByCode(id, value);
      setMainSearch(response);
    } catch (error) {
      if (error.response) {
        message.error(error.response.data?.message || _t("msgUnknownError"), 3);
        setLoadData(false);
      } else if (error.request) {
        message.error(_t("msgServerDisconnected"), 3);
        setLoadData(false);
      } else {
        message.error(_t("msgUnknownError"), 3);
        setLoadData(false);
      }
    }
  };

  const documentMS = async (value) => {
    try {
      const { data: response } = await searchByDocument(id, value);
      setMainSearch(response);
    } catch (error) {
      if (error.response) {
        message.error(error.response.data?.message || _t("msgUnknownError"), 3);
        setLoadData(false);
      } else if (error.request) {
        message.error(_t("msgServerDisconnected"), 3);
        setLoadData(false);
      } else {
        message.error(_t("msgUnknownError"), 3);
        setLoadData(false);
      }
    }
  };

  const setMainSearch = (response) => {
    setSearchDataMunf(response.data);
    if (response.data.length > 1) {
      showModalMunfOrder();
    } else if (response.data.length === 1) {
      const list = response.data[0];
      setTypeNumber(list.type.toString());
      if (list.type === 1) {
        setComplexTabDisabled(false);
        setManualTabDisabled(true);
      } else if (list.type === 2) {
        setManualTabDisabled(false);
        setComplexTabDisabled(true);
      }

      form.setFieldsValue({
        id: list.id,
        code: list.code,
        type: list.type,
        documentCode: list.documentCode,
        fromStoreId: list.fromStoreId,
        toStoreId: list.toStoreId,
        munfDate: list.munfDate !== null ? moment(list.munfDate) : "",
        note: list.note,
        munfDetails: list.munfDetails,
        munfDetailManualIns: list.munfDetailManualIns,
        munfDetailManualOuts: list.munfDetailManualOuts,
      });
      setListStateMnufComplex(list.munfDetails);
      setListState_IN(list.munfDetailManualIns);
      setListState_Out(list.munfDetailManualOuts);
      initPermissions(false, list.type); // Set bool if new record or not, Set type of record.
      setDeleteDis(false);
    } else {
      return message.error(_t("msgThereIsNoValue"), 3);
    }
  };

  const handelDataMunfOredr = (data) => {
    setVisibilityMunfOredr(false);
    setTypeNumber(data.type.toString());
    if (data.type === 1) {
      setComplexTabDisabled(false);
      setManualTabDisabled(true);
    } else if (data.type === 2) {
      setManualTabDisabled(false);
      setComplexTabDisabled(true);
    }

    form.setFieldsValue({
      id: data.id,
      code: data.code,
      type: data.type,
      documentCode: data.documentCode,
      fromStoreId: data.fromStoreId,
      toStoreId: data.toStoreId,
      munfDate: data.munfDate !== null ? moment(data.munfDate) : "",
      note: data.note,
      munfDetails: data.munfDetails,
      munfDetailManualIns: data.munfDetailManualIns,
      munfDetailManualOuts: data.munfDetailManualOuts,
    });
    setListStateMnufComplex(data.munfDetails);
    setListState_IN(data.munfDetailManualIns);
    setListState_Out(data.munfDetailManualOuts);
    initPermissions(false, data.type); // Set bool if new record or not, Set type of record.
    setDeleteDis(false);

    // setEditing(false);
    setProChange(false);
  };

  //******************************// //*******************//  Munf Edit Ins Outs//*******************// //******************************//

  const handelDataMunfEditInOut = (data) => {
    setVisibilityMunfEditInOut(false);
    const list = form.getFieldValue("munfDetails");
    list[indexEdit].count = data.count;
    list[indexEdit].munfDetailIns = data.munfDetailIns;
    list[indexEdit].munfDetailOuts = data.munfDetailOuts;

    form.setFieldsValue({ munfDetails: list });
  };

  //******************************// //*******************// Search Munf List //*******************// //******************************//

  const onEditINOUT = (i) => {
    const itemComplex = form.getFieldValue("munfDetails");

    if (itemComplex[i].fromStoreId) {
      setIndexEdit(i);
      setStateMnufComplex(itemComplex[i]);
      showEditINOutPupop();
    } else {
      message.error(_t("msgThereIsNoData"), 3);
    }
  };

  const showEditINOutPupop = () => {
    setVisibilityMunfEditInOut(true);
  };

  const searchMunfObj = {
    code: null,
    isMatchingCode: false,
    name: null,
    isMatchingName: false,
  };

  const onCheckListMunf = (values) => {
    let start = false;
    const removingItemEmpty = values.filter(function (item) {
      return item.munfTemplateId !== undefined;
    });

    if (removingItemEmpty.length === 0) {
      message.error(_t("msgListOrderReq"), 5);
      return false;
    }

    removingItemEmpty.map((items, index) => {
      if (
        items.count === null ||
        items.count === 0 ||
        items.count === undefined
      ) {
        message.error(_t("msgQTYreqOne") + (index + 1) + _t("msgQTYreqTwo"), 3);
        return false;
      }
      start = true;
    });
    if (start) {
      return removingItemEmpty;
    } else {
      return false;
    }
  };

  const codeMunfSearch = async (value, i, add) => {
    try {
      searchMunfObj.code = value;
      const { data: response } = await searchMunf(searchMunfObj);

      response.data.map((_, i) => {
        response.data[i].count = 1;
      });

      setSearchDataMunf(response.data);
      setSearchIndex(i);

      if (response.data.length > 1) {
        showModalMunfList();
      } else if (response.data.length === 1) {
        const newList = form.getFieldValue("munfDetails");
        const OldId = newList[i].id;
        const OldStoreTransactionInId = newList[i].storeTransactionInId;
        const OldStoreTransactionOutId = newList[i].storeTransactionOutId;
        newList.splice(i, 1, ...response.data);

        const fromStoreId = form.getFieldValue("fromStoreId");
        const toStoreId = form.getFieldValue("toStoreId");

        newList[i].fromStoreId =
          fromStoreId !== undefined && fromStoreId !== null
            ? fromStoreId
            : newList[i].fromStoreId;
        newList[i].toStoreId =
          toStoreId !== undefined && fromStoreId !== null
            ? toStoreId
            : newList[i].toStoreId;

        newList[i].id = OldId;
        newList[i].storeTransactionInId = OldStoreTransactionInId;
        newList[i].storeTransactionOutId = OldStoreTransactionOutId;

        form.setFieldsValue({ munfDetails: newList });
        setManualTabDisabled(true);
        setComplexTabDisabled(false);
        setListStateMnufComplex(newList);
        let lastItem = newList?.length - 1;
        if (newList[lastItem].itemId !== 0) {
          add();
        }
        setProChange(true);
      } else {
        if (listStateMnufComplex.length !== 0) {


          debugger;
          form.setFieldsValue({ munfDetails: listStateMnufComplex });
          let lastItem = listStateMnufComplex.length - 1;
          if (listStateMnufComplex[lastItem].itemId !== 0) {
            add();
          }
        }
        return message.error(_t("msgThereIsNoValue"), 3);
      }
    } catch (error) {
      if (error.response !== undefined) {
        if (error.response) {
          message.error(
            error.response.data?.message || _t("msgUnknownError"),
            3
          );
          setLoadData(false);
        } else if (error.request) {
          message.error(_t("msgServerDisconnected"), 3);
          setLoadData(false);
        } else {
          message.error(_t("msgUnknownError"), 3);
          setLoadData(false);
        }
      }
    }
  };

  const nameMunfSearch = async (value, i, add) => {
    try {
      searchMunfObj.name = value;
      const { data: response } = await searchMunf(searchMunfObj);
      response.data.map((_, i) => {
        response.data[i].count = 1;
      });

      setSearchDataMunf(response.data);
      setSearchIndex(i);

      if (response.data.length > 1) {
        showModalMunfList();
      } else if (response.data.length === 1) {
        const newList = form.getFieldValue("munfDetails");

        const OldId = newList[i].id;
        const OldStoreTransactionInId = newList[i].storeTransactionInId;
        const OldStoreTransactionOutId = newList[i].storeTransactionOutId;

        newList.splice(i, 1, ...response.data);

        const fromStoreId = form.getFieldValue("fromStoreId");
        const toStoreId = form.getFieldValue("toStoreId");
        newList[i].fromStoreId =
          fromStoreId !== undefined ? fromStoreId : newList[i].fromStoreId;
        newList[i].toStoreId =
          toStoreId !== undefined ? toStoreId : newList[i].toStoreId;

        newList[i].id = OldId;
        newList[i].storeTransactionInId = OldStoreTransactionInId;
        newList[i].storeTransactionOutId = OldStoreTransactionOutId;

        form.setFieldsValue({ munfDetails: newList });
        setManualTabDisabled(true);
        setComplexTabDisabled(false);
        setListStateMnufComplex(newList);
        let lastItem = newList?.length - 1;
        if (newList[lastItem].itemId !== 0) {
          add();
        }
        setProChange(true);
      } else {
        if (listStateMnufComplex.length !== 0) {
          form.setFieldsValue({ munfDetails: listStateMnufComplex });
          let lastItem = listStateMnufComplex.length - 1;
          if (listStateMnufComplex[lastItem].itemId !== 0) {
            add();
          }
        }
        return message.error(_t("msgThereIsNoValue"), 3);
      }
    } catch (error) {
      if (error.response !== undefined) {
        if (error.response) {
          message.error(
            error.response.data?.message || _t("msgUnknownError"),
            3
          );
          setLoadData(false);
        } else if (error.request) {
          message.error(_t("msgServerDisconnected"), 3);
          setLoadData(false);
        } else {
          message.error(_t("msgUnknownError"), 3);
          setLoadData(false);
        }
      }
    }
  };

  const handelDataMunfList = (data, add) => {
    setVisibilityMunfList(false);

    const fromStoreId = form.getFieldValue("fromStoreId");
    const toStoreId = form.getFieldValue("toStoreId");
    data.map((_, i) => {
      data[i].fromStoreId =
        fromStoreId !== undefined ? fromStoreId : data[i].fromStoreId;
      data[i].toStoreId =
        toStoreId !== undefined ? toStoreId : data[i].toStoreId;
    });

    const newList = form.getFieldValue("munfDetails");
    const OldId = newList[searchIndex]?.id;
    const OldStoreTransactionInId = newList[searchIndex]?.storeTransactionInId;
    const OldStoreTransactionOutId = newList[searchIndex]?.storeTransactionOutId;

    newList.splice(searchIndex, 1, ...data);

    newList[searchIndex].id = OldId;
    newList[searchIndex].storeTransactionInId = OldStoreTransactionInId;
    newList[searchIndex].storeTransactionOutId = OldStoreTransactionOutId;

    form.setFieldsValue({ munfDetails: newList });
    setMUNF(newList);
    let lastItem = newList.length - 1;
    if (newList[lastItem]?.id !== 0) {
      add();
    }
    setListStateMnufComplex(newList);
    setProChange(true);
  };

  //******************************// //*******************// Search List IN//*******************// //******************************//

  const onCheckList_IN = (values) => {
    let start = false;
    const removingItemEmpty = values.filter(function (item) {
      return item.itemId !== undefined;
    });

    if (removingItemEmpty.length === 0) {
      message.error(_t("msgListOrderReq"), 5);
      return false;
    }

    removingItemEmpty.map((items, index) => {
      if (items.qty === null || items.qty === 0 || items.qty === undefined) {
        message.error(_t("msgQTYreqOne") + (index + 1) + _t("msgQTYreqTwo"), 3);
        return false;
      }
      start = true;
    });
    if (start) {
      return removingItemEmpty;
    } else {
      return false;
    }
  };

  const codeSearch_IN = async (value, i, add) => {
    try {
      const { data: response } = await searchItem(value, "", false);
      setSearchData_IN(response.data);
      setSearchIndex(i);
      if (response.data.length > 1) {
        showModalInvItemList_IN();
      } else if (response.data.length === 1) {
        const newList = form.getFieldValue("munfDetailManualIns");
        const OldId = newList[i].id;
        response.data.map((_, i) => {
          response.data[i].itemUnit_MapId =
            response.data[i].itemUnit_Maps[0].id;
          response.data[i].itemId = response.data[i].id;
          response.data[i].itemCode = response.data[i].code;
          response.data[i].itemName = response.data[i].name;
          response.data[i].qty = "1";
          response.data[i].note = "";
          delete response.data[i].id;
        });
        newList.splice(i, 1, ...response.data);
        newList[i].id = OldId;

        form.setFieldsValue({ munfDetailManualIns: newList });
        setManualTabDisabled(false);
        setComplexTabDisabled(true);
        setListState_IN(newList);
        let lastItem = newList?.length - 1;
        if (newList[lastItem].itemId !== 0) {
          add();
        }
        setProChange(true);
      } else {
        if (listState_IN.length !== 0) {
          form.setFieldsValue({ munfDetailManualIns: listState_IN });
          setManualTabDisabled(false);
          setComplexTabDisabled(true);
          let lastItem = listState_IN.length - 1;
          if (listState_IN[lastItem].itemId !== 0) {
            add();
          }
        }
        return message.error(_t("msgThereIsNoValue"), 3);
      }
    } catch (error) {
      if (error.response !== undefined) {
        if (error.response) {
          message.error(
            error.response.data?.message || _t("msgUnknownError"),
            3
          );
          setLoadData(false);
        } else if (error.request) {
          message.error(_t("msgServerDisconnected"), 3);
          setLoadData(false);
        } else {
          message.error(_t("msgUnknownError"), 3);
          setLoadData(false);
        }
      }
    }
  };

  const nameSearch_IN = async (value, i, add) => {
    try {
      const { data: response } = await searchItem("", value, false);
      setSearchData_IN(response.data);
      setSearchIndex(i);
      if (response.data.length > 1) {
        showModalInvItemList_IN();
      } else if (response.data.length === 1) {
        const newList = form.getFieldValue("munfDetailManualIns");
        const OldId = newList[i].id;

        response.data.map((_, i) => {
          response.data[i].itemUnit_MapId =
            response.data[i].itemUnit_Maps[0].id;
          response.data[i].itemId = response.data[i].id;
          response.data[i].itemCode = response.data[i].code;
          response.data[i].itemName = response.data[i].name;
          response.data[i].qty = "1";
          response.data[i].note = "";
          delete response.data[i].id;
        });

        newList.splice(i, 1, ...response.data);

        newList[i].id = OldId;

        form.setFieldsValue({ munfDetailManualIns: newList });
        setManualTabDisabled(false);
        setComplexTabDisabled(true);
        setListState_IN(newList);
        let lastItem = newList.length - 1;
        if (newList[lastItem].itemId !== 0) {
          add();
        }
        setProChange(true);
      } else {
        if (listState_IN.length !== 0) {
          form.setFieldsValue({ munfDetailManualIns: listState_IN });
          setManualTabDisabled(false);
          setComplexTabDisabled(true);
          let lastItem = listState_IN.length - 1;
          if (listState_IN[lastItem].itemId !== 0) {
            add();
          }
        }
        return message.error(_t("msgThereIsNoValue"), 3);
      }
    } catch (error) {
      if (error.response) {
        if (error.response) {
          message.error(
            error.response.data?.message || _t("msgUnknownError"),
            3
          );
          setLoadData(false);
        } else if (error.request) {
          message.error(_t("msgServerDisconnected"), 3);
          setLoadData(false);
        } else {
          message.error(_t("msgUnknownError"), 3);
          setLoadData(false);
        }
      }
    }
  };

  const handelDataInvItemList_IN = (data, add) => {
    setVisibilityInvItemList_IN(false);
    const newList = form.getFieldValue("munfDetailManualIns");
    const OldId = newList[searchIndex]?.id;

    data.map((_, i) => {
      data[i].itemUnit_MapId = data[i].itemUnit_Maps[0].id;
      data[i].itemId = data[i].id;
      data[i].itemCode = data[i].code;
      data[i].itemName = data[i].name;
      data[i].qty = "1";
      data[i].note = "";
      delete data[i].id;
    });

    newList.splice(searchIndex, 1, ...data);

    newList[searchIndex].id = OldId;

    form.setFieldsValue({ munfDetailManualIns: newList });
    setManualTabDisabled(false);
    setComplexTabDisabled(true);
    let lastItem = newList.length - 1;
    if (newList[lastItem]?.itemId !== 0) {
      add();
    }
    setListState_IN(newList);
    setProChange(true);
  };

  //******************************// //*******************// Search List Out//*******************// //******************************//

  const onCheckList_Out = (values) => {
    let start = true;
    let totalDistribution = 0;
    const removingItemEmpty = values.filter(function (item) {
      return item.itemId !== undefined;
    });

    if (removingItemEmpty.length === 0) {
      message.error(_t("msgListOrderReq"), 5);
      start = false;
    }
    if (start) {
      removingItemEmpty.map((items, index) => {
        if (items.qty === null || items.qty === "" || items.qty === undefined) {
          message.error(
            _t("msgQTYreqOne") + (index + 1) + _t("msgQTYreqTwo"),
            3
          );
          start = false;
        }
      });
    }
    if (start) {
      removingItemEmpty.map((items, index) => {
        totalDistribution = totalDistribution + items.distributionRatio;
        if (
          items.distributionRatio === null ||
          items.distributionRatio === 0 ||
          items.distributionRatio === undefined
        ) {
          message.error(
            _t("msgDistributionReqOne") +
            (index + 1) +
            _t("msgDistributionReqTwo"),
            5
          );
          start = false;
        }
      });
    }
    if (start) {
      if (totalDistribution > 100 || totalDistribution < 100) {
        message.error(
          _t("msgDistributionRatio") +
          " " +
          totalDistribution +
          "%" +
          " " +
          _t("msgDistributionRatio2"),
          5
        );
        start = false;
      }
    }

    if (start) {
      return removingItemEmpty;
    } else {
      return false;
    }
  };

  const codeSearch_Out = async (value, i, add) => {
    try {
      const { data: response } = await searchItem(value, "", false);

      setSearchData_Out(response.data);
      setSearchIndex(i);
      if (response.data.length > 1) {
        showModalInvItemList_Out();
      } else if (response.data.length === 1) {
        const newList = form.getFieldValue("munfDetailManualOuts");
        const OldId = newList[i].id;

        response.data.map((_, i) => {
          response.data[i].itemUnit_MapId =
            response.data[i].itemUnit_Maps[0].id;
          response.data[i].itemId = response.data[i].id;
          response.data[i].itemCode = response.data[i].code;
          response.data[i].itemName = response.data[i].name;
          response.data[i].qty = "1";
          response.data[i].note = "";
          delete response.data[i].id;
        });
        newList.splice(i, 1, ...response.data);

        newList[i].id = OldId;

        form.setFieldsValue({ munfDetailManualOuts: newList });
        setManualTabDisabled(false);
        setComplexTabDisabled(true);
        setListState_Out(newList);

        let lastItem = newList?.length - 1;
        if (newList[lastItem].itemId !== 0) {
          add();
        }
        setProChange(true);
      } else {
        if (listState_Out.length !== 0) {
          form.setFieldsValue({ munfDetailManualOuts: listState_Out });

          let lastItem = listState_Out.length - 1;
          if (listState_Out[lastItem].itemId !== 0) {
            add();
          }
        }
        return message.error(_t("msgThereIsNoValue"), 3);
      }
    } catch (error) {
      if (error.response !== undefined) {
        if (error.response) {
          message.error(
            error.response.data?.message || _t("msgUnknownError"),
            3
          );
          setLoadData(false);
        } else if (error.request) {
          message.error(_t("msgServerDisconnected"), 3);
          setLoadData(false);
        } else {
          message.error(_t("msgUnknownError"), 3);
          setLoadData(false);
        }
      }
    }
  };

  const nameSearch_Out = async (value, i, add) => {
    try {
      const { data: response } = await searchItem("", value, false);
      setSearchData_Out(response.data);
      setSearchIndex(i);
      if (response.data.length > 1) {
        showModalInvItemList_Out();
      } else if (response.data.length === 1) {
        const newList = form.getFieldValue("munfDetailManualOuts");
        const OldId = newList[i].id;

        response.data.map((_, i) => {
          response.data[i].itemUnit_MapId =
            response.data[i].itemUnit_Maps[0].id;
          response.data[i].itemId = response.data[i].id;
          response.data[i].itemCode = response.data[i].code;
          response.data[i].itemName = response.data[i].name;
          response.data[i].qty = "1";
          response.data[i].note = "";
          delete response.data[i].id;
        });
        newList.splice(i, 1, ...response.data);
        newList[i].id = OldId;

        form.setFieldsValue({ munfDetailManualOuts: newList });
        setManualTabDisabled(false);
        setComplexTabDisabled(true);
        setListState_Out(newList);
        let lastItem = newList.length - 1;
        if (newList[lastItem].itemId !== 0) {
          add();
        }
        setProChange(true);
      } else {
        if (listState_Out.length !== 0) {
          form.setFieldsValue({ munfDetailManualOuts: listState_Out });
          let lastItem = listState_Out.length - 1;
          if (listState_Out[lastItem].itemId !== 0) {
            add();
          }
        }
        return message.error(_t("msgThereIsNoValue"), 3);
      }
    } catch (error) {
      if (error.response) {
        if (error.response) {
          message.error(
            error.response.data?.message || _t("msgUnknownError"),
            3
          );
          setLoadData(false);
        } else if (error.request) {
          message.error(_t("msgServerDisconnected"), 3);
          setLoadData(false);
        } else {
          message.error(_t("msgUnknownError"), 3);
          setLoadData(false);
        }
      }
    }
  };

  const handelDataInvItemList_Out = (data, add) => {
    setVisibilityInvItemList_Out(false);
    const newList = form.getFieldValue("munfDetailManualOuts");
    const OldId = newList[searchIndex]?.id;

    data.map((_, i) => {
      data[i].itemUnit_MapId = data[i].itemUnit_Maps[0].id;
      data[i].itemId = data[i].id;
      data[i].itemCode = data[i].code;
      data[i].itemName = data[i].name;
      data[i].qty = "1";
      data[i].note = "";
      delete data[i].id;
    });
    newList.splice(searchIndex, 1, ...data);
    newList[searchIndex].id = OldId;

    form.setFieldsValue({ munfDetailManualOuts: newList });
    setManualTabDisabled(false);
    setComplexTabDisabled(true);
    let lastItem = newList.length - 1;
    if (newList[lastItem]?.itemId !== 0) {
      add();
    }
    setListState_Out(newList);
    setProChange(true);
  };

  //******************************// //*******************// List State//*******************// //******************************//

  const onDeleteList = (i, remove, typeList) => {
    remove(i);
    if (typeList == "In") {
      const newList = form.getFieldValue("munfDetailManualIns");
      setListState_IN(newList);
    } else {
      const newList = form.getFieldValue("munfDetailManualOuts");
      setListState_Out(newList);
    }
  };

  const onChangelist_In = () => {
    const newList = form.getFieldValue("munfDetailManualIns");
    setListState_IN(newList);
  };

  const onChangelist_Out = () => {
    const newList = form.getFieldValue("munfDetailManualOuts");
    setListState_Out(newList);
  };

  //******************************// //*******************// Next & Prev //*******************// //******************************//

  const onClickNext = async () => {
    if (proChange) {
      confirm({
        icon: <ExclamationCircleOutlined />,
        content: _t("msSureToNew"),
        onOk() {
          try {
            setProChange(false);
            nextAction();
          } catch (error) {
            if (error.response) {
              message.error(
                error.response.data?.message || _t("msgUnknownError"),
                3
              );
              setLoadData(false);
            } else if (error.request) {
              message.error(_t("msgServerDisconnected"), 3);
              setLoadData(false);
            } else {
              message.error(_t("msgUnknownError"), 3);
              setLoadData(false);
            }
          }
        },
        onCancel() { },
      });
    } else {
      nextAction();
    }
  };

  const nextAction = async () => {
    try {
      const objId = form.getFieldValue("id");
      const { data: response } = await getNext(
        id,
        objId === undefined ? "" : objId
      );

      if (response.data !== null) {
        setTypeNumber(response.data.type.toString());
        form.setFieldsValue({
          id: response.data.id,
          code: response.data.code,
          type: response.data.type,
          documentCode: response.data.documentCode,
          fromStoreId: response.data.fromStoreId,
          toStoreId: response.data.toStoreId,
          munfDate:
            response.data.munfDate !== null
              ? moment(response.data.munfDate)
              : "",
          note: response.data.note,
          munfDetails: response.data.munfDetails,
          munfDetailManualIns: response.data.munfDetailManualIns,
          munfDetailManualOuts: response.data.munfDetailManualOuts,
        });
        if (response.data.type === 1) {
          setComplexTabDisabled(false);
          setManualTabDisabled(true);
        } else if (response.data.type === 2) {
          setComplexTabDisabled(true);
          setManualTabDisabled(false);
        }
        initPermissions(false, response.data.type); // Set bool if new record or not, Set type of record.
        setDeleteDis(false);
      }
    } catch (error) {
      if (error.response) {
        message.error(error.response.data?.message || _t("msgUnknownError"), 3);
        setLoadData(false);
      } else if (error.request) {
        message.error(_t("msgServerDisconnected"), 3);
        setLoadData(false);
      } else {
        message.error(_t("msgUnknownError"), 3);
        setLoadData(false);
      }
    }
  };

  const onClickPrev = async () => {
    if (proChange) {
      confirm({
        icon: <ExclamationCircleOutlined />,
        content: _t("msSureToNew"),
        onOk() {
          try {
            setProChange(false);
            prevAction();
          } catch (error) {
            if (error.response) {
              message.error(
                error.response.data?.message || _t("msgUnknownError"),
                3
              );
              setLoadData(false);
            } else if (error.request) {
              message.error(_t("msgServerDisconnected"), 3);
              setLoadData(false);
            } else {
              message.error(_t("msgUnknownError"), 3);
              setLoadData(false);
            }
          }
        },
        onCancel() { },
      });
    } else {
      prevAction();
    }
  };

  const prevAction = async () => {
    try {
      const objId = form.getFieldValue("id");
      const { data: response } = await getPrev(
        id,
        objId === undefined ? "" : objId
      );
      if (response.data !== null) {
        setTypeNumber(response.data.type.toString());
        form.setFieldsValue({
          id: response.data.id,
          type: response.data.type,
          code: response.data.code,
          documentCode: response.data.documentCode,
          fromStoreId: response.data.fromStoreId,
          toStoreId: response.data.toStoreId,
          munfDate:
            response.data.munfDate !== null
              ? moment(response.data.munfDate)
              : "",
          note: response.data.note,
          munfDetails: response.data.munfDetails,
          munfDetailManualIns: response.data.munfDetailManualIns,
          munfDetailManualOuts: response.data.munfDetailManualOuts,
        });
        if (response.data.type === 1) {
          setComplexTabDisabled(false);
          setManualTabDisabled(true);
        } else if (response.data.type === 2) {
          setComplexTabDisabled(true);
          setManualTabDisabled(false);
        }
        initPermissions(false, response.data.type); // Set bool if new record or not, Set type of record.
        setDeleteDis(false);
      }
    } catch (error) {
      if (error.response) {
        message.error(error.response.data?.message || _t("msgUnknownError"), 3);
        setLoadData(false);
      } else if (error.request) {
        message.error(_t("msgServerDisconnected"), 3);
        setLoadData(false);
      } else {
        message.error(_t("msgUnknownError"), 3);
        setLoadData(false);
      }
    }
  };
  const onCloseDrawer = () => {
    setVisibleDrawer(false);
  };
  //******************************// //*******************// Form //*******************// //******************************//

  return (
    <React.Fragment>
      <Prompt when={proChange} message={_t("strUnsavedChanges")} />

      <Drawer width={720} onClose={onCloseDrawer} visible={visibleDrawer} title={_t("strPrintPreview")}>
        <Printing id={form.getFieldValue("id")} sender="Munf" />
      </Drawer>

      <Modal
        visible={visibilityMunfOredr}
        onCancel={() => handleCancelMunfOredr()}
        footer={null}
        destroyOnClose={true}
        width={1000}
      >
        <br />
        <MunfOrderSelectorForm
          onHandelData={(data) => handelDataMunfOredr(data)}
          onSearchData={searchDataMunf}
        />
      </Modal>

      <div>
        <Spin spinning={loadData} tip={_t("strLoading")}>
          <Form
            form={form}
            name="mainform"
            onFinish={onFinish}
            layout="vertical"
            initialValues={{ munfDate: moment() }}
          >
            <Row gutter={12} className="frHeader">
              <Form.Item name="id" hidden>
                <Input />
              </Form.Item>
              <Form.Item name="type" hidden>
                <Input />
              </Form.Item>

              <Col xs={24} md={12} lg={6}>
                <Form.Item
                  name="code"
                  label={_t("strCode")}
                  rules={[
                    { required: true, message: `${_t("strIsRequired")}` },
                    { min: 1, message: `${_t("strFrom3-200")}` },
                    { max: 200, message: `${_t("strFrom3-200")}` },
                  ]}
                >
                  <Search
                    size="small"
                    placeholder={_t("strCode")}
                    autoComplete="off"
                    maxLength={200}
                    onSearch={(value) => codeMainSearch(value, false)}
                    ref={IX_Code}
                    suffix={
                      _t("strName") !== "Name" ? (
                        <LeftOutlined
                          style={{ color: "#b3b3b3" }}
                          onClick={() => onClickNext()}
                        />
                      ) : (
                        <RightOutlined
                          style={{ color: "#b3b3b3" }}
                          onClick={() => onClickNext()}
                        />
                      )
                    }
                    prefix={
                      _t("strName") !== "Name" ? (
                        <RightOutlined
                          style={{ color: "#b3b3b3" }}
                          onClick={() => onClickPrev()}
                        />
                      ) : (
                        <LeftOutlined
                          style={{ color: "#b3b3b3" }}
                          onClick={() => onClickPrev()}
                        />
                      )
                    }
                  />
                </Form.Item>

              </Col>
              <Col xs={24} md={12} lg={6}>
                <Form.Item
                  name="documentCode"
                  label={_t("strDocument")}
                  rules={[
                    { required: false, message: `${_t("strIsRequired")}` },
                    { min: 1, message: `${_t("strFrom3-200")}` },
                    { max: 200, message: `${_t("strFrom3-200")}` },
                  ]}
                >
                  <Search
                    size="small"
                    placeholder={_t("strDocument")}
                    autoComplete="off"
                    maxLength={200}
                    onSearch={(value) => documentMainSearch(value)}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} md={12} lg={6}>
                <Form.Item
                  name="fromStoreId"
                  label={_t("strFromStore")}
                  rules={[
                    {
                      required: complexTabDisabled,
                      message: `${_t("strIsRequired")}`,
                    },
                  ]}
                >
                  <Select
                    size="small"
                    disabled={editMainSimple}
                    placeholder={_t("strFromStore")}
                    autoComplete="off"
                    onChange={() => {
                      setProChange(true);
                    }}
                    showSearch
                    allowClear
                    optionFilterProp="children"
                  >
                    {storeChildsOptList}
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} md={12} lg={6}>
                <Form.Item
                  name="toStoreId"
                  label={_t("strToStore")}
                  rules={[
                    {
                      required: complexTabDisabled,
                      message: `${_t("strIsRequired")}`,
                    },
                  ]}
                >
                  <Select
                    size="small"
                    disabled={editMainSimple}
                    placeholder={_t("strToStore")}
                    autoComplete="off"
                    ref={toStoreInput}
                    onChange={() => {
                      setProChange(true);
                    }}
                    showSearch
                    allowClear
                    optionFilterProp="children"
                  >
                    {storeChildsOptList}
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} md={12} lg={6}>
                <Form.Item
                  name="munfDate"
                  label={_t("strDate")}
                  rules={[
                    { required: true, message: `${_t("strIsRequired")}` },
                  ]}
                >
                  <DatePicker
                    size="small"
                    disabled={editMainSimple}
                    allowClear={false}
                    showTime={{ format: "HH:mm" }}
                    format={dateFormatList}
                    className="dateTimeStyle"
                    onChange={() => {
                      setProChange(true);
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} lg={6}>
                <Form.Item
                  name="note"
                  label={_t("strNote")}
                  rules={[
                    { required: false, message: `${_t("strIsRequired")}` },
                    { min: 1, message: `${_t("strFrom1-1000")}` },
                    { max: 1000, message: `${_t("strFrom1-1000")}` },
                  ]}
                >
                  <TextArea
                    size="small"
                    disabled={editMainSimple}
                    placeholder={_t("strNote")}
                    maxLength={1000}
                    autoComplete="off"
                    onChange={() => {
                      setProChange(true);
                    }}
                    autoSize={{ minRows: 1, maxRows: 3 }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={12} className="frTabX">
              <Tabs
                activeKey={typeNumber}
                type="card"
                style={{ width: "100%" }}
                onChange={(e) => {
                  setTypeNumber(e);
                }}
              >
                <TabPane
                  tab={_t("strComplex")}
                  key="1"
                  disabled={complexTabDisabled}
                >
                  <Col xs={24} md={24} style={{ width: "100%" }}>
                    <Form.Item>
                      <Card bodyStyle={{ padding: 10 }} className="scrollCard">
                        <Form.List name="munfDetails">
                          {(fields, { add, remove }) => {
                            return (
                              <div>
                                <Modal
                                  visible={visibilityMunfList}
                                  onCancel={() => handleCancelMunfList()}
                                  footer={null}
                                  destroyOnClose={true}
                                  width={1000}
                                >
                                  <br />
                                  <MunfTemplateListSelectorForm
                                    onHandelData={(data) =>
                                      handelDataMunfList(data, add)
                                    }
                                    onSearchData={searchDataMunf}
                                  />
                                </Modal>

                                <Modal
                                  visible={visibilityMunfEditInOut}
                                  onCancel={() => handleCancelMunfEditInOut()}
                                  footer={null}
                                  destroyOnClose={true}
                                  width={1200}
                                >
                                  <MunfEditInOutForm
                                    onHandelData={(data) =>
                                      handelDataMunfEditInOut(data, add)
                                    }
                                    onSelectEditObj={stateMnufComplex}
                                  />
                                </Modal>

                                {fields.map((field, i) => (
                                  <Row gutter={[8, 8]} key={field.key}>
                                    <Col hidden>
                                      <Form.Item
                                        {...field}
                                        defaultValue={0}
                                        name={[field.name, "id"]}
                                        fieldKey={[field.fieldKey, "id"]}
                                        hidden
                                      >
                                        <Input
                                          autoComplete="off"
                                          onChange={() => {
                                            setProChange(true);
                                          }}
                                        />
                                      </Form.Item>
                                    </Col>

                                    <Col hidden>
                                      <Form.Item
                                        {...field}
                                        defaultValue={0}
                                        name={[field.name, "munfTemplateId"]}
                                        fieldKey={[
                                          field.fieldKey,
                                          "munfTemplateId",
                                        ]}
                                        hidden
                                      >
                                        <Input
                                          autoComplete="off"
                                          onChange={() => {
                                            setProChange(true);
                                          }}
                                        />
                                      </Form.Item>
                                    </Col>

                                    <Col xs={1} md={1} sm={1}>
                                      <Form.Item
                                        style={{ textAlign: "center" }}
                                        fieldKey={[field.fieldKey, "rowCount"]}
                                        style={{ marginBottom: -25 }}
                                      >
                                        <label>{i + 1}</label>
                                      </Form.Item>
                                    </Col>

                                    <Col xs={7} md={4} sm={4}>
                                      <Form.Item
                                        {...field}
                                        name={[field.name, "munfTemplateCode"]}
                                        fieldKey={[
                                          field.fieldKey,
                                          "munfTemplateCode",
                                        ]}
                                        style={{ marginBottom: -25 }}
                                      >
                                        <Search
                                          placeholder={_t("strCode")}
                                          size="small"
                                          autoComplete="off"
                                          onFocus={()=>{ setListStateMnufComplex(form.getFieldValue("munfDetails"))}}
                                          onSearch={(v) =>
                                            codeMunfSearch(v, field.name, add)
                                          }
                                        />
                                      </Form.Item>
                                    </Col>

                                    <Col xs={8} md={5} sm={5}>
                                      <Form.Item
                                        {...field}
                                        name={[field.name, "munfTemplateName"]}
                                        fieldKey={[
                                          field.fieldKey,
                                          "munfTemplateName",
                                        ]}
                                        style={{ marginBottom: -25 }}
                                        rules={[
                                          {
                                            required: false,
                                            message: _t("strFieldRequired"),
                                          },
                                        ]}
                                      >
                                        <Search
                                          placeholder={_t("strName")}
                                          autoComplete="off"
                                          size="small"
                                          onFocus={()=>{ setListStateMnufComplex(form.getFieldValue("munfDetails"))}}
                                          onSearch={(v) =>
                                            nameMunfSearch(v, field.name, add)
                                          }
                                        />
                                      </Form.Item>
                                    </Col>

                                    <Col xs={8} md={3} sm={3}>
                                      <Form.Item
                                        {...field}
                                        name={[field.name, "fromStoreId"]}
                                        rules={[
                                          { required: false, message: " " },
                                        ]}
                                      >
                                        <Select
                                          showSearch
                                          optionFilterProp="children"
                                          placeholder={_t("strFromStore")}
                                          autoComplete="off"
                                          size="small"
                                          onChange={(value) => {
                                            setProChange(true);
                                          }}
                                        >
                                          {storeChildsOptList}
                                        </Select>
                                      </Form.Item>
                                    </Col>
                                    <Col xs={8} md={3} sm={3}>
                                      <Form.Item
                                        {...field}
                                        name={[field.name, "toStoreId"]}
                                        rules={[
                                          { required: false, message: " " },
                                        ]}
                                      >
                                        <Select
                                          showSearch
                                          optionFilterProp="children"
                                          placeholder={_t("strToStore")}
                                          autoComplete="off"
                                          size="small"
                                          onChange={(value) => {
                                            setProChange(true);
                                          }}
                                        >
                                          {storeChildsOptList}
                                        </Select>
                                      </Form.Item>
                                    </Col>

                                    <Col xs={10} md={2} sm={2}>
                                      <Form.Item
                                        {...field}
                                        name={[field.name, "count"]}
                                        fieldKey={[field.fieldKey, "count"]}
                                        initialValues={1}
                                        rules={[
                                          { required: false, message: " " },
                                        ]}
                                        style={{ marginBottom: -25 }}
                                      >
                                        <InputNumber
                                          style={{ width: "100%" }}
                                          step={0.1}
                                          size="small"
                                          min={0}
                                          max={999999999999}
                                          placeholder={_t("strQuantity")}
                                          autoComplete="off"
                                          onChange={(value) => {
                                            setProChange(true);
                                          }}
                                        />
                                      </Form.Item>
                                    </Col>

                                    <Col xs={12} md={4} sm={4}>
                                      <Form.Item
                                        {...field}
                                        name={[field.name, "note"]}
                                        fieldKey={[field.fieldKey, "note"]}
                                        rules={[
                                          {
                                            required: false,
                                            message: _t("strFieldRequired"),
                                          },
                                        ]}
                                        style={{ marginBottom: -25 }}
                                      >
                                        <Input
                                          min={0}
                                          max={1000}
                                          size="small"
                                          placeholder={_t("strNote")}
                                          onChange={(value) => {
                                            setProChange(true);
                                          }}
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col
                                      xs={2}
                                      md={1}
                                      style={{ paddingTop: 6 }}
                                      sm={1}
                                    >
                                      <Form.Item
                                        hidden
                                        {...field}
                                        name={[
                                          field.name,
                                          "munfDetailManualIns",
                                        ]}
                                        fieldKey={[
                                          field.fieldKey,
                                          "munfDetailManualIns",
                                        ]}
                                      >
                                        <Input />
                                      </Form.Item>

                                      <Form.Item
                                        hidden
                                        {...field}
                                        name={[
                                          field.name,
                                          "munfDetailManualOuts",
                                        ]}
                                        fieldKey={[
                                          field.fieldKey,
                                          "munfDetailManualOuts",
                                        ]}
                                      >
                                        <Input />
                                      </Form.Item>

                                      <Space
                                        size="middle"
                                        style={{
                                          marginTop: 5,
                                          marginRight: 2,
                                          marginLeft: 2,
                                        }}
                                      >
                                        {permissions._MunfCompositeTemp_Get && (
                                          <a onClick={() => onEditINOUT(i)}>
                                            <EditTwoTone
                                              style={{ fontSize: 20 }}
                                            />
                                          </a>
                                        )}

                                        <Popconfirm
                                          title={_t("strSureToDelete")}
                                          onConfirm={() =>
                                            onDeleteList(i, remove)
                                          }
                                        >
                                          <DeleteTwoTone
                                            style={{ fontSize: 20 }}
                                            twoToneColor="#ff5252"
                                          />
                                        </Popconfirm>
                                      </Space>
                                    </Col>
                                    <Form.Item
                                      {...field}
                                      name={[field.name, "munfDetailManualIns"]}
                                      fieldKey={[
                                        field.fieldKey,
                                        "munfDetailManualIns",
                                      ]}
                                    ></Form.Item>
                                  </Row>
                                ))}

                                <Form.Item style={{ marginBottom: 0 }}>
                                  <Button
                                    type="dashed"
                                    style={{ marginTop: 5 }}
                                    onClick={() => {
                                      add();
                                    }}
                                    block
                                  >
                                    <PlusOutlined /> {_t("strAdd")}
                                  </Button>
                                </Form.Item>
                              </div>
                            );
                          }}
                        </Form.List>
                      </Card>
                    </Form.Item>
                  </Col>
                </TabPane>

                {showSimple && (
                  <TabPane
                    tab={_t("strManual")}
                    key="2"
                    disabled={manualTabDisabled}
                  >
                    <Row gutter={12} className="miniCardManufact">
                      <Col xs={24} md={24}>
                        <Form.Item label={_t("strTheInput")}>
                          <Card
                            bodyStyle={{ padding: 10 }}
                            className="scrollCard"
                          >
                            <Form.List name="munfDetailManualIns">
                              {(fields, { add, remove }) => {
                                return (
                                  <div>
                                    <Modal
                                      visible={visibilityInvItemList_IN}
                                      onCancel={() =>
                                        handleCancelInvItemList_IN()
                                      }
                                      title={_t("strUnit")}
                                      footer={null}
                                      destroyOnClose={true}
                                      width={1000}
                                    >
                                      <InvItemListSelectorForm
                                        onHandelData={(data) =>
                                          handelDataInvItemList_IN(data, add)
                                        }
                                        onSearchData={searchData_IN}
                                      />
                                    </Modal>
                                    {fields.map((field, i) => (
                                      <Row gutter={[8, 8]} key={field.key}>
                                        <Col hidden>
                                          <Form.Item
                                            {...field}
                                            defaultValue={0}
                                            name={[field.name, "id"]}
                                            fieldKey={[field.fieldKey, "id"]}
                                            hidden
                                          >
                                            <Input
                                              autoComplete="off"
                                              onChange={() => {
                                                setProChange(true);
                                              }}
                                            />
                                          </Form.Item>
                                        </Col>
                                        <Col hidden>
                                          <Form.Item
                                            {...field}
                                            defaultValue={0}
                                            name={[field.name, "itemId"]}
                                            fieldKey={[
                                              field.fieldKey,
                                              "itemId",
                                            ]}
                                            hidden
                                          >
                                            <Input
                                              autoComplete="off"
                                              onChange={() => {
                                                setProChange(true);
                                              }}
                                            />
                                          </Form.Item>
                                        </Col>
                                        <Col xs={1} md={1} sm={1}>
                                          <Form.Item
                                            style={{ textAlign: "center" }}
                                            fieldKey={[
                                              field.fieldKey,
                                              "rowCount",
                                            ]}
                                            style={{ marginBottom: -25 }}
                                          >
                                            <label>{i + 1}</label>
                                          </Form.Item>
                                        </Col>
                                        <Col xs={7} md={4} sm={4}>
                                          <Form.Item
                                            {...field}
                                            name={[field.name, "itemCode"]}
                                            fieldKey={[
                                              field.fieldKey,
                                              "itemCode",
                                            ]}
                                            style={{ marginBottom: -25 }}
                                          >
                                            <Search
                                              min={0}
                                              max={9999}
                                              placeholder={_t("strCode")}
                                              size="small"
                                              disabled={editSimple}
                                              autoComplete="off"
                                              onSearch={(v) =>
                                                codeSearch_IN(
                                                  v,
                                                  field.name,
                                                  add
                                                )
                                              }
                                            />
                                          </Form.Item>
                                        </Col>
                                        <Col xs={8} md={6} sm={6}>
                                          <Form.Item
                                            {...field}
                                            name={[field.name, "itemName"]}
                                            fieldKey={[
                                              field.fieldKey,
                                              "itemName",
                                            ]}
                                            style={{ marginBottom: -25 }}
                                            rules={[
                                              {
                                                required: false,
                                                message: _t("strFieldRequired"),
                                              },
                                            ]}
                                          >
                                            <Search
                                              placeholder={_t("strName")}
                                              autoComplete="off"
                                              size="small"
                                              disabled={editSimple}
                                              onSearch={(v) =>
                                                nameSearch_IN(
                                                  v,
                                                  field.name,
                                                  add
                                                )
                                              }
                                            />
                                          </Form.Item>
                                        </Col>
                                        <Col hidden>
                                          <Form.Item
                                            {...field}
                                            name={[field.name, "itemUnit_Maps"]}
                                            fieldKey={[
                                              field.fieldKey,
                                              "itemUnit_Maps",
                                            ]}
                                            hidden
                                          >
                                            <Input size="small" />
                                          </Form.Item>
                                        </Col>
                                        <Col xs={8} md={3} sm={3}>
                                          <Form.Item
                                            key={field.key}
                                            shouldUpdate={(prev, curr) =>
                                              prev.munfDetailManualIns
                                                ?.length !==
                                              curr.munfDetailManualIns?.length
                                            }
                                            fieldKey={[
                                              field.fieldKey,
                                              "itemUnit_MapId",
                                            ]}
                                            style={{ marginBottom: -25 }}
                                          >
                                            {({ getFieldValue }) => {
                                              const list = getFieldValue(
                                                "munfDetailManualIns"
                                              );
                                              const unitList =
                                                Array.isArray(list) &&
                                                list[field.name]?.itemUnit_Maps;
                                              return (
                                                <Form.Item
                                                  {...field}
                                                  name={[
                                                    field.name,
                                                    "itemUnit_MapId",
                                                  ]}
                                                  rules={[
                                                    {
                                                      required: false,
                                                      message: " ",
                                                    },
                                                  ]}
                                                >
                                                  <Select
                                                    showSearch
                                                    disabled={editSimple}
                                                    optionFilterProp="children"
                                                    placeholder={_t("strUnit")}
                                                    autoComplete="off"
                                                    size="small"
                                                    onChange={() => {
                                                      setProChange(true);
                                                      onChangelist_In();
                                                    }}
                                                  >
                                                    {unitList &&
                                                      unitList.map((item) => (
                                                        <Option
                                                          key={item.id}
                                                          value={item.id}
                                                        >
                                                          {item.itemUnitName}
                                                        </Option>
                                                      ))}
                                                  </Select>
                                                </Form.Item>
                                              );
                                            }}
                                          </Form.Item>
                                        </Col>
                                        <Col xs={10} md={2} sm={2}>
                                          <Form.Item
                                            {...field}
                                            name={[field.name, "qty"]}
                                            fieldKey={[field.fieldKey, "qty"]}
                                            rules={[
                                              { required: false, message: " " },
                                            ]}
                                            style={{ marginBottom: -25 }}
                                          >
                                            <InputNumber
                                              style={{ width: "100%" }}
                                              disabled={editSimple}
                                              min={0}
                                              step={0.1}
                                              size="small"
                                              placeholder={_t("strQuantity")}
                                              autoComplete="off"
                                              onChange={() => {
                                                setProChange(true);
                                                onChangelist_In();
                                              }}
                                            />
                                          </Form.Item>
                                        </Col>

                                        <Col xs={10} md={2} sm={2}>
                                          <Form.Item
                                            {...field}
                                            name={[field.name, "wasteRatio"]}
                                            fieldKey={[
                                              field.fieldKey,
                                              "wasteRatio",
                                            ]}
                                            rules={[
                                              { required: false, message: " " },
                                            ]}
                                            style={{ marginBottom: -25 }}
                                          >
                                            <InputNumber
                                              disabled={editSimple}
                                              style={{ width: "100%" }}
                                              step={0.1}
                                              size="small"
                                              min={0}
                                              max={100}
                                              placeholder={
                                                "%" + _t("strWasteRatio")
                                              }
                                              autoComplete="off"
                                              onChange={() => {
                                                setProChange(true);
                                                onChangelist_In();
                                              }}
                                            />
                                          </Form.Item>
                                        </Col>

                                        <Col xs={12} md={5} sm={5}>
                                          <Form.Item
                                            {...field}
                                            name={[field.name, "note"]}
                                            fieldKey={[field.fieldKey, "note"]}
                                            rules={[
                                              {
                                                required: false,
                                                message: _t("strFieldRequired"),
                                              },
                                            ]}
                                            style={{ marginBottom: -25 }}
                                          >
                                            <Input
                                              min={0}
                                              disabled={editSimple}
                                              max={9999}
                                              size="small"
                                              placeholder={_t("strNote")}
                                              autoComplete="off"
                                              onChange={() => {
                                                setProChange(true);
                                                onChangelist_In();
                                              }}
                                            />
                                          </Form.Item>
                                        </Col>
                                        <Col
                                          xs={2}
                                          md={1}
                                          style={{ paddingTop: 6 }}
                                          sm={1}
                                        >
                                          <Popconfirm
                                            disabled={editSimple}
                                            title={_t("strSureToDelete")}
                                            onConfirm={() =>
                                              onDeleteList(i, remove)
                                            }
                                          >
                                            <DeleteTwoTone
                                              style={{ fontSize: 20 }}
                                              twoToneColor="#ff5252"
                                            />
                                          </Popconfirm>
                                        </Col>
                                      </Row>
                                    ))}

                                    <Form.Item style={{ marginBottom: 0 }}>
                                      <Button
                                        disabled={editSimple}
                                        type="dashed"
                                        style={{ marginTop: 5 }}
                                        onClick={() => {
                                          add();
                                        }}
                                        block
                                      >
                                        <PlusOutlined /> {_t("strAdd")}
                                      </Button>
                                    </Form.Item>
                                  </div>
                                );
                              }}
                            </Form.List>
                          </Card>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Divider
                      dashed
                      style={{ marginBottom: -5, marginTop: -5 }}
                    />
                    <Row gutter={12} className="miniCardManufact">
                      <Col xs={24} md={24}>
                        <Form.Item label={_t("strTheOutput")}>
                          <Card
                            bodyStyle={{ padding: 10 }}
                            className="scrollCard"
                          >
                            <Form.List name="munfDetailManualOuts">
                              {(fields, { add, remove }) => {
                                return (
                                  <div>
                                    <Modal
                                      visible={visibilityInvItemList_Out}
                                      onCancel={() =>
                                        handleCancelInvItemList_Out()
                                      }
                                      title={_t("strUnit")}
                                      footer={null}
                                      destroyOnClose={true}
                                      width={1000}
                                    >
                                      <InvItemListSelectorForm
                                        onHandelData={(data) =>
                                          handelDataInvItemList_Out(data, add)
                                        }
                                        onSearchData={searchData_Out}
                                      />
                                    </Modal>

                                    {fields.map((field, i) => (
                                      <Row gutter={[8, 8]} key={field.key}>
                                        <Col hidden>
                                          <Form.Item
                                            {...field}
                                            defaultValue={0}
                                            name={[field.name, "id"]}
                                            fieldKey={[field.fieldKey, "id"]}
                                            hidden
                                          >
                                            <Input
                                              autoComplete="off"
                                              onChange={() => {
                                                setProChange(true);
                                              }}
                                            />
                                          </Form.Item>
                                        </Col>

                                        <Col hidden>
                                          <Form.Item
                                            {...field}
                                            defaultValue={0}
                                            name={[field.name, "itemId"]}
                                            fieldKey={[
                                              field.fieldKey,
                                              "itemId",
                                            ]}
                                            hidden
                                          >
                                            <Input
                                              autoComplete="off"
                                              onChange={() => {
                                                setProChange(true);
                                              }}
                                            />
                                          </Form.Item>
                                        </Col>

                                        <Col xs={1} md={1} sm={1}>
                                          <Form.Item
                                            style={{ textAlign: "center" }}
                                            fieldKey={[
                                              field.fieldKey,
                                              "rowCount",
                                            ]}
                                            style={{ marginBottom: -25 }}
                                          >
                                            <label>{i + 1}</label>
                                          </Form.Item>
                                        </Col>

                                        <Col xs={7} md={4} sm={4}>
                                          <Form.Item
                                            {...field}
                                            name={[field.name, "itemCode"]}
                                            fieldKey={[
                                              field.fieldKey,
                                              "itemCode",
                                            ]}
                                            style={{ marginBottom: -25 }}
                                          >
                                            <Search
                                              min={0}
                                              max={9999}
                                              disabled={editSimple}
                                              placeholder={_t("strCode")}
                                              size="small"
                                              autoComplete="off"
                                              onSearch={(v) =>
                                                codeSearch_Out(
                                                  v,
                                                  field.name,
                                                  add
                                                )
                                              }
                                            />
                                          </Form.Item>
                                        </Col>

                                        <Col xs={8} md={6} sm={6}>
                                          <Form.Item
                                            {...field}
                                            name={[field.name, "itemName"]}
                                            fieldKey={[
                                              field.fieldKey,
                                              "itemName",
                                            ]}
                                            style={{ marginBottom: -25 }}
                                            rules={[
                                              {
                                                required: false,
                                                message: _t("strFieldRequired"),
                                              },
                                            ]}
                                          >
                                            <Search
                                              disabled={editSimple}
                                              placeholder={_t("strName")}
                                              autoComplete="off"
                                              size="small"
                                              onSearch={(v) =>
                                                nameSearch_Out(
                                                  v,
                                                  field.name,
                                                  add
                                                )
                                              }
                                            />
                                          </Form.Item>
                                        </Col>

                                        <Col hidden>
                                          <Form.Item
                                            {...field}
                                            name={[field.name, "itemUnit_Maps"]}
                                            fieldKey={[
                                              field.fieldKey,
                                              "itemUnit_Maps",
                                            ]}
                                            hidden
                                          >
                                            <Input size="small" />
                                          </Form.Item>
                                        </Col>

                                        <Col xs={8} md={3} sm={3}>
                                          <Form.Item
                                            key={field.key}
                                            shouldUpdate={(prev, curr) =>
                                              prev.munfDetailManualOuts
                                                ?.length !==
                                              curr.munfDetailManualOuts?.length
                                            }
                                            fieldKey={[
                                              field.fieldKey,
                                              "itemUnit_MapId",
                                            ]}
                                            style={{ marginBottom: -25 }}
                                          >
                                            {({ getFieldValue }) => {
                                              const list = getFieldValue(
                                                "munfDetailManualOuts"
                                              );
                                              const unitList =
                                                Array.isArray(list) &&
                                                list[field.name]?.itemUnit_Maps;
                                              return (
                                                <Form.Item
                                                  {...field}
                                                  name={[
                                                    field.name,
                                                    "itemUnit_MapId",
                                                  ]}
                                                  rules={[
                                                    {
                                                      required: false,
                                                      message: " ",
                                                    },
                                                  ]}
                                                >
                                                  <Select
                                                    showSearch
                                                    disabled={editSimple}
                                                    optionFilterProp="children"
                                                    placeholder={_t("strUnit")}
                                                    autoComplete="off"
                                                    size="small"
                                                    onChange={() => {
                                                      setProChange(true);
                                                      onChangelist_Out();
                                                    }}
                                                  >
                                                    {unitList &&
                                                      unitList.map((item) => (
                                                        <Option
                                                          key={item.id}
                                                          value={item.id}
                                                        >
                                                          {item.itemUnitName}
                                                        </Option>
                                                      ))}
                                                  </Select>
                                                </Form.Item>
                                              );
                                            }}
                                          </Form.Item>
                                        </Col>

                                        <Col xs={10} md={2} sm={2}>
                                          <Form.Item
                                            {...field}
                                            name={[field.name, "qty"]}
                                            fieldKey={[field.fieldKey, "qty"]}
                                            rules={[
                                              { required: false, message: " " },
                                            ]}
                                            style={{ marginBottom: -25 }}
                                          >
                                            <InputNumber
                                              disabled={editSimple}
                                              style={{ width: "100%" }}
                                              min={0}
                                              step={0.1}
                                              size="small"
                                              placeholder={_t("strQuantity")}
                                              autoComplete="off"
                                              onChange={() => {
                                                setProChange(true);
                                                onChangelist_Out();
                                              }}
                                            />
                                          </Form.Item>
                                        </Col>

                                        <Col xs={10} md={2} sm={2}>
                                          <Form.Item
                                            {...field}
                                            name={[
                                              field.name,
                                              "distributionRatio",
                                            ]}
                                            fieldKey={[
                                              field.fieldKey,
                                              "distributionRatio",
                                            ]}
                                            rules={[
                                              { required: false, message: " " },
                                            ]}
                                            style={{ marginBottom: -25 }}
                                          >
                                            <InputNumber
                                              disabled={editSimple}
                                              style={{ width: "100%" }}
                                              step={0.1}
                                              min={0}
                                              max={100}
                                              size="small"
                                              placeholder={
                                                "%" + _t("strDistribution")
                                              }
                                              autoComplete="off"
                                              onChange={() => {
                                                setProChange(true);
                                                onChangelist_Out();
                                              }}
                                            />
                                          </Form.Item>
                                        </Col>

                                        <Col xs={12} md={5} sm={5}>
                                          <Form.Item
                                            {...field}
                                            name={[field.name, "note"]}
                                            fieldKey={[field.fieldKey, "note"]}
                                            rules={[
                                              {
                                                required: false,
                                                message: _t("strFieldRequired"),
                                              },
                                            ]}
                                            style={{ marginBottom: -25 }}
                                          >
                                            <Input
                                              disabled={editSimple}
                                              min={0}
                                              max={9999}
                                              size="small"
                                              placeholder={_t("strNote")}
                                              autoComplete="off"
                                              onChange={() => {
                                                setProChange(true);
                                                onChangelist_Out();
                                              }}
                                            />
                                          </Form.Item>
                                        </Col>

                                        <Col
                                          xs={2}
                                          md={1}
                                          sm={1}
                                          style={{ paddingTop: 6 }}
                                        >
                                          <Popconfirm
                                            disabled={editSimple}
                                            title={_t("strSureToDelete")}
                                            onConfirm={() =>
                                              onDeleteList(i, remove)
                                            }
                                          >
                                            <DeleteTwoTone
                                              style={{ fontSize: 20 }}
                                              twoToneColor="#ff5252"
                                            />
                                          </Popconfirm>
                                        </Col>
                                      </Row>
                                    ))}

                                    <Form.Item style={{ marginBottom: 0 }}>
                                      <Button
                                        disabled={editSimple}
                                        type="dashed"
                                        style={{ marginTop: 5 }}
                                        onClick={() => {
                                          add();
                                        }}
                                        block
                                      >
                                        <PlusOutlined /> {_t("strAdd")}
                                      </Button>
                                    </Form.Item>
                                  </div>
                                );
                              }}
                            </Form.List>
                          </Card>
                        </Form.Item>
                      </Col>
                    </Row>
                  </TabPane>
                )}
              </Tabs>
            </Row>

            <Row gutter={12} className="frFooter">
              <Col lg={12} md={12} xs={24} style={{ textAlign: "right" }}>
                <Space size="large">
                  {showSaveButton && (
                    <Form.Item>
                      <Button
                        onClick={() => form.submit()}
                        type="primary"
                        style={{ width: 100 }}
                      >
                        {_t("strSave")}
                      </Button>
                    </Form.Item>
                  )}
                  {showNewButton && (
                    <Form.Item>
                      <Button
                        style={{ width: 100 }}
                        type="dashed"
                        onClick={() => onNew(false)}
                      >
                        {_t("strNew")}
                      </Button>
                    </Form.Item>
                  )}
                  {permissions._Munf_Delete && (
                    <Form.Item>

                      <Space>
                        <Button
                          style={{ width: 100 }}
                          type="dashed"
                          onClick={() =>
                            setVisibleDrawer(true)
                          }
                          disabled={deleteDis}
                        >
                          {_t("strPrint")}
                        </Button>


                        <Popconfirm
                          title={_t("strSureToDelete")}
                          placement={_t("ntfoPlacement")}
                          onConfirm={() => onDelete()}
                          disabled={deleteDis}
                        >
                          <Button
                            style={{ width: 100 }}
                            type="dashed"
                            danger
                            disabled={deleteDis}
                          >
                            {_t("strDelete")}
                          </Button>
                        </Popconfirm>


                      </Space>

                    </Form.Item>
                  )}
                </Space>
              </Col>
            </Row>
          </Form>
        </Spin>
      </div>
    </React.Fragment>
  );
};

export default ManufacturingOrdersForm;
