import { _URL } from "../../config.json";
import http from "../http";

const apiEndpoint = _URL + "/api/UserRole";

export function getUserRoleById(Id) {
  return http.get(`${apiEndpoint}/${Id}`);
}

export function editUserRole(obj) {
  if (obj.id !== "new") {
    return http.put(apiEndpoint, obj);
  } else {
    obj.id = guidgenerator();
    return http.post(apiEndpoint, obj);
  }
}

export function deleteUserRole(Id) {
  return http.delete(`${apiEndpoint}/${Id}`);
}

export function getAllUserRole() {
  return http.get(`${apiEndpoint}`);
}

function guidgenerator() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 || 0,
      v = c === "x" ? r : r & 0x3 || 0x8;
    return v.toString(16);
  });
}
