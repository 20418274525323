import React from "react";
import { Popover, Row, Col, Button, Divider, Space, Tooltip } from "antd";
import { Container, Draggable } from "react-smooth-dnd";
import sortArrayAfterDrop from "./sortArrayAfterDrop";
import _t from "../../../languages/translate";
import {
  MoreOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  SettingOutlined,
} from "@ant-design/icons";

const SortColumns = ({ columns, onHideClick, onSort, onClearCash }) => {
  return (
    <Tooltip placement="topLeft" title={_t("strSortColumns")} color="#2db7f5">
      <Popover
        placement="bottomRight"
        title={
          <Button onClick={onClearCash} type="link">
            {_t("strDefaultMode")}
          </Button>
        }
        trigger="click"
        overlayClassName="sort-cols-wp"
        content={
          <Container
            onDrop={(e) => {
              const results = sortArrayAfterDrop(columns, e);
              onSort(results);
            }}
          >
            {columns.map((item) => (
              <Draggable key={item.key}>
                <Row
                  align="middle"
                  gutter={16}
                  className={!item.hidden ? "col-visible" : "col-hidden"}
                >
                  <Col flex="28px">
                    <MoreOutlined />
                  </Col>
                  <Col flex="auto" style={{ cursor: "move" }}>
                    {item.title}
                  </Col>
                  <Col flex="48px">
                    <Button
                      className={
                        !item.hidden ? "delete-btn" : "primary-text-btn"
                      }
                      type="link"
                      onClick={() => onHideClick(!!!item.hidden, item.key)}
                      icon={
                        !item.hidden ? (
                          <EyeOutlined className="eyeTrue" />
                        ) : (
                          <EyeInvisibleOutlined className="eyeFalse" />
                        )
                      }
                    />
                  </Col>
                  <Divider style={{ marginBottom: 0, marginTop: 0 }} dashed />
                </Row>
              </Draggable>
            ))}
          </Container>
        }
      >
        <SettingOutlined style={{ fontSize: 16, marginTop: 5 }} />
      </Popover>
    </Tooltip>
  );
};

export default SortColumns;
